<template>
  <DefaultDialog v-model="internalModelOpen">
    <template #header
      ><span class="title-holder">{{ name }}</span></template
    >
    <template #content
      ><span class="content-holder"
        >Are u sure you want to delete this item?</span
      ></template
    >
    <template #footer
      ><v-spacer></v-spacer>
      <v-btn @click="$emit('cancel')">Cancel</v-btn>
      <v-btn color="error" :disabled="deleting" @click="$emit('delete')"
        >Delete</v-btn
      ></template
    >
  </DefaultDialog>
</template>

<script setup lang="ts">
import DefaultDialog from "@/components/dialogs/DefaultDialog.vue.html";
import { ref, watch } from "vue";

interface CustomsInvoiceFooterTextProps {
  modelOpen: boolean;
  name?: string | null | undefined;
  deleting: boolean;
}

const props = defineProps<CustomsInvoiceFooterTextProps>();
const emit = defineEmits(["delete", "cancel", "update:model-open"]);

const internalModelOpen = ref(props.modelOpen);

watch(
  () => props.modelOpen,
  (newVal) => {
    if (internalModelOpen.value != newVal) {
      internalModelOpen.value = newVal;
    }
  },
);

watch(internalModelOpen, (newVal) => {
  emit("update:model-open", newVal);
});
</script>
<style lang="scss" scoped>
.title-holder {
  display: inline-block;
  min-height: 32px;
}
.content-holder {
  font-weight: bold;
}
</style>
