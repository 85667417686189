<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="pa-6">
        <v-card class="pa-6">
          <v-toolbar class="elevation-0" dense>
            <v-toolbar-title>Payment terms</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="openDialog(-1)">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-toolbar>
          <v-simple-table class="payment-table">
            <template #default>
              <thead>
                <tr>
                  <th>Payment terms (DE)</th>
                  <th>Payment terms (EN)</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(model, index) in models" :key="model.id">
                  <td>{{ model.text }}</td>
                  <td>{{ model.textEnglish }}</td>
                  <td>
                    <v-btn icon @click="openDialog(index)">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn icon @click="openDeleteDialog(index)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
              <PaymentTermDialog
                :key="timeStampKey"
                :model-open="showDialog"
                :model-value="currentModel"
                :loading="loading"
                @cancel="showDialog = false"
                @save="saveItem"
              />
              <DeleteDialog
                :model-open="showDeleteDialog"
                :name="currentModel.text"
                :deleting="deleting"
                @cancel="showDeleteDialog = false"
                @delete="deleteItem(currentModel.id)"
                @update:model-open="(value) => (showDeleteDialog = value)"
              />
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import {
  PaymentTermsCustomsApi,
  PaymentTermsCustomsViewModel,
} from "@/openapi";
import PaymentTermDialog from "@/components/dialogs/PaymentTermDialog.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import { emitErrorWithFallback } from "@/event-bus";

const defaultModel = {
  id: 0,
  text: null,
  htmlTemplate: null,
  textEnglish: null,
  htmlTemplateEnglish: null,
  showInCustoms: false,
  showInCourierInvoice: false,
  showInPaymentReminder: false,
};
const api = new PaymentTermsCustomsApi(undefined, "");
const loading = ref(false);
const deleting = ref(false);
const showDialog = ref(false);
const showDeleteDialog = ref(false);
const models = ref([] as PaymentTermsCustomsViewModel[]);
const currentModel = ref<PaymentTermsCustomsViewModel>(defaultModel);
const timeStampKey = ref(Date.now());

const openDialog = (index: number) => {
  timeStampKey.value = Date.now();
  if (index > -1) {
    currentModel.value = models.value[index];
  } else {
    currentModel.value = { ...defaultModel };
  }
  showDialog.value = true;
};
const openDeleteDialog = (index: number) => {
  currentModel.value = models.value[index];
  showDeleteDialog.value = true;
};
const deleteItem = (id: number) => {
  deleting.value = true;
  try {
    api.deleteCustomsPaymentTerms(id).then(() => {
      deleting.value = false;
      showDeleteDialog.value = false;
      setModels();
    });
  } catch (e: unknown) {
    emitErrorWithFallback("Something went wrong");
    setModels();
  }
};
const saveItem = (model: PaymentTermsCustomsViewModel) => {
  loading.value = true;
  try {
    api.saveCustomsPaymentTerms(model).then(() => {
      loading.value = false;
      showDialog.value = false;
      setModels();
    });
  } catch (e: unknown) {
    emitErrorWithFallback("Something went wrong");
    setModels();
  }
};

const setModels = () => {
  api.getCustomsPaymentTerms().then((response) => {
    models.value = response.data;
  });
};
onMounted(async () => {
  setModels();
});
</script>

<style lang="scss" scoped>
.payment-table {
  tr {
    td:nth-child(3) {
      text-align: end;
      padding-right: 0;
    }
  }
}
</style>
