import { Component, Vue, Prop } from 'vue-property-decorator';
import { CustomsExportInformationApi, CustomsExportInformationViewModel } from '../../openapi';

@Component({})
export default class AddRouteDialog extends Vue {
    @Prop({default: false})
    value!: Boolean;

    @Prop({default: false})
    isLoading!: Boolean;

    @Prop({default: 'Add route'})
    title!: String

    @Prop({default: 'Confirm'})
    confirmButtonText!: String

    @Prop({default: 'Close'})
    closeButtonText!: String

    rules = {
        required: (value: string) =>
            (value && !!value.toString()) || 'Required'
    };
    valid = true;
    isRetrievingRoutes = false;
    routeId = 0;
    exportInformationItems: CustomsExportInformationViewModel[] = [];

    customsExportInformationApi: CustomsExportInformationApi = new CustomsExportInformationApi(undefined, '')

    created() {
        this.getExportInformation();
    }

    getExportInformation() {
        this.exportInformationItems = [];
        this.isRetrievingRoutes = true;
        this.customsExportInformationApi.getCustomsExportInformation(
            100,
            1,
            '',
            'ASC'
        )
            .then((result) => {
                this.exportInformationItems = result.data.items as CustomsExportInformationViewModel[];
                this.isRetrievingRoutes = false;
            })
            .catch(() => {
                this.displayError('Something went wrong while retrieving the routes.')
            })
    }

    displayError(errorMessage: string) {
        this.$emit('errorOccured', errorMessage);
    }
}