<template>
  <Fragment>
    <v-data-table
      :headers="headers"
      :items="importFiles"
      :items-per-page="20"
      :footer-props="{ itemsPerPageOptions: [5, 10, 20, -1] }"
      :loading="loading"
    >
      <template #item.download="{ item }">
        <v-btn @click="downloadFile(item.id)" color="primary">Download</v-btn>
        &nbsp;
        <v-btn @click="openDeleteFileDialog(item.id)" color="error"
          ><v-icon color="white">mdi-delete-forever</v-icon></v-btn
        >
      </template>
      <template #item.downloadedAt="{ item }">
        {{ formatDateTime(item.downloadedAt) }}
      </template>
      <template #item.mawb="{ item }">
        <v-autocomplete
          v-model.number="item.mawb"
          :items="mawbs"
          label="MAWB"
          item-text="value"
          item-value="key"
          :disabled="!item.isEditable"
        ></v-autocomplete>
      </template>
      <template #item.connect="{ item }">
        <v-btn
          @click="openConfirmConnectDialog(item)"
          color="primary"
          :disabled="
            !item.isEditable || !item.mawb || item.mawb == item.oldMawb
          "
          :loading="item.loading"
          >Connect</v-btn
        >
      </template>

      <template #item.mrn="{ item }">
        <v-btn
          @click="openEnterMrnDialog(item)"
          color="primary"
          :loading="item.loading"
          :disabled="!item.isEditable"
          >Enter MRN</v-btn
        >
      </template>
    </v-data-table>
    <v-snackbar
      v-model="saveSuccessful"
      timeout="4000"
      top
      right
      color="success"
    >
      Save successful
    </v-snackbar>
    <v-snackbar
      v-model="deleteSuccessful"
      timeout="4000"
      top
      right
      color="success"
    >
      Delete successful
    </v-snackbar>
    <v-dialog
      v-if="confirmDeleteDialog && currentDeleteFile"
      :value="true"
      persistent
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 error white--text">
          Are you sure?
        </v-card-title>
        <v-card-text>
          Are you sure you want to delete file '{{
            currentDeleteFile.fileName
          }}'? can't be undone.
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn text @click="cancelFileDeletion"> Cancel </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="deleteFile"> Delete </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ValidationObserver slim v-slot="{ invalid }">
      <DefaultDialog v-if="currentItem" v-model="showEnterMrnDialog">
        <template #header>Enter MRN</template>
        <template #content>
          <ValidationProvider name="MRN" rules="required" v-slot="{ errors }">
            <v-text-field
              v-model="currentItem.mrn"
              label="MRN"
              :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
        </template>
        <template #footer>
          <v-spacer></v-spacer>
          <v-btn
            @click="closeEnterMrnDialog"
            :loading="currentItem.loading"
            text
            small
            >Close</v-btn
          >
          <v-btn
            @click="confirmSetMrn"
            :disabled="invalid"
            :loading="currentItem.loading"
            text
            small
            >Set MRN</v-btn
          >
        </template>
      </DefaultDialog>
    </ValidationObserver>

    <DefaultDialog v-model="showConfirmDialog">
      <template #header>Confirm current action</template>
      <template #content>
        <p>
          Are you sure you want to proceed with this action? This action can't
          be undone.
        </p>
      </template>
      <template #footer>
        <v-spacer></v-spacer>
        <v-btn @click="cancelConfirm" text small>Close</v-btn>
        <v-btn @click="confirm" text small>Confirm</v-btn>
      </template>
    </DefaultDialog>
  </Fragment>
</template>

<script setup lang="ts">
import { emitError } from "@/event-bus";
import moment from "moment";
import {
  FileImportApi,
  FileImportViewModel,
  KeyValueItem,
} from "../../openapi";
import DefaultDialog from "@/components/dialogs/DefaultDialog.vue.html";
import { onBeforeMount, ref } from "vue";
import { DataTableHeader } from "vuetify";
import { ToolbarItem } from "@/models/ToolbarItem";
import { Fragment } from "vue-frag";

interface Enrichment {
  loading: boolean;
  oldMawb?: number | null;
  mrn: string | null;
}

type EnrichedFileImportViewModel = FileImportViewModel & Enrichment;

const emits = defineEmits<{
  (e: "PageInfoReceived", title: string, items: ToolbarItem[]): void;
}>();

const api = new FileImportApi(undefined, "");
const importFiles = ref<EnrichedFileImportViewModel[]>([]);
const saveSuccessful = ref(false);
const deleteSuccessful = ref(false);
const currentDeleteFile = ref<EnrichedFileImportViewModel | null>(null);
const confirmDeleteDialog = ref(false);
const loading = ref(false);
const mawbs = ref<KeyValueItem[]>([]);

const showEnterMrnDialog = ref(false);
const currentItem = ref<EnrichedFileImportViewModel | null>(null);

const showConfirmDialog = ref(false);
const confirmCallback = ref<Function | null>(null);
const cancelConfirmCallback = ref<Function | null>(null);

const headers = ref<DataTableHeader[]>([
  {
    text: "Actions",
    value: "download",
    sortable: false,
  },
  {
    text: "Customer",
    value: "customer",
  },
  {
    text: "File name",
    value: "fileName",
  },
  {
    text: "Downloaded at",
    value: "downloadedAt",
  },
  {
    text: "MRN",
    value: "mrn",
    sortable: false,
  },
  {
    text: "MAWB",
    value: "mawb",
  },
  {
    text: "Connect",
    value: "connect",
    sortable: false,
  },
]);

const loadData = async () => {
  loading.value = true;
  try {
    const response = await api.getImportFileList();
    importFiles.value = response.data.map((c) => ({
      ...c,
      loading: false,
      oldMawb: c.mawb,
      mrn: null,
    }));
  } catch {
    emitError("Something went wrong while loading the data.");
  }
  loading.value = false;
};

const getMawbs = async () => {
  try {
    const response = await api.getMawbSelections();
    mawbs.value = response.data;
  } catch {
    emitError("Something went wrong while retrieving the MAWBs");
  }
};

const downloadFile = async (id: number) => {
  loading.value = true;
  try {
    const response = await api.downloadFile(id);
    const fileName = response.headers["content-disposition"]
      .split("filename=")[1]
      .split(";")[0];
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch {
    emitError("Something went wrong while downloading the file");
  }
  loading.value = false;
};

const openDeleteFileDialog = (id: number) => {
  const existingImportFile = importFiles.value.find((value) => value.id === id);
  if (!existingImportFile) {
    return;
  }

  currentDeleteFile.value = existingImportFile;
  confirmDeleteDialog.value = true;
};

const cancelFileDeletion = () => {
  currentDeleteFile.value = null;
  confirmDeleteDialog.value = false;
};

const deleteFile = async () => {
  loading.value = true;

  try {
    const response = await api.deleteFile(currentDeleteFile.value?.id);
    if (response) {
      const existingImportFileIndex = importFiles.value.findIndex(
        (value) => value.id === currentDeleteFile.value?.id,
      );
      if (existingImportFileIndex != -1) {
        importFiles.value.splice(existingImportFileIndex, 1);
      }
      deleteSuccessful.value = true;
      currentDeleteFile.value = null;
      confirmDeleteDialog.value = false;
    } else {
      emitError("An error occured while deleting the file. Please try again");
    }
  } catch {
    emitError("An error occured while deleting the file. Please try again");
  }
  loading.value = false;
};

const openEnterMrnDialog = (item: EnrichedFileImportViewModel) => {
  showEnterMrnDialog.value = true;
  currentItem.value = item;
};

const closeEnterMrnDialog = () => {
  showEnterMrnDialog.value = false;
  currentItem.value!.mrn = "";
  currentItem.value = null;
};

const confirmSetMrn = () => {
  showConfirmDialog.value = true;
  confirmCallback.value = setMrn;
  cancelConfirmCallback.value = cancelSetMrn;
};

const setMrn = async () => {
  loading.value = true;
  currentItem.value!.loading = true;
  try {
    await api.updateImportFileMRN(
      currentItem.value!.mrn!,
      currentItem.value!.id,
    );
    showEnterMrnDialog.value = false;
    currentItem.value!.loading = false;
    currentItem.value = null;
    closeConfirm();
    await loadData();
  } catch {
    currentItem.value!.loading = false;
    emitError("Something went wrong while setting the MRN.");
  }
  loading.value = false;
};

const cancelSetMrn = () => {
  currentItem.value!.loading = false;
  closeConfirm();
};

const formatDateTime = (datetime: string) => {
  if (!datetime) {
    return "";
  }

  const dateTimeMoment = moment(datetime);
  return dateTimeMoment.format("YYYY-MM-DD HH:mm:ss");
};

const confirm = () => {
  confirmCallback.value!();
};

const cancelConfirm = () => {
  cancelConfirmCallback.value!();
};

const closeConfirm = () => {
  showConfirmDialog.value = false;
  confirmCallback.value = null;
  cancelConfirmCallback.value = null;
};

const openConfirmConnectDialog = (item: EnrichedFileImportViewModel) => {
  showConfirmDialog.value = true;
  currentItem.value = item;
  confirmCallback.value = connect;
  cancelConfirmCallback.value = cancelConnect;
};

const connect = async () => {
  const item = currentItem.value!;
  if (!item.mawb) {
    emitError("Selected MAWB is not valid");
    return;
  }

  loading.value = true;
  item.loading = true;
  try {
    await api.updateImportFileMAWB(item.mawb, item.id!);
    item.loading = false;
    currentItem.value = null;
    closeConfirm();
    await loadData();
  } catch {
    emitError("Something went wrong while connecting to the MAWB.");
    item.loading = false;
  }

  loading.value = false;
};

const cancelConnect = () => {
  currentItem.value!.loading = false;
  currentItem.value = null;
  closeConfirm();
};

onBeforeMount(() => {
  emits("PageInfoReceived", "Import shipment files", [
    {
      callback: async () => await loadData(),
      icon: "mdi-refresh",
      tooltipText: "Refresh overview",
    },
  ]);

  loadData();
  getMawbs();
});
</script>
