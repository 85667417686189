<template>
  <Fragment>
    <ValidationObserver slim ref="observer" v-slot="{ invalid }">
      <DefaultDialog
        :value="value"
        @input="$emit('input', $event)"
        :loading="loading"
      >
        <template v-slot:header> Edit T1/T2 definition </template>
        <template v-slot:content>
          <v-form ref="refForm">
            <v-container>
              <v-row>
                <v-col cols="6">
                  <ValidationProvider
                    rules="required"
                    name="Name"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="form.name"
                      label="Name"
                      :error-messages="errors"
                      :disabled="loading"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="6">
                  <ValidationProvider
                    rules="required"
                    name="Street"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="form.street"
                      label="Street"
                      :error-messages="errors"
                      :disabled="loading"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="6">
                  <ValidationProvider
                    rules="required"
                    name="Postal code"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="form.postalCode"
                      label="Postal code"
                      :error-messages="errors"
                      :disabled="loading"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="6">
                  <ValidationProvider
                    rules="required"
                    name="City"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="form.city"
                      label="City"
                      :error-messages="errors"
                      :disabled="loading"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="6">
                  <ValidationProvider
                    rules="required"
                    name="Country"
                    v-slot="{ errors }"
                  >
                    <v-autocomplete
                      v-model.number="form.country"
                      :items="countries"
                      :error-messages="errors"
                      item-value="id"
                      item-text="code"
                      label="Country"
                      :disabled="loading"
                      clearable
                      hide-details
                    ></v-autocomplete>
                  </ValidationProvider>
                </v-col>
                <v-col cols="6">
                  <ValidationProvider name="EORI number" v-slot="{ errors }">
                    <v-text-field
                      v-model="form.eoriNumber"
                      label="EORI number"
                      :error-messages="errors"
                      :disabled="loading"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="6">
                  <ValidationProvider
                    name="EORI branch number"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="form.eoriBranchNumber"
                      label="EORI branch number"
                      :error-messages="errors"
                      :disabled="loading"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="6">
                  <ValidationProvider name="Loading code" v-slot="{ errors }">
                    <v-text-field
                      v-model="form.loadingCode"
                      label="Loading code"
                      :error-messages="errors"
                      :disabled="loading"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="form.unloadingCode"
                    label="Unloading code"
                    :disabled="loading"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <ValidationProvider
                    rules="required"
                    name="Responsible customs office"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="form.responsibleCustomsOffice"
                      label="Responsible customs office"
                      :error-messages="errors"
                      :disabled="loading"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <div class="consignee-selector">
                    <v-autocomplete
                      v-model="selectedConsignee"
                      :items="filteredConsignees"
                      item-value="key"
                      item-text="value"
                    ></v-autocomplete>
                    <v-btn
                      color="success"
                      :disabled="!selectedConsignee"
                      @click="addRoute"
                      >Add <v-icon>mdi-plus</v-icon></v-btn
                    >
                  </div>
                </v-col>
                <v-col cols="12">
                  <ul class="transit-routes">
                    <li v-for="route in form.routes" class="transit-route">
                      <span class="transit-route__name"
                        >Name:
                        <span> {{ route.name }} </span>
                      </span>
                    </li>
                  </ul>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </template>
        <template v-slot:footer>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            :disabled="loading"
            @click="$emit('input', false), $emit('close')"
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            text
            :loading="loading"
            :disabled="invalid"
            @click="saveTransitDeclarationDefinitionDetail"
          >
            Save
          </v-btn>
        </template>
      </DefaultDialog>
    </ValidationObserver>

    <v-snackbar v-model="showSnackbar" color="success" class="text-center"
      >Successfully saved "{{ form.name }}"</v-snackbar
    >
  </Fragment>
</template>

<script setup lang="ts">
import { emitError } from "@/event-bus";
import {
  CountryOfOriginViewModel,
  CustomsTransitDeclarationDefinitionApi,
  DataApi,
  Int32StringKeyValueItem,
  TransitDeclarationDefinitionRoute,
  TransitDeclarationDefinitionRouteEdit,
} from "@/openapi";
import DefaultDialog from "@/components/dialogs/DefaultDialog.vue.html";
import { DataAction } from "@/store/dataModule";
import { computed, onMounted, ref, watch } from "vue";
import store from "@/store";

interface TransitDeclarationDefinitionProps {
  value: boolean;
  id?: number | null;
}

interface TransitDeclarationDefinitionForm {
  name: string;
  street: string;
  postalCode: string;
  city: string | null;
  country: number | null;
  eoriNumber: string;
  eoriBranchNumber: string;
  loadingCode: string;
  unloadingCode: string;
  responsibleCustomsOffice: string;
  routes: TransitDeclarationDefinitionRoute[];
}

const api = new CustomsTransitDeclarationDefinitionApi(undefined, "");
const dataApi = new DataApi(undefined, "");

const props = defineProps<TransitDeclarationDefinitionProps>();
const emits = defineEmits(["saved"]);
const defaultFormValues: TransitDeclarationDefinitionForm = {
  name: "",
  street: "",
  postalCode: "",
  city: null,
  country: null,
  eoriNumber: "",
  eoriBranchNumber: "",
  loadingCode: "",
  unloadingCode: "",
  responsibleCustomsOffice: "",
  routes: [],
};

const loading = ref(false);

const form = ref<TransitDeclarationDefinitionForm>({ ...defaultFormValues });
const showSnackbar = ref(false);

const consignees = ref<Int32StringKeyValueItem[]>([]);
const selectedConsignee = ref<number | null>();
const refForm = ref<(Vue & { resetValidation: () => boolean }) | null>(null);

const filteredConsignees = computed(() =>
  consignees.value.filter(
    (c) =>
      props.id !== c.key &&
      !form.value.routes.some((r) => r.consigneeId === c.key),
  ),
);

const countries = computed(
  (): CountryOfOriginViewModel[] => store.getters.countriesOfOrigin,
);

const getTransitDeclarationDefinitionDetail = async (id: number) => {
  loading.value = true;
  try {
    const response = await api.getTransitDeclarationDefinition(id);
    form.value.name = response.data.name!;
    form.value.street = response.data.street!;
    form.value.postalCode = response.data.postalCode!;
    form.value.city = response.data.city!;
    form.value.country = response.data.country!;
    form.value.eoriNumber = response.data.eoriNumber!;
    form.value.eoriBranchNumber = response.data.eoriBranchNumber!;
    form.value.loadingCode = response.data.loadingCode!;
    form.value.unloadingCode = response.data.unloadingCode!;
    form.value.responsibleCustomsOffice =
      response.data.responsibleCustomsOffice!;
    form.value.routes = response.data.routes ?? [];
  } catch {
    emitError("Something went wrong while retrieving the information.");
  }
  loading.value = false;
};

const saveTransitDeclarationDefinitionDetail = async () => {
  loading.value = true;
  try {
    await api.saveTransitDeclarationDefinition({
      id: props.id,
      name: form.value.name,
      street: form.value.street,
      postalCode: form.value.postalCode,
      city: form.value.city!,
      country: form.value.country!,
      eoriNumber: form.value.eoriNumber,
      eoriBranchNumber: form.value.eoriBranchNumber,
      loadingCode: form.value.loadingCode,
      unloadingCode: form.value.unloadingCode,
      responsibleCustomsOffice: form.value.responsibleCustomsOffice,
      routes: form.value.routes.map(
        (c) =>
          ({
            id: c.id,
            consigneeId: c.consigneeId,
            customsOffice: c.customsOffice,
            deliveryDays: c.deliveryDays,
          }) as TransitDeclarationDefinitionRouteEdit,
      ),
    });
    showSnackbar.value = true;
    emits("saved");
  } catch {
    emitError("Something went wrong while saving the entered information.");
  }
  loading.value = false;
};

const getConsignees = async () => {
  loading.value = true;
  try {
    const response =
      await dataApi.getTransitDeclarationDefinitionConsignees(false);
    consignees.value = response.data;
  } catch {
    emitError("Something went wrong while receiving the consignees");
  }

  loading.value = false;
};

const addRoute = () => {
  if (!selectedConsignee.value) {
    return;
  }
  form.value.routes.push({
    consigneeId: selectedConsignee.value,
    name: filteredConsignees.value.find(
      (c) => selectedConsignee.value! === c.key,
    )?.value,
  });
  selectedConsignee.value = null;
};

watch(
  () => props.value,
  (newValue: boolean, oldVal: boolean) => {
    if (newValue == false) {
      form.value = { ...defaultFormValues };
      refForm.value!.resetValidation();
    }
  },
);

watch(
  () => props.id,
  (newVal: number | null, oldVal: number | null) => {
    if (newVal == null) {
      form.value = { ...defaultFormValues };
    } else {
      getTransitDeclarationDefinitionDetail(newVal);
    }
  },
  { immediate: true },
);

onMounted(async () => {
  await Promise.all([
    getConsignees(),
    store.dispatch(DataAction.FetchCountriesOfOrigin),
  ]);
});
</script>

<style scoped lang="scss">
.transit-routes {
  margin: 0;
  padding-left: 0;
}

.transit-route {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;

  &__name {
    flex-basis: 100%;
    font-size: 16px;
    font-weight: 600;

    span {
      font-weight: normal;
    }
  }
}
</style>
