import { emitError } from "@/event-bus";
import CrudPageMixin from "@/mixins/CrudPageMixin";
import {
  CustomsArticleMaintenanceApi,
  IncotermViewModel,
  OpenArticle,
} from "@/openapi";
import { DataAction } from "@/store/dataModule";
import { DefaultDataTableItem } from "@/types/types";
import { Component, Mixins, Prop, Vue, Watch } from "vue-property-decorator";
import { DataOptions, DataTableHeader } from "vuetify";
import { ArticleMaintenanceFulfillmentForm } from "./dialogs/ArticleMaintenanceDialog";

import ArticleMaintenanceDialog from "./dialogs/ArticleMaintenanceDialog.vue.html";
import SenderAutocomplete from "./editors/SenderAutocomplete.vue.html";

const api = new CustomsArticleMaintenanceApi(undefined, "");

@Component({
  components: {
    ArticleMaintenanceDialog,
    SenderAutocomplete,
  },
})
export default class CustomsArticleMaintenanceOpen extends Mixins<
  CrudPageMixin<object>
>(CrudPageMixin) {
  @Prop({ default: () => [] })
  customers!: DefaultDataTableItem<string>[];

  @Prop({ default: () => [] })
  senders!: DefaultDataTableItem<string>[];

  @Prop({ default: () => [] })
  incotermIds!: number[];

  loading = false;
  dialogLoading = false;

  selectedCustomer: string | null = null;
  selectedSender: string | null = null;
  selectedIncoterm: number | null = null;
  isGroupedByCustomer = false;

  items: OpenArticle[] = [];
  currentItem: OpenArticle | null = null;

  options: DataOptions = {
    page: 1,
    itemsPerPage: 50,
    sortBy: [],
    sortDesc: [],
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
  };

  footerOptions: any = {
    showFirstLastPage: true,
    itemsPerPageOptions: [5, 25, 50, 100],
    disablePagination: false,
  };

  totalAmountOfItems = 0;

  showDialog = false;

  fulfillmentArticleData: ArticleMaintenanceFulfillmentForm | null = null;

  created() {
    this.$store.dispatch(DataAction.FetchIncoterms);
  }

  timeoutDelay = 0;
  @Watch("options")
  onOptionsChanged(newVal: any, oldVal: any) {
    clearTimeout(this.timeoutDelay);
    this.timeoutDelay = setTimeout(() => {
      this.getOpenArticles(this.options.page);
    }, 250);
  }

  async getOpenArticles(page: number) {
    this.loading = true;
    try {
      this.items = [];
      const response = await api.getOpenArticles(
        !!this.selectedCustomer ? parseInt(this.selectedCustomer) : undefined,
        this.selectedSender ?? undefined,
        this.isGroupedByCustomer,
        this.selectedIncoterm ?? undefined,
        this.sortBy,
        this.sortDesc,
        page,
        this.options.itemsPerPage,
      );
      this.items = response.data.items ?? [];
      this.totalAmountOfItems = response.data.totalAmountOfItems ?? 0;
    } catch {
      emitError("Something went wrong while retrieving the open articles.");
    }

    this.loading = false;
  }

  async onFilterSelected() {
    await this.getOpenArticles(1);
  }

  fulfill(item: OpenArticle) {
    this.currentItem = item;
    this.showDialog = true;
    this.fulfillmentArticleData = {
      eza: {
        hsCode: null,
        description: "",
      },
      impost: {
        hsCode: null,
        description: "",
      },
      useGeneralCustomerForAutomatedProcess: false,
      useSenderForAutomatedProcess: false,
      documents: false,
      customer: item.customer,
      senderName: item.sender,
    } as ArticleMaintenanceFulfillmentForm;
  }

  async onFulfillConfirmed(eventData: ArticleMaintenanceFulfillmentForm) {
    this.dialogLoading = true;
    try {
      await api.fulFillOpenArticle({
        openArticleIds: !!this.currentItem!.groupedIds?.length
          ? this.currentItem!.groupedIds!
          : [this.currentItem!.id!],
        ezaHsCode: eventData.eza.hsCode!,
        ezaDescription: eventData.eza.description,
        impostHsCode: eventData.impost.hsCode,
        impostDescription: eventData.impost.description,
        useGeneralCustomerForAutomatedProcess:
          eventData.useGeneralCustomerForAutomatedProcess,
        useSenderForAutomatedProcess: eventData.useSenderForAutomatedProcess,
        documents: eventData.documents,
      });
      this.currentItem = null;
      this.dialogLoading = false;
      this.showDialog = false;
      await this.getOpenArticles(this.options.page);
    } catch (e: any) {
      this.dialogLoading = false;
      let errorMessages: string[] = [
        "Something went wrong while fulfilling the open article.",
      ];

      if (e?.response?.data?.errorMessages?.length) {
        errorMessages = e.response.data.errorMessages;
      }
      emitError(...errorMessages);
    }
  }

  get headers(): DataTableHeader[] {
    return !this.isGroupedByCustomer
      ? [
          { text: "Customer", value: "customer", sortable: false },
          { text: "Sender", value: "sender", sortable: false },
          { text: "HS Code", value: "hsCode", sortable: false },
          {
            text: "Content description",
            value: "description",
            sortable: false,
          },
          { text: "DAP/DDP", value: "incoterm", sortable: false },
          {
            text: "Last import date",
            value: "lastImportDate",
            sortable: true,
          },
          { text: "Count", value: "count", sortable: true },
          {
            text: " ",
            value: "actions",
            sortable: false,
            width: "6em",
          },
        ]
      : [
          { text: "Customer", value: "customer", sortable: false },
          { text: "HS Code", value: "hsCode", sortable: false },
          {
            text: "Content description",
            value: "description",
            sortable: false,
          },
          {
            text: "Last import date",
            value: "lastImportDate",
            sortable: true,
          },
          { text: "Count", value: "count", sortable: true },
          {
            text: " ",
            value: "actions",
            sortable: false,
            width: "6em",
          },
        ];
  }

  get sortBy(): string {
    return this.options.sortBy?.length ? this.options.sortBy[0] : "";
  }

  get sortDesc(): boolean {
    return this.options.sortDesc?.length ? this.options.sortDesc[0] : false;
  }

  get incoterms() {
    return this.$store.getters.incoterms.filter((c: IncotermViewModel) =>
      this.incotermIds.includes(c.id!),
    );
  }
}
