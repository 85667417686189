import { ToolbarItem } from '@/models/ToolbarItem';
import {
  CustomerViewModel,
  CustomsStatusCustomersApi,
  CustomsStatusCustomersViewModel,
  DataApi,
} from '@/openapi';
import { ValidationProvider } from 'vee-validate';
import { ValidationResult } from 'vee-validate/dist/types/types';
import { Component, Mixins, Vue, Watch } from 'vue-property-decorator';
import CrudPageMixin from '../mixins/CrudPageMixin';

const dataApi = new DataApi(undefined, '');
const customsStatusCustomersApi = new CustomsStatusCustomersApi(undefined, '');

@Component({})
export default class CustomsStatusCustomers extends Mixins<CrudPageMixin<CustomsStatusCustomersViewModel>>(CrudPageMixin) {
  headers = [
    { text: 'Actions', value: 'actions', sortable: false, width: '6em' },
    { text: 'Customer*', align: 'start', value: 'customerId' },
    { text: 'Name*', value: 'name' },
    { text: 'SFTP Server*', value: 'sftpServer' },
    { text: 'SFTP User*', value: 'sftpUsername' },
    { text: 'SFTP Port*', value: 'sftpPort' },
    { text: 'Record Enabled', value: 'recordEnabled' },
    { text: 'SFTP Password', value: 'sftpPassword' },
    { text: 'SFTP Key File', value: 'sftpKeyFile' },
    { text: 'SFTP Key File Password', value: 'sftpKeyFilePassword' },
    { text: 'SFTP Output Folder', value: 'sftpOutputFolder' },
    { text: 'SFTP Invoice Output Folder', value: 'sftpInvoiceOutputFolder' },
    { text: 'Actions', value: 'actions', sortable: false, width: '6em' },
  ];

  isLoading = false;
  footerOptions: any = {
    showFirstLastPage: true,
    itemsPerPageOptions: [25, 50, 100, -1],
    disablePagination: false,
  };
  options: any = {
    page: 1,
    itemsPerPage: 100,
    sortBy: [],
    sortDesc: [],
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
  };

  totalItems: Number = 0;
  timeoutDelay: any = null;

  customers: CustomerViewModel[] = [];
  showPassword = false;
  created() {
    var toolbarButtons: ToolbarItem[] = [
      {
        callback: () => this.refresh(),
        icon: 'mdi-refresh',
        tooltipText: 'Refresh overview',
      },
    ];
    this.$emit('PageInfoReceived', 'Customs Status Customers', toolbarButtons);

    dataApi.getCustomers(true).then((result) => {
      this.customers = result.data;
    });
  }

  @Watch('options')
  onOptionsChanged(newVal: any, oldVal: any) {
    clearTimeout(this.timeoutDelay);
    this.timeoutDelay = setTimeout(() => {
      this.disableNewItemButton = false;
      this.loadItems(
        newVal.page,
        newVal.itemsPerPage,
        this.getSort(newVal.sortDesc),
        this.getSortField(newVal.sortBy)
      );
    }, 250);
  }

  loadItems(
    page: number,
    itemsPerPage: number,
    orderByField: string,
    orderBy: string
  ) {
    this.isLoading = true;
    this.footerOptions.disablePagination = true;
    customsStatusCustomersApi
      .getCustomsStatusCustomers(itemsPerPage, page, orderBy, orderByField)
      .then((result) => {
        this.items = result.data.items as CustomsStatusCustomersViewModel[];
        this.totalItems = result.data.totalItems as number;
      })
      .finally(() => {
        this.isLoading = false;
        this.footerOptions.disablePagination = false;
      });
  }

  async saveItem(item: CustomsStatusCustomersViewModel) {
    if (this.isLoading) {
      return;
    }

    var validationObserver = this.getValidationObserver(item);
    const promises: Promise<ValidationResult>[] = [];
    for (const key in this.$refs) {
      const split = key.split('-');
      if (!split.length || key === 'observer-' + item.id) {
        continue;
      }

      const propName = split[0];
      const id = parseInt(split[1]);
      if (id != item.id) {
        continue;
      }

      const validationProvider = (<Vue[]>this.$refs[key])[0] as InstanceType<
        typeof ValidationProvider
      >;
      promises.push(
        validationProvider.validate(
          item[propName as keyof CustomsStatusCustomersViewModel]
        )
      );
    }
    validationObserver.validate().then(async (isValid) => {
      const validationResults = await Promise.all(promises);
      let errorsArray: { id: string; errors: string[] }[] = [];
      Object.keys(validationObserver.errors).map((key) => {
        if (validationObserver.errors[key].length > 0) {
          errorsArray.push({ id: key, errors: validationObserver.errors[key] });
        }
      });
      for (var validationResult of validationResults) {
        if (!validationResult.valid) {
          var errorObject = errorsArray.find(
            (o) => o.errors.indexOf(validationResult.errors[0]) !== -1
          );
          if (errorObject) {
            var errorElementId = errorObject?.id;
            document
              .getElementById(errorElementId)
              ?.scrollIntoView({ inline: 'start' });
          }

          return;
        }
      }

      this.isLoading = true;
      const isNewItem = this.isNewItem(item);

      customsStatusCustomersApi
        .saveCustomsStatusCustomer(item)
        .then((result) => {
          if (!result?.data) {
            return;
          }

          this.mapItem(item, result.data);
          if (isNewItem) {
            this.disableNewItemButton = false;
          }
        })
        .catch((error) => {
          this.$emit('errorOccured', [error.message]);
        })
        .finally(() => {
          this.isLoading = false;
        });
    });
  }

  deleteItem(item: any) {
    this.isLoading = true;
    const index = this.items.findIndex((cdec) => cdec.id === item.id);
    if (this.isNewItem(item)) {
      this.items.splice(index, 1);
      this.isLoading = false;
      this.disableNewItemButton = false;
      return;
    }

    customsStatusCustomersApi
      .deleteCustomsStatusCustomer(item.id)
      .then((c) => {
        this.items.splice(index, 1);
      })
      .catch(() => {})
      .finally(() => {
        const options = this.options;
        this.loadItems(
          options.page,
          options.itemsPerPage,
          this.getSort(options.sortDesc),
          this.getSortField(options.sortBy)
        );
      });
  }

  getSortField(sortFields: string[]) {
    return sortFields[0] ?? '';
  }

  getSort(sortDesc: Boolean[]) {
    let isDesc = sortDesc[0] ?? null;
    if (!(isDesc === false || isDesc === true)) {
      return '';
    }
    return isDesc ? 'DESC' : 'ASC';
  }

  refresh() {
    this.loadItems(
      this.options.page,
      this.options.itemsPerPage,
      this.getSort(this.options.sortDesc),
      this.getSortField(this.options.sortBy)
    );
    this.disableNewItemButton = false;
  }

  getCustomerName(item: CustomsStatusCustomersViewModel) {
    var customer = this.customers.find((c) => c.id === item.customerId);
    if (!customer) {
      return '';
    }

    return customer.shortName;
  }

  displayAsterisks(value: string) {
    return value?.length ? '*'.repeat(10) : '';
  }
}
