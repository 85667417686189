import CrudPageMixin from '@/mixins/CrudPageMixin';
import {
    CustomerViewModel,
    CustomsExportCustomersApi,
    CustomsExportCustomersViewModel,
    DataApi,
} from '@/openapi';
import { ValidationProvider } from 'vee-validate';
import { Component, Mixins } from 'vue-property-decorator';
import { DataOptions } from 'vuetify';

const dataApi = new DataApi(undefined, '');
const customsExportCustomersApi = new CustomsExportCustomersApi(undefined, '');

@Component({})
export default class CustomsExportCustomers extends Mixins<
    CrudPageMixin<CustomsExportCustomersViewModel>
>(CrudPageMixin) {
    loading = false;

    customers: CustomerViewModel[] = [];

    headers = [
        { text: ' ', value: 'actions', sortable: false, width: '6em' },
        { text: 'Customer', value: 'customerId' },
    ];

    options: DataOptions = {
        page: 1,
        itemsPerPage: 5,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
    };

    footerOptions: any = {
        showFirstLastPage: true,
        itemsPerPageOptions: [5, 25, 50, 100],
        disablePagination: false,
    };

    totalAmountOfItems = 0;

    async created() {
        await this.getCustomers();
    }

    addNewItem() {
        this.disableNewItemButton = true;
        this.items.unshift({ id: 0 } as CustomsExportCustomersViewModel);
        this.totalAmountOfItems += 1;
    }

    async getCustomers() {
        try {
            const response = await dataApi.getCustomers(true);
            this.customers = response.data;
        } catch {
            this.displayError(
                'Something went wrong while retrieving the customers.'
            );
        }
    }

    async getCustomsExportCustomers() {
        try {
            const response =
                await customsExportCustomersApi.getCustomsExportCustomers(                    
                    this.sortBy,
                    this.sortDesc,
                    this.options.page,
                    this.options.itemsPerPage,
                );
            this.items = response.data.items ?? [];
            this.totalAmountOfItems = response.data.totalAmountOfItems ?? 0;
            this.options.page = response.data.page ?? 1;
        } catch {
            this.displayError(
                'Something went wrong while retrieving the items.'
            );
        }
    }

    displayError(errorMessage: string) {
        this.$emit('errorOccured', errorMessage);
    }

    onUpdateOptions(options: DataOptions) {
        this.getCustomsExportCustomers();
    }

    customerDisplayName(customerId: number | null) {
        return customerId === null
            ? ''
            : this.customers.find((c) => c.id == customerId)?.shortName;
    }

    async deleteItem(item: any) {
        this.loading = true;
        const index = this.items.findIndex((x) => x.id === item.id);
        if (this.isNewItem(item)) {
            this.items.splice(index, 1);
            this.loading = false;
            this.disableNewItemButton = false;
            this.totalAmountOfItems -= 1;
            return;
        }

        try {
            await customsExportCustomersApi.deleteCustomsExportCustomers(
                item.id
            );
            await this.getCustomsExportCustomers();
        } catch {
            this.displayError('Something went wrong while deleting the item.');
        }

        this.loading = false;
    }

    async saveItem(item: CustomsExportCustomersViewModel) {
        if (this.loading) {
            return;
        }

        const promises: Promise<any>[] = [];
        const validationObserver = this.getValidationObserver(item);
        for (const key in item) {
            if (Object.prototype.hasOwnProperty.call(item, key)) {
                const refElements: Vue[] = this.$refs[
                    `${key}-${item.id}`
                ] as Vue[];
                if (!refElements) {
                    continue;
                }
                const validationProvider = refElements[0] as InstanceType<
                    typeof ValidationProvider
                >;
                promises.push(
                    validationProvider.validate(
                        item[key as keyof CustomsExportCustomersViewModel]
                    )
                );
            }
        }

        await Promise.all(promises);

        validationObserver.validate().then(async (isValid) => {
            if (!isValid) {
                return;
            }

            this.loading = true;
            const isNewItem = this.isNewItem(item);

            let promise: Promise<any>;

            if (isNewItem) {
                promise =
                    customsExportCustomersApi.createCustomsExportCustomers({
                        customerId: item.customerId,
                    });
            } else {
                promise =
                    customsExportCustomersApi.updateCustomsExportCustomers({
                        id: item.id,
                        customerId: item.customerId,
                    });
            }
            try {
                await promise;
                this.disableNewItemButton = false;
            } catch {
                this.displayError(
                    'Something went wrong while creating/updating the entity.'
                );
            }

            await this.getCustomsExportCustomers();
            this.loading = false;
        });
    }

    get sortBy() {
        return this.options.sortBy?.[0] ?? '';
    }

    get sortDesc() {
        return this.options.sortDesc?.[0] ?? false;
    }
}
