import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

import * as CustomsExportController from '@/api/CustomsExportController';
import { ReturnReasonFirstViewModel } from '@/api/viewmodels/ReturnReasonFirstViewModel';
import { ReturnReasonSecondViewModel } from '@/api/viewmodels/ReturnReasonSecondViewModel';

import { Actions } from '@/config';
import { emitError } from '@/event-bus';

export interface ReturnShipmentReasonsForm {
    shipmentReasonFirst?: number | null;
    shipmentReasonSecond?: number | null;
}

@Component({})
export default class SetReturnReasonsDialog extends Vue {
    @Prop({default: false})
    value!: Boolean;

    @Prop({default: false})
    isLoading!: Boolean;

    @Prop({default: ''})
    title!: String

    @Prop({default: 'Confirm'})
    confirmButtonText!: String

    @Prop({default: 'Close'})
    closeButtonText!: String

    rules = {
        required: (value: string) =>
            (value && !!value.toString()) || 'Required'
    };
    valid = true;

    form = this.initializeForm();

    isRetrievingReturnReasons = false

    returnReasonsFirst: ReturnReasonFirstViewModel[] = [];
    returnReasonsSecond: ReturnReasonSecondViewModel[] = [];

    get displayTitle() {
        return !this.title ? Actions.RETURN : this.title;
    }

    @Watch('value')
    onValueChanged(newValue: boolean, oldVal: boolean) {
        if(newValue == false) {
            this.form = this.initializeForm();
            (this.$refs.form as Vue & { resetValidation: () => boolean}).resetValidation();
            this.returnReasonsSecond = [];
        }
    }

    created() {
        this.getReturnReasons();
    }

    returnReasonsChange() {
        var reasonFirst = this.returnReasonsFirst.find(
            (x) => x.id == this.form.shipmentReasonFirst
        );
        if (reasonFirst == null) {
            this.returnReasonsSecond = [];
            return;
        }

        this.returnReasonsSecond = reasonFirst.returnReasonsSecond;
        if (this.returnReasonsSecond.length == 1) {
            this.form.shipmentReasonSecond = this.returnReasonsSecond[0].id;
        }
    }

    async getReturnReasons() {
        this.returnReasonsFirst = [];
        this.isRetrievingReturnReasons = true;
        try {
            var response = await CustomsExportController.getReturnReasons()
            this.returnReasonsFirst = response.data;
        } catch {
            emitError('Something went wrong while retrieving the return reasons.')
        }
        this.isRetrievingReturnReasons = false;
    }

    initializeForm(): ReturnShipmentReasonsForm {
        return {
            shipmentReasonFirst: null,
            shipmentReasonSecond: null
        }
    }
}