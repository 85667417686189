<template>
    <Fragment>
        <v-toolbar
            class="email-tabs grey darken-3"
            color="white"
            elevation="0"
            dark
            rounded
        >
            <v-tabs
                background-color="grey darken-3"
                color="white"
                dark
                fixed-tabs
            >
                <v-tab @change="isPlainText = false">HTML</v-tab>
                <v-tab @change="isPlainText = true">Plain text</v-tab>
            </v-tabs>
        </v-toolbar>
        <table
            v-if="selectedEmailTemplate"
            border="0"
            cellpadding="0"
            cellspacing="0"
            class="email-preview"
        >
            <tr>
                <td></td>
                <td class="email-preview__container">
                    <div class="email-preview__content">
                        <div
                            v-if="!isPlainText && hasLogo"
                            class="email-preview__header"
                        >
                            <img
                                v-if="selectedEmailTemplate.logoPreview"
                                :src="selectedEmailTemplate.logoPreview"
                                alt="Useful alt text"
                                border="0"
                                :class="[
                                    'email-preview__logo',
                                    !selectedEmailTemplate.logoTwoPreview
                                        ? 'email-preview__logo--single'
                                        : '',
                                ]"
                            />
                            <span
                                v-if="hasTwoLogos"
                                class="email-preview__logo-text"
                                >IM AUFTRAG VON</span
                            >
                            <img
                                v-if="selectedEmailTemplate.logoTwoPreview"
                                :src="selectedEmailTemplate.logoTwoPreview"
                                alt="Useful alt text"
                                border="0"
                                class="email-preview__logo"
                            />
                        </div>
                        <table class="email-preview-main">
                            <tr>
                                <td class="email-preview-main__wrapper">
                                    <table
                                        border="0"
                                        cellpadding="0"
                                        cellspacing="0"
                                    >
                                        <tr>
                                            <td>
                                                <template v-if="isPlainText">
                                                    <p
                                                        v-html="
                                                            emailTemplatePlainText
                                                        "
                                                        class="normal-text"
                                                    ></p>
                                                </template>
                                                <template v-else>
                                                    <p
                                                        v-html="
                                                            emailTemplateBodyHtml
                                                        "
                                                        class="normal-text"
                                                    ></p>
                                                    <p
                                                        v-html="
                                                            emailTemplateLegalInformationHtml
                                                        "
                                                        class="normal-text"
                                                    ></p>
                                                </template>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>

                        <div v-if="!isPlainText" class="email-preview-footer">
                            <table border="0" cellpadding="0" cellspacing="0">
                                <tr>
                                    <td
                                        class="email-preview-footer__text-wrapper"
                                    >
                                        <span
                                            v-html="emailTemplateFooterHtml"
                                            class="apple-link email-preview-footer__text"
                                        >
                                        </span>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </td>
                <td></td>
            </tr>
        </table>
    </Fragment>
</template>

<script setup lang="ts">
import { useEmailPreview } from '@/composables/emailPreview';
import { EnrichedEmailTemplate } from '@/types/email';

interface EmailPreviewProps {
    selectedEmailTemplate: EnrichedEmailTemplate | undefined;
}

const props = defineProps<EmailPreviewProps>();

const {
    isPlainText,
    emailTemplatePlainText,
    emailTemplateBodyHtml,
    emailTemplateFooterHtml,
    emailTemplateLegalInformationHtml,
    hasLogo,
    hasTwoLogos,
} = useEmailPreview(props);
</script>
