<template>
  <CrudDataTable
    :headers="headers"
    :items="items"
    :loading="loading"
    :server-items-length="totalAmountOfItems"
    :options.sync="options"
    :footer-props="footerOptions"
    @click:create="onCreateItem"
    @click:edit="onEditItem"
    @click:delete="onDeleteItem"
    :height="tableHeightCalc"
  >
    <template #item.countryId="{ item }">
      {{ getCountryCode(item) }}
    </template>
  </CrudDataTable>
</template>

"
<script setup lang="ts">
import { useDataTableDefaults } from "@/composables/dataTable";
import { emitErrorWithFallback, emitSuccess } from "@/event-bus";
import { AusfuhrInfoDto, CustomsAusfuhrInfoApi } from "@/openapi";
import { onBeforeMount, ref, watch } from "vue";
import router from "@/router";
import { DataOptions, DataTableHeader } from "vuetify";
import { determineTableHeightCalc } from "@/helpers/dataTableHelper";
import CrudDataTable from "@/components/CrudDataTable.vue";
import { useDataStore } from "@/stores/data-store";

const api = new CustomsAusfuhrInfoApi(undefined, "");
const dataStore = useDataStore();

const tableHeightCalc = ref<string>(determineTableHeightCalc(187));
const headers = ref<DataTableHeader[]>([
  {
    text: "Name",
    value: "name",
    sortable: false,
  },
  {
    text: "Address",
    value: "address",
    sortable: false,
  },
  {
    text: "Postal code",
    value: "postalCode",
    sortable: false,
  },
  {
    text: "City",
    value: "city",
    sortable: false,
  },
  {
    text: "Country",
    value: "countryId",
    sortable: false,
  },
  {
    text: "Zusatz",
    value: "zusatz",
    sortable: false,
  },
  {
    text: "Ausfuhrzollstelle",
    value: "ausfuhrzollstelle",
    sortable: false,
  },
  {
    text: "Vorgesehene Ausgangszollstelle",
    value: "vorgeseheneAusgangszollstelle",
    sortable: false,
  },
  {
    text: "Special Circumstances Characteristics",
    value: "specialCircumstancesCharacteristics",
    sortable: false,
  },
  {
    text: "Remark Special Circumstances Characteristics",
    value: "remarkSpecialCircumstancesCharacteristics",
    sortable: false,
  },
  {
    text: "ABD Email",
    value: "abdEmail",
    sortable: false,
  },
  {
    text: "AVM Email",
    value: "avmEmail",
    sortable: false,
  },
]);

const { items, footerOptions, loading, options, totalAmountOfItems } =
  useDataTableDefaults();

const getItems = async () => {
  try {
    const response = await api.getAusfuhrInfoList(
      options.value.page,
      options.value.itemsPerPage,
    );

    items.value = response.data.items ?? [];
    totalAmountOfItems.value = response.data.totalAmountOfItems ?? 0;
  } catch (e: unknown) {
    emitErrorWithFallback("Something went wrong while retrieving the items");
  }
};

const onCreateItem = () => {
  router.push({
    name: "MaintenanceOperationalCustomsAusfuhrInfoDetail",
  });
};
const onEditItem = (item: AusfuhrInfoDto) => {
  if (!item) {
    return;
  }

  router.push({
    name: "MaintenanceOperationalCustomsAusfuhrInfoDetail",
    params: {
      id: item.id!.toString(),
    },
  });
};

const onDeleteItem = async (item: AusfuhrInfoDto) => {
  loading.value = true;
  try {
    await deleteItem(item.id!);
    await getItems();
  } finally {
    loading.value = false;
  }
};

const deleteItem = async (id: number) => {
  try {
    await api.deleteAusfuhrInfo(id);
    emitSuccess("Delete successful");
    return Promise.resolve();
  } catch (e: unknown) {
    emitErrorWithFallback("Something went wrong during deletion");
    return Promise.reject();
  }
};

const getCountryCode = (item: AusfuhrInfoDto) => {
  return (
    dataStore.originCountries.find((c) => c.id == item.countryId)?.code ?? ""
  );
};

watch(
  () => options.value,
  async (newValue: DataOptions, oldValue: DataOptions) => {
    loading.value = true;
    await getItems();
    loading.value = false;
  },
);

onBeforeMount(async () => {
  await dataStore.fetchOriginCountries();
});
</script>
