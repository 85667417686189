import { computed, ref } from 'vue';
import { EnrichedEmailTemplate } from '@/types/email';

export interface UseEmailPreviewArgs {
    selectedEmailTemplate: EnrichedEmailTemplate | undefined;
}

interface TemplatesVariables {
    linkButton: string;
}

export function useEmailPreview(args: Readonly<UseEmailPreviewArgs>) {
    const templateVariables = ref<TemplatesVariables>({
        linkButton: `<a class="email-btn" href="$1" target="_blanc">$2</a>`,
    });

    const isPlainText = ref(false);

    const emailTemplateBodyHtml = computed(() => {
        let html = args.selectedEmailTemplate?.bodyText ?? '';

        for (const propName in templateVariables.value) {
            html = replaceEmailTemplateVariables(html, propName);
        }
        return html;
    });

    const emailTemplatePlainText = computed(() => {
        let plainText = args.selectedEmailTemplate?.plainTextBody ?? '';
        plainText = plainText.replaceAll('\n', '<br />');

        for (const propName in templateVariables.value) {
            plainText = replaceEmailTemplateVariables(plainText, propName);
        }
        return plainText;
    });

    const emailTemplateFooterHtml = computed(() => {
        let html = args.selectedEmailTemplate?.footerText ?? '';

        for (const propName in templateVariables.value) {
            html = replaceEmailTemplateVariables(html, propName);
        }
        return html;
    });

    const emailTemplateLegalInformationHtml = computed(() => {
        let html = args.selectedEmailTemplate?.legalInformationText ?? '';
        for (const propName in templateVariables.value) {
            html = replaceEmailTemplateVariables(html, propName);
        }
        return html;
    });

    const hasLogo = computed(() => {
        return (
            !!args.selectedEmailTemplate?.logoPreview ||
            !!args.selectedEmailTemplate?.logoTwoPreview
        );
    });

    const hasTwoLogos = computed(() => {
        return (
            !!args.selectedEmailTemplate?.logoPreview &&
            !!args.selectedEmailTemplate?.logoTwoPreview
        );
    });

    const replaceEmailTemplateVariables = (
        text: string,
        variableName: string
    ) => {
        return text?.replace(
            new RegExp(
                `{{(${variableName})(?::([^{}]*(?:{(?:{[^{}]*})?[^{}]*}[^{}]*)*)?)?}}`,
                'gi'
            ),
            (match, ...args) => {
                let replacement =
                    templateVariables.value[
                        variableName as keyof TemplatesVariables
                    ];
                if (args.length == 4) {
                    let paramsGroup: string = args[1];
                    var params =
                        paramsGroup?.split('|').filter((c) => c !== '') ?? [];
                    for (let i = 0; i < params.length; i++) {
                        replacement = replacement.replace(
                            `$${i + 1}`,
                            params[i]
                        );
                    }
                }
                return replacement;
            }
        );
    };

    return {
        isPlainText,
        templateVariables,
        emailTemplatePlainText,
        emailTemplateBodyHtml,
        emailTemplateFooterHtml,
        emailTemplateLegalInformationHtml,
        hasLogo,
        hasTwoLogos,
        replaceEmailTemplateVariables,
    };
}
