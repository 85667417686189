<template>
    <Fragment>
        <v-card>
            <v-card-text>
                <ValidationObserver
                    ref="observer"
                    immediate
                    v-slot="{ invalid }"
                >
                    <v-form>
                        <v-data-table
                            :headers="headers"
                            :items="mrns"
                            hide-default-footer
                            loading="isLoading"
                        >
                            <template v-slot:item.mrn="{ item }">
                                <ValidationProvider
                                    v-show="item.editable"
                                    name="Mrn"
                                    :rules="{
                                        required: true,
                                        regex: /^[0-9]{2}[A-Z]{2}[a-zA-Z0-9]{14}$/,
                                    }"
                                    v-slot="{ errors }"
                                    :vid="item.id.toString()"
                                    slim
                                    immediate
                                >
                                    <v-text-field
                                        v-model="item.mrn"
                                        @keydown.enter.prevent="saveMrn(item)"
                                        dense
                                        autofocus
                                        :error-messages="errors"
                                    ></v-text-field>
                                </ValidationProvider>
                                <span
                                    v-show="!item.editable"
                                    @click="editMrn(item)"
                                    >{{ item.mrn ? item.mrn : '-' }}</span
                                >
                            </template>

                            <template
                                v-slot:item.exportDeclarationFile="{ item }"
                            >
                                <ButtonGroup>
                                    <v-btn
                                        color="info"
                                        small
                                        class="text-center"
                                        @click="
                                            (currentItem = item),
                                                (showDialogUploadFile = true)
                                        "
                                        ><v-icon color="white" left
                                            >mdi-upload</v-icon
                                        >
                                        <span v-if="!item.exportDeclarationFile"
                                            >Upload export declaration</span
                                        ><span v-else>{{
                                            item.exportDeclarationFile.name
                                        }}</span>
                                    </v-btn>
                                    <v-btn
                                        v-if="item.exportDeclarationFile"
                                        color="info"
                                        small
                                        class="text-center pa-0"
                                        @click="
                                            item.exportDeclarationFile = null
                                        "
                                        ><v-icon color="white" small
                                            >mdi-close</v-icon
                                        ></v-btn
                                    >
                                </ButtonGroup>
                            </template>

                            <template v-slot:item.actions="{ item }">
                                <v-icon
                                    v-if="item.editable"
                                    @click="saveMrn(item)"
                                    >mdi-content-save</v-icon
                                >
                                <v-icon @click="removeMrn(item)"
                                    >mdi-delete</v-icon
                                >
                            </template>
                        </v-data-table>
                        <div class="mt-4">
                            <v-btn
                                :disabled="!noActiveEditableFields"
                                @click="addMrn"
                                color="success"
                                >Add mrn <v-icon>mdi-plus</v-icon>
                            </v-btn>

                            <v-btn
                                :disabled="invalid || !noActiveEditableFields"
                                color="primary"
                                @click="$emit('next-step')"
                                class="ml-2"
                            >
                                Continue
                            </v-btn>
                        </div>
                    </v-form>
                </ValidationObserver>
            </v-card-text>
        </v-card>

        <UploadFileDialog
            v-model="showDialogUploadFile"
            @confirm="onFileUploaded"
        ></UploadFileDialog>
    </Fragment>
</template>

<script setup lang="ts">
import { CustomsConsolidationApi } from '@/openapi';
import { emitError } from '@/event-bus';
import { UploadFileForm } from './dialogs/UploadFileDialog';
import UploadFileDialog from './dialogs/UploadFileDialog.vue.html';
import ButtonGroup from './buttons/ButtonGroup.vue.html';
import { computed, onMounted, ref } from 'vue';
import { DataTableHeader } from 'vuetify';
import { useConsolidationStore } from '@/stores/consolidation-store';
import { Mrn } from '@/types/consolidation-types';

interface CustomsConsolidationMrnProps {
    parcelNumbers: string[];
}
const api = new CustomsConsolidationApi(undefined, '');
const consolidationStore = useConsolidationStore();
const props = withDefaults(defineProps<CustomsConsolidationMrnProps>(), {
    parcelNumbers: () => [],
});

const isLoading = ref(false);
const headers = ref<DataTableHeader[]>([
    {
        text: 'Mrn',
        align: 'start',
        value: 'mrn',
    },
    {
        text: 'File',
        align: 'start',
        value: 'exportDeclarationFile',
    },
    { text: 'Actions', align: 'end', sortable: false, value: 'actions' },
]);
const originalMrns = ref<string[]>([]);

const currentItem = ref<Mrn | null>(null);
const currentItemCopy = ref<Mrn | null>(null);
const currentId = ref(0);

const showDialogUploadFile = ref(false);

onMounted(async () => {
    await getMrns();
});

const getMrns = async () => {
    try {
        isLoading.value = true;
        const response = await api.getMrns({
            parcelNumbers: props.parcelNumbers,
        });

        originalMrns.value = response.data;

        consolidationStore.setMrns(
            response.data.map((c: string, index: number) => ({
                id: index,
                mrn: c,
                editable: false,
                new: false,
                exportDeclarationFile: null,
            }))
        );

        if (mrns.value.length) {
            const lastMappedMrn = mrns.value[mrns.value.length - 1];
            currentId.value = lastMappedMrn.id;
        }
    } catch (e) {
        emitError('Something went wrong while retrieving the MRNs');
    } finally {
        isLoading.value = false;
    }
};

const addMrn = () => {
    currentId.value++;
    const newMrn = {
        id: currentId.value,
        mrn: '',
        editable: true,
        new: true,
        exportDeclarationFile: null,
    };
    currentItem.value = newMrn;
    currentItemCopy.value = JSON.parse(JSON.stringify(newMrn));
    consolidationStore.addMrn(newMrn);
};

const editMrn = (item: Mrn) => {
    currentItem.value = item;
    currentItemCopy.value = JSON.parse(JSON.stringify(item));

    for (const mrn of mrns.value) {
        mrn.editable = mrn.id === item.id;
        consolidationStore.editMrn(mrn);
    }
};

const removeMrn = (item: Mrn) => {
    consolidationStore.removeMrn(item.id);
};

const saveMrn = (item: Mrn) => {
    item.editable = false;
    item.new = false;
    consolidationStore.editMrn(item);
};

const onFileUploaded = (item: UploadFileForm) => {
    showDialogUploadFile.value = false;
    currentItem.value!.exportDeclarationFile = item.files![0];
    consolidationStore.editMrn(currentItem.value!);
    currentItem.value = null;
};

const noActiveEditableFields = computed(() => {
    return mrns.value.every((c) => !c.editable);
});

const mrns = computed(() => {
    return [...consolidationStore.mrns];
});
</script>
