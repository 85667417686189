<template>
    <Fragment>
        <v-tabs v-model="currentTab" dark background-color="grey darken-3">
            <v-tab>Bundeskasse discrepancies</v-tab>
            <v-tab>Check up</v-tab>
        </v-tabs>

        <v-tabs-items v-model="currentTab">
            <v-tab-item>
                <v-data-table
                    v-if="currentTab === 0"
                    v-model="expandedItems"
                    :items="items"
                    :headers="headers"
                    :options.sync="options"
                    :footer-props="footerOptions"
                    :server-items-length="totalAmountOfItems"
                    :expanded.sync="expandedItems"
                    :show-expand="true"
                    :single-expand="true"
                    :loading="loading"
                    height="calc(100vh - 175px)"
                >
                    <template #top
                        ><div class="d-flex pt-4">
                            <v-btn
                                class="ml-auto mr-4"
                                color="info"
                                @click="showDialog = true"
                            >
                                <v-icon small left>mdi-upload</v-icon>Upload new
                                Bundeskasse</v-btn
                            >
                        </div>
                    </template>
                    <template #item.affectedMonth="{ item }">
                        {{ item.affectedMonth | formatDate('MM-YYYY') }}
                    </template>

                    <template #item.lastCheckDate="{ item }">
                        {{
                            item.lastCheckDate |
                                formatDate('DD-MM-YYYY HH:mm:ss')
                        }}
                    </template>

                    <template #item.dueDate="{ item }">
                        {{ item.dueDate | formatDate('DD-MM-YYYY') }}
                    </template>

                    <template #item.actions="{ item }">
                        <v-btn
                            color="warning"
                            small
                            :disabled="item.isClosed || loading"
                            :loading="item.isChecking"
                            @click="checkDiscrepancies(item)"
                            ><v-icon small left>mdi-vector-difference</v-icon
                            >Check for discrepancies
                        </v-btn>
                        <v-btn
                            class="ml-2"
                            color="success"
                            small
                            :disabled="
                                item.isClosed || item.isChecking || loading
                            "
                            :loading="item.isClosing"
                            @click="onShowMarkAsClosedConfirmDialog(item)"
                            ><v-icon small left>mdi-marker</v-icon>Mark as
                            closed
                        </v-btn>
                        <v-btn
                            class="ml-2"
                            color="info"
                            small
                            :disabled="
                                item.isClosed || item.isChecking || loading
                            "
                            @click="reuploadBundeskasse(item)"
                            ><v-icon small left>mdi-upload</v-icon>Reüpload
                            Bundeskasse
                        </v-btn>
                    </template>

                    <template #expanded-item="{ item }">
                        <td v-if="expandedItems.length" colspan="10">
                            <v-card class="rounded-0">
                                <v-card-text>
                                    <DutiesAndTaxesDetail
                                        :id="expandedItems[0].id"
                                    ></DutiesAndTaxesDetail>
                                </v-card-text>
                            </v-card>
                        </td>
                    </template>
                </v-data-table>
            </v-tab-item>
            <v-tab-item>
                <DutiesAndTaxesCheckUp
                    v-if="currentTab === 1"
                ></DutiesAndTaxesCheckUp>
            </v-tab-item>
        </v-tabs-items>

        <UploadBundeskasseDialog
            v-model="showDialog"
            :id="currentBundeskasse?.id"
            @upload:success="onSuccessfulUpload"
        ></UploadBundeskasseDialog>

        <ConfirmDialog
            v-model="showMarkAsClosedConfirmDialog"
            title="ACHTUNG!"
            close-button-text="Nein"
            confirm-button-text="Ja"
            :is-loading="currentBundeskasse?.isClosing"
            @close="currentBundeskasse = null"
            @confirm="markAsClosed"
        >
            Mit Bestätigung dieser Sicherheitsabfrage wird dieser Auszug
            geschlossen! Sämtliche Änderungen, nachträgliche Berechnungen etc.
            sind danach geschlossen und können nicht mehr angelegt oder geändert
            werden. Bitte diese Sicherheitsabfrage nur bestätigen, wenn alle
            Änderungen erfolgt sind. Soll der Monat geschlossen werden?
        </ConfirmDialog>
    </Fragment>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { DataOptions, DataTableHeader } from 'vuetify';
import UploadBundeskasseDialog from '@/components/dialogs/UploadBundeskasseDialog.vue';
import DutiesAndTaxesCheckUp from '@/components/duties-and-taxes/DutiesAndTaxesCheckUp.vue';
import DutiesAndTaxesDetail from '@/components/duties-and-taxes/DutiesAndTaxesDetail.vue';
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue.html';
import { BundeskasseOverviewItem, DutiesAndTaxesApi } from '@/openapi';
import { FooterOptions } from '@/types/types';
import { emitError, emitSuccess } from '@/event-bus';
import store from '@/store';

interface EnrichedBundeskasseItem extends BundeskasseOverviewItem {
    isClosing: boolean;
    isChecking: boolean;
}

const api = new DutiesAndTaxesApi(undefined, '');

const headers = ref<DataTableHeader[]>([
    { text: 'Total', value: 'totalAmount', sortable: false, filterable: true },
    {
        text: 'Not in Sovex',
        value: 'amountNotInSovex',
        sortable: false,
        filterable: false,
    },
    {
        text: 'Not in Bundeskasse',
        value: 'amountNotInBundeskasse',
        sortable: false,
        filterable: false,
    },
    {
        text: 'Amount difference',
        value: 'amountOfAmountDifference',
        sortable: false,
        filterable: false,
    },
    {
        text: 'Month',
        value: 'affectedMonth',
        sortable: false,
        filterable: false,
    },
    {
        text: 'Account number',
        value: 'accountNumber',
        sortable: false,
        filterable: false,
    },
    {
        text: 'Last check date',
        value: 'lastCheckDate',
        sortable: false,
        filterable: false,
    },
    {
        text: 'Due date',
        value: 'dueDate',
        sortable: false,
        filterable: false,
    },
    {
        text: '',
        value: 'actions',
        sortable: false,
        filterable: false,
        align: 'end',
    },
]);

const options = ref<DataOptions>({
    page: 1,
    itemsPerPage: 25,
    sortBy: [],
    sortDesc: [],
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
});

const footerOptions = ref<FooterOptions>({
    showFirstLastPage: true,
    itemsPerPageOptions: [5, 25, 50, 100],
    disablePagination: false,
});

const items = ref<EnrichedBundeskasseItem[]>([]);
const expandedItems = ref<any[]>([]);
const totalAmountOfItems = ref(0);
const loading = ref(false);
const currentBundeskasse = ref<EnrichedBundeskasseItem | null>();

const currentTab = ref(0);
const showDialog = ref(false);
const showMarkAsClosedConfirmDialog = ref(false);

let timeoutDelay = 0;
watch(options, (newValue: DataOptions, oldValue: DataOptions) => {
    clearTimeout(timeoutDelay);
    timeoutDelay = setTimeout(async () => {
        await getItems();
    }, 250);
});

watch(showDialog, (newValue: boolean, oldValue: boolean) => {
    if (!newValue) {
        currentBundeskasse.value = null;
    }
});

async function getItems(page?: number) {
    page ??= options.value.page;
    loading.value = true;
    try {
        const response = await api.getBundeskasseOverviewItems(
            page,
            options.value.itemsPerPage
        );
        items.value =
            response.data.items?.map((c) => ({
                ...c,
                isClosing: false,
                isChecking: false,
            })) ?? [];
        totalAmountOfItems.value = response.data.totalAmountOfItems ?? 0;
    } catch {
        emitError('Something went wrong while retrieving the items.');
    }
    loading.value = false;
}

async function markAsClosed() {
    try {
        currentBundeskasse.value!.isClosing = true;
        await api.closeBundeskasse(currentBundeskasse.value!.id);
        currentBundeskasse.value!.isClosing = false;
        emitSuccess('Successfully closed the selected Bundeskasse.');
        showMarkAsClosedConfirmDialog.value = false;
        currentBundeskasse.value = null;
        await getItems();
    } catch {
        emitError('Something went wrong while trying to close the Bundeskasse');
    }
}

async function onSuccessfulUpload() {
    showDialog.value = false;
    await getItems();
}

function reuploadBundeskasse(item: EnrichedBundeskasseItem) {
    currentBundeskasse.value = item;
    showDialog.value = true;
}

async function checkDiscrepancies(item: EnrichedBundeskasseItem) {
    try {
        item.isChecking = true;
        await api.recheckBundeskasse(item.id);
        item.isChecking = false;
        await getItems();
    } catch {
        emitError('Something went wrong while checking for discrepancies');
        item.isChecking = false;
    }
}

function onShowMarkAsClosedConfirmDialog(item: EnrichedBundeskasseItem) {
    currentBundeskasse.value = item;
    showMarkAsClosedConfirmDialog.value = true;
}
</script>
