import { emitError } from '@/event-bus';
import { CustomsArticle, DataApi } from '@/openapi';
import Vue from 'vue';
import Component from "vue-class-component";

const api = new DataApi(undefined, '');

@Component({})
export default class ArticlesMixin extends Vue {

    ezaArticles: CustomsArticle[] = [];
    impostArticles: CustomsArticle[] = [];

    loadingEzaArticles = false;
    loadingImpostArticles = false;

    created() {
        this.getEzaArticles();
        this.getImpostArticles();
    }

    async getEzaArticles() {
        this.loadingEzaArticles = true;
        try {
            const response = await api.getCustomsArticles(false);
            this.ezaArticles = response.data;
        } catch {
            emitError(
                'Something went wrong while retrieving the EZA HS codes.'
            );
        }
        this.loadingEzaArticles = false;
    }

    async getImpostArticles() {
        this.loadingImpostArticles = true;
        try {
            const response = await api.getCustomsArticles(true);
            this.impostArticles = response.data;
        } catch {
            emitError(
                'Something went wrong while retrieving the IMPOST HS codes.'
            );
        }
        this.loadingImpostArticles = false;
    }
}