import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

const vuetify = new Vuetify({
    theme: {
        dark: false,
        themes: {
            light: {
                primary: '#F9A825',
                secondary: '#b0bec5',
                accent: '#8c9eff',
                error: '#b71c1c',
                
            },
            dark: {
                primary: '#FFEB3B',
                secondary: '#b0bec5',
                accent: '#8c9eff',
                error: '#b71c1c'
            }
        }
    },
    icons: {
        iconfont: 'mdi'
    }
})

export default vuetify