import { Component, Vue, Watch } from 'vue-property-decorator';
import { CustomsHandlingItemViewModel } from '../api/viewmodels/CustomsHandlingItemViewModel';
import * as CustomsController from '../api/CustomsController';
import moment from 'moment';

@Component({})
export default class Customs extends Vue {
    expanded = [];

    isLoading = false;

    currentHandlingItem: CustomsHandlingItemViewModel | null = null;
    customsHandlingItems: CustomsHandlingItemViewModel[] = [];

    @Watch('expanded')
    onExpandedChanged(newVal: CustomsHandlingItemViewModel[], oldVal: CustomsHandlingItemViewModel[]) {
        if (newVal.length === 0) {
            this.currentHandlingItem = null;
        }
        else {
            var customsHandlingId = newVal[0].id;
            this.isLoading = true;
            CustomsController.getCustomsHandlingItemById(customsHandlingId)
                .then(response => {
                    this.currentHandlingItem = response.data;
                    this.isLoading = false;
                })
                .catch(error => {
                    this.isLoading = false;
                })
        }
    }

    created() {
        this.$emit('PageInfoReceived', 'Main HAWB', [])

        this.isLoading = true;
        CustomsController.getCustomsHandlingItems().then(response => {
            this.customsHandlingItems = response.data;
            this.isLoading = false;
        }).catch(error => {
            this.isLoading = false;
        });
    }

    formatDateTime(dateTime: Date) {
        return moment(dateTime).format('DD.MM.YYYY');
    }

    singleExpand = true;
    itemsRc = ['Accessoires - Armband', 'Accessoires - brille', 'Accessoires - Headband', 'buzz'];
    valuesRc = ['foo', 'bar'];
    valuesCurrency = ['EUR', 'GBP'];

    fullView = false;
    tab = null;
    darkMode() {
        this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    };
    drawer = false;
    menu = false;
    dates = ['2021-09-10', '2021-09-20'];
    dateRangeText() {
        return this.dates.join(' ~ ')
    };
    autoUpdate = true;
    mrn = ['21GB00024612B25A06'];
    isUpdating = false;
    mrnNumbers = [
        { header: 'Confirmed' },
        { name: '21GB00024612B25A06', date: '30.06.2021', confirmed: true },
        { name: '21DE586600390742M0', date: '28.06.2021', confirmed: true },
        { name: '21GB00024612B12890', date: '25.06.2021', confirmed: true },
        { name: '21GB00024612B12892', date: '25.06.2021', confirmed: true },
        { divider: true },
        { header: 'Unconfirmed' },
        { name: '21GB00024612B12894', date: '30.06.2021', confirmed: false },
        { name: '21GB00024612B12895', date: '30.06.2021', confirmed: false },
        { name: '21GB00024612B12896', date: '30.06.2021', confirmed: false },
        { name: '21GB00024612B12897', date: '30.06.2021', confirmed: false },
    ];
    headers = [
        {
            text: '',
            align: ' d-none',
            value: 'status',
        },

        { text: 'HAWB', value: 'hawb' },
        { text: 'Pieces', value: 'pieces' },
        { text: 'Weight', value: 'weight' },
        { text: 'Value', value: 'value' },
        { text: 'Currency', value: 'currency' },
        { text: 'Shipper', value: 'shipper' },
        { text: 'Shipper address', value: 'shipperAddress' },
        { text: 'Shipper city', value: 'shipperCity' },
        { text: 'Shipper Zip', value: 'shipperZip' },
        { text: 'Shipper Country', value: 'shipperCountry' },
        { text: 'Consignee', value: 'consignee' },
        { text: 'Consignee Address', value: 'consigneeAddress' },
        { text: 'Consignee city', value: 'consigneeCity' },
        { text: 'Consignee Zip', value: 'consigneeZip' },
        { text: 'Consignee Country', value: 'consigneeCountry' },
        { text: 'EORI number', value: 'eoriNumber' },
    ];
    shipments = [
        {
            status: 1,
            hawb: 28336871590,
            pieces: 3,
            weight: '1',
            value: '123',
            currency: 'EUR',
            shipper: 'LE COL',
            shipperAddress: 'Sesamestreet 12',
            shipperCity: 'Hamburg',
            shipperZip: 1234,
            shipperCountry: 'Germany',
            consignee: 'Greg Gregson',
            consigneeAddress: 'Sesamestreet 12',
            consigneeCity: 'Hamburg',
            consigneeZip: 11234,
            consigneeCountry: 'Germany',
            eoriNumber: '123',
        },
        {
            status: 2,
            hawb: 38336871590,
            pieces: 3,
            weight: '1',
            value: '123',
            currency: 'EUR',
            shipper: 'LE COL',
            shipperAddress: 'Sesamestreet 12',
            shipperCity: 'Hamburg',
            shipperZip: 1234,
            shipperCountry: 'Germany',
            consignee: 'Greg Gregson',
            consigneeAddress: 'Sesamestreet 12',
            consigneeCity: 'Hamburg',
            consigneeZip: 11234,
            consigneeCountry: 'Germany',
            eoriNumber: '123',
        },
        {
            status: 3,
            hawb: 18336871590,
            pieces: 3,
            weight: '1',
            value: '123',
            currency: 'EUR',
            shipper: 'LE COL',
            shipperAddress: 'Sesamestreet 12',
            shipperCity: 'Hamburg',
            shipperZip: 1234,
            shipperCountry: 'Germany',
            consignee: 'Greg Gregson',
            consigneeAddress: 'Sesamestreet 12',
            consigneeCity: 'Hamburg',
            consigneeZip: 11234,
            consigneeCountry: 'Germany',
            eoriNumber: '123',
        },
    ];
    headersGrouped = [
        { text: 'Warenbeschreibung', value: 'warenbeschreibung', width: '40%' },
        { text: 'RC Tarif', value: 'rcTarif' },
        { text: 'SC Tarif', value: 'scTarif' },
        { text: 'Eigenmasse', value: 'eigenmasse' },
        { text: 'Rohmasse', value: 'rohmasse' },
        { text: 'Rechungspreis', value: 'rechungspreis' },
        { text: 'Rechungswahrung', value: 'rechungswahrung' },

    ];
    artikelenGrouped = [
        {
            id: 1,
            warenbeschreibung: 'Schuhpflegemittel in Sprayform',
            rcTarif: 34051000000,
            scTarif: 34051000000,
            eigenmasse: 0.5,
            rohmasse: 1.5,
            rechungspreis: 19.50,
            rechungswahrung: 'GBP',
        },
        {
            id: 2,
            warenbeschreibung: 'Schuhpflegemittel in Tuben, Dosen und in Sprayform',
            rcTarif: 34051000000,
            scTarif: 14051000000,
            eigenmasse: 0.5,
            rohmasse: 1.5,
            rechungspreis: 19.50,
            rechungswahrung: 'GBP',
        },
        {
            id: 3,
            warenbeschreibung: 'elektronisches Gerät, dient zum Ausführen von Videospielen',
            rcTarif: 95045000,
            scTarif: 85045000,
            eigenmasse: 0.5,
            rohmasse: 1.5,
            rechungspreis: 19.50,
            rechungswahrung: 'GBP',
        },
        {
            id: 4,
            warenbeschreibung: 'elektronisches Gerät, dient zum Ausführen von Videospielen',
            rcTarif: 95045000,
            scTarif: 85045000,
            eigenmasse: 0.5,
            rohmasse: 1.5,
            rechungspreis: 19.50,
            rechungswahrung: 'GBP',
        },
        {
            id: 5,
            warenbeschreibung: 'elektronisches Gerät, dient zum Ausführen von Videospielen',
            rcTarif: 95045000,
            scTarif: 75045000,
            eigenmasse: 0.5,
            rohmasse: 1.5,
            rechungspreis: 19.50,
            rechungswahrung: 'GBP',
        },

    ];
    artikelHeaders = [
        {
            text: 'Artikel',
            value: 'artikel',
        },
        { text: 'WTN', value: 'wtn' },
        { text: 'Zusatzcode', value: 'zusatzcode' },
        { text: 'RcTarif', value: 'rcTarif' },
        { text: 'ScTarif', value: 'scTarif' },
        { text: 'Eigenmasse', value: 'eigenmasse' },
        { text: 'Rohmasse', value: 'rohmasse' },
        { text: 'Rechungspreis', value: 'rechungspreis' },
        { text: 'Rechungswahrung', value: 'rechungswahrung' },
        { text: 'Artikelpreis', value: 'artikelpreis' },
        { text: 'Frachtkosten', value: 'frachtkosten' },
        { text: 'Frachtkostenwahrung', value: 'frachtkostenwahrung' },
        { text: 'Zollwert', value: 'zollwert' },
        { text: 'Stat. Wert', value: 'statWert' },
        { text: 'Stat. Menge', value: 'statMenge' },
    ];
    artikelen = [
        {
            artikel: 'Frozen Yogurt',
            wtn: 159,
            zusatzcode: 6.0,
            rcTarif: 24,
            scTarif: 'EUR',
            eigenmasse: '1%',
            rohmasse: 6.0,
            rechungspreis: 24,
            rechungswahrung: 'EUR',
            artikelpreis: '1%',
            frachtkosten: '1%',
            frachtkostenwahrung: 'EUR',
            zollwert: '1%',
            statWert: '1%',
            statMenge: 'EUR',
        },
        {
            artikel: 'Frozen Yogurt',
            wtn: 159,
            zusatzcode: 6.0,
            rcTarif: 24,
            scTarif: 'EUR',
            eigenmasse: '1%',
            rohmasse: 6.0,
            rechungspreis: 24,
            rechungswahrung: 'EUR',
            artikelpreis: '1%',
            frachtkosten: '1%',
            frachtkostenwahrung: 'EUR',
            zollwert: '1%',
            statWert: '1%',
            statMenge: 'EUR',
        },
        {
            artikel: 'Frozen Yogurt',
            wtn: 159,
            zusatzcode: 6.0,
            rcTarif: 24,
            scTarif: 'EUR',
            eigenmasse: '1%',
            rohmasse: 6.0,
            rechungspreis: 24,
            rechungswahrung: 'EUR',
            artikelpreis: '1%',
            frachtkosten: '1%',
            frachtkostenwahrung: 'EUR',
            zollwert: '1%',
            statWert: '1%',
            statMenge: 'EUR',
        },
    ];
    items = ['Foo', 'Bar', 'Fizz', 'Buzz'];
}