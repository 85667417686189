import { CustomsParcelApi, ShipmentDetailParcelViewModel } from "@/openapi";
import { Component, Prop, Vue } from "vue-property-decorator";
import { DataOptions, DataTableHeader } from "vuetify";
import { getSortBy, getSortDesc } from "@/helpers/dataTableSortHelper";
import CustomsParcelDialog from "./dialogs/CustomsParcelDialog.vue";
import DeleteDialog from "./dialogs/DeleteDialog.vue";
import { emitError, emitSuccess } from "@/event-bus";

const parcelsApi = new CustomsParcelApi(undefined, "");

@Component({
  components: {
    DeleteDialog,
    CustomsParcelDialog,
  },
})
export default class CustomsParcels extends Vue {
  @Prop({ required: true })
  shipmentId!: number;

  @Prop({ default: "" })
  height!: string;
  @Prop({ default: false })
  readonly!: boolean;

  @Prop({ default: false })
  adminView!: boolean;

  items: ShipmentDetailParcelViewModel[] = [];
  totalAmountOfItems = 0;
  loading = false;

  options: DataOptions = {
    page: 1,
    itemsPerPage: 100,
    sortBy: [],
    sortDesc: [],
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: true,
  };

  footerOptions: any = {
    showFirstLastPage: true,
    itemsPerPageOptions: [1, 5, 25, 50, 100],
    disablePagination: false,
  };
  headers = [
    { text: "Parcelnumber", value: "parcelNumber", width: "30%" },
    { text: "Weight", value: "weight" },
    { text: "Dimensions", value: "dimensions" },
  ] as DataTableHeader[];

  defaultModel = {
    id: 0,
    parcelNumber: null,
    weight: null,
    dimensions: null,
    index: 1,
  };

  selectedItem: ShipmentDetailParcelViewModel = { ...this.defaultModel };
  timeStampKey = Date.now();
  showEditDialog = false;
  saving = false;

  showDeleteDialog = false;
  deleting = false;

  openEditDialog(item: ShipmentDetailParcelViewModel | null) {
    this.timeStampKey = Date.now();
    this.selectedItem = item ? { ...item } : { ...this.defaultModel };
    this.showEditDialog = true;
  }

  openDeleteDialog(item: ShipmentDetailParcelViewModel) {
    this.selectedItem = item;
    this.showDeleteDialog = true;
  }

  async saveItem() {
    try {
      this.saving = true;
      const response = await parcelsApi.saveParcel(
        this.shipmentId,
        this.selectedItem,
      );

      this.saving = false;
      this.showEditDialog = false;
      this.selectedItem = { ...this.defaultModel };
      emitSuccess("Parcel saved.");
      await this.getParcels();
    } catch {
      emitError("Something went wrong while saving parcel.");
      this.saving = false;
    }
  }

  async deleteItem() {
    try {
      await parcelsApi.deleteParcel(this.selectedItem.id);
      this.showEditDialog = false;
      this.selectedItem = { ...this.defaultModel };
      emitSuccess("Parcel deleted.");
    } catch {
      emitError("Something went wrong while deleting parcel.");
    }
    await this.getParcels();
    this.showDeleteDialog = false;
    this.selectedItem = { ...this.defaultModel };
  }

  async created() {
    if (this.adminView) {
      this.headers.push({
        text: "",
        sortable: false,
        value: "action",
        align: "end",
      });
    }
    await this.getParcels();
  }

  async getParcels() {
    try {
      this.loading = true;
      const response = await parcelsApi.getParcels(
        this.shipmentId,
        getSortBy(this.options),
        getSortDesc(this.options),
        this.options.page,
        this.options.itemsPerPage,
      );

      this.items = response.data.items ?? [];
      this.totalAmountOfItems = response.data.totalAmountOfItems ?? 0;
    } catch {
      emitError(
        "Something went wrong while retrieving the parcels of the shipment.",
      );
    }

    this.loading = false;
  }

  onUpdateOptions(options: DataOptions) {
    this.getParcels();
  }
}
