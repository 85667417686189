<template>
  <Fragment>
    <v-card>
      <v-card-text>
        <v-data-table
          v-model="consolidationStore.articleInformationItems"
          :headers="headers"
          :items="computedItems"
          :show-select="true"
          :loading="loading"
          fixed-header
          :footer-props="footerProps"
          dense
        >
          <template #header.data-table-select></template>
          <template #item.processingDate="{ item }">
            <span>{{ item.processingDate | formatDate }}</span>
          </template>
          <template #item.download="{ item }">
            <v-btn icon @click="downloadArticleInformationFile(item.id)"
              ><v-icon>mdi-file-xml-box</v-icon></v-btn
            >
          </template>
          <template #item.actions="{ item }">
            <v-btn icon @click="deleteArticleInformationFile(item)"
              ><v-icon>mdi-delete</v-icon></v-btn
            >
          </template>
        </v-data-table>

        <v-container>
          <v-row>
            <v-col cols="6">
              <div>
                <span
                  class="font-weight-medium text-decoration-underline text-h6"
                  >Export declaration</span
                >
                <div v-if="consolidationStore.selectedExportDeclaration">
                  <div>
                    Shipper name:
                    {{
                      consolidationStore.selectedExportDeclaration.shipperName
                    }}
                  </div>
                  <div>
                    Mrn:
                    {{ consolidationStore.selectedExportDeclaration.mrn }}
                  </div>
                  <div>
                    Invoice number:
                    {{
                      consolidationStore.selectedExportDeclaration.invoiceNumber
                    }}
                  </div>
                  <div
                    v-if="
                      consolidationStore.selectedExportDeclaration
                        .processingDate
                    "
                  >
                    Processing date:
                    {{
                      consolidationStore.selectedExportDeclaration
                        .processingDate | formatDate
                    }}
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols="6"
              ><div>
                <span
                  class="font-weight-medium text-decoration-underline text-h6"
                  >Invoice</span
                >
                <div v-if="consolidationStore.selectedInvoice">
                  <div>
                    Shipper name:
                    {{ consolidationStore.selectedInvoice.shipperName }}
                  </div>
                  <div>
                    Invoice number:
                    {{ consolidationStore.selectedInvoice.invoiceNumber }}
                  </div>
                  <div v-if="consolidationStore.selectedInvoice.processingDate">
                    Processing date:
                    {{
                      consolidationStore.selectedInvoice.processingDate
                        | formatDate
                    }}
                  </div>
                </div>
              </div></v-col
            >
          </v-row>
        </v-container>
        <v-btn
          class="mt-4"
          color="primary"
          :disabled="consolidationStore.articleInformationItems.length === 1"
          @click="$emit('next-step')"
        >
          Continue
        </v-btn>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="showConfirmDeleteArticleFileDialog"
      persistent
      max-width="400px"
    >
      <v-card>
        <v-card-title>Are you sure?</v-card-title>
        <v-card-text
          >Are you sure you want to delete the file with MRN '{{
            currentDeleteArticleFile?.mrn
          }}', invoice number '{{ currentDeleteArticleFile?.invoiceNumber }}'
          and type '{{ currentDeleteArticleFile?.type }}'? This can't be
          undone.</v-card-text
        >
        <v-card-actions>
          <v-btn text @click="cancelDeleteArticleInformationFile">Cancel</v-btn>
          <v-spacer />
          <v-btn color="error" text @click="confirmDeleteArticleInformationFile"
            >Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </Fragment>
</template>

<script setup lang="ts">
import { emitErrorWithFallback } from '@/event-bus';
import { ArticleInformationFile, CustomsConsolidationApi } from '@/openapi';
import { computed, onBeforeMount, ref } from 'vue';
import { DataTableHeader } from 'vuetify';

import {
  downloadFile,
  getFileNameFromContentDispositionHeader,
} from "@/helpers/downloadHelper";
import { FooterOptions } from "@/types/types";
import { useConsolidationStore } from "@/stores/consolidation-store";
import { Fragment } from "vue-frag";
interface SelectableArticleInformationFile extends ArticleInformationFile {
    isSelectable: boolean;
}

const api = new CustomsConsolidationApi(undefined, '');
const consolidationStore = useConsolidationStore();

const footerProps = ref<FooterOptions>({
    showFirstLastPage: true,
    itemsPerPageOptions: [5, 25, 50, 100],
    disablePagination: false,
});

const headers = ref<DataTableHeader[]>([
  {
    value: "shipperName",
    text: "Shipper name",
    sortable: true,
  },
  {
    value: "mrn",
    text: "Mrn",
    sortable: true,
  },
  {
    value: "invoiceNumber",
    text: "Invoice number",
    sortable: true,
  },
  {
    value: "type",
    text: "Type",
    sortable: true,
  },
  {
    value: "processingDate",
    text: "Processing date",
    sortable: true,
  },
  {
    value: "download",
    text: "Download",
    sortable: false,
  },
  {
    value: "actions",
    text: "",
    sortable: false,
  },
]);

const items = ref<ArticleInformationFile[]>([]);
const loading = ref(false);

const showConfirmDeleteArticleFileDialog = ref(false);
const currentDeleteArticleFile = ref<ArticleInformationFile | null>(null);

const downloadArticleInformationFile = async (id: number) => {
  try {
    const response = await api.getArticleInformationFile(id);
    const fileName = getFileNameFromContentDispositionHeader(response);
    downloadFile(response.data, fileName!);
  } catch (e: unknown) {
    emitErrorWithFallback(e, "Something went wrong while downloading the file");
  }
};

const deleteArticleInformationFile = (file: ArticleInformationFile) => {
  currentDeleteArticleFile.value = file;
  showConfirmDeleteArticleFileDialog.value = true;
};

const cancelDeleteArticleInformationFile = () => {
  showConfirmDeleteArticleFileDialog.value = false;
  currentDeleteArticleFile.value = null;
};

const confirmDeleteArticleInformationFile = async () => {
  try {
    if (!currentDeleteArticleFile.value) {
      cancelDeleteArticleInformationFile();
      return;
    }
    const response = await api.deleteArticleInformationFile(
      currentDeleteArticleFile.value.id,
    );
    consolidationStore.articleInformationItems = [];
    await getArticleInformationFiles();
    showConfirmDeleteArticleFileDialog.value = false;
    currentDeleteArticleFile.value = null;
  } catch (e: unknown) {
    emitErrorWithFallback(e, "Something went wrong while deleting the file");
  }
};

const computedItems = computed(() => {
    return items.value.map(
        (item) =>
            ({
                ...item,
                isSelectable:
                    !consolidationStore.articleInformationItems?.length ||
                    !consolidationStore.articleInformationItems?.some(
                        (c) => c.id !== item.id && c.type === item.type
                    ),
            } as SelectableArticleInformationFile)
    );
});

const getArticleInformationFiles = async () => {
  loading.value = true;
  try {
    const response = await api.getArticleInformationFiles();
    items.value = response.data.map((item) => ({
      ...item,
      isSelectable: true,
    }));
  } catch (e: unknown) {
    emitErrorWithFallback(
      e,
      "Something went wrong while retrieving the information",
    );
  }
  loading.value = false;
};

onBeforeMount(async () => {
  await getArticleInformationFiles();
});
</script>
