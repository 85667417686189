import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { CustomsArticle, DataApi } from '@/openapi';
import { emitError } from '@/event-bus';
import { ValidationObserver } from 'vee-validate';

export interface ArticleMaintenanceEditableData {
    hsCode: number | undefined | null;
    description: string | undefined | null;
}

export interface ArticleMaintenanceFulfillmentForm {
    eza: ArticleMaintenanceEditableData;
    impost: ArticleMaintenanceEditableData;
    useGeneralCustomerForAutomatedProcess: boolean;
    useSenderForAutomatedProcess: boolean;
    documents: boolean;
    customer?: string | null;
    senderName?: string | null;
}

const api = new DataApi(undefined, '');

@Component({})
export default class ArticleMaintenanceDialog extends Vue {
    @Prop({ default: false })
    value!: Boolean;

    @Prop({ default: false })
    isLoading!: Boolean;

    @Prop({ default: 'Fulfill open article' })
    title!: String;

    @Prop({ default: 'Confirm' })
    confirmButtonText!: String;

    @Prop({ default: 'Close' })
    closeButtonText!: String;

    @Prop()
    initialData!: ArticleMaintenanceFulfillmentForm;

    ezaArticles: CustomsArticle[] = [];
    impostArticles: CustomsArticle[] = [];

    loadingEzaArticles = false;
    loadingImpostArticles = false;

    form: ArticleMaintenanceFulfillmentForm | null = {
        eza: {
            hsCode: null,
            description: '',
        },
        impost: {
            hsCode: null,
            description: '',
        },
        useGeneralCustomerForAutomatedProcess: false,
        useSenderForAutomatedProcess: false,
        documents: false,
    };

    created() {
        this.getEzaArticles();
        this.getImpostArticles();
        if (!!this.initialData) {
            this.form = this.initialData;
        }
    }

    async getEzaArticles() {
        this.loadingEzaArticles = true;
        try {
            const response = await api.getCustomsArticles(false);
            this.ezaArticles = response.data;
        } catch {
            emitError(
                'Something went wrong while retrieving the EZA HS codes.'
            );
        }
        this.loadingEzaArticles = false;
    }

    async getImpostArticles() {
        this.loadingImpostArticles = true;
        try {
            const response = await api.getCustomsArticles(true);
            this.impostArticles = response.data;
        } catch {
            emitError(
                'Something went wrong while retrieving the IMPOST HS codes.'
            );
        }
        this.loadingImpostArticles = false;
    }

    getItemName(item: CustomsArticle) {
        return item.hsCode === item.description
            ? item.hsCode
            : `${item.hsCode} - ${item.description}`;
    }

    confirm() {
        this.$emit('confirm', {
            eza: {
                ...this.form!.eza,
            },
            impost: {
                ...this.form!.impost,
            },
            useGeneralCustomerForAutomatedProcess:
                this.form!.useGeneralCustomerForAutomatedProcess,
            useSenderForAutomatedProcess:
                this.form!.useSenderForAutomatedProcess,
            documents: this.form?.documents,
        } as ArticleMaintenanceFulfillmentForm);
    }
}
