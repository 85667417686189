<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-toolbar dark color="grey darken-3">
                        <v-btn icon :to="{ name: 'Employees' }" exact>
                            <v-icon>mdi-arrow-left</v-icon></v-btn
                        >
                        <v-toolbar-title>{{ title }}</v-toolbar-title>

                        <template v-slot:extension>
                            <v-tabs align-with-title>
                                <v-tabs-slider color="yellow"></v-tabs-slider>
                                <v-tab
                                    :to="{
                                        name: 'EmployeeDetailInfo',
                                        params: { employeeId: employeeId },
                                    }"
                                    exact
                                    ><v-icon left>mdi-account</v-icon> Info
                                </v-tab>
                                <v-tab
                                    :to="{
                                        name: 'EmployeeDetailRights',
                                        params: { employeeId: employeeId },
                                    }"
                                    exact
                                    :disabled="!employeeId"
                                    ><v-icon left>mdi-account-key</v-icon>User
                                    rights</v-tab
                                >
                            </v-tabs>
                        </template>
                    </v-toolbar>
                    <v-card-text>
                        <router-view></router-view>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
import { computed } from 'vue';

interface EmployeeDetailProps {
    employeeId?: number;
}

const props = defineProps<EmployeeDetailProps>();

const title = computed(() => {
    return props.employeeId
        ? `Employee - ${props.employeeId}`
        : 'Employee - Create';
});
</script>
