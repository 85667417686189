<template>
  <ValidationObserver slim v-slot="{ invalid }" ref="observer">
    <DefaultDialog :value="value">
      <template #header>Street MRN</template>
      <template #content>
        <v-form>
          <ValidationProvider
            rules="required"
            v-slot="{ errors }"
            name="Street MRN"
          >
            <v-text-field
              v-model="mrn"
              label="Street MRN"
              :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
        </v-form>
      </template>
      <template #footer>
        <v-spacer></v-spacer>
        <v-btn @click="close" :disabled="dialogLoading" text small>Close</v-btn>
        <v-btn
          @click="confirm"
          :disabled="invalid"
          :loading="dialogLoading"
          text
          small
          color="primary"
          >Confirm</v-btn
        >
      </template>
    </DefaultDialog>
  </ValidationObserver>
</template>

<script setup lang="ts">
import { ValidationObserver } from "vee-validate";
import { ref, watch } from "vue";
import DefaultDialog from "@/components/dialogs/DefaultDialog.vue.html";
import {
  CustomsFlightHandlingApi,
  FlightHandlingSetStreetMrnRequest,
} from "@/openapi";
import { emitErrorWithFallback, emitSuccess } from "@/event-bus";

interface StreetMrnDialogProps {
  value: boolean;
  consolidationId: number;
  t1DeclarationId: number;
}

const flightHandlingApi = new CustomsFlightHandlingApi(undefined, "");
const observer = ref<InstanceType<typeof ValidationObserver> | null>(null);

const props = defineProps<StreetMrnDialogProps>();
const emits = defineEmits(["input", "confirm"]);

const mrn = ref("");
const dialogLoading = ref(false);

watch(
  () => props.value,
  (newVal, oldVal) => {
    mrn.value = "";
  },
);

const close = () => {
  emits("input", false);
};

const confirm = async () => {
  if (!mrn.value) {
    return;
  }

  dialogLoading.value = true;
  try {
    const request: FlightHandlingSetStreetMrnRequest = {
      consolidationId: props.consolidationId,
      t1DeclarationId: props.t1DeclarationId,
      streetMrn: mrn.value,
    };
    await flightHandlingApi.setConsolidationStreetMrn(request);
    mrn.value = "";
    emitSuccess("Street MRN was set");
    emits("confirm");
  } catch (error: unknown) {
    emitErrorWithFallback(
      error,
      "Something went wrong while setting the street MRN",
    );
  } finally {
    dialogLoading.value = false;
  }
};
</script>
