import { ValidationObserver } from "vee-validate";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import DefaultDialog from "./DefaultDialog.vue.html";
import TransitDefinitionEditor from "@/components/editors/TransitDefinitionEditor.vue.html";
import MultipleItemsEditor from "@/components/editors/MultipleItemsEditor.vue.html";
import { DataAction } from "@/store/dataModule";
import TransitTemplateEditor from "@/components/editors/TransitTemplateEditor.vue";
import { CountryOfOriginViewModel } from "@/openapi";

export interface CreateTransitDeclarationForm {
  amount: number;
  registration: string;
  registrationTruck: string;
  country: string;
  countryTruck: string;
  transportAcrossEuBorder: boolean;
  signs: TransitDeclarationSign[];
  routeId: number | null;
  templateId: number | null;
}

export interface TransitDeclarationSign {
  value: string;
}

const sign: TransitDeclarationSign = {
  value: "",
};

@Component({
  components: {
    DefaultDialog,
    TransitDefinitionEditor,
    MultipleItemsEditor,
    TransitTemplateEditor,
  },
})
export default class CreateTransitDeclarationDialog extends Vue {
  @Prop()
  value!: boolean; // Used to determine whether to show/hide the dialog

  @Prop({ default: false })
  createTransitDeclarationLoading!: boolean;

  @Prop({ default: false })
  hideSignSection!: boolean;

  @Prop({ default: false })
  hideTransitDefinitionSection!: boolean;

  $refs!: {
    observer: InstanceType<typeof ValidationObserver>;
  };

  form: CreateTransitDeclarationForm = this.setDefaultFormValues();

  @Watch("value")
  onValueChanged(newValue: boolean, oldVal: boolean) {
    if (!newValue) {
      this.form = this.setDefaultFormValues();
      this.$refs.observer.reset();
    }
  }

  @Watch("form.routeId")
  onRouteIdChanged(newValue: boolean, oldVal: boolean) {
    this.form.templateId = null;
  }

  async created() {
    await this.$store.dispatch(DataAction.FetchCountriesOfOrigin);
  }

  setDefaultFormValues(): CreateTransitDeclarationForm {
    return {
      amount: 0,
      registration: "",
      registrationTruck: "",
      country: "",
      countryTruck: "",
      transportAcrossEuBorder: false,
      signs: [{ ...sign }],
      routeId: null,
      templateId: null,
    };
  }

  confirm() {
    this.$emit(
      "confirm",
      JSON.parse(JSON.stringify(this.form)) as CreateTransitDeclarationForm,
    );
  }

  createSign(): TransitDeclarationSign {
    return {
      value: "",
    };
  }

  get customsCountries() {
    return this.$store.getters.countriesOfOrigin;
  }

  getCountryValueString(country: CountryOfOriginViewModel) {
    return `${country.code} (${country.description})`;
  }
}
