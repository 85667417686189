<template>
    <Fragment>
        <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="totalAmountOfItems"
            item-key="level"
            hide-default-footer
            fixed-header
            height="calc(100vh - 280px)"
            class="elevation-1"
            :loading="loading"
        >
            <template #top>
                <div class="filters">
                    <v-autocomplete
                        v-model="filters.employeeId"
                        :items="employees"
                        item-value="id"
                        item-text="name"
                        label="Employee"
                        clearable
                        @change="getItems"
                    ></v-autocomplete>

                    <DatePicker
                        v-model="filters.month"
                        label="Month"
                        type="month"
                        @input="getItems"
                    ></DatePicker>
                </div>
            </template>
        </v-data-table>
    </Fragment>
</template>
<script setup lang="ts">
import { emitError } from '@/event-bus';
import { ToolbarItem } from '@/models/ToolbarItem';
import store from '@/store';
import { DataAction } from '@/store/dataModule';
import { computed, onBeforeMount, ref } from 'vue';
import { DataTableHeader } from 'vuetify';
import DatePicker from '@/components/editors/DatePicker.vue.html';
import { ImportClearancesDashboardApi } from '@/openapi';
import moment from 'moment';

interface EmployeeJobStatusDashboardFilters {
    employeeId: number | null;
    month: string | null;
}

const api = new ImportClearancesDashboardApi(undefined, '');

const emits = defineEmits(['PageInfoReceived']);

const headers = ref<DataTableHeader[]>([
    {
        text: 'Level',
        value: 'level',
        sortable: false,
    },
    {
        text: 'Completed',
        value: 'amountOfCompleted',
        sortable: false,
    },
    {
        text: 'Escalated',
        value: 'amountOfEscalated',
        sortable: false,
    },
]);

const filters = ref<EmployeeJobStatusDashboardFilters>({
    employeeId: null,
    month: null,
});

const items = ref<any[]>([]);
const totalAmountOfItems = ref(0);
const loading = ref(false);

const getItems = async () => {
    if (!filters.value.employeeId) {
        items.value = [];
        totalAmountOfItems.value = 0;
        return;
    }
    loading.value = true;
    try {
        const formattedDate = filters.value.month
            ? moment(filters.value.month).format('YYYY-MM-DD')
            : undefined;

        const response = await api.getJobStatusByLevelForEmployee(
            filters.value.employeeId!,
            formattedDate
        );
        items.value = response.data ?? [];
        totalAmountOfItems.value = response.data.length ?? 0;
    } catch {
        emitError('Something went while retrieving the items');
    }
    loading.value = false;
};

const employees = computed(() => store.getters.employees);

onBeforeMount(async () => {
    var toolbarButtons: ToolbarItem[] = [
        {
            callback: () => getItems(),
            icon: 'mdi-refresh',
            tooltipText: 'Refresh overview',
        },
    ];
    emits('PageInfoReceived', 'Test', toolbarButtons);

    store.dispatch(DataAction.FetchEmployees);
});
</script>

<style scoped lang="scss">
.filters {
    display: flex;
    justify-content: center;
    gap: 15px;
    padding: 15px;
}

</style>
