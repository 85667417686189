<template>
    <v-menu
        transition="scale-transition"
        offset-y
        :close-on-content-click="false"
        min-width="290px"
        @input="onMenuChange"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                :value="displayText"
                :label="label"
                prepend-icon="mdi-calendar"
                readonly
                clearable
                :dense="dense"
                :outlined="outlined"
                v-bind="attrs"
                v-on="on"
                @click:clear="clearValue"
                :error-messages="errorMessages"
            ></v-text-field>
        </template>
        <v-tabs v-model="currentTab" fixed-tabs background-color="primary" dark>
            <v-tab><v-icon>mdi-calendar</v-icon></v-tab>
            <v-tab :disabled="!localDateValue"
                ><v-icon>mdi-clock</v-icon></v-tab
            >
        </v-tabs>
        <v-tabs-items v-model="currentTab">
            <v-tab-item
                ><v-date-picker v-model="localDateValue"></v-date-picker
            ></v-tab-item>
            <v-tab-item>
                <v-time-picker
                    v-model="localTimeValue"
                    format="24hr"
                ></v-time-picker>
            </v-tab-item>
        </v-tabs-items>
    </v-menu>
</template>

<script setup lang="ts">
import moment from 'moment';
import { computed, ref } from 'vue';

interface DateTimePickerProps {
    value?: string | null;
    label: string;
    errorMessages?: string[];
    dense?: boolean;
    outlined?: boolean;
}

const props = withDefaults(defineProps<DateTimePickerProps>(), {
    value: null,
    errorMessages: () => [],
    label: 'Date time',
    dense: false,
    outlined: false,
});

const emits = defineEmits(['input']);

const currentTab = ref(0);

const displayText = computed(() => {
    if (!props.value) {
        return props.value;
    }
    return moment(props.value).format('DD-MM-YYYY HH:mm:ss');
});

const localDateValue = computed({
    get() {
        if (!props.value) {
            return props.value;
        }
        return props.value?.split('T')?.at(0) ?? '';
    },
    set(value: string | null) {
        if (value && localDateValue.value) {
            value = `${value}T${localTimeValue.value}`;
        }
        emits('input', value);
    },
});

const localTimeValue = computed({
    get() {
        if (!props.value) {
            return '';
        }

        const splittedDateTime = props.value?.split('T');
        return splittedDateTime.at(1)?.substring(0, 8) ?? '';
    },
    set(value: string | null) {
        value = value ? `${localDateValue.value}T${value}` : value;
        emits('input', value);
    },
});

const onMenuChange = (value: boolean) => {
    if (!value) {
        currentTab.value = 0;
    }
};

const clearValue = () => {
    currentTab.value = 0;
    emits('input', null);
};
</script>

<style scoped lang="scss">
:deep(.v-picker) {
    border-radius: 0;
}
</style>
