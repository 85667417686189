import { render, staticRenderFns } from "./InvoiceFooterTexts.vue?vue&type=template&id=6777bc2e&scoped=true"
import script from "./InvoiceFooterTexts.vue?vue&type=script&setup=true&lang=ts"
export * from "./InvoiceFooterTexts.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./InvoiceFooterTexts.vue?vue&type=style&index=0&id=6777bc2e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6777bc2e",
  null
  
)

export default component.exports