import axios from 'axios';
import Vue from 'vue';
import { isOf } from './helpers/typeGuard';
import { ProblemDetails } from './openapi';

const EventBus = new Vue();

export default EventBus;

export function emitError(...errorMessages: string[]) {
    EventBus.$emit('onError', errorMessages);
}

export function emitErrorWithFallback(
    errorResponse: unknown,
    ...errorMessages: string[]
) {
    if (
        axios.isAxiosError(errorResponse) &&
        isOf<ProblemDetails>(errorResponse.response?.data, (c) => c?.detail)
    ) {
        if (errorResponse.response!.data.detail) {
            errorMessages = [errorResponse.response!.data.detail];
        }
    }
    emitError(...errorMessages);
}

export function emitSuccess(...successMessages: string[]) {
    EventBus.$emit('onSuccess', successMessages);
}
