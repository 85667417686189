<template>
  <ValidationObserver v-slot="{ invalid }" ref="observer">
    <DefaultDialog :value="value">
      <template v-slot:header>{{ header }}</template>
      <template v-slot:content>
        <v-form>
          <ValidationProvider
            rules="required"
            name="customer"
            v-slot="{ errors }"
          >
            <v-autocomplete
              v-model="formData.customerId"
              :items="customers"
              item-value="id"
              item-text="shortName"
              name="Customer"
              label="Select a customer"
              :error-messages="errors"
            ></v-autocomplete>
          </ValidationProvider>
          <ValidationProvider
            rules="required|min_value:1"
            name="First digit to scan"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model.number="formData.firstDigitToScan"
              type="number"
              label="First digit to scan"
              :min="1"
              :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
          <v-text-field
            v-model.number="formData.lastDigitToScan"
            type="number"
            label="Last digit to scan"
          ></v-text-field>
        </v-form>
      </template>
      <template v-slot:footer>
        <v-spacer></v-spacer>
        <v-btn @click="close" :disabled="dialogLoading" text small>Close</v-btn>
        <v-btn
          @click="$emit('confirm', formData)"
          :disabled="invalid"
          :loading="dialogLoading"
          text
          small
          color="primary"
          >{{ actionName }}</v-btn
        >
      </template>
    </DefaultDialog>
  </ValidationObserver>
</template>
<script setup lang="ts">
import store from "@/store";
import { DataAction } from "@/store/dataModule";
import { ValidationObserver } from "vee-validate";
import { computed, ref, watch } from "vue";
import DefaultDialog from "./DefaultDialog.vue.html";

export interface HawbDefinitionForm {
  id?: number | null;
  customerId: number | null;
  firstDigitToScan: number | null;
  lastDigitToScan: number | null;
}

interface HawbDefinitionDialogProps {
  value: boolean;
  dialogLoading: boolean;
  initialData?: HawbDefinitionForm | null;
}
const props = defineProps<HawbDefinitionDialogProps>();
const emits = defineEmits(["input", "close", "confirm"]);
const defaultFormValues: HawbDefinitionForm = {
  customerId: null,
  firstDigitToScan: null,
  lastDigitToScan: null,
};

const formData = ref<HawbDefinitionForm>({ ...defaultFormValues });
const observer = ref<InstanceType<typeof ValidationObserver> | null>(null);

const header = computed(() =>
  props.initialData?.id ? "Edit HAWB definition" : "Create HAWB definition",
);

const actionName = computed(() => (props.initialData?.id ? "Edit" : "Create"));
const customers = computed(() => store.getters.generalCustomers);

watch(
  () => props.initialData,
  (newValue: HawbDefinitionForm) => {
    if (newValue) {
      formData.value = { ...newValue };
    }
  },
  {
    immediate: true,
  },
);

watch(
  () => props.value,
  (newValue: boolean, oldVal: boolean) => {
    if (newValue == false) {
      formData.value = { ...defaultFormValues };
      observer.value!.reset();
    }
  },
);

const close = () => {
  emits("input", false);
  emits("close");
};

store.dispatch(DataAction.FetchGeneralCustomers);
</script>
