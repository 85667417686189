import { Component, Vue, Watch } from 'vue-property-decorator';

@Component({})
export default class ButtonGroup extends Vue {
    btnToggleState = [];

    @Watch('btnToggleState')
    btnToggleStateWatcher(n: any, o: any) {
        if (JSON.stringify(n) == JSON.stringify(o)) {
            return;
        }
        this.btnToggleState = [];
    }
}