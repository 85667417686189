

import axios, { AxiosPromise, AxiosTransformer } from 'axios';

let ISO_8601 = /^(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2}:\d{2})$/;
let transformDate = function (data: any) {
    if(data === undefined || data === null)
    {
        return null;
    }

    Object.keys(data).forEach(function (k) {
        if(data[k] instanceof Object) {
            transformDate(data[k]);
        } else {
            if (ISO_8601.test(data[k])) {
                data[k] = new Date(Date.parse(data[k]));
            }
        }
    });
    return data;
};
let transformResponse = (axios.defaults.transformResponse as AxiosTransformer[]).concat(transformDate)

import { GroupArticlesViewModel } from './viewmodels/GroupArticlesViewModel'
import { CustomsHandlingItemViewModel } from './viewmodels/CustomsHandlingItemViewModel'
import { EscalateToNextLevelViewModel } from './viewmodels/EscalateToNextLevelViewModel'
import { DakosyExportViewModel } from './viewmodels/DakosyExportViewModel'
import { ArticleViewModel } from './viewmodels/ArticleViewModel'


        
    // GetCustomsHandlingItems
    export function getCustomsHandlingItems () : AxiosPromise<CustomsHandlingItemViewModel[]> {
        return axios({
            url: `api/Customs/getCustomsHandlingItems/`, 
            method: "get",
            data: null,
            transformResponse: transformResponse,
            responseType: "json"
        });
    };    
    // GetCustomsHandlingItemById
    export function getCustomsHandlingItemById (customsHandlingItemId: number) : AxiosPromise<CustomsHandlingItemViewModel> {
        return axios({
            url: `api/Customs/getCustomsHandlingItemById/?customsHandlingItemId=${customsHandlingItemId}`, 
            method: "get",
            data: null,
            transformResponse: transformResponse,
            responseType: "json"
        });
    };    
    // GroupArticles
    export function groupArticles (vm: GroupArticlesViewModel) : AxiosPromise<ArticleViewModel[]> {
        return axios({
            url: `api/Customs/groupArticles/`, 
            method: "post",
            data: vm,
            transformResponse: transformResponse,
            responseType: "json"
        });
    };    
    // SaveCustomsHandlingItem
    export function saveCustomsHandlingItem (model: CustomsHandlingItemViewModel) : AxiosPromise<CustomsHandlingItemViewModel> {
        return axios({
            url: `api/Customs/saveCustomsHandlingItem/`, 
            method: "post",
            data: model,
            transformResponse: transformResponse,
            responseType: "json"
        });
    };    
    // ExportToDakosy
    export function exportToDakosy (customsHandlingItemId: number) : AxiosPromise<DakosyExportViewModel> {
        return axios({
            url: `api/Customs/exportToDakosy/?customsHandlingItemId=${customsHandlingItemId}`, 
            method: "post",
            data: null,
            transformResponse: transformResponse,
            responseType: "json"
        });
    };    
    // EscalateToNextLevel
    export function escalateToNextLevel (vm: EscalateToNextLevelViewModel) : AxiosPromise<any> {
        return axios({
            url: `api/Customs/escalateToNextLevel/`, 
            method: "post",
            data: vm,
            transformResponse: transformResponse,
            responseType: "json"
        });
    };

