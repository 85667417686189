<template>
  <Fragment>
    <ValidationObserver slim v-slot="{ invalid }">
      <ConfirmDialog
        v-model="localValue"
        @confirm="onConfirm"
        :is-confirm-disabled="isRetrieving || invalid"
        :is-loading="isProcessing"
        max-width="1000px"
        title="Release | Information required"
        class="information-required-dialog"
      >
        <div class="main-fields">
          <ValidationProvider rules="required" name="Name" v-slot="{ errors }">
            <v-text-field
              v-model="formData.name"
              dense
              label="Name*"
              outlined
              :loading="isRetrieving"
              :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
          <ValidationProvider
            rules="required"
            name="Address"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="formData.address"
              dense
              label="Address*"
              outlined
              :loading="isRetrieving"
              :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
          <ValidationProvider
            rules="required"
            name="Zipcode"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="formData.postalCode"
              dense
              label="Zipcode*"
              outlined
              :loading="isRetrieving"
              :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
          <ValidationProvider rules="required" name="City" v-slot="{ errors }">
            <v-text-field
              v-model="formData.city"
              dense
              label="City*"
              outlined
              :loading="isRetrieving"
              :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
          <ValidationProvider
            name="Country"
            rules="required"
            v-slot="{ errors }"
          >
            <v-select
              v-model="formData.country"
              :items="countries"
              item-text="code"
              item-value="code"
              dense
              clearable
              label="Country*"
              outlined
              :loading="isRetrieving"
              :error-messages="errors"
            ></v-select>
          </ValidationProvider>
          <ValidationProvider name="IOSS number">
            <v-text-field
              v-model="formData.iossNumber"
              dense
              label="IOSS number"
              outlined
              :loading="isRetrieving"
            ></v-text-field>
          </ValidationProvider>
        </div>

        <div class="article-fields">
          <span class="d-block font-weight-bold mb-4 grey--text text--darken-4">
            Articles
          </span>
          <MultipleItemsEditor
            v-if="formData.articles?.length"
            v-model="formData.articles"
            :can-delete="false"
          >
            <template #editor="{ item }">
              <ValidationProvider
                rules="required"
                v-slot="{ errors }"
                name="Description"
                slim
              >
                <v-text-field
                  v-model="item.description"
                  :error-messages="errors"
                  class="article-description"
                  outlined
                  dense
                  label="Description"
                ></v-text-field>
              </ValidationProvider>
              <ValidationProvider
                rules="required"
                v-slot="{ errors }"
                name="Tarif"
                slim
              >
                <v-text-field
                  v-model="item.tarifCode"
                  :error-messages="errors"
                  class="article-tarif"
                  outlined
                  dense
                  label="Tarif"
                ></v-text-field>
              </ValidationProvider>
              <ValidationProvider
                rules="required"
                v-slot="{ errors }"
                name="Value"
                slim
              >
                <v-text-field
                  v-model="item.value"
                  type="number"
                  :error-messages="errors"
                  class="article-value"
                  outlined
                  dense
                  label="Value"
                ></v-text-field>
              </ValidationProvider>
              <ValidationProvider
                rules="required"
                v-slot="{ errors }"
                name="Currency"
                slim
              >
                <v-autocomplete
                  v-model="item.valueCurrency"
                  :items="currencies"
                  :error-messages="errors"
                  item-key="id"
                  item-text="name"
                  label="Currency"
                  class="article-currency"
                  clearable
                  dense
                  outlined
                  single-line
                ></v-autocomplete>
              </ValidationProvider>
            </template>
          </MultipleItemsEditor>
          <span v-else-if="!formData.articles?.length && !isRetrieving"
            >No corresponding articles exist</span
          >
        </div>
      </ConfirmDialog>
    </ValidationObserver>
  </Fragment>
</template>

<script setup lang="ts">
import { emitErrorWithFallback, emitSuccess } from "@/event-bus";
import { ProblemShipmentsApi } from "@/openapi";
import store from "@/store";
import { DataAction } from "@/store/dataModule";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { computed, onBeforeMount, ref, watch } from "vue";
import ConfirmDialog from "../ConfirmDialog.vue.html";
import MultipleItemsEditor from "@/components/editors/MultipleItemsEditor.vue.html";

export interface InformationRequiredFormData {
  name: string;
  address: string;
  postalCode: string;
  city: string;
  country: string;
  iossNumber: string;
  articles: InformationRequiredArticleFormData[];
}

export interface InformationRequiredArticleFormData {
  id: number;
  description: string;
  tarifCode: string;
  value: number | null;
  valueCurrency: string;
}

interface InformationRequiredDialogProps {
  value: boolean;
  blockingMilestoneId: number;
}

const api = new ProblemShipmentsApi(undefined, "");
const props = defineProps<InformationRequiredDialogProps>();
const emits = defineEmits(["input", "confirm"]);

const defaultFormValues: InformationRequiredFormData = {
  name: "",
  address: "",
  postalCode: "",
  city: "",
  country: "",
  iossNumber: "",
  articles: [],
};

const formData = ref<InformationRequiredFormData>({ ...defaultFormValues });
const isRetrieving = ref(false);
const isProcessing = ref(false);

const getRequiredInformationData = async () => {
  isRetrieving.value = true;
  try {
    const response = await api.getInformationRequiredCategory(
      props.blockingMilestoneId,
    );
    formData.value.name = response.data.name!;
    formData.value.address = response.data.address!;
    formData.value.postalCode = response.data.postalCode!;
    formData.value.city = response.data.city!;
    formData.value.country = response.data.country!;
    formData.value.iossNumber = response.data.iossNumber!;
    formData.value.articles =
      response.data.articles?.map(
        (article) =>
          ({
            id: article.id,
            description: article.description,
            tarifCode: article.tarifCode,
            value: article.value,
            valueCurrency: article.valueCurrency,
          }) as InformationRequiredArticleFormData,
      ) ?? [];
  } catch (e: unknown) {
    emitErrorWithFallback(
      e,
      "Something went wrong while retrieving the necessary data",
    );
  }
  isRetrieving.value = false;
};

const onConfirm = async () => {
  isProcessing.value = true;
  try {
    await api.setInformationRequiredCategoryData({
      blockingMilestoneId: props.blockingMilestoneId,
      name: formData.value.name,
      address: formData.value.address,
      postalCode: formData.value.postalCode,
      city: formData.value.city,
      country: formData.value.country,
      iossNumber: formData.value.iossNumber,
      articles: formData.value.articles?.map((c) => ({
        id: c.id,
        description: c.description,
        tarifCode: c.tarifCode,
        value: c.value,
        valueCurrency: c.valueCurrency,
      })),
    });
    localValue.value = false;
    emitSuccess("Release successful");
    emits("confirm");
  } catch (e: unknown) {
    emitErrorWithFallback(e, "Something went wrong while saving the changes");
  } finally {
    isProcessing.value = false;
  }
};

const localValue = computed({
  get() {
    return props.value;
  },
  set(newValue: boolean) {
    emits("input", newValue);
  },
});

const countries = computed(() => store.getters.countriesOfOrigin ?? []);
const currencies = computed(() => store.getters.currencies ?? []);

watch(
  () => props.value,
  (newValue: boolean, oldValue: boolean) => {
    if (!newValue) {
      formData.value = { ...defaultFormValues };
    }
  },
);

onBeforeMount(async () => {
  await getRequiredInformationData();
  store.dispatch(DataAction.FetchCountriesOfOrigin);
  store.dispatch(DataAction.FetchCurrencies);
});
</script>

<style scoped lang="scss">
.main-fields {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px 15px;
}

.article-fields::v-deep .multiple-items-editor {
  gap: 15px;
}

.article-description {
  flex: 4 1 225px;
}

.article-tarif {
  flex: 1 1 225px;
}

.article-value {
  flex: 1 1 75px;
}
.article-currency {
  flex: 1 1 115px;
}
</style>
