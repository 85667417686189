<template>
    <v-card>
        <v-card-text>
            <v-form v-model="valid">
                <v-select
                    v-model="selectedIncoterm"
                    :items="mappedIncoterms"
                    label="Incoterms"
                    :loading="isLoading"
                    :rules="[rules.required]"
                ></v-select>
            </v-form>
            <v-btn
                class="mt-4"
                :disabled="!valid"
                primary
                @click="$emit('create-consolidation')"
                :loading="isCreatingConsolidation"
                >Create consolidation</v-btn
            >
        </v-card-text>
    </v-card>
</template>

<script setup lang="ts">
import { emitErrorWithFallback } from '@/event-bus';
import { CustomsConsolidationApi, KeyValueItem } from '@/openapi';
import { useConsolidationStore } from '@/stores/consolidation-store';
import { computed, onBeforeMount, ref } from 'vue';

interface CustomsConsolidationIncotermsProps {
    parcelNumbers: string[];
    isCreatingConsolidation: boolean;
}

interface Incoterm {
    value: number;
    text: string;
}

const api = new CustomsConsolidationApi(undefined, '');
const consolidationStore = useConsolidationStore();

const props = withDefaults(defineProps<CustomsConsolidationIncotermsProps>(), {
    parcelNumbers: () => [],
    isCreatingConsolidation: false,
});

const isLoading = ref(false);
const valid = ref(true);

const mappedIncoterms = ref<Incoterm[]>([]);
const rules = ref<any>({
    required: (value: string) => !!value || "Field can't be empty",
});

onBeforeMount(() => {
    getIncoterms();
});

const getIncoterms = async () => {
    try {
        isLoading.value = true;
        const response = await api.getConsolidationIncoterms({
            parcelNumbers: props.parcelNumbers,
        });

        mappedIncoterms.value = response.data.incoterms!.map(
            (incoterm: KeyValueItem) =>
                ({ value: incoterm!.key, text: incoterm!.value } as Incoterm)
        );
    } catch (e: unknown) {
        emitErrorWithFallback(
            e,
            'Something went wrong while retrieving the incoterms'
        );
    } finally {
        isLoading.value = false;
    }
};

const selectedIncoterm = computed({
    get() {
        return consolidationStore.incoterm;
    },
    set(value: string | null) {
        consolidationStore.updateIncoterm(value);
    },
});
</script>
