<template>
    <v-card>
        <v-card-text>
            <v-form v-model="valid">
                <v-data-table
                    :headers="headers"
                    :items="mappedFreights"
                    hide-default-footer
                    :loading="isLoading"
                >
                    <template v-slot:item.costs="{ item }">
                        <span>&euro; {{ item.costs.toFixed(2) }}</span>
                    </template>
                </v-data-table>
                <div
                    class="mt-4 font-weight-bold d-flex flex-column"
                    style="font-size: 20px"
                >
                    <span class="mb-2">Total freight costs:</span>
                    <keep-alive>
                        <v-text-field
                            v-if="editable"
                            v-click-outside="onClickOutside"
                            v-model.number="totalFreightCosts"
                            @keydown.enter.prevent="save"
                            :rules="[rules.required]"
                            dense
                            autofocus
                            type="number"
                            step="0.01"
                            min="0"
                        ></v-text-field>
                    </keep-alive>
                    <span v-if="!editable" @click="editable = true"
                        >&#8364; {{ totalFreightCosts.toFixed(2) }}</span
                    >
                </div>
            </v-form>

            <v-btn class="mt-4" color="primary" @click="$emit('next-step')">
                Continue
            </v-btn>
        </v-card-text>
    </v-card>
</template>

<script setup lang="ts">
import { CustomsConsolidationApi } from '@/openapi';
import { computed, onBeforeMount, ref } from 'vue';
import { DataTableHeader } from 'vuetify';
import { emitErrorWithFallback } from '@/event-bus';
import { useConsolidationStore } from '@/stores/consolidation-store';

interface CustomsConsolidationFreightProps {
    parcelNumbers: string[];
}

interface Freight {
    costs: number;
}
const api = new CustomsConsolidationApi(undefined, '');
const consolidationStore = useConsolidationStore();

const props = withDefaults(defineProps<CustomsConsolidationFreightProps>(), {
    parcelNumbers: () => [],
});

const isLoading = ref(false);
const valid = ref(true);

const headers = ref<DataTableHeader[]>([
    {
        text: 'Freight costs',
        align: 'start',
        value: 'costs',
    },
]);

const editable = ref(false);

const mappedFreights = ref<Freight[]>([]);
const oldTotalFreightCosts = ref(0);

const rules = ref<any>({
    required: (value: string) => !!value || "Field can't be empty",
});

onBeforeMount(() => {
    getFreightCosts();
});

const getFreightCosts = async () => {
    try {
        isLoading.value = true;
        const response = await api.getFreightsCosts({
            parcelNumbers: props.parcelNumbers,
        });

        mappedFreights.value = response.data.freightCosts!.map(
            (costs: number) => ({ costs })
        );
        totalFreightCosts.value = response.data.totalFreightCost ?? 0;
        oldTotalFreightCosts.value = totalFreightCosts.value;
    } catch (e) {
        emitErrorWithFallback(
            e,
            'Something wrong while retrieving the freight costs'
        );
    } finally {
        isLoading.value = false;
    }
};

const save = () => {
    if (valid.value) {
        editable.value = false;
        oldTotalFreightCosts.value = totalFreightCosts.value;
    }
};

const onClickOutside = () => {
    editable.value = false;
    totalFreightCosts.value = oldTotalFreightCosts.value;
};

const totalFreightCosts = computed({
    get() {
        return consolidationStore.freightCosts;
    },
    set(value: number) {
        consolidationStore.updateFreightCosts(value);
    },
});
</script>
