import { emitError } from '@/event-bus';
import {
    Int32StringKeyValueItem,
    ShipmentApi,
    ShipmentConsolidation,
} from '@/openapi';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { DataOptions, DataTableHeader } from 'vuetify';
import ShipmentsHistory from './ShipmentsHistory.vue.html';
import { downloadFile } from '@/helpers/downloadHelper';
import { DataAction } from '@/store/dataModule';

interface Filters {
    search?: string;
}

const api = new ShipmentApi(undefined, '');

@Component({
    components: {
        ShipmentsHistory,
    },
})
export default class ShipmentConsolidationHistory extends Vue {
    isLoading = false;
    headers: DataTableHeader[] = [
        {
            text: 'Consolidation name',
            align: 'start',
            value: 'name',
            width: 300,
            sortable: false,
        },
        {
            text: 'Consolidation date',
            align: 'start',
            value: 'date',
            sortable: false,
        },
        { text: 'Type', align: 'start', value: 'type', sortable: false },
        {
            text: 'Transit MRN',
            align: 'start',
            value: 'transitMrn',
            sortable: false,
        },
        {
            text: 'Dakosy reference',
            align: 'start',
            value: 'dakosyReference',
            sortable: false,
        },
        { text: '', align: 'end', value: 'actions', sortable: false },
    ];

    options: DataOptions = {
        page: 1,
        itemsPerPage: 50,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
    };
    footerOptions = {
        showFirstLastPage: true,
        itemsPerPageOptions: [5, 25, 50, 100],
        disablePagination: false,
    };

    totalAmountOfItems: number = 0;
    items: ShipmentConsolidation[] = [];
    expandedItems: ShipmentConsolidation[] = [];

    filters: Filters = {
        search: '',
    };

    showDateSelection = false;
    dateRange: string[] | null = null;
    categoryId: number | null = null;

    timeoutDelay = 0;
    @Watch('options')
    onOptionsChanged(newVal: any, oldVal: any) {
        clearTimeout(this.timeoutDelay);
        this.timeoutDelay = setTimeout(() => {
            this.getShipmentConsolidationHistory();
        }, 250);
    }

    async created() {
        await this.$store.dispatch(DataAction.FetchCustomsHandlingCategories);
    }

    async getShipmentConsolidationHistory(page?: number) {
        page ??= this.options.page;
        this.isLoading = true;
        try {
            const response = await api.getShipmentConsolidationsHistory(
                this.filters.search ?? undefined,
                page,
                this.options.itemsPerPage
            );
            this.items = response.data.items ?? [];
            this.options.page = response.data.page ?? 1;
            this.options.itemsPerPage = response.data.itemsPerPage ?? 5;
            this.totalAmountOfItems = response.data.totalAmountOfItems ?? 0;
        } catch {
            emitError('Something went wrong while retrieving the items.');
        }
        this.isLoading = false;
    }

    async reloadConsolidations() {
        this.options.page == 1
            ? await this.getShipmentConsolidationHistory(1)
            : (this.options.page = 1);
    }

    onFilterSelected() {
        this.$nextTick(() => {
            this.reloadConsolidations();
        });
    }

    enterDateSelection() {
        this.showDateSelection = true;
    }

    cancelDateSelection() {
        this.showDateSelection = false;
    }

    async createExportForRefund(consolidation: string) {
        try {
            const response = await api.createExportForRefundRequest(
                consolidation,
                {
                    responseType: 'blob',
                }
            );
            downloadFile(response.data, 'Declaration-' + Date.now() + '.xlsx');
        } catch {
            emitError(
                'Something went wrong while downloading the export declaration.'
            );
        }
    }

    async createExportDeclarationByDate() {
        this.showDateSelection = false;
        try {
            const response = await api.createExportDeclarationsByDate(
                this.dateRange![0],
                this.dateRange![1],
                this.categoryId ?? undefined,
                {
                    responseType: 'blob',
                }
            );
            downloadFile(response.data, 'Declaration-' + Date.now() + '.xlsx');
        } catch {
            emitError(
                'Something went wrong while creating of the data by date.'
            );
        }
    }

    get categories(): Int32StringKeyValueItem[] {
        return this.$store.getters.customsHandlingCategories;
    }
}
