import { emitError, emitErrorWithFallback, emitSuccess } from '@/event-bus';
import {
    CreateDeclarationsForConsolidationViewModel,
    ShipmentApi,
    ShipmentConsolidation,
} from '@/openapi';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { DataOptions, DataTableHeader } from 'vuetify';
import ShipmentsOfConsolidation from './ShipmentsOfConsolidation.vue.html';
import { downloadFile } from '@/helpers/downloadHelper';
import CreateTransitDeclarationDialog from '@/components/dialogs/CreateTransitDeclarationDialog.vue.html';
import { CreateTransitDeclarationForm } from '../dialogs/CreateTransitDeclarationDialog';
import ConfirmDialog from '../dialogs/ConfirmDialog.vue.html';
import { useDataStore } from '@/stores/data-store';
import { CONSOLIDATION_TYPE_T1 } from '@/constants/constants';

interface Filters {
    search?: string;
}

@Component({
    components: {
        ShipmentsOfConsolidation,
        CreateTransitDeclarationDialog,
        ConfirmDialog,
    },
})
export default class ShipmentConsolidations extends Vue {
    api: ShipmentApi = new ShipmentApi(undefined, '');
    dataStore = useDataStore();

    consolidationHeaders: DataTableHeader[] = [
        {
            text: 'Consolidation name',
            align: 'start',
            value: 'name',
            width: 300,
            sortable: false,
        },
        {
            text: 'Consolidation date',
            align: 'start',
            value: 'date',
            sortable: false,
        },
        { text: 'Type', align: 'start', value: 'type', sortable: false },
        { text: '', align: 'end', value: 'actions', sortable: false },
    ];

    options: DataOptions = {
        page: 1,
        itemsPerPage: 50,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
    };
    footerOptions = {
        showFirstLastPage: true,
        itemsPerPageOptions: [5, 25, 50, 100],
        disablePagination: false,
    };

    totalAmountOfItems: number = 0;

    consolidations: ShipmentConsolidation[] = [];
    expandedConsolidations: ShipmentConsolidation[] = [];
    currentShipmentConsolidation: ShipmentConsolidation | null = null;

    exportMrn = {
        mrnNumber: '',
        mawb: '',
        gatewayExport: '',
        sealNumber: '',
        registrationNumber: '',
        returnShipmentLoaded: false,
    };
    consolNrToExport: string = '';

    isLoading = false;
    createT1Loading = false;
    createT1OrT2Loading = false;
    exportMrnLoading = false;
    updateConsolidationTypeLoading = false;

    showExportMrnModal = false;
    showT1OrT2Dialog = false;
    showConsolidationTypeDialog = false;

    newConsolidationType = '';

    filters: Filters = {
        search: '',
    };

    timeoutDelay = 0;
    @Watch('options')
    onOptionsChanged(newVal: any, oldVal: any) {
        clearTimeout(this.timeoutDelay);
        this.timeoutDelay = setTimeout(() => {
            this.getConsolidations();
        }, 250);
    }

    async created() {
        await Promise.all([
            this.dataStore.fetchCustomsHandlingCategories(),
            this.dataStore.fetchConsolidationTypes(),
        ]);
    }

    async getConsolidations(page?: number) {
        page ??= this.options.page;
        this.isLoading = true;
        try {
            const response = await this.api.getShipmentConsolidations(
                this.filters.search ?? undefined,
                page,
                this.options.itemsPerPage
            );
            this.consolidations = response.data.items ?? [];
            this.totalAmountOfItems = response.data.totalAmountOfItems ?? 0;
        } catch {
            emitError(
                'Something went wrong while retrieving the consolidations.'
            );
        }
        this.isLoading = false;
    }

    async reloadConsolidations() {
        this.options.page == 1
            ? await this.getConsolidations(1)
            : (this.options.page = 1);
    }

    onFilterSelected() {
        this.$nextTick(() => {
            this.reloadConsolidations();
        });
    }

    enterExportMrn(consolNr: string) {
        this.showExportMrnModal = true;

        this.consolNrToExport = consolNr;
    }
    async createExportMrn() {
        this.showExportMrnModal = false;
        this.exportMrnLoading = true;
        try {
            const response = await this.api.exportMRN(
                this.consolNrToExport,
                this.exportMrn.mrnNumber,
                this.exportMrn.mawb,
                this.exportMrn.gatewayExport,
                this.exportMrn.sealNumber,
                this.exportMrn.registrationNumber,
                this.exportMrn.returnShipmentLoaded
            );

            downloadFile(response.data, 'CONSOL-' + Date.now());
            this.consolNrToExport = '';
        } catch {
            emitError(
                'Something went wrong while during the "Enter Export MRN" process.'
            );
        }
        this.exportMrnLoading = false;
    }

    cancelExportMrn() {
        this.showExportMrnModal = false;
    }

    openT1T2CreationDialog(item: ShipmentConsolidation) {
        this.showT1OrT2Dialog = true;
        this.currentShipmentConsolidation = item;
    }

    onDialogClose() {
        this.currentShipmentConsolidation = null;
    }

    onClickType(currentShipmentConsolidation: ShipmentConsolidation) {
        if (
            !currentShipmentConsolidation.canCreateT1 &&
            !currentShipmentConsolidation.canCreateT2
        ) {
            return;
        }
        this.newConsolidationType = currentShipmentConsolidation.type!;
        this.currentShipmentConsolidation = currentShipmentConsolidation;
        this.showConsolidationTypeDialog = true;
    }

    async onConsolidationTypeUpdate() {
        if (!this.currentShipmentConsolidation) {
            return;
        }
        this.updateConsolidationTypeLoading = true;
        try {
            await this.api.updateConsolidationType({
                consolidationNumber: this.currentShipmentConsolidation.name!,
                consolidationType: this.newConsolidationType,
            });

            emitSuccess('Successfully updated the type');
            this.showConsolidationTypeDialog = false;
            this.resetConsolidationUpdateValues();
            await this.getConsolidations();
        } catch (e: unknown) {
            emitErrorWithFallback(
                e,
                'Something went wrong while updating the type'
            );
        }
        this.updateConsolidationTypeLoading = false;
    }

    async createT1OrT2(formData: CreateTransitDeclarationForm) {
        this.createT1OrT2Loading = true;

        try {
            var requestData: CreateDeclarationsForConsolidationViewModel = {
                amount: formData.amount!,
                consolidationNumber: this.currentShipmentConsolidation?.name,
                country: formData.country,
                registration: formData.registration,
                countryTruck: formData.countryTruck,
                registrationTruck: formData.registrationTruck,
                transportAcrossEuBorder: formData.transportAcrossEuBorder,
                signs: formData.signs.map((c) => c.value).filter((c) => !!c),
                routeId: formData.routeId!,
                templateId: formData.templateId!,
            };

            if (this.currentShipmentConsolidation?.canCreateT1) {
                await this.api.createT1DeclarationsForConsolidation(
                    requestData
                );
            } else if (this.currentShipmentConsolidation?.canCreateT2) {
                await this.api.createT2DeclarationsForConsolidation(
                    requestData
                );
            } else {
                emitError(
                    'No declaration can be created for this consolidation.'
                );
                this.createT1OrT2Loading = false;
                return;
            }

            this.currentShipmentConsolidation = null;
            this.showT1OrT2Dialog = false;
            await this.getConsolidations();
        } catch {
            emitError('Something went wrong while creating the declaration.');
            this.createT1OrT2Loading = false;
        }
    }

    resetConsolidationUpdateValues() {
        this.currentShipmentConsolidation = null;
        this.newConsolidationType = '';
    }

    get createT1OrT2DialogTitle() {
        return this.currentShipmentConsolidation?.canCreateT1
            ? 'Create T1 document'
            : this.currentShipmentConsolidation?.canCreateT2
            ? 'Create T2 document'
            : '';
    }

    get consolidationTypes() {
        if (this.currentShipmentConsolidation?.canOnlyChangeToT1) {
            return this.dataStore.consolidationTypes.filter(
                (c: string) => c == CONSOLIDATION_TYPE_T1
            );
        }
        return this.dataStore.consolidationTypes;
    }
}
