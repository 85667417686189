import moment from 'moment';
import { Component, Vue, Prop } from 'vue-property-decorator';

export interface HoldReturnExportDialogDateFilter {
    fromDate: string;
    tillDate: string;
}

@Component({})
export default class HoldReturnExportDialog extends Vue {
    @Prop({ default: false })
    value!: Boolean;

    @Prop({ default: false })
    includeReturn!: Boolean;

    @Prop({ default: () => [] })
    shipmentIds!: number[];

    @Prop({ default: null })
    returnDateFilter!: HoldReturnExportDialogDateFilter | null;

    setDefaultExportUrlParameters(searchParams: URLSearchParams) {
        searchParams.append('includeReturn', this.includeReturn.toString());
        if (!!this.returnDateFilter) {
            searchParams.append('fromDate', this.returnDateFilter.fromDate);
            searchParams.append('tillDate', this.returnDateFilter.tillDate);
        }
    }

    get visibleExportUrl() {
        var searchParams = new URLSearchParams();
        this.setDefaultExportUrlParameters(searchParams);
        if (!!this.shipmentIds?.length)
            for (const shipmentId of this.shipmentIds) {
                searchParams.append('ids', shipmentId.toString());
            }

        return `/api/customsReturn/CreateExport?${searchParams.toString()}`;
    }

    get allExportUrl() {
        var searchParams = new URLSearchParams();
        this.setDefaultExportUrlParameters(searchParams);
        return `/api/customsReturn/CreateExport?${searchParams.toString()}`;
    }

    get visibleExportDownloadName() {
        var now = moment();
        return `visible-export-${now.format('YYYY-MM-DD')}`;
    }

    get allExportDownloadName() {
        var now = moment();
        return `all-export-${now.format('YYYY-MM-DD')}`;
    }
}
