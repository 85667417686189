import { render, staticRenderFns } from "./CreateConsolidationDialog.vue.html?vue&type=template&id=437d3e04"
import script from "./CreateConsolidationDialog.ts?vue&type=script&lang=js&external"
export * from "./CreateConsolidationDialog.ts?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports