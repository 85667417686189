import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Actions } from '@/config';

export interface SetHoldReasonForm {
    reason?: string;
}

@Component({})
export default class SetInspectionReasonDialog extends Vue {
    @Prop({default: false})
    value!: Boolean;

    @Prop({default: false})
    isLoading!: Boolean;

    @Prop({default: ''})
    title!: String

    @Prop({default: 'Confirm'})
    confirmButtonText!: String

    @Prop({default: 'Close'})
    closeButtonText!: String

    maxCharactersReason = 1500;

    rules = {
        required: (value: string) =>
            (value && !!value.toString()) || 'Required'
        ,
        maxChar: (value: string) => value.length <= this.maxCharactersReason || `Max ${this.maxCharactersReason} characters`
    };
    valid = true;

    form = this.initializeForm();

    get displayTitle() {
        return !this.title ? Actions.HOLD : this.title;
    }

    @Watch('value')
    onValueChanged(newValue: boolean, oldVal: boolean) {
        if(newValue == false) {
            this.form = this.initializeForm();
            (this.$refs.form as Vue & { resetValidation: () => boolean}).resetValidation();
        }
    }

    initializeForm(): SetHoldReasonForm {
        return {
            reason: '',
        }
    }
}