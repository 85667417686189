import { Component, Prop, Vue } from 'vue-property-decorator';
import TextAreaCollection from '@/components/editors/TextAreaCollection.vue.html';
import {
    CustomsCountryViewModel,
    CustomsT2CreationApi,
    CustomsCountriesApi,
} from '@/openapi';
import { emitError } from '@/event-bus';
import { ValidationObserver } from 'vee-validate';

interface T2CreationForm {
    hawbNumbers: string[],
    transitMrn: string;
    type: string;
    amount: number;
    registration: string;
    country: string;
    sign: string;
    finalWabNumber: string;
}

const customsT2CreationApi = new CustomsT2CreationApi(undefined, '');
const customsCountryApi = new CustomsCountriesApi(undefined, '');

@Component({
    components: {
        TextAreaCollection,
    },
})
export default class CustomsT2Creation extends Vue {
    @Prop({ default: '' })
    color!: string;

    $refs!: {
        hawbObserver: InstanceType<typeof ValidationObserver>,
        createObserver: InstanceType<typeof ValidationObserver>
    };

    customsCountries: CustomsCountryViewModel[] | null = [];
    
    loading = false;

    hasValidHawbNumbers = false;
    invalidHawbNumbers: string[] = [];

    form: T2CreationForm = this.initializeForm()

    showSnackbar = false;

    async created() {
        await this.getCustomsCountries();
    }

    async getCustomsCountries() {
        this.customsCountries = [];
        try {
            var response = await customsCountryApi.getCustomsCountries();
            this.customsCountries = response.data;
            this.customsCountries!.forEach(function(item) {
                item.description = item.code + ' - ' + item.description;
            });
        } catch {
            emitError(
                'Something went wrong while retrieving the country options for the dropdown'
            );
        }
    }

    initializeForm() {
        return {
            hawbNumbers: [],
            transitMrn: '',
            type: '',
            amount: 0,
            registration: '',
            country: '',
            sign: '',
            finalWabNumber: '',
        };
    }

    async validateHawbNumbers() {
        this.loading = true;
        try {
            const response = await customsT2CreationApi.validateHawbNumbers(
                this.form.hawbNumbers
            );

            this.invalidHawbNumbers = response.data.invalidHawbNumbers ?? [];
            this.hasValidHawbNumbers = !this.invalidHawbNumbers.length;
        } catch {
            emitError(
                'Something went wrong while trying to validte the enter HAWB numbers'
            );
        }

        this.loading = false;
    }

    async createT2() {
        this.loading = true;

        try {
            await customsT2CreationApi.createT2({
                ...this.form
            });

            this.resetForm();
            this.loading = false;
            this.showSnackbar = true;

        } catch(e: any) {
            let errorMessages: string[] = [
                'Something went wrong while trying to create the T2',
            ];

            if (e?.response?.data?.errorMessages?.length) {
                errorMessages = e.response.data.errorMessages;
            }
            emitError(...errorMessages);
        }

        this.loading = false;
    }

    resetForm() {
        this.form = this.initializeForm();
        this.$refs.hawbObserver.reset();
        this.$refs.createObserver.reset();
        this.hasValidHawbNumbers = false;
    }
}
