import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "EditorToolbar",
  data: () => ({
    disabled: false,
  }),
  props: {
    tooltipText: { type: String },
    icon: { type: String },
    action: { type: Function },
    active: { type: Boolean },
    textIcon: { type: Boolean },
    textIconText: { type: String },
  },
});
