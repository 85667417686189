<template>
    <Fragment>
        <ConfirmDialog
            v-model="localValue"
            :is-loading="loading"
            title="Request jobs"
            @confirm="onConfirm"
        >
            <v-slider
                v-model="formData.amountOfJobsToRequest"
                :min="1"
                :max="amountOfJobsToRequestMaximum"
                :disabled="loadingRequiredData"
                thumb-label="always"
                label="No. of jobs"
                class="mt-6"
            ></v-slider>
            <v-select
                v-model="formData.optionalLevels"
                :items="optionalLevels"
                :disabled="loadingRequiredData"
                item-value="levelId"
                item-text="level"
                label="Optional levels"
                multiple
            ></v-select>
        </ConfirmDialog>
    </Fragment>
</template>

<script setup lang="ts">
import { emitErrorWithFallback } from '@/event-bus';
import {
    AccountApi,
    EmployeeCustomsApi,
    EmployeeCustomsLevelItem,
} from '@/openapi';
import { computed, onBeforeMount, ref, watch } from 'vue';
import ConfirmDialog from './ConfirmDialog.vue.html';

interface RequestJobsDialogFormData {
    amountOfJobsToRequest: number;
    optionalLevels: number[];
}

interface RequestJobsDialogProps {
    value: boolean;
}

const api = new EmployeeCustomsApi(undefined, '');
const accountApi = new AccountApi(undefined, '');

const defaultFormValues: RequestJobsDialogFormData = {
    amountOfJobsToRequest: 0,
    optionalLevels: [],
};

const props = defineProps<RequestJobsDialogProps>();
const emits = defineEmits(['input', 'close', 'confirm']);
const loadingRequiredData = ref(false);
const loading = ref(false);

const amountOfJobsToRequestMaximum = ref(0);
const optionalLevels = ref<EmployeeCustomsLevelItem[]>([]);
const formData = ref<RequestJobsDialogFormData>({ ...defaultFormValues });

const retrieveData = async () => {
    loadingRequiredData.value = true;
    try {
        const response = await api.getEmployeeCustomsItem();
        formData.value.amountOfJobsToRequest =
            amountOfJobsToRequestMaximum.value =
                response.data.maximumNumberOfJobsToAssign ?? 0;
        optionalLevels.value =
            response.data.allowedLevels
                ?.filter((c) => c.isOptional)
                .sort((a, b) => a.level! - b.level!) ?? [];
    } catch (e: unknown) {
        emitErrorWithFallback(
            e,
            'Something went wrong while retrieving the amount of jobs the user is allowed to request'
        );
    }
    loadingRequiredData.value = false;
};

const requestJobs = async () => {
    loading.value = true;
    try {
        await accountApi.addJobsToEmployee({
            numberOfJobs: formData.value.amountOfJobsToRequest,
            levelIds: formData.value.optionalLevels,
        });
        return true;
    } catch (e: unknown) {
        emitErrorWithFallback(
            e,
            'Something went wrong while requesting new jobs'
        );
        return false;
    } finally {
        loading.value = false;
    }
};

const onConfirm = async () => {
    const success = await requestJobs();

    if (success) {
        emits('input', false);
        emits('confirm');
    }
};

const localValue = computed({
    get() {
        return props.value;
    },

    set(newValue: boolean) {
        emits('input', newValue);
    },
});

watch(
    () => props.value,
    (newValue: boolean, oldValue: boolean) => {
        if (!newValue) {
            formData.value = { ...defaultFormValues };
        }
    }
);

onBeforeMount(async () => {
    await retrieveData();
});
</script>
