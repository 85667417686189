<template>
    <Fragment>
        <CrudDataTable
            :headers="headers"
            :items="items"
            :server-items-length="totalAmountOfItems"
            :options.sync="options"
            :footer-props="footerOptions"
            :single-expand="true"
            :expanded.sync="expandedItems"
            item-key="id"
            fixed-footer
            fixed-header
            show-expand
            dense
            height="calc(100vh - 223px)"
            class="elevation-1"
            :loading="loading"
            @item-expanded="onItemExpanded"
            @click:create="onCreateItem"
            @click:edit="onEditItem"
            @click:delete="onDeleteItem"
        >
            <template #expanded-item="{ headers, item }">
                <td v-if="expandedItems?.length" :colspan="headers.length">
                    <v-card color="lighten-3" class="ma-6">
                        <v-card-text>
                            <CrudDataTable
                                :headers="childHeaders"
                                :items="childItems"
                                :server-items-length="totalAmountOfChildItems"
                                :options.sync="childOptions"
                                :footer-props="childFooterOptions"
                                item-key="id"
                                fixed-footer
                                fixed-header
                                dense
                                :loading="childLoading"
                                @click:create="onCreateItem(item)"
                                @click:edit="onEditItem"
                                @click:delete="onDeleteItem"
                            ></CrudDataTable>
                        </v-card-text>
                    </v-card>
                </td>
            </template>

            <template #item.hasChildren="{ item }">
                <v-icon :color="item.hasChildren ? 'success' : 'error'">{{
                    item.hasChildren ? 'mdi-check' : 'mdi-close'
                }}</v-icon>
            </template>

            <template #confirm-delete-text>
                Deleting this item will also delete all subcategories. Are you
                sure you want to delete this item?
            </template>
        </CrudDataTable>

        <ValidationObserver slim v-slot="{ invalid }">
            <ConfirmDialog
                v-if="showDialog && formData"
                v-model="showDialog"
                title="Create/edit category"
                :is-loading="dialogLoading"
                :is-confirm-disabled="invalid"
                @cancel="(formData = null), (parentItem = null)"
                @confirm="onConfirmed"
            >
                <ValidationProvider
                    name="Name"
                    rules="required|max:50"
                    v-slot="{ errors }"
                >
                    <v-text-field
                        v-model="formData.name"
                        label="Name"
                        autofocus
                        :counter="50"
                        :error-messages="errors"
                    ></v-text-field>
                </ValidationProvider>
                <ValidationProvider
                    name="Remark"
                    rules="required|max:200"
                    v-slot="{ errors }"
                >
                    <v-textarea
                        v-model="formData.remark"
                        label="Remark"
                        :counter="200"
                        :error-messages="errors"
                    ></v-textarea>
                </ValidationProvider>
            </ConfirmDialog>
        </ValidationObserver>
    </Fragment>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import { DataOptions, DataTableHeader } from 'vuetify';
import {
    CustomsArticleCategoryApi,
    CustomsArticleCategoryDto,
} from '@/openapi/api';
import { emitErrorWithFallback, emitSuccess } from '@/event-bus';
import { useSorting } from '@/composables/sort';
import { useDataTableDefaults } from '@/composables/dataTable';
import { useFormReset } from '@/composables/formReset';
import CrudDataTable from '@/components/CrudDataTable.vue';
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue.html';
import { ValidationObserver } from 'vee-validate';

interface ArticleCategoryForm {
    name: string;
    remark: string;
}

const api = new CustomsArticleCategoryApi(undefined, '');
const defaultFormValues: ArticleCategoryForm = {
    name: '',
    remark: '',
};

const headers = ref<DataTableHeader[]>([
    {
        text: 'Name',
        value: 'name',
        sortable: true,
    },
    {
        text: 'Remark',
        value: 'remark',
        sortable: false,
    },
    {
        text: 'Has subcategories',
        value: 'hasChildren',
        sortable: false,
    },
]);
const childHeaders = ref<DataTableHeader[]>([
    {
        text: 'Name',
        value: 'name',
        sortable: true,
    },
    {
        text: 'Remark',
        value: 'remark',
        sortable: false,
    },
]);

const { options, footerOptions, items, loading, totalAmountOfItems } =
    useDataTableDefaults<CustomsArticleCategoryDto>();
const {
    options: childOptions,
    footerOptions: childFooterOptions,
    items: childItems,
    loading: childLoading,
    totalAmountOfItems: totalAmountOfChildItems,
} = useDataTableDefaults<CustomsArticleCategoryDto>();
const { sortBy, sortDesc } = useSorting(options);
const expandedItems = ref<CustomsArticleCategoryDto[]>();
const parentItem = ref<CustomsArticleCategoryDto | null>();

const observer = ref<InstanceType<typeof ValidationObserver>>();
const showDialog = ref(false);
const dialogLoading = ref(false);
const currentItem = ref<CustomsArticleCategoryDto | null>();
const formData = ref<ArticleCategoryForm | null>({ ...defaultFormValues });

useFormReset(showDialog, formData, defaultFormValues, observer);

watch(
    () => options.value,
    async (newValue: DataOptions, oldValue: DataOptions) => {
        expandedItems.value = [];
        await getItems();
    },
    { deep: true }
);

watch(
    () => childOptions.value,
    (newValue: DataOptions, oldValue: DataOptions) => {
        getItems(expandedItems.value?.at(0)?.id);
    },
    { deep: true }
);

const getItems = async (
    parentId?: number,
    page: number = options.value.page
) => {
    parentId ? (childLoading.value = true) : (loading.value = true);
    try {
        const response = await api.getCustomsArticleCategories(
            parentId ?? undefined,
            sortBy.value,
            sortDesc.value,
            page,
            options.value.itemsPerPage
        );
        if (parentId) {
            childItems.value = response.data.items ?? [];
            totalAmountOfChildItems.value =
                response.data.totalAmountOfItems ?? 0;
        } else {
            items.value = response.data.items ?? [];
            totalAmountOfItems.value = response.data.totalAmountOfItems ?? 0;
        }
    } catch (e: unknown) {
        emitErrorWithFallback(
            e,
            'Something went wrong while retrieving the categories'
        );
    } finally {
        parentId ? (childLoading.value = false) : (loading.value = false);
    }
};

const onCreateItem = (item?: CustomsArticleCategoryDto) => {
    formData.value = { ...defaultFormValues };
    parentItem.value = item;
    showDialog.value = true;
};

const onEditItem = (item: CustomsArticleCategoryDto) => {
    currentItem.value = item;
    formData.value = {
        name: item.name!,
        remark: item.remark!,
    };
    showDialog.value = true;
};

const onConfirmed = async () => {
    const parentId = parentItem.value?.id;
    if (currentItem.value) {
        await editItem();
    } else if (await createItem(parentId)) {
        parentItem.value = null;
        await getItems(parentId);
    }
};

const onDeleteItem = async (item: CustomsArticleCategoryDto) => {
    currentItem.value = item;
    if (await deleteItem(item)) {
        currentItem.value = null;
        await getItems(item.parentId!);
    }
};

const createItem = async (parentId?: number) => {
    dialogLoading.value = true;
    try {
        await api.createCustomsArticleCategory({
            parentId: parentId ?? undefined,
            name: formData.value!.name!,
            remark: formData.value!.remark!,
        });
        showDialog.value = false;
        emitSuccess('Successfully created the item');
        return true;
    } catch (e: unknown) {
        emitErrorWithFallback(
            e,
            'Something went wrong while creating the category'
        );
        return false;
    } finally {
        dialogLoading.value = false;
    }
};

const editItem = async () => {
    if (!currentItem.value?.id || !formData.value) {
        return;
    }

    dialogLoading.value = true;
    try {
        await api.editCustomsArticleCategory(currentItem.value.id, {
            name: formData.value.name,
            remark: formData.value.remark,
        });
        currentItem.value!.name = formData.value.name;
        currentItem.value!.remark = formData.value.remark;
        currentItem.value = null;
        showDialog.value = false;
        emitSuccess('Successfully edited the item');
    } catch (e: unknown) {
        emitErrorWithFallback(
            e,
            'Something went wrong while editing the category'
        );
    } finally {
        dialogLoading.value = false;
    }
};

const deleteItem = async (item: CustomsArticleCategoryDto) => {
    item.parentId ? (childLoading.value = true) : (loading.value = true);
    try {
        await api.deleteCustomsArticleCategory(item.id!);
        emitSuccess('Successfully deleted the item');
        return true;
    } catch (e: unknown) {
        emitErrorWithFallback(
            e,
            'Something went wrong while deleting the category'
        );
        return false;
    } finally {
        item.parentId ? (childLoading.value = true) : (loading.value = true);
    }
};

let prevExpandStatus = false;
const onItemExpanded = async ({
    item,
    value,
}: {
    item: CustomsArticleCategoryDto;
    value: boolean;
}) => {
    childItems.value = [];
    totalAmountOfChildItems.value = 0;
    childOptions.value.page = 1;

    if (value && value === prevExpandStatus) {
        await getItems(item.id);
    }
    prevExpandStatus = value;
};
</script>
