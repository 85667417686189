import { Component, Prop, Vue } from 'vue-property-decorator';
import { emitError } from '@/event-bus';
import { ShipmentStatusApi } from '@/openapi';

const shipmentStatusApi = new ShipmentStatusApi(undefined, '');

interface Successful {
    success: boolean;
}

@Component({})
export default class ReleaseShipmentButton extends Vue {
    @Prop()
    shipmentId!: number;

    @Prop()
    exportFlowStatus!: number;

    loading = false;

    async changeShipmentStatus() {
        this.loading = true;
        let success = false;
        try {
            await shipmentStatusApi.setReleaseStatus(
                this.shipmentId
            );
            success = true;
        } catch {
            emitError('Something went wrong while releasing this shipment.');
        }

        this.loading = false;
        this.$emit('shipmentStatusChanged', { success } as Successful);
    }
}
