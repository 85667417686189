import { emitError } from "@/event-bus";
import {
  ConsolidationCustomsInspectionShipment,
  CustomsFlightHandlingApi,
} from "@/openapi";
import { ValidationObserver } from "vee-validate";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import DefaultDialog from "../dialogs/DefaultDialog.vue.html";

interface Filters {
  hawb?: string;
}

interface AuthorityForm {
  authority: string;
}

const api = new CustomsFlightHandlingApi(undefined, "");

@Component({
  components: {
    DefaultDialog,
  },
})
export default class CustomsConsolidationInspectionShipments extends Vue {
  @Prop()
  consolidationId!: number;

  $refs!: {
    observer: InstanceType<typeof ValidationObserver>;
  };

  loading = false;
  items: ConsolidationCustomsInspectionShipment[] = [];
  currentItem: ConsolidationCustomsInspectionShipment | null = null;

  filters: Filters = {
    hawb: "",
  };

  showAuthorityFormDialog = false;
  authorityFormLoading = false;

  form: AuthorityForm = this.initializeForm();

  headers = [
    {
      text: "HAWB/Parcel ID",
      value: "hawb",
      sortable: false,
    },
    {
      text: "Anzahl ",
      value: "pieces",
      sortable: false,
    },
    {
      text: "Gewicht ",
      value: "weight",
      sortable: false,
    },
    {
      text: "Customs inspection authority",
      value: "authority",
      sortable: false,
    },
    {
      text: "Actions",
      value: "actions",
      sortable: false,
    },
  ];

  @Watch("showAuthorityFormDialog")
  showAuthorityFormDialogWatcher(newValue: boolean, oldValue: boolean) {
    if (!newValue) {
      this.form = this.initializeForm();
      this.currentItem = null;
      this.$refs.observer.reset();
    }
  }

  created() {
    this.getShipments();
  }

  initializeForm() {
    return {
      authority: "",
    };
  }

  async getShipments() {
    this.loading = true;
    try {
      const response = await api.getConsolidationCustomsInspectionShipments(
        this.consolidationId,
        this.filters.hawb ?? "",
      );
      this.items = response.data;
    } catch {
      emitError(
        "Something went wrong while retrieving the customs inspection shipments.",
      );
    }
    this.loading = false;
  }

  timeout = 0;
  async onFilterSelected() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(async () => {
      await this.getShipments();
    }, 250);
  }

  async setAuthority() {
    this.authorityFormLoading = true;
    if (!this.currentItem) {
      return;
    }

    try {
      await api.inspectConsolidationShipment({
        id: this.currentItem.id,
        authority: this.form.authority,
      });
      this.authorityFormLoading = false;
      this.showAuthorityFormDialog = false;

      await this.getShipments();
    } catch {
      this.authorityFormLoading = false;
      emitError("Something went wrong while saving the authority");
    }
  }
}
