export function getStatuses(includeDataReceived: boolean = false, includeReturn: boolean = false, includeCustomsInpsection: boolean = false, includeHold: boolean = false, includeManualPostNordTransfer: boolean = false) {
    var statuses: number[] = [];
    for (let i = 0; i < 14; i++) {
        statuses.push(i + 1);
    }

    if (includeDataReceived) {
        statuses.unshift(0);
    }

    if(includeHold) {
        statuses.push(20);
    }

    if(includeReturn) {
        statuses.push(21);
    }

    if (includeCustomsInpsection) {
        statuses.push(22);
    }

    if (includeManualPostNordTransfer) {
        statuses.push(31);
    }

    return statuses;
}
