import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

export interface AtbInfo {
    number: string;
    from: string;
    to: string;
}

export interface AtbInfoSingle {
    number: string;
    position: string;
}

@Component({})
export default class AtbNumberEditor extends Vue {
    @Prop()
    value!: AtbInfo | AtbInfoSingle;

    created() {
        this.info = this.value;
    }

    @Watch('info', { deep: true })
    infoWatcher(newValue: AtbInfo, oldValue: AtbInfo) {
        this.$emit('input', newValue);
    }

    get info() {
        return this.value;
    }

    get singlePosition() {
        return Object.keys(this.value).includes('position');
    }

    set info(value: AtbInfo | AtbInfoSingle) {
        this.$emit('input', value);
    }
}