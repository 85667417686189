import { Component, Vue, Watch } from 'vue-property-decorator';

import CustomsArticleMaintenanceOpen from '@/components/CustomsArticleMaintenanceOpen.vue.html';
import CustomsArticleMaintenanceFulfilled from '@/components/CustomsArticleMaintenanceFulfilled.vue.html';

import { CustomsArticleMaintenanceApi, DataApi } from '@/openapi';
import { emitError } from '@/event-bus';
import { DefaultDataTableItem } from '@/types/types';

const api = new CustomsArticleMaintenanceApi(undefined, '');
const dataApi = new DataApi(undefined, '');

@Component({
    components: {
        CustomsArticleMaintenanceOpen,
        CustomsArticleMaintenanceFulfilled,
    },
})
export default class CustomsArticleMaintenance extends Vue {
    currentTab = 0;

    filteredIncotermIds: number[] = [];
    customers: DefaultDataTableItem<string>[] = [];

    async created() {
        this.$emit('PageInfoReceived', 'Article Maintenance', []);
        await this.getCustomers();
        await this.getDAPAndDDP();
    }

    async getCustomers() {
        try {
            const response = await dataApi.getCustomers(true);
            this.customers = response.data.map((customer: any) => ({
                value: customer.id,
                text: customer.shortName,
            }));
        } catch (ex) {
            emitError(
                'Something went wrong while retrieving the "Customers" filter options.'
            );
        }
    }

    async getDAPAndDDP() {
        try {
            const response = await api.getDAPAndDDP();
            this.filteredIncotermIds = response.data ?? [];
        } catch {
            emitError('Failed to retrieve the DAP/DDP filter options');
        }
    }
}
