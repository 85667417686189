import ToolbarMixin from '@/mixins/ToolbarMixin';
import {
    CustomsDiscrepanciesApi,
    MissingPaperworkShipment,
    MissingPaperworkShipmentPagedResult,
} from '@/openapi';
import axios from 'axios';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import {
    PaperworkGrouping,
    UploadPaperworkFormConfirmed,
} from './dialogs/UploadPaperworkDialog';
import UploadPaperworkDialog from './dialogs/UploadPaperworkDialog.vue.html';
import { serialize } from 'object-to-formdata';
import { emitError } from '@/event-bus';

const api = new CustomsDiscrepanciesApi(undefined, '');

interface Enrichment {
    loading: boolean;
    success: boolean;
    initialReportedToCustomer: string;
}

type EnrichedMissingPaperworkShipment = MissingPaperworkShipment & Enrichment;

@Component({
    components: {
        UploadPaperworkDialog,
    },
})
export default class CustomsDiscrepanciesMissing extends Mixins(ToolbarMixin) {
    isLoading = false;
    isLoadingReturn = false;
    isLoadingRelease = false;

    options: any = {
        page: 1,
        itemsPerPage: 5,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: true,
    };
    footerOptions: any = {
        showFirstLastPage: true,
        itemsPerPageOptions: [5, 25, 50, 100],
        disablePagination: false,
    };

    items: EnrichedMissingPaperworkShipment[] = [];
    totalAmountOfItems = 0;
    currentItem: EnrichedMissingPaperworkShipment | null = null;

    showDialog = false;
    dialogIsLoading = false;

    headers = [
        {
            text: 'General customer',
            value: 'customer',
            align: 'center',
            sortable: false,
        },
        {
            text: 'HAWB',
            value: 'hawbNumber',
            align: 'center',
            sortable: false,
        },
        {
            text: 'Parcel numbers',
            value: 'parcelNumbers',
            align: 'center',
            sortable: false,
        },
        {
            text: 'Destination country',
            value: 'destinationCountry',
            align: 'center',
            sortable: false,
        },
        {
            text: 'Date, reported to customer',
            value: 'reportedToCustomer',
            align: 'center',
            sortable: false,
        },
        {
            text: '',
            value: 'actions',
            align: 'end',
            sortable: false,
        },
    ];

    created() {
        this.addRefreshToolbarOption(this.reloadMissingPaperworkShipments);
    }

    @Watch('options')
    onOptionsChanged(newVal: any, oldVal: any) {
        this.getMissingPaperworkShipments(this.options.page);
    }

    async getMissingPaperworkShipments(page: number) {
        this.isLoading = true;
        this.items = [];
        try {
            const response = await api.getShipmentsWithMissingPaperwork(
                page,
                this.options.itemsPerPage
            );
            const data: MissingPaperworkShipmentPagedResult = response.data;
            this.items =
                data.items?.map((item) => ({
                    ...item,
                    loading: false,
                    success: false,
                    initialReportedToCustomer: item.reportedToCustomer ?? '',
                })) ?? [];
            this.totalAmountOfItems = data.totalAmountOfItems ?? 0;
        } catch {
            emitError(
                'Something went wrong while retrieving the missing paperwork shipments'
            );
        }
        this.isLoading = false;
    }

    async reloadMissingPaperworkShipments() {
        this.options.page == 1
            ? await this.getMissingPaperworkShipments(this.options.page)
            : (this.options.page = 1);
    }

    async onReturn(item: MissingPaperworkShipment) {
        this.isLoadingReturn = true;
        await this.returnShipment(item)
            .then(() => {
                this.isLoadingReturn = false;
                this.getMissingPaperworkShipments(this.options.page);
            })
            .catch(() => (this.isLoadingReturn = false));
    }

    async onRelease(item: EnrichedMissingPaperworkShipment) {
        this.currentItem = item;
        this.showDialog = true;
    }

    async onFilesSelected(eventData: UploadPaperworkFormConfirmed) {
        this.dialogIsLoading = true;

        await this.releaseShipment(
            this.currentItem!.shipmentId as number,
            eventData.paperworkGroupings
        )
            .then(() => {
                this.dialogIsLoading = false;
                this.showDialog = false;
                this.getMissingPaperworkShipments(this.options.page);
            })
            .catch(() => (this.dialogIsLoading = false));
    }

    async returnShipment(item: MissingPaperworkShipment) {
        try {
            await api.returnShipment({ shipmentId: item.shipmentId });
        } catch (e) {
            emitError(
                `Something went wrong during the "Return" action of the item with HawbNumber "${item.hawbNumber}"`
            );
            throw e;
        }
    }

    async releaseShipment(
        shipmentId: number,
        paperworkGroupings: PaperworkGrouping[]
    ) {
        try {
            const formData = serialize(
                {
                    shipmentId: shipmentId,
                    paperworkGroupings: paperworkGroupings.map((grouping) => ({
                        paperworkCategory: grouping.category,
                        documents: grouping.files,
                    })),
                },
                {
                    indices: true,
                    dotsForObjectNotation: true,
                    noFilesWithArrayNotation: true,
                }
            );
            await axios.post(
                'api/CustomsDiscrepancies/ReleaseShipment',
                formData
            );
        } catch (e) {
            emitError(
                `Something went wrong during the "Release" action of the item with id "${shipmentId}"`
            );
            throw e;
        }
    }

    async setReportedToCustomer(item: EnrichedMissingPaperworkShipment) {
        item.loading = true;
        try {
            await api.setReportedToCustomer({
                shipmentId: item.shipmentId,
                reportedToCustomer: item.reportedToCustomer,
            });
            item.success = true;
            item.initialReportedToCustomer = item.reportedToCustomer ?? '';
        } catch {
            emitError(
                'Something went wrong while setting the "Reported to customer" value.'
            );
        }

        item.loading = false;
    }
}
