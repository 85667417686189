<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" class="pa-0">
                <v-tabs v-model="currentTab">
                    <v-tab>Active</v-tab>
                    <v-tab>History</v-tab>
                </v-tabs>
                <v-tabs-items v-model="currentTab">
                    <v-tab-item
                        ><MrnDiscrepanciesOverview></MrnDiscrepanciesOverview
                    ></v-tab-item>
                    <v-tab-item
                        ><MrnDiscrepanciesOverview
                            :archived="true"
                        ></MrnDiscrepanciesOverview
                    ></v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import MrnDiscrepanciesOverview from '@/components/MrnDiscrepanciesOverview.vue';
const currentTab = ref(0);
</script>
