import {
    CustomsHandlingPaperworkViewModel,
    CustomsPaperworkHandlingApi,
    DataApi,
    KeyValueItem,
} from '@/openapi';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import ButtonGroup from './buttons/ButtonGroup.vue.html';

const customsPaperworkHandlingApi = new CustomsPaperworkHandlingApi(
    undefined,
    ''
);

const dataApi = new DataApi(undefined, '');

interface EditableCategory {
    editable: boolean;
    loading: boolean;
}

type EnrichedPaperwork = CustomsHandlingPaperworkViewModel & EditableCategory;

@Component({
    components: {
        ButtonGroup
    },
})
export default class ShipmentPaperwork extends Vue {
    @Prop()
    shipmentId!: number;

    paperworkDocuments: EnrichedPaperwork[] = [];

    documentTypes: KeyValueItem[] = [];
    selectedDocumentType: number | null = null;

    async created() {
        this.getPaperworkDocuments();
        this.getDocumentTypes();
    }

    getPaperworkDocuments() {
        customsPaperworkHandlingApi
            .getPaperworkForShipment(this.shipmentId)
            .then((result) => {
                this.paperworkDocuments = result.data.map((c) => {
                    return {
                        ...c,
                        editable: false,
                        loading: false,
                    };
                });
            })
            .catch((error) => {
                this.displayError(error.message);
            });
    }

    async getDocumentTypes() {
        try {
            const response = await dataApi.getDocumentTypesRelatedToPaperworkCategories();
            this.documentTypes = response.data;
        } catch {
            this.displayError('Failed to retrieve the paperwork categories');
        }
    }

    displayError(errorMessage: string) {
        this.$emit('errorOccured', errorMessage);
    }

    makeEditable(document: EnrichedPaperwork) {
        if (document.editable) {
            this.selectedDocumentType = null;
        } else {
            this.selectedDocumentType = document.documentTypeId!;
        }
        document.editable = !document.editable;
    }

    async changeDocumentType(
        documentTypeId: number,
        document: EnrichedPaperwork
    ) {
        try {
            document.loading = true;
            await customsPaperworkHandlingApi.changeDocumentType(
                document.documentId,
                documentTypeId
            );
            document.documentType = this.documentTypes.find(
                (dt) => dt.key === documentTypeId
            )?.value;
            document.documentTypeId = documentTypeId;
            document.editable = false;
        } catch {
            this.displayError(
                'Something went wrong while changing the category'
            );
        }

        document.loading = false;
    }
}
