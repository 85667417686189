import { render, staticRenderFns } from "./CustomsExportMrnDialog.vue?vue&type=template&id=4f59faa0&scoped=true"
import script from "./CustomsExportMrnDialog.vue?vue&type=script&setup=true&lang=ts"
export * from "./CustomsExportMrnDialog.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./CustomsExportMrnDialog.vue?vue&type=style&index=0&id=4f59faa0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f59faa0",
  null
  
)

export default component.exports