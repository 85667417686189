import { CONSOLIDATION_TYPE_T1 } from '@/constants/constants';
import { emitError } from '@/event-bus';
import { ShipmentApi } from '@/openapi';
import { DataAction } from '@/store/dataModule';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

export interface CreateConsolidationForm {
    name: string;
    type: string;
}

const api = new ShipmentApi(undefined, '');

@Component({})
export default class CreateConsolidationDialog extends Vue {
    @Prop()
    value!: boolean; // Determines whether to show the dialog or not

    @Prop({ default: () => [] })
    shipmentIds!: number[];

    @Prop({ default: false })
    onlyT1!: boolean;

    validForm = false;
    form: CreateConsolidationForm = this.setDefaultFormValues();
    isLoading = false;

    @Watch('value')
    onValueChanged(newValue: boolean, oldVal: boolean) {
        if (newValue == false) {
            this.form = this.setDefaultFormValues();
            (
                this.$refs.form as Vue & { resetValidation: () => boolean }
            ).resetValidation();
        }
    }

    async created() {
        await this.$store.dispatch(DataAction.FetchConsolidationTypes);
    }

    async createConsolidation() {
        this.isLoading = true;
        try {
            await api.addConsolidationToShipments({
                shipmentIds: this.shipmentIds,
                consolidationNumber: this.form.name,
                consolidationType: this.form.type,
            });
            this.$emit('created-consolidation');
            this.$emit('input', false);
        } catch {
            emitError('Something went wrong while creating a consolidation.');
        }
        this.isLoading = false;
    }

    setDefaultFormValues(): CreateConsolidationForm {
        return {
            name: '',
            type: '',
        };
    }

    get consolidationTypes(): string[] {
        if (this.onlyT1) {
            return this.$store.getters.consolidationTypes.filter(
                (type: string) => type == CONSOLIDATION_TYPE_T1
            );
        }
        return this.$store.getters.consolidationTypes;
    }
}
