<template>
    <EditableValue
        :wrapper="wrapper"
        :readonly="readonly"
        :value="valueOfKey"
        :differenceFunc="isDifferentValue"
        :check-difference="checkDifference"
    >
        <template #display-value>
            {{ displayValue }}
        </template>
        <template #editor="{ setEditable }">
            <v-autocomplete
                :value="value"
                :item-value="itemValue"
                :item-text="itemText"
                :items="items"
                :return-object="returnObject"
                @change="onChange($event, setEditable)"
                @blur="setEditable(false)"
                :autofocus="true"
                :error-messages="errorMessages"
                :multiple="multiple"
                class="d-inline-block"
            ></v-autocomplete>
        </template>
    </EditableValue>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import EditableValue from '@/components/editors/EditableValue.vue';
const stringify = require('json-stable-stringify');

interface EditableDropdownProps {
    value?: any;
    wrapper?: string;
    readonly?: boolean;
    items?: any[];
    returnObject?: boolean;
    itemValue?: string;
    itemText?: string;
    displayText?: string;
    multiple?: boolean;
    errorMessages?: string[];
    checkDifference?: boolean;
}

const props = withDefaults(defineProps<EditableDropdownProps>(), {
    wrapper: 'div',
    readonly: false,
    returnObject: true,
    items: () => [],
    itemValue: 'key',
    itemText: 'value',
    multiple: false,
    errorMessages: () => [],
    checkDifference: true,
});
const emits = defineEmits(['input']);

const valueOfKey = computed((): any => {
    return props.returnObject
        ? props.value?.[props.itemValue] ?? null
        : props.value;
});

const isDifferentValue = (initialValue: any, currentValue: any): boolean => {
    if (!props.multiple && !props.returnObject) {
        return initialValue !== currentValue;
    }

    return stringify(initialValue) !== stringify(currentValue);
};

const onChange = (value: any, setEditable: Function) => {
    if (!props.multiple) {
        setEditable(false);
    }
    emits('input', value);
};

const getDisplayText = (item: any): string => {
    if (!item && item !== 0) {
        return 'x';
    }
    return item?.[displayTextPropName.value] ?? 'x';
};

const displayTextPropName = computed(() => {
    return props.displayText ? props.displayText : props.itemText;
});

const displayValue = computed(() => {
    if (!props.value) {
        return 'x';
    }

    if (props.returnObject) {
        return processReturnObject(props.value);
    }

    return processNonObjectValue(props.value);
});

const processReturnObject = (value: any) => {
    if (props.multiple) {
        const joinedValues = (<any[]>props.value)
            ?.map((c) => getDisplayText(c))
            .join(', ');
        return joinedValues || 'x';
    } else {
        return getDisplayText(value);
    }
};

const processNonObjectValue = (value: any) => {
    if (props.multiple) {
        const joinedValues = props.items
            .reduce((accumulator, currentValue) => {
                if (
                    (<any[]>props.value).includes(currentValue[props.itemValue])
                ) {
                    accumulator.push(getDisplayText(currentValue));
                }
                return accumulator;
            }, [] as any[])
            .join(', ') as string;
        return joinedValues || 'x';
    } else {
        const displayItem = props.items?.find(
            (item) => item[props.itemValue] === value
        );
        return displayItem ? getDisplayText(displayItem) : 'x';
    }
};
</script>
