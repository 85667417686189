<template>
    <Fragment>
        <ValidationObserver
            :disabled="true"
            slim
            ref="observer"
            v-slot="{ invalid }"
        >
            <v-dialog v-model="localValue" max-width="250px">
                <v-card>
                    <v-card-title>Button link</v-card-title>
                    <v-card-text>
                        <v-form>
                            <ValidationProvider
                                :rules="{
                                    required: true,
                                    regex: /^(http(s):\/\/)[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//={}]*)$/,
                                }"
                                name="Url"
                                v-slot="{ errors }"
                            >
                                <v-text-field
                                    v-model="formData.url"
                                    dense
                                    prepend-icon="mdi-link"
                                    label="Url"
                                    :error-messages="errors"
                                ></v-text-field>
                            </ValidationProvider>
                            <ValidationProvider
                                rules="required"
                                name="Text"
                                v-slot="{ errors }"
                            >
                                <v-text-field
                                    v-model="formData.text"
                                    dense
                                    prepend-icon="mdi-format-text"
                                    label="Text"
                                    :error-messages="errors"
                                ></v-text-field>
                            </ValidationProvider>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="confirmAction" :disabled="invalid"
                            >Confirm</v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </ValidationObserver>
    </Fragment>
</template>

<script setup lang="ts">
import { useFormReset } from '@/composables/formReset';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { computed, ref } from 'vue';

export interface LinkButtonFormData {
    url: string;
    text: string;
}

interface LinkButtonDialogProps {
    value: boolean;
}

const defaultFormValues: LinkButtonFormData = {
    text: '',
    url: '',
};

const props = defineProps<LinkButtonDialogProps>();
const emits = defineEmits(['input', 'confirm']);

const observer = ref<InstanceType<typeof ValidationObserver>>();
const formData = ref<LinkButtonFormData>({ ...defaultFormValues });

const confirmAction = () => {
    emits('confirm', { ...formData.value });
    localValue.value = false;
};

const localValue = computed({
    get() {
        return props.value;
    },
    set(val: boolean) {
        emits('input', val);
    },
});
useFormReset(localValue, formData, defaultFormValues, observer);
</script>
