import { emitError } from '@/event-bus';
import { CountryOfOriginViewModel, DataApi } from '@/openapi';
import Vue from 'vue';
import Component from "vue-class-component";

const dataApi = new DataApi(undefined, '');

@Component({})
export default class CountriesMixin extends Vue {

    dataCountries: CountryOfOriginViewModel[] = [];

    created() {
        this.getCountries();
    }

    async getCountries() {
        try {
            const response = await dataApi.getOriginCountries();
            this.dataCountries = response.data;
        } catch {
            emitError('Something went wrong while retrieving the countries');
        }
    }
}