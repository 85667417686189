<template>
    <v-checkbox
        :input-value="value"
        :indeterminate="isIndeterminate"
        @change="onChange"
        v-on="$listeners"
        v-bind="$attrs"
    ></v-checkbox>
</template>

<script lang="ts">
export default {
    inheritAttrs: false,
};
</script>

<script setup lang="ts">
import { computed } from 'vue';

interface IndeterminateCheckboxProps {
    value?: boolean | null;
}

const props = defineProps<IndeterminateCheckboxProps>();
const emits = defineEmits(['input']);

const isIndeterminate = computed(() => {
    return props.value == null;
});

let oldValue = props.value;
const onChange = (event: boolean | null) => {
    let emitValue;
    if (oldValue == null) {
        emitValue = true;
    } else if (oldValue) {
        emitValue = false;
    } else {
        emitValue = null;
        event = null;
    }
    emits('input', emitValue);
    oldValue = event;
};
</script>
