<template>
  <Fragment>
    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="20"
      :footer-props="{ itemsPerPageOptions: [10, 20, 50, -1] }"
      :loading="loading"
    >
      <template #item.download="{ item }">
        <v-btn @click="downloadFremdGestellungFile(item.id)" color="primary"
          >Download</v-btn
        >
        &nbsp;
        <v-btn @click="openDeleteFileDialog(item)" color="error"
          ><v-icon color="white">mdi-delete-forever</v-icon></v-btn
        >
      </template>
      <template #item.importedAt="{ item }">
        {{ formatDateTime(item.importedAt) }}
      </template>
      <template #item.mawb="{ item }">
        <v-autocomplete
          v-model.number="item.mawbId"
          :items="mawbConsolidations"
          label="MAWB"
          item-text="mawb"
          item-value="mawbId"
        ></v-autocomplete>
      </template>
      <template #item.consolidation="{ item }">
        <v-autocomplete
          v-model.number="item.consolidationId"
          :items="getConsolidationsForMawb(getSelectedMawb(item.mawbId))"
          label="Consolidation"
          item-text="value"
          item-value="key"
        ></v-autocomplete>
      </template>
      <template #item.assign="{ item }">
        <v-btn
          @click="assignFremdGestellung(item)"
          color="primary"
          :disabled="!item.mawbId || !item.consolidationId"
          :loading="item.assigning"
          >Assign</v-btn
        >
      </template>
    </v-data-table>
    <v-snackbar
      v-model="assignedSuccessful"
      timeout="4000"
      top
      right
      color="success"
    >
      Fremd gestellung was assigned
    </v-snackbar>
    <v-snackbar
      v-model="deleteSuccessful"
      timeout="4000"
      top
      right
      color="success"
    >
      Item was deleted
    </v-snackbar>
    <v-dialog
      v-if="
        showDeleteFremdGestellungItemDialog && currentDeleteFremdGestellungItem
      "
      :value="true"
      persistent
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 error white--text">
          Are you sure?
        </v-card-title>
        <v-card-text>
          Are you sure you want to delete file '{{
            currentDeleteFremdGestellungItem.fileName
          }}'? This action can't be undone.
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn text @click="cancelFileDeletion"> Cancel </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="deleteFile"> Delete </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="
        showFremdGestellungAssignedDialog &&
        assignedFremdGestellungRecordResponse
      "
      :value="true"
      persistent
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 success white--text">
          Fremd gestellung assigned
        </v-card-title>
        <v-card-text>
          <p>The Fremd gestellung was assigned.</p>
          <p>
            <b>
              The following positions were extra in the Fremd gestellung and were
              not found in the database:
            </b>
            <table class="full">
              <tr>
                <th class="half">HAWB</th>
                <th class="half">Position number in Fremd gestellung file</th>
              </tr>
              <tr
                v-for="(
                  item, index
                ) in assignedFremdGestellungRecordResponse.notImportedPositionsFromFremdGestellung"
                :key="`notImported-${index}`"
              >
                <td>{{ item.hawb }}</td>
                <td>{{ item.positionNumber }}</td>
              </tr>
            </table>
          </p>
          <p>
            <b>
              The following shipments were found in the database but were not
              found in the Fremd gestellung file:
            </b>
            <table class="half">
              <tr>
                <th>HAWB</th>
              </tr>
              <tr
                v-for="(
                  item, index
                ) in assignedFremdGestellungRecordResponse.shipmentsWithoutFremdGestellungPosition"
                :key="`shipmentsWithoutPosition-${index}`"
              >
                <td>{{ item.hawb }}</td>
              </tr>
            </table>
          </p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="success"
            text
            @click="closeFremdGestellungAssignmentDialog"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </Fragment>
</template>

<script setup lang="ts">
import { emitError } from "@/event-bus";
import {
  downloadFile,
  getFileNameFromContentDispositionHeader,
} from "@/helpers/downloadHelper";
import { ToolbarItem } from "@/models/ToolbarItem";
import {
  DataApi,
  FileImportApi,
  FileImportAssignFremdGestellungRequest,
  FileImportAssignFremdGestellungResponse,
  FileImportFremdGestellungViewModel,
  MawbConsolidations,
} from "@/openapi";
import moment from "moment";
import { onBeforeMount, ref } from "vue";
import { DataTableHeader } from "vuetify";

const emits = defineEmits<{
  (e: "PageInfoReceived", title: String, items: ToolbarItem[]): void;
}>();

const api = new FileImportApi(undefined, "");
const dataApi = new DataApi(undefined, "");
const loading = ref(false);
const headers = ref<DataTableHeader[]>([
  {
    text: "Actions",
    value: "download",
    sortable: false,
  },
  {
    text: "File name",
    value: "fileName",
  },
  {
    text: "Imported at",
    value: "importedAt",
  },
  {
    text: "MRN",
    value: "mrn",
  },
  {
    text: "ATB",
    value: "atb",
  },
  {
    text: "MAWB",
    value: "mawb",
  },
  {
    text: "Consolidation",
    value: "consolidation",
  },
  {
    text: "Assign",
    value: "assign",
    sortable: false,
  },
]);
const items = ref<FileImportFremdGestellungViewModel[]>([]);
const mawbConsolidations = ref<MawbConsolidations[]>([]);

const currentDeleteFremdGestellungItem =
  ref<FileImportFremdGestellungViewModel | null>(null);
const showDeleteFremdGestellungItemDialog = ref(false);

const showFremdGestellungAssignedDialog = ref(false);
const assignedFremdGestellungRecordResponse =
  ref<FileImportAssignFremdGestellungResponse | null>(null);

const assignedSuccessful = ref(false);
const deleteSuccessful = ref(false);

const getMawbConsolidations = async () => {
  try {
    mawbConsolidations.value = [];
    const response = await dataApi.getMawbConsolidations();
    mawbConsolidations.value = response.data;
  } catch (error: unknown) {
    emitError("An error occurred when loading the MAWB consolidations");
  }
};

const getFremdGestellungItems = async () => {
  loading.value = true;
  try {
    items.value = [];
    const response = await api.getFremdGestellungItems();
    items.value = response.data;
  } catch (error: unknown) {
    emitError("An error occurred when loading the Fremd gestellung items");
  } finally {
    loading.value = false;
  }
};

const downloadFremdGestellungFile = async (id: number) => {
  try {
    const response = await api.downloadFremdGestellungItem(id);
    const fileName = getFileNameFromContentDispositionHeader(response);
    downloadFile(response.data, fileName!);
  } catch (error: unknown) {
    emitError("An error occurred when downloading the file");
  }
};

const openDeleteFileDialog = (item: FileImportFremdGestellungViewModel) => {
  currentDeleteFremdGestellungItem.value = item;
  showDeleteFremdGestellungItemDialog.value = true;
};

const cancelFileDeletion = () => {
  currentDeleteFremdGestellungItem.value = null;
  showDeleteFremdGestellungItemDialog.value = false;
};

const deleteFile = async () => {
  if (!currentDeleteFremdGestellungItem.value) {
    return;
  }

  try {
    await api.deleteFremdGestellungItem(
      currentDeleteFremdGestellungItem.value.id,
    );
    await getFremdGestellungItems();
    currentDeleteFremdGestellungItem.value = null;
    showDeleteFremdGestellungItemDialog.value = false;
  } catch (error: unknown) {
    emitError("An error occurred when deleting the file");
  }
};

const formatDateTime = (datetime: string) => {
  if (!datetime) {
    return "";
  }

  const dateTimeMoment = moment(datetime);
  return dateTimeMoment.format("YYYY-MM-DD HH:mm:ss");
};

const getSelectedMawb = (id: number) => {
  return mawbConsolidations.value.find((mawb) => mawb.mawbId === id);
};

const getConsolidationsForMawb = (mawb: MawbConsolidations | undefined) => {
  if (!mawb) {
    return [];
  }

  return mawb.consolidations;
};

const assignFremdGestellung = async (
  item: FileImportFremdGestellungViewModel,
) => {
  try {
    item.assigning = true;

    const request: FileImportAssignFremdGestellungRequest = {
      fremdGestellungId: item.id,
      mawbId: item.mawbId,
      consolidationId: item.consolidationId,
    };
    const response = await api.assignFremdGestellungItem(request);
    assignedFremdGestellungRecordResponse.value = response.data;
    showFremdGestellungAssignedDialog.value = true;
    await getFremdGestellungItems();
  } catch (error: unknown) {
    emitError("An error occurred when assigning the Fremd gestellung");
  }
};

const closeFremdGestellungAssignmentDialog = () => {
  showFremdGestellungAssignedDialog.value = false;
  assignedFremdGestellungRecordResponse.value = null;
};

onBeforeMount(async () => {
  emits("PageInfoReceived", "Fremd gestellung", [
    {
      callback: getFremdGestellungItems,
      icon: "mdi-refresh",
      tooltipText: "Refresh",
    },
  ]);

  try {
    loading.value = true;
    await Promise.all([getMawbConsolidations(), getFremdGestellungItems()]);
  } catch (error) {
    emitError("Could not load data");
  } finally {
    loading.value = false;
  }
});
</script>

<style scoped lang="scss">
.full {
  width: 100%;
}

.half {
  width: 50%;
}
</style>
