<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" class="pa-0">
                <v-tabs
                    v-model="currentTab"
                    background-color="grey darken-3"
                    center-active
                    dark
                >
                    <v-tab>Definition</v-tab>
                    <v-tab>Templates</v-tab>
                </v-tabs>
                <v-tabs-items v-model="currentTab">
                    <v-tab-item
                        ><CustomsTransitDeclarationDefinitions></CustomsTransitDeclarationDefinitions
                    ></v-tab-item>
                    <v-tab-item
                        ><CustomsTransitDeclarationTemplates></CustomsTransitDeclarationTemplates
                    ></v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import CustomsTransitDeclarationDefinitions from '@/components/CustomsTransitDeclarationDefinitions.vue';
import CustomsTransitDeclarationTemplates from '@/components/CustomsTransitDeclarationTemplates.vue';
const currentTab = ref(0);
</script>
