import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import * as CustomsExportController from '../api/CustomsExportController';
import CustomsExportShipmentDetailViewNew from './CustomsExportShipmentDetailViewNew.vue.html';
import { CustomsShipmentDetailViewModel } from '@/api/viewmodels/CustomsShipmentDetailViewModel';
import AddMrnDialog from './dialogs/AddMrnDialog.vue.html';
import ConfirmShipmentReasonDialog from './dialogs/ConfirmShipmentReasonDialog.vue.html';
import CustomsFlowStepsMixin from '@/mixins/CustomsFlowStepsMixin';
import ShipmentStatusFlow from './ShipmentStatusFlow.vue.html';
import { ReturnReasonSecondViewModel } from '@/api/viewmodels/ReturnReasonSecondViewModel';
import { ReturnReasonFirstViewModel } from '@/api/viewmodels/ReturnReasonFirstViewModel';
import { HoldReturnExportDialogDateFilter } from './dialogs/HoldReturnExportDialog';
import HoldReturnExportDialog from './dialogs/HoldReturnExportDialog.vue.html';
import { getStatuses } from '@/helpers/statusHelper';
import { CustomsReturnApi, CustomsShipmentItemViewModel } from '@/openapi';
import CountriesMixin from '@/mixins/CountriesMixin';
import ShipmentPaperwork from './ShipmentPaperwork.vue.html';
import ToolbarMixin from '@/mixins/ToolbarMixin';
import FilterParcels from './FilterParcels.vue.html';

import ReleaseShipmentButton from './buttons/ReleaseShipmentButton.vue.html';
import ReturnShipmentButton from './buttons/ReturnShipmentButton.vue.html';
import CustomsInspectionButton from './buttons/CustomsInspectionButton.vue.html';
import HoldShipmentButton from './buttons/HoldShipmentButton.vue.html';

const customsReturnApi = new CustomsReturnApi(undefined, '');

@Component({
    components: {
        CustomsExportShipmentDetailViewNew,
        AddMrnDialog,
        ShipmentStatusFlow,
        ConfirmShipmentReasonDialog,
        HoldReturnExportDialog,
        ShipmentPaperwork,
        FilterParcels,
        ReleaseShipmentButton,
        ReturnShipmentButton,
        CustomsInspectionButton,
        HoldShipmentButton,
    },
})
export default class CustomsExportHoldReturn extends mixins(
    CustomsFlowStepsMixin,
    CountriesMixin,
    ToolbarMixin
) {
    @Prop({ default: '' })
    color!: string;

    isLoading = false;
    options: any = {
        page: 1,
        itemsPerPage: 5,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: true,
    };
    filter: any = {
        step: null,
        shipmentStatusDescription: '',
        parcels: [],
        exportMrn: '',
        showReturn: false,
        returnShipmentsDateRange: null,
        shipmentConfirmed: null,
        shipmentLoaded: null,
        showHoldOnly: false,
    };
    footerOptions: any = {
        showFirstLastPage: true,
        itemsPerPageOptions: [5, 25, 50, 100],
        disablePagination: false,
    };
    totalItems: Number = 0;
    expanded = [];

    statusFilter: number[] = [];
    shipmentStatusFilter = [
        'Hold',
        'Return',
        'Customs inspection',
        'Loading confirmed',
        'Waiting for customs release',
        'Customs EXIT released',
    ];
    booleanFilter = ['Yes', 'No'];
    returnReasonsFirst: ReturnReasonFirstViewModel[] = [];
    returnReasonsSecond: ReturnReasonSecondViewModel[] = [];

    items: CustomsShipmentItemViewModel[] = [];
    currentHandlingItem: CustomsShipmentItemViewModel | null = null;
    currentShipmentDetail: CustomsShipmentDetailViewModel | null = null;
    selectedHandlingItems: CustomsShipmentItemViewModel[] = [];

    showHoldReturnExportDialog = false;

    headers = [
        {
            text: 'Step',
            value: 'exportFlowStatus',
            align: 'center',
        },
        {
            text: 'Loaded',
            value: 'isLoaded',
            align: 'center',
        },
        {
            text: 'Confirmed',
            value: 'isConfirmed',
            align: 'center',
        },
        {
            text: 'Shipment status',
            value: 'shipmentStatusDescription',
        },
        {
            text: 'HAWB/Parcel ID',
            value: 'hawb',
            sortable: false,
            width: '15em',
        },
        {
            text: 'MRN ',
            value: 'exportMrn',
            width: '18em',
        },
        {
            text: 'Return reason ',
            value: 'shipmentReason',
            width: '18em',
        },
        { text: 'Anzahl ', value: 'pieces', sortable: false, align: 'end' },
        {
            text: 'Gewicht ',
            value: 'grossWeight',
            sortable: false,
            align: 'end',
        },
        { text: 'EORI', value: 'eori', sortable: false },
        {
            text: 'Versender',
            value: 'shipper',
            sortable: false,
            width: '15em',
        },
        {
            text: 'Empfänger',
            value: 'consignee',
            sortable: false,
            width: '15em',
        },
        { text: 'Warenwert', value: 'value', sortable: false, align: 'end' },
        { text: 'Währung', value: 'valueCurrency', sortable: false },
        {
            text: 'Warenbeschreibung / tarifnummer',
            value: 'articles',
            sortable: false,
            width: '25em',
        },
    ];

    created() {
        this.getReturnReasons();
        this.statusFilter = getStatuses(false, true, true, true, false);

        this.addRefreshToolbarOption(this.reloadShipments);
    }

    get returnReasonsFilter() {
        if (!this.returnReasonsFirst?.length) {
            return [];
        }

        var filters = [];
        for (const reason of this.returnReasonsFirst) {
            if (!reason.returnReasonsSecond?.length) {
                continue;
            }

            filters.push({
                divider: true,
                header: reason.reason,
            });

            for (const secondReason of reason.returnReasonsSecond) {
                filters.push({
                    value: secondReason.id,
                    text: secondReason.reason,
                });
            }
        }

        return filters;
    }

    get customsHandlingItemIds() {
        return this.items?.map((c) => c.id) ?? [];
    }

    get holdReturnExportDateFilter(): HoldReturnExportDialogDateFilter | null {
        return this.filter.returnShipmentsDateRange?.length === 2
            ? {
                  fromDate: this.filter.returnShipmentsDateRange[0],
                  tillDate: this.filter.returnShipmentsDateRange[1],
              }
            : null;
    }

    async loadShipments(page: number) {
        this.isLoading = true;
        try {
            let startDateString: string = '';
            let endDateString: string = '';
            if (this.filter.returnShipmentsDateRange?.length === 2) {
                startDateString = this.filter.returnShipmentsDateRange[0];
                endDateString = this.filter.returnShipmentsDateRange[1];
            }

            const response = await customsReturnApi.getHoldReturnShipments({
                page,
                itemsPerPage: this.options.itemsPerPage,
                step: this.filter.step ?? undefined,
                shipmentStatusDescription:
                    this.filter.shipmentStatusDescription ?? undefined,
                parcels: this.filter.parcels,
                exportMrn: this.filter.exportMrn ?? undefined,
                shipmentLoaded: this.filter.shipmentLoaded ?? undefined,
                shipmentConfirmed: this.filter.shipmentConfirmed ?? undefined,
                showReturn: this.filter.showReturn ?? undefined,
                returnReasonSecond: this.filter.returnReasonSecond ?? undefined,
                returnStartDate: startDateString,
                returnEndDate: endDateString,
                shipperCountry: this.filter.shipperCountry ?? undefined,
                consigneeCountry: this.filter.consigneeCountry ?? undefined,
                showHoldOnly: this.filter.showHoldOnly ?? undefined,
            });

            this.items = response.data.shipments ?? [];
            this.totalItems = response.data.totalAmount ?? 0;
        } catch (e) {
            this.displayError(
                'Something went wrong while retrieving the shipments'
            );
        }
        this.isLoading = false;
    }

    async reloadShipments() {
        this.options.page == 1
            ? await this.loadShipments(1)
            : (this.options.page = 1);
    }

    timeoutDelay = 0;
    @Watch('options')
    onOptionsChanged(newVal: any, oldVal: any) {
        clearTimeout(this.timeoutDelay);
        this.timeoutDelay = setTimeout(() => {
            this.loadShipments(this.options.page);
        }, 250);
    }

    @Watch('expanded')
    onExpandedChanged(
        newVal: CustomsShipmentItemViewModel[],
        oldVal: CustomsShipmentItemViewModel[]
    ) {
        if (newVal.length === 0) {
            this.currentHandlingItem = null;
            this.currentShipmentDetail = null;
        } else {
            this.currentHandlingItem = newVal[0];

            this.isLoading = true;
            this.currentShipmentDetail = null;
            CustomsExportController.getShipmentDetail(
                this.currentHandlingItem.id!
            )
                .then(
                    (response) => (this.currentShipmentDetail = response.data)
                )
                .catch((error) =>
                    this.displayError(
                        'Something went wrong while retrieving the detail data.'
                    )
                )
                .finally(() => (this.isLoading = false));
        }
    }

    onFilterSelected() {
        this.$nextTick(() => {
            this.reloadShipments();
        });
    }

    displayError(errorMessage: string) {
        this.$emit('errorOccured', errorMessage);
    }

    async getReturnReasons() {
        this.returnReasonsFirst = [];
        try {
            var response = await CustomsExportController.getReturnReasons();
            this.returnReasonsFirst = response.data;
        } catch {
            this.displayError(
                'Something went wrong while retrieving the return reason filters.'
            );
        }
    }

    onShipmentStatusChanged({ success }: any) {
        if (success) {
            this.selectedHandlingItems = [];
            this.loadShipments(this.options.page);
        }
    }
}
