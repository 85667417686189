<template>
    <ValidationObserver slim v-slot="{ invalid }" ref="observer">
        <DefaultDialog :value="value">
            <template v-slot:header>ICS 2 Nachrichten</template>
            <template v-slot:content>
                <v-form>
                    <ValidationProvider
                        rules="required"
                        v-slot="{ errors }"
                        name="ICS 2 MRN number"
                    >
                        <v-text-field
                            v-model="formData.mrnNumber"
                            label="ICS 2 MRN number"
                            :error-messages="errors"
                        ></v-text-field>
                    </ValidationProvider>
                </v-form>
            </template>
            <template v-slot:footer>
                <v-spacer></v-spacer>
                <v-btn @click="close" :disabled="dialogLoading" text small
                    >Close</v-btn
                >
                <v-btn
                    @click="$emit('confirm', formData)"
                    :disabled="invalid"
                    :loading="dialogLoading"
                    text
                    small
                    color="primary"
                    >Confirm</v-btn
                >
            </template>
        </DefaultDialog>
    </ValidationObserver>
</template>

<script setup lang="ts">
import { ValidationObserver } from 'vee-validate';
import { ref, watch } from 'vue';
import DefaultDialog from '@/components/dialogs/DefaultDialog.vue.html';

export interface NachrichtenFormData {
    mrnNumber: string;
}

interface NachrichtenDialogProps {
    value?: boolean;
    dialogLoading?: boolean;
    mrn: string;
}

const defaultFormValues: NachrichtenFormData = {
    mrnNumber: '',
};
const props = defineProps<NachrichtenDialogProps>();
const emits = defineEmits(['input', 'close', 'confirm']);

const formData = ref<NachrichtenFormData>({ ...defaultFormValues });
const observer = ref<InstanceType<typeof ValidationObserver> | null>(null);

watch(
    () => props.value,
    (newValue: boolean | undefined, oldVal: boolean | undefined) => {
        if (newValue !== undefined && !newValue) {
            formData.value = { ...defaultFormValues };
            observer.value!.reset();
        }
    }
);

watch(
    () => props.mrn,
    (newVal: string, oldVal: string) => {
        formData.value.mrnNumber = newVal;
    }
)

const close = () => {
    emits('input', false);
    emits('close');
};
</script>
