<template>
    <ValidationObserver slim v-slot="{ invalid }" ref="observer">
        <DefaultDialog :value="value">
            <template v-slot:header>Erstelle Gestellung (Airport)</template>
            <template v-slot:content>
                <v-form>
                    <ValidationProvider
                        rules="required"
                        v-slot="{ errors }"
                        name="Customs clearance office"
                    >
                        <v-autocomplete
                            v-model="formData.customsClearanceOfficeId"
                            :items="customsOffices"
                            item-value="id"
                            :item-text="getCustomsOfficeName"
                            label="Customs clearance office"
                            :error-messages="errors"
                        ></v-autocomplete>
                    </ValidationProvider>

                    <ValidationProvider
                        rules="required"
                        v-slot="{ errors }"
                        name="Customer"
                    >
                        <v-autocomplete
                            v-model="formData.customerId"
                            :items="customers"
                            item-value="id"
                            item-text="shortName"
                            label="Customer"
                            :error-messages="errors"
                        ></v-autocomplete>
                    </ValidationProvider>

                    <ValidationProvider
                        rules="required"
                        v-slot="{ errors }"
                        name="Flugnummer"
                    >
                        <v-text-field
                            v-model="formData.flugnummer"
                            label="Flugnummer"
                            :error-messages="errors"
                        ></v-text-field>
                    </ValidationProvider>

                    <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                            name="Ankunftsdatum"
                        >
                        <DatePicker
                            v-model="formData.ankunftsdatum"
                            :error-messages="errors"
                            type="date"
                            label="Ankunftsdatum"
                        ></DatePicker>
                    </ValidationProvider>

                    <ValidationProvider
                        rules="required"
                        v-slot="{ errors }"
                        name="Origin Airport Code"
                    >
                        <v-text-field
                            v-model="formData.originAirportCode"
                            label="Origin Airport Code"
                            :error-messages="errors"
                        ></v-text-field>
                    </ValidationProvider>
                </v-form>
            </template>
            <template v-slot:footer>
                <v-spacer></v-spacer>
                <v-btn @click="close" :disabled="dialogLoading" text small
                    >Close</v-btn
                >
                <v-btn
                    @click="$emit('confirm', formData)"
                    :disabled="invalid"
                    :loading="dialogLoading"
                    text
                    small
                    color="primary"
                    >Confirm</v-btn
                >
            </template>
        </DefaultDialog>
    </ValidationObserver>
</template>

<script setup lang="ts">
import { ValidationObserver } from 'vee-validate';
import { computed, ref, watch } from 'vue';
import DefaultDialog from '@/components/dialogs/DefaultDialog.vue.html';
import store from '@/store';
import { DataAction } from '@/store/dataModule';
import { CustomsOfficeViewModel } from '@/openapi';

export interface ErstelleGestellungFormData {
    customsClearanceOfficeId: number | null;
    customerId: number | null;
    flugnummer: string | null;
    ankunftsdatum: string | null;
    originAirportCode: string | null;
}

interface ErstelleGestellungProps {
    value?: boolean;
    dialogLoading?: boolean;
}

const defaultFormValues: ErstelleGestellungFormData = {
    customsClearanceOfficeId: null,
    customerId: null,
    ankunftsdatum: null,
    flugnummer: null,
    originAirportCode: null,
};
const props = defineProps<ErstelleGestellungProps>();
const emits = defineEmits(['input', 'close', 'confirm']);

const formData = ref<ErstelleGestellungFormData>({ ...defaultFormValues });
const observer = ref<InstanceType<typeof ValidationObserver> | null>(null);

const customsOffices = computed(() => store.getters.customsOffices);
const customers = computed(() => store.getters.generalCustomers);

watch(
    () => props.value,
    (newValue: boolean | undefined, oldVal: boolean | undefined) => {
        if (newValue == false) {
            formData.value = { ...defaultFormValues };
            observer.value!.reset();
        }
    }
);

const getCustomsOfficeName = (item: CustomsOfficeViewModel) => {
    return `${item.officeNumber} | ${item.name}`;
};

const close = () => {
    emits('input', false);
    emits('close');
};

store.dispatch(DataAction.FetchCustomsOffices);
store.dispatch(DataAction.FetchGeneralCustomers);
</script>
