import { Ref, ref } from 'vue';

export function useCrudPage<T>(
    newItemDefaults: T,
    identifierPropName: string,
    identifierPropDefault: any
) {
    const disableNewItemButton = ref(false);
    const items = ref<T[]>([]) as Ref<T[]>;
    const totalAmountOfItems = ref(0);
    const isLoading = ref(false);

    const addNewItem = () => {
        items.value.unshift({ ...newItemDefaults });
        disableNewItemButton.value = true;
    };

    const isNewItem = (item: T): boolean => {
        return item?.[identifierPropName as keyof T] == identifierPropDefault;
    };

    const deleteItem = (item: T) => {
        const index = items.value.findIndex(
            (x) =>
                x[identifierPropName as keyof T] ===
                item[identifierPropName as keyof T]
        );

        items.value.splice(index, 1);
    };

    const mapItem = (oldItem: T, newItem: T) => {
        for (const key in oldItem) {
            oldItem[key] = newItem[key];
        }
    };

    return {
        disableNewItemButton,
        items,
        totalAmountOfItems,
        isLoading,
        addNewItem,
        deleteItem,
        isNewItem,
        mapItem,
    };
}
