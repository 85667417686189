<template>
  <Fragment>
    <v-data-table
      v-if="currentTab === 0"
      :headers="headers"
      :items="items"
      :server-items-length="totalAmountOfItems"
      :options.sync="options"
      :footer-props="footerOptions"
      :disable-sort="disableFiltering"
      :disable-filtering="disableFiltering"
      item-key="shipmentId"
      fixed-footer
      fixed-header
      dense
      height="calc(100vh - 257px)"
      class="elevation-1"
      :loading="loading"
      @update:options="onUpdateOptions"
    >
      <template v-slot:top>
        <div class="px-4 py-4">
          <div class="py-4 d-flex align-baseline justify-space-between">
            <div class="d-flex align-baseline">
              <v-btn
                :disabled="loading || disableFiltering"
                color="primary"
                @click="openMrnsDialog"
                >MRN Number <v-icon right> mdi-magnify </v-icon></v-btn
              >
            </div>
            <div class="selected-mrn">
              <p v-if="currentItem">
                <b>MRN number:</b>
                {{ currentItem.mrn }}
              </p>
              <p class="ml-5" v-if="currentItem">
                <b>General customer:</b>
                {{ currentItem.customer }}
              </p>
            </div>

            <div v-if="!archived">
              <v-btn
                :disabled="loading || !currentItem"
                :loading="isArchiving"
                color="primary"
                @click="archiveMrn"
                >Archive
                <v-icon right> mdi-archive-plus-outline </v-icon></v-btn
              >
            </div>
          </div>
        </div>
      </template>
      <template #item.isScanned="{ item }">
        <v-icon :color="item.isScanned ? 'success' : 'error'">{{
          item.isScanned ? "mdi-check" : "mdi-close"
        }}</v-icon>
      </template>
    </v-data-table>
    <ShowDiscrepancyMrnDialog
      v-if="showDialog"
      v-model="showDialog"
      :archived="archived"
      @selectedMrn="onSelectedMrn"
    >
    </ShowDiscrepancyMrnDialog>
  </Fragment>
</template>

<script setup lang="ts">
import {
  MrnDiscrepanciesApi,
  DiscrepancyMrnShipment,
  DiscrepancyMrn,
} from "@/openapi";
import { ToolbarItem } from "../models/ToolbarItem";
import { DataOptions, DataTableHeader } from "vuetify";
import ShowDiscrepancyMrnDialog from "@/components/dialogs/ShowDiscrepancyMrnDialog.vue";
import { onBeforeMount, ref } from "vue";
import { FooterOptions } from "@/types/types";
import { emitError, emitSuccess } from "@/event-bus";
import store from "@/store";
import { DataAction } from "@/store/dataModule";

interface MrnDiscrepanciesOverviewProps {
  archived?: boolean;
}

const mrnDiscrepanciesApi = new MrnDiscrepanciesApi(undefined, "");

const props = defineProps<MrnDiscrepanciesOverviewProps>();
const emits = defineEmits(["PageInfoReceived"]);

const currentTab = ref(0);

const loading = ref(false);
const isArchiving = ref(false);
const disableFiltering = ref(false);

const items = ref<DiscrepancyMrnShipment[]>([]);
const copyOfItems = ref<string | null>();
const headers = ref<DataTableHeader[]>([
  { text: "HAWB", value: "hawbNumber", sortable: false },
  { text: "Scanned", value: "isScanned", sortable: false },
]);

const options = ref<DataOptions>({
  page: 1,
  itemsPerPage: 25,
  sortBy: [],
  sortDesc: [],
  groupBy: [],
  groupDesc: [],
  multiSort: false,
  mustSort: false,
});

const footerOptions = ref<FooterOptions>({
  showFirstLastPage: true,
  itemsPerPageOptions: [5, 25, 50, 100],
  disablePagination: false,
});

const totalAmountOfItems = ref<number | undefined>(0);
const currentItem = ref<DiscrepancyMrn | null>();

const showDialog = ref(false);

const openMrnsDialog = () => {
  showDialog.value = true;
};

const getShipments = async () => {
  loading.value = true;
  copyOfItems.value = null;
  try {
    const response = await mrnDiscrepanciesApi.getDiscrepancyMrnShipments(
      currentItem.value?.id,
      options.value.page,
      options.value.itemsPerPage,
    );

    items.value = response.data.items ?? [];
    totalAmountOfItems.value = response.data.totalAmountOfItems;
    loading.value = false;
  } catch {
    emitError("Something went wrong while retrieving the shipments");
  }
};

const onSelectedMrn = (mrn: DiscrepancyMrn) => {
  currentItem.value = mrn;
  showDialog.value = false;
  getShipments();
};

const onUpdateOptions = () => {
  if (currentItem.value?.mrn) {
    getShipments();
  }
};

const archiveMrn = async () => {
  loading.value = true;
  isArchiving.value = true;
  try {
    await mrnDiscrepanciesApi.archiveDiscrepancyMrn({
      mrnId: currentItem.value!.id,
    });

    currentItem.value = null;
    items.value = [];
    emitSuccess("Successfully moved to the archive");
  } catch {
    emitError("Something went wrong during archiving");
  }
  loading.value = false;
  isArchiving.value = false;
};

onBeforeMount(() => {
  store.dispatch(DataAction.FetchIncoterms);
  store.dispatch(DataAction.FetchCountriesOfOrigin);

  var toolbarButtons: ToolbarItem[] = [
    {
      callback: () => {
        if (currentItem.value?.id) {
          getShipments();
        }
      },
      icon: "mdi-refresh",
      tooltipText: "Refresh overview",
    },
  ];
  emits("PageInfoReceived", "Pre-check shipments", toolbarButtons);
});
</script>

<style scoped lang="scss">
.selected-mrn {
  display: flex;
  margin: auto;
}
</style>
