<template>
    <Fragment>
        <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
        <v-container>
            <v-row>
                <v-col v-for="item in items" :cols="3">
                    <v-card>
                        <div class="card-halve">
                            <span class="card-halve__text"> Level </span>
                            <span class="card-halve__value">
                                {{ item.level }}
                            </span>
                        </div>
                        <div class="card-halve text-right pr-5 pt-2">
                            <span class="card-halve__text"> Employees </span>
                            <v-list class="card-halve__list pa-0">
                                <v-list-item
                                    class="pa-0"
                                    dense
                                    v-for="employee in item.employeeNames"
                                >
                                    <v-list-item-content>
                                        <v-list-item-title>{{
                                            employee
                                        }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </Fragment>
</template>

<script setup lang="ts">
import { emitErrorWithFallback } from '@/event-bus'
import { EmployeeLevelInfo, ImportClearancesDashboardApi } from '@/openapi'
import { onBeforeMount, ref } from 'vue'

const api = new ImportClearancesDashboardApi(undefined, '')

const items = ref<EmployeeLevelInfo[]>([])
const loading = ref(false)

onBeforeMount(async () => {
    await getInfo()
})

const getInfo = async () => {
    loading.value = true
    try {
        const response = await api.getEmployeesByLevel()
        items.value = response.data
    } catch (e: unknown) {
        emitErrorWithFallback(
            e,
            'Something went wrong while retrieving the information'
        )
    }

    loading.value = false
}
</script>

<style scoped lang="scss">
.v-card {
    padding-bottom: 35px;
    background-color: #eeeeee;
}
.card-halve {
    display: flex;
    flex-direction: column;

    &:first-of-type {
        background-color: #f9a825;
        position: absolute;
        left: 0;
        top: 0;
        padding: 15px 15px 10px 15px;
        line-height: 1;
        border: 0;
        border-bottom-right-radius: inherit;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        text-align: center;
    }

    &__text {
        font-weight: 600;
        font-size: 1.25em;
    }

    &__value {
        font-weight: 600;
        font-size: 1.75em;
    }
}
.card-halve__list{
    background-color: #eeeeee;
}
.card-halve__list ::v-deep .v-list-item {
    min-height: 0;

    &__content {
        padding: 4px 0;
    }
}
</style>
