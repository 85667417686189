<template>
  <v-expansion-panel>
    <div class="v-expansion-panel-header">
      <slot name="header"></slot>
      <v-btn
        icon
        class="v-expansion-panel-header__icon"
        @click="toggleExpansionPanel(props.panelPosition)"
      >
        <v-icon v-if="!panelIsOpen">mdi-chevron-down</v-icon>
        <v-icon v-else>mdi-chevron-up</v-icon>
      </v-btn>
    </div>
    <v-expansion-panel-content>
      <slot name="content"></slot>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script setup lang="ts">
import { computed } from "vue";

interface SelectableHeaderExpansionPanelProps {
  expanded: number[];
  panelPosition: number;
}

const props = defineProps<SelectableHeaderExpansionPanelProps>();
const emits = defineEmits<{
  (e: "toggleExpansionPanel", position: number): void;
}>();

const toggleExpansionPanel = (panel: number) => {
  emits("toggleExpansionPanel", panel);
};

const panelIsOpen = computed(() => {
  return props.expanded.indexOf(props.panelPosition) !== -1;
});
</script>
