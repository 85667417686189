import { ToolbarItem } from '@/models/ToolbarItem';
import {
  CustomerViewModel,
  DataApi,
  CustomsMilestoneHandlingViewModel,
  CustomsMilestoneHandlingApi,
  MilestoneViewModel,
} from '@/openapi';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { ValidationResult } from 'vee-validate/dist/types/types';
import { Component, Mixins, Vue, Watch } from 'vue-property-decorator';

const dataApi = new DataApi(undefined, '');
const customsMilestoneHandlingApi = new CustomsMilestoneHandlingApi(
  undefined,
  ''
);

@Component({})
export default class CustomsMilestoneHandling extends Vue {
  headers = [
    { text: 'Actions', value: 'actions', sortable: false, width: '6em' },
    { text: 'Milestone name', align: 'start', value: 'milestoneName' },
    { text: 'Export code 1', value: 'exportCode1' },
    { text: 'Export code 2', value: 'exportCode2' },
    { text: 'Export code 1 for export', value: 'exportCode1ForExport' },
    { text: 'Export code 2 for export', value: 'exportCode2ForExport' },
    { text: 'Comment 1', value: 'comment1' },
    { text: 'Comment 2', value: 'comment2' },
    { text: 'Comment 1 for export', value: 'comment1ForExport' },
    { text: 'Comment 2 for export', value: 'comment2ForExport' },
    { text: 'Export milestone comment', value: 'exportMilestoneComment' },
    { text: 'Wait for milestone', value: 'waitForMilestone' },
    { text: 'Wait x seconds for milestone', value: 'waitXSecondsForMilestone' },
    { text: 'Actions', value: 'actions', sortable: false, width: '6em' },
  ];

  isLoading = false;
  footerOptions: any = {
    showFirstLastPage: true,
    itemsPerPageOptions: [25, 50, 100, -1],
    disablePagination: false,
  };
  options: any = {
    page: 1,
    itemsPerPage: 100,
    sortBy: [],
    sortDesc: [],
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
  };
  customerId: number = 0;
  items: CustomsMilestoneHandlingViewModel[] = [];
  totalItems: Number = 0;
  timeoutDelay: any = null;
  disableNewItemButton: Boolean = false;
  customers: CustomerViewModel[] = [];
  customerMilestones: MilestoneViewModel[] = [];

  created() {
    var toolbarButtons: ToolbarItem[] = [
      {
        callback: () => this.refresh(),
        icon: 'mdi-refresh',
        tooltipText: 'Refresh overview',
      },
    ];
    this.$emit(
      'PageInfoReceived',
      'Customs milestone handling',
      toolbarButtons
    );

    dataApi.getCustomers(true).then((result) => {
      this.customers = result.data;
      this.customerId = this.customers[0].id != null ? this.customers[0].id : 0;
    });
  }

  @Watch('customerId')
  onCustomerIdChanged(newVal: any, oldVal: any) {
    clearTimeout(this.timeoutDelay);
    this.timeoutDelay = setTimeout(() => {
      this.disableNewItemButton = false;
      this.loadItems(
        this.options.page,
        this.options.itemsPerPage,
        this.getSort(this.options.sortDesc),
        this.getSortField(this.options.sortBy)
      );
    }, 250);

    dataApi.getCustomerMilestones(this.customerId).then((result) => {
      this.customerMilestones = result.data;
    });
  }

  @Watch('options')
  onOptionsChanged(newVal: any, oldVal: any) {
    clearTimeout(this.timeoutDelay);
    this.timeoutDelay = setTimeout(() => {
      this.disableNewItemButton = false;
      this.loadItems(
        newVal.page,
        newVal.itemsPerPage,
        this.getSort(newVal.sortDesc),
        this.getSortField(newVal.sortBy)
      );
    }, 250);
  }

  loadItems(
    page: number,
    itemsPerPage: number,
    orderByField: string,
    orderBy: string
  ) {
    this.isLoading = true;
    this.footerOptions.disablePagination = true;
    customsMilestoneHandlingApi
      .getCustomsMilestoneHandling(
        itemsPerPage,
        page,
        orderBy,
        orderByField,
        this.customerId
      )
      .then((result) => {
        this.items = result.data.items ?? [];
        this.totalItems = result.data.totalItems as number;
      })
      .finally(() => {
        this.isLoading = false;
        this.footerOptions.disablePagination = false;
      });
  }

  async saveItem(item: CustomsMilestoneHandlingViewModel) {
    if (this.isLoading) {
      return;
      }

    var validationObserver = (<Vue[]>(
      this.$refs['observer-' + item.id]
    ))[0] as InstanceType<typeof ValidationObserver>;
    const promises: Promise<ValidationResult>[] = [];
    for (const key in this.$refs) {
      const split = key.split('-');
      if (!split.length || key === 'observer-' + item.id) {
        continue;
      }

      item.waitXSecondsForMilestone = !item.waitXSecondsForMilestone && item.waitForMilestone != 0 ? null : item.waitXSecondsForMilestone;

      const propName = split[0];
      const id = parseInt(split[1]);
      if (id != item.id) {
        continue;
      }

      const validationProvider = (<Vue[]>this.$refs[key])[0] as InstanceType<
        typeof ValidationProvider
      >;
      promises.push(
        validationProvider.validate(
          item[propName as keyof CustomsMilestoneHandlingViewModel]
        )
      );
    }
    validationObserver.validate().then(async (isValid) => {
      const validationResults = await Promise.all(promises);
      let errorsArray: { id: string; errors: string[] }[] = [];
      Object.keys(validationObserver.errors).map((key) => {
        if (validationObserver.errors[key].length > 0) {
          errorsArray.push({ id: key, errors: validationObserver.errors[key] });
        }
      });
      for (var validationResult of validationResults) {
        if (!validationResult.valid) {
          var errorObject = errorsArray.find(
            (o) => o.errors.indexOf(validationResult.errors[0]) !== -1
          );
          if (errorObject) {
            var errorElementId = errorObject?.id;
            document
              .getElementById(errorElementId)
              ?.scrollIntoView({ inline: 'start' });
          }

          return;
        }
      }

      this.isLoading = true;
      const isNewItem = this.isNewItem(item);

      customsMilestoneHandlingApi
        .saveCustomsMilestoneHandling(item)
        .then((result) => {
          if (!result?.data) {
            return;
          }

          this.mapItem(item, result.data);
          if (isNewItem) {
            this.disableNewItemButton = false;
          }
        })
        .catch((error) => {
          this.$emit('errorOccured', [error.message]);
        })
        .finally(() => {
          this.isLoading = false;
        });
    });
  }

  isNewItem(item: CustomsMilestoneHandlingViewModel): Boolean {
    return item?.id === 0;
  }
  getSortField(sortFields: string[]) {
    return sortFields[0] ?? '';
  }

  getSort(sortDesc: Boolean[]) {
    let isDesc = sortDesc[0] ?? null;
    if (!(isDesc === false || isDesc === true)) {
      return '';
    }
    return isDesc ? 'DESC' : 'ASC';
  }

  mapItem(oldItem: any, newItem: any) {
    for (const key in oldItem) {
      oldItem[key] = newItem[key];
    }
  }

  refresh() {
    this.loadItems(
      this.options.page,
      this.options.itemsPerPage,
      this.getSort(this.options.sortDesc),
      this.getSortField(this.options.sortBy)
    );
    this.disableNewItemButton = false;
  }

  getCustomerName(customerId: number) {
    var customer = this.customers.find((c) => c.id === customerId);
    if (!customer) {
      return '';
    }

    return customer.shortName;
  }

  getMilestoneName(waitForMilestone: number) {
    var milestone = this.customerMilestones.find(
      (c) => c.milestoneId === waitForMilestone
    );
    if (!milestone) {
      return '';
    }

    return milestone.milestoneName;
  }
}
