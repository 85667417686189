<template>
    <Fragment>
        <div class="duties-taxes-detail__tabs">
            <v-tabs v-model="currentTab" background-color="grey lighten-2">
                <v-tab>All</v-tab>
                <v-tab>Not in Sovex</v-tab>
                <v-tab>Not in Bundeskasse</v-tab>
                <v-tab>Difference in amount</v-tab>
            </v-tabs>

            <v-tabs-items v-model="currentTab">
                <v-tab-item>
                    <DutiesAndTaxesAllDiscrepancies
                        v-if="currentTab === 0"
                        :id="id"
                    ></DutiesAndTaxesAllDiscrepancies>
                </v-tab-item>
                <v-tab-item>
                    <DutiesAndTaxesNotInSovexDiscrepancies
                        v-if="currentTab === 1"
                        :id="id"
                    ></DutiesAndTaxesNotInSovexDiscrepancies>
                </v-tab-item>
                <v-tab-item>
                    <DutiesAndTaxesNotInBundeskasseDiscrepancies
                        v-if="currentTab === 2"
                        :id="id"
                    ></DutiesAndTaxesNotInBundeskasseDiscrepancies>
                </v-tab-item>
                <v-tab-item>
                    <DutiesAndTaxesDifferenceInAmountDiscrepancies
                        v-if="currentTab === 3"
                        :id="id"
                    ></DutiesAndTaxesDifferenceInAmountDiscrepancies>
                </v-tab-item>
            </v-tabs-items>
        </div>
    </Fragment>
</template>

<script setup lang="ts">
import DutiesAndTaxesAllDiscrepancies from '@/components/duties-and-taxes/DutiesAndTaxesAllDiscrepancies.vue';
import DutiesAndTaxesNotInSovexDiscrepancies from '@/components/duties-and-taxes/DutiesAndTaxesNotInSovexDiscrepancies.vue';
import DutiesAndTaxesNotInBundeskasseDiscrepancies from '@/components/duties-and-taxes/DutiesAndTaxesNotInBundeskasseDiscrepancies.vue';
import DutiesAndTaxesDifferenceInAmountDiscrepancies from '@/components/duties-and-taxes/DutiesAndTaxesDifferenceInAmountDiscrepancies.vue';
import { ref } from 'vue';

interface DutiesAndTaxesDetailProps {
    id: number;
}

defineProps<DutiesAndTaxesDetailProps>();

const currentTab = ref(0);
</script>
