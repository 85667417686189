import Vue from "vue";
import Vuex from "vuex";

import DataModule from "./dataModule";

Vue.use(Vuex);

export interface RootState {
  loggedIn: boolean;
  userRights: string[];
  isBpoUser: boolean;
}
export default new Vuex.Store<RootState>({
  modules: {
    data: DataModule,
  },
  state: {
    loggedIn: false,
    userRights: [],
    isBpoUser: false,
  },
  mutations: {
    setLoggedIn(state) {
      state.loggedIn = true;
    },
    setLoggedOut(state) {
      state.loggedIn = false;
    },
    setUserRights(state, userRights) {
      state.userRights = userRights;
    },
    setIsBpoUser(state, value) {
      state.isBpoUser = value;
    },
  },
  actions: {},
  getters: {
    hasUserRight: (state) => (userRight: string) => {
      return state.userRights.includes(userRight);
    },
    hasAnyUserRight: (state) => (userRights: string[]) => {
      return userRights.some((ur) => state.userRights.includes(ur));
    },
    isBpoUser: (state) => {
      return state.isBpoUser;
    },
  },
});
