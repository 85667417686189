import { Component, Prop, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import CustomsFlowStepsMixin from '@/mixins/CustomsFlowStepsMixin';
import * as CustomsExportController from '@/api/CustomsExportController';
import { ExportFlowStatusHistoryItem } from '@/api/viewmodels/ExportFlowStatusHistoryItem';

@Component({})
export default class ShipmentStatusFlow extends mixins(CustomsFlowStepsMixin) {
    @Prop({ default: null })
    shipmentId!: number | null;

    @Prop()
    activeStatus!: number | null;

    historyItems: ExportFlowStatusHistoryItem[]  = [];
    fullfilledStep = 14;

    @Watch('shipmentId', { immediate: true })
    onShipmentIdChanged(newVal: any, oldVal: any) {
        if(!newVal) {
            return;
        }

        this.getHistoryItems(newVal);
    }

    @Watch('activeStatus')
    onActiveStatusChanged(newVal: any, oldVal: any) {
        if(!newVal || this.shipmentId == null) {
            return;
        }

        this.getHistoryItems(this.shipmentId);
    }

    get groupedHistoryItems() {
        const map = new Map();
        this.historyItems.forEach((item) => {
            const key = (item.status);
            const values = map.get(key);
            if (!values) {
                map.set(key, [item]);
            } else {
                values.push(item);
            }
        });
        return map;
    }

    async getHistoryItems(shipmentId: number) {
        try {
            var response = await CustomsExportController.getExportFlowStatusHistory(shipmentId);
            this.historyItems = response.data;
        } catch {
            this.displayError('Something went wrong while retrieving the history of the shipment statuses.');
        }
    }
    displayError(errorMessage: string) {
        this.$emit('errorOccured', errorMessage);
    }
}
