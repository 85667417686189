import { Component, Prop, Vue } from 'vue-property-decorator';
import { emitError } from '@/event-bus';
import { ShipmentStatusApi } from '@/openapi';

import SetReturnReasonsDialog from '../dialogs/SetReturnReasonsDialog.vue.html';
import { ReturnShipmentReasonsForm } from '../dialogs/SetReturnReasonsDialog';

const shipmentStatusApi = new ShipmentStatusApi(undefined, '');

interface Successful {
    success: boolean;
}

@Component({
    components: {
        SetReturnReasonsDialog
    }
})
export default class ReturnShipmentButton extends Vue {
    @Prop()
    shipmentId!: number;

    @Prop()
    exportFlowStatus!: number;

    loading = false;
    showDialog = false;

    async onConfirmedReturnReasons(value: ReturnShipmentReasonsForm) {
        this.loading = true;
        let success = false;
        try {
            await shipmentStatusApi.setReturnStatus({
                shipmentId: this.shipmentId,
                returnReasonFirstId: value.shipmentReasonFirst,
                returnReasonSecondId: value.shipmentReasonSecond
            });

            success = true;
            this.showDialog = false;
        } catch {
            emitError('Something went wrong while returning the shipment.');
        }

        this.loading = false;
        this.$emit('shipmentStatusChanged', { success } as Successful);
    }

    get cssClasses() {
        return this.$vnode?.data?.staticClass ?? '';
    }
}
