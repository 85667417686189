import { ToolbarItem } from "@/models/ToolbarItem";
import Vue from "vue";
import Component from "vue-class-component";

@Component({})
export default class ToolbarMixin extends Vue {
    addRefreshToolbarOption(callback: Function) {
        this.$emit('PageInfoReceived', [
            {
              callback: callback,
              icon: 'mdi-refresh',
              tooltipText: 'Refresh overview',
            },
          ] as ToolbarItem[])
    }
}