<template>
    <Fragment>
        <v-data-table
            v-model="selected"
            :headers="headers"
            :items="items"
            :options.sync="options"
            :footer-props="footerOptions"
            :server-items-length="totalAmountOfItems"
            item-key="id"
            fixed-header
            fixed-footer
            show-select
            class="elevation-1"
            :loading="loading"
            :height="tableHeightCalc"
            @click:row="openDetails"
        >
            <template #top>
                <div class="top">
                    <v-text-field
                        v-model="search"
                        label="Search"
                        placeholder="Search"
                        class="top__search"
                        clearable
                        append-icon="mdi-magnify"
                        @keyup.enter="getEmployees"
                        @click:clear="getEmployees"
                    >
                    </v-text-field>
                    <div>
                        <v-btn
                            class="mr-4"
                            color="primary"
                            :disabled="!selected.length"
                            @click="openBulkAssignment"
                            >Bulk right assignment</v-btn
                        >
                        <v-btn color="success" :to="{ name: 'EmployeeDetail' }"
                            ><v-icon left>mdi-plus-thick</v-icon>Add</v-btn
                        >
                    </div>
                </div>
            </template>
        </v-data-table>
        <router-view></router-view>
    </Fragment>
</template>
<script setup lang="ts">
import { useDataTableDefaults } from '@/composables/dataTable';
import { EmployeeOverviewItemDto, EmployeesApi } from '@/openapi';
import { ref, watch } from 'vue';
import { DataOptions, DataTableHeader } from 'vuetify';
import { useSorting } from '@/composables/sort';
import { emitError } from '@/event-bus';
import router from '@/router';
import { determineTableHeightCalc } from '@/helpers/dataTableHelper';

const employeesApi = new EmployeesApi(undefined, '');

const { footerOptions, items, loading, options, totalAmountOfItems } =
    useDataTableDefaults<EmployeeOverviewItemDto>();
const { sortBy, sortDesc } = useSorting(options);

const headers = ref<DataTableHeader[]>([
    {
        text: 'First name',
        value: 'firstName',
        sortable: true,
    },
    {
        text: 'Last name',
        value: 'lastName',
        sortable: true,
    },
    {
        text: 'Company phone',
        value: 'companyPhone',
        sortable: true,
    },
    {
        text: 'Company mobile',
        value: 'companyMobile',
        sortable: true,
    },
    {
        text: 'Emergency phone',
        value: 'emergencyPhone',
        sortable: true,
    },
    {
        text: 'Email',
        value: 'email',
        sortable: true,
    },
]);

const selected = ref<EmployeeOverviewItemDto[]>([]);
const search = ref('');
const tableHeightCalc = ref<string>(determineTableHeightCalc(297));

const getEmployees = async (page = options.value.page) => {
    loading.value = true;
    try {
        const response = await employeesApi.getEmployees(
            sortBy.value,
            sortDesc.value,
            page,
            options.value.itemsPerPage,
            search.value
        );

        items.value = response.data.items ?? [];
        totalAmountOfItems.value = response.data.totalAmountOfItems ?? 0;
    } catch {
        emitError('Something went wrong while retrieving the employees');
    }
    loading.value = false;
};

const openDetails = (item: EmployeeOverviewItemDto) => {
    router.push({
        name: 'EmployeeDetail',
        params: {
            employeeId: item.id!.toString(),
        },
    });
};

const openBulkAssignment = () => {
    router.push({
        name: 'EmployeeBulkAssignment',
        params: {
            employeeIds: JSON.stringify(selected.value.map((c) => c.id)),
        },
    });
};

watch(
    () => options.value,
    (newValue: DataOptions, oldValue: DataOptions) => {
        getEmployees();
    }
);
</script>

<style scoped lang="scss">
.top {
    display: flex;
    padding: 16px;
    justify-content: space-between;

    &__search {
        flex: 0 1 350px;
    }
}
</style>
