

import axios, { AxiosPromise, AxiosTransformer } from 'axios';

let ISO_8601 = /^(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2}:\d{2})$/;
let transformDate = function (data: any) {
    if(data === undefined || data === null)
    {
        return null;
    }

    Object.keys(data).forEach(function (k) {
        if(data[k] instanceof Object) {
            transformDate(data[k]);
        } else {
            if (ISO_8601.test(data[k])) {
                data[k] = new Date(Date.parse(data[k]));
            }
        }
    });
    return data;
};
let transformResponse = (axios.defaults.transformResponse as AxiosTransformer[]).concat(transformDate)

import { WarehouseViewModel } from './viewmodels/WarehouseViewModel'


        
    // GetWarehouses
    export function getWarehouses () : AxiosPromise<WarehouseViewModel[]> {
        return axios({
            url: `api/WarehouseLocation/getWarehouses/`, 
            method: "get",
            data: null,
            transformResponse: transformResponse,
            responseType: "json"
        });
    };    
    // SaveWarehouse
    export function saveWarehouse (model: WarehouseViewModel) : AxiosPromise<WarehouseViewModel> {
        return axios({
            url: `api/WarehouseLocation/saveWarehouse/`, 
            method: "post",
            data: model,
            transformResponse: transformResponse,
            responseType: "json"
        });
    };    
    // DeleteWarehouse
    export function deleteWarehouse (id: number) : AxiosPromise<boolean> {
        return axios({
            url: `api/WarehouseLocation/deleteWarehouse/${id}`, 
            method: "post",
            data: null,
            transformResponse: transformResponse,
            responseType: "json"
        });
    };

