import { Vue, Component, Prop } from "vue-property-decorator";

@Component({})
export default class FilterParcels extends Vue {
    @Prop({default: () => []})
    value!: string[]

    singleParcelText = '';
    multiParcelsText = '';
    showMultiParcelsFilterDialog = false;
    currentMultiParcels = '';

    get multiParcels() {
        return this.multiParcelsText?.trim()
            ? this.multiParcelsText
                  .trim()
                  .split('\n')
                  .filter((p: string) => p)
            : [];
    }

    onShowMultiParcelsFilterDialog() {
        this.currentMultiParcels = this.multiParcelsText;
        this.showMultiParcelsFilterDialog = true;
    }

    onCancelMultiParcelsFilterDialog() {
        this.multiParcelsText = this.currentMultiParcels;
        this.currentMultiParcels = '';
        this.showMultiParcelsFilterDialog = false;
    }

    onConfirmMultiParcelsFilterDialog() {
        this.showMultiParcelsFilterDialog = false;
        this.filterConfirmed();
    }

    filterConfirmed() {
        let parcels = this.multiParcels?.length
                ? this.multiParcels
                : this.singleParcelText
                    ? [this.singleParcelText]
                    : [];
        this.$emit('input', parcels);
    }
}