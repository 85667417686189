<template>
  <ValidationObserver ref="observer" v-slot="{ invalid }">
    <DefaultDialog v-model="modelOpen">
      <template #header
        ><span class="title-holder">{{
          internalModelValue.parcelNumber
        }}</span></template
      >
      <template #content>
        <v-form slim>
          <div class="form-fields">
            <ValidationProvider
              v-slot="{ errors }"
              name="Parcelnumber"
              rules="required"
            >
              <v-text-field
                v-model="internalModelValue.parcelNumber"
                label="Parcelnumber"
                :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
            <v-text-field
              v-model="internalModelValue.weight"
              label="Weight (kg)"
              type="number"
              step="0.01"
            ></v-text-field>
            <v-text-field
              v-model="internalModelValue.dimensions"
              label="Dimensions"
            ></v-text-field>
            <v-text-field
              v-model="internalModelValue.cost"
              label="Cost"
              type="number"
              step="0.01"
            ></v-text-field>
            <v-select
              v-model="internalModelValue.currency"
              :items="currencies"
              label="Currency"
              :loading="currenciesLoading"
              item-text="value"
              item-value="value"
            ></v-select>
          </div>
        </v-form>
      </template>
      <template #footer
        ><v-spacer></v-spacer>
        <v-btn :disabled="internalSaving" @click="closeDialog">Cancel</v-btn>
        <v-btn
          :disabled="invalid || internalSaving"
          color="success"
          @click="$emit('save', internalModelValue)"
          >Save</v-btn
        ></template
      >
    </DefaultDialog>
  </ValidationObserver>
</template>

<script setup lang="ts">
import { ShipmentDetailParcelViewModel } from "@/openapi";
import { CurrencyItemModel } from "@/api/viewmodels/CurrencyItemModel";
import DefaultDialog from "@/components/dialogs/DefaultDialog.vue.html";
import * as CurrencyController from "@/api/CurrencyController";
import { ref, watch, onMounted } from "vue";
import { emitError } from "@/event-bus";

interface CustomsParcelProps {
  modelOpen: boolean;
  modelValue: ShipmentDetailParcelViewModel;
  modelSaving: boolean;
}

const props = defineProps<CustomsParcelProps>();
const emit = defineEmits([
  "cancel",
  "save",
  "update:model-open",
  "update:model-saving",
]);

const internalModelValue = ref(props.modelValue);
const internalSaving = ref(props.modelSaving);
const currencies = ref<CurrencyItemModel[]>([]);
const currenciesLoading = ref(false);

const getCurrencies = async () => {
  try {
    currenciesLoading.value = true;
    var response = await CurrencyController.getCurrencies();
    currencies.value = response.data;
    currenciesLoading.value = false;
  } catch {
    emitError(
      "Something went wrong while retrieving the currencies necessary when changes the currency of the shipment",
    );
  }
};

const closeDialog = () => {
  emit("update:model-open", false);
};

watch(
  () => props.modelSaving,
  (newVal) => {
    if (internalSaving.value != newVal) {
      internalSaving.value = newVal;
    }
  },
);

onMounted(() => {
  getCurrencies();
});
</script>
<style lang="scss" scoped>
.title-holder {
  display: inline-block;
  min-height: 32px;
}
</style>
