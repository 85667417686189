<template>
    <v-row>
        <v-col cols="12">
            <v-row class="mb-5">
                <v-col class="mb-5" cols="12">
                    <ValidationProvider
                        name="Customer/scheme"
                        rules="required"
                        v-slot="{ errors }"
                    >
                        <v-text-field
                            v-model="value.customerScheme"
                            hide-details
                            dense
                            label="Customer/scheme"
                            outlined
                            :error-messages="errors"
                        ></v-text-field>
                    </ValidationProvider>
                </v-col>
                <v-col cols="6">
                    <h3 class="mb-5">Repeat rate</h3>
                    <div class="d-flex align-center scheme-rates">
                        <span class="mr-2">Avis</span>
                        <ValidationProvider
                            name="Avis repeat rate"
                            rules="required|min_value:0"
                            v-slot="{ errors }"
                        >
                            <v-text-field
                                v-model.number="value.avisRepeatRate"
                                class="mr-2"
                                dense
                                type="number"
                                min="0"
                                step="1"
                                outlined
                                :error-messages="errors"
                            ></v-text-field>
                        </ValidationProvider>
                        <span>Hours</span>
                    </div>
                    <div class="d-flex align-center scheme-rates">
                        <span class="mr-2">Documents</span>
                        <ValidationProvider
                            name="Documents repeat rate"
                            rules="required|min_value:0"
                            v-slot="{ errors }"
                        >
                            <v-text-field
                                v-model.number="value.documentsRepeatRate"
                                class="mr-2"
                                :error-messages="errors"
                                dense
                                type="number"
                                min="0"
                                step="1"
                                outlined
                            ></v-text-field>
                        </ValidationProvider>
                        <span>Hours</span>
                    </div>
                    <div class="d-flex align-center scheme-rates">
                        <span class="mr-2">Invoice</span>
                        <ValidationProvider
                            name="Invoice repeat rate"
                            rules="required|min_value:0"
                            v-slot="{ errors }"
                        >
                            <v-text-field
                                v-model.number="value.invoiceRepeatRate"
                                class="mr-2"
                                :error-messages="errors"
                                dense
                                type="number"
                                min="0"
                                step="1"
                                outlined
                            ></v-text-field>
                        </ValidationProvider>
                        <span>Hours</span>
                    </div>
                </v-col>

                <v-col cols="6">
                    <h3 class="mb-5">Maximum number of attempts</h3>
                    <div class="d-flex align-center scheme-rates">
                        <span class="mr-2">Avis</span>
                        <ValidationProvider
                            name="Avis max attempts"
                            rules="required|min_value:0"
                            v-slot="{ errors }"
                        >
                            <v-text-field
                                v-model.number="value.avisMaxAttempts"
                                class="mr-2"
                                :error-messages="errors"
                                dense
                                type="number"
                                min="0"
                                step="1"
                                outlined
                            ></v-text-field>
                        </ValidationProvider>
                        <span>Attempts</span>
                    </div>
                    <div class="d-flex align-center scheme-rates">
                        <span class="mr-2">Documents</span>
                        <ValidationProvider
                            name="Documents max attempts"
                            rules="required|min_value:0"
                            v-slot="{ errors }"
                        >
                            <v-text-field
                                v-model.number="value.documentsMaxAttempts"
                                class="mr-2"
                                :error-messages="errors"
                                dense
                                type="number"
                                min="0"
                                step="1"
                                outlined
                            ></v-text-field>
                        </ValidationProvider>
                        <span>Attempts</span>
                    </div>
                    <div class="d-flex align-center scheme-rates">
                        <span class="mr-2">Invoice</span>
                        <ValidationProvider
                            name="Invoice max attempts"
                            rules="required|min_value:0"
                            v-slot="{ errors }"
                        >
                            <v-text-field
                                v-model.number="value.invoiceMaxAttempts"
                                class="mr-2"
                                :error-messages="errors"
                                dense
                                type="number"
                                min="0"
                                step="1"
                                outlined
                            ></v-text-field>
                        </ValidationProvider>
                        <span>Attempts</span>
                    </div>
                </v-col>
            </v-row>

            <v-divider class="mb-8"></v-divider>
            <v-row class="mb-5">
                <v-col cols="6">
                    <ValidationProvider
                        name="Phone number"
                        rules="required|min_value:0"
                        v-slot="{ errors }"
                    >
                        <v-text-field
                            v-model="value.phoneNumber"
                            :error-messages="errors"
                            dense
                            label="Telephone number"
                            outlined
                        ></v-text-field>
                    </ValidationProvider>
                </v-col>
                <v-col cols="6">
                    <v-checkbox
                        v-model="value.enableEmailSMS"
                        class="mt-0"
                        hide-details
                        label="Enable email and SMS"
                    ></v-checkbox>
                    <v-checkbox
                        v-model="value.sendNotificationToEmailAddress"
                        hide-details
                        label="Send notification to invoice address"
                    ></v-checkbox>
                </v-col>
            </v-row>
            <v-divider class="mb-8"></v-divider>
            <v-row>
                <v-col cols="6">
                    <ValidationProvider
                        name="Customs clearance mode code"
                        rules="required|digits:4"
                        v-slot="{ errors }"
                    >
                        <v-text-field
                            v-model.number="value.registration"
                            dense
                            label="Customs clearance mode code"
                            outlined
                            :error-messages="errors"
                        ></v-text-field>
                    </ValidationProvider>
                </v-col>
                <v-col cols="6">
                    <ValidationProvider
                        name="Customs Status"
                        :rules="{ required: true, regex: /^[A-Z]{2}$/ }"
                        v-slot="{ errors }"
                    >
                        <v-text-field
                            v-model="value.customsStatus"
                            :error-messages="errors"
                            dense
                            label="Customs Status"
                            outlined
                        ></v-text-field>
                    </ValidationProvider>
                </v-col>
                <v-col cols="6">
                    <ValidationProvider
                        name="Statistischer Status"
                        rules="required"
                        v-slot="{ errors }"
                    >
                        <v-select
                            v-model="value.statisticalStatus"
                            :items="statisticalStatusses"
                            :error-messages="errors"
                            dense
                            label="Statistical Status"
                            outlined
                        ></v-select>
                    </ValidationProvider>
                </v-col>
                <v-col cols="6">
                    <ValidationProvider
                        name="Clearance Office"
                        rules="digits:4"
                        v-slot="{ errors }"
                    >
                        <v-text-field
                            v-model.number="value.clearanceOffice"
                            :error-messages="errors"
                            dense
                            label="Clearance Office"
                            outlined
                        ></v-text-field>
                    </ValidationProvider>
                </v-col>
                <v-col cols="6">
                    <ValidationProvider
                        name="Freight Cost Percentage"
                        rules="required|min_value:0"
                        v-slot="{ errors }"
                    >
                        <v-text-field
                            v-model.number="value.freightCostPercentage"
                            :error-messages="errors"
                            dense
                            label="Freight Cost Percentage"
                            type="number"
                            min="0"
                            step="1"
                            outlined
                        ></v-text-field>
                    </ValidationProvider>
                </v-col>
                <v-col cols="6">
                    <ValidationProvider
                        name="Destination EU country"
                        v-slot="{ errors }"
                    >
                        <v-select
                            v-model="value.destinationCountry"
                            :items="mappedEuDestinationCountries"
                            :error-messages="errors"
                            dense
                            label="Destination EU country"
                            outlined
                        ></v-select>
                    </ValidationProvider>
                </v-col>
                <v-col cols="6">
                    <ValidationProvider
                        name="Delivery Condition"
                        v-slot="{ errors }"
                    >
                        <v-select
                            v-model="value.deliveryCondition"
                            :items="mappedIncoterms"
                            :error-messages="errors"
                            dense
                            label="Delivery Condition"
                            outlined
                        ></v-select>
                    </ValidationProvider>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script setup lang="ts">
import {
    DestinationCountryViewModel,
    IncotermViewModel,
    Scheme,
} from '@/openapi';
import store from '@/store';
import { DataAction } from '@/store/dataModule';
import { computed, ref } from 'vue';

interface CustomsSchemeFormProps {
    value: Scheme;
}

withDefaults(defineProps<CustomsSchemeFormProps>(), {
    value: () => ({}),
});

const statisticalStatusses = ref<string[]>(['01', '02', '04']);

const mappedEuDestinationCountries = computed(() => {
    return store.getters.euDestinationCountries.map(
        (c: DestinationCountryViewModel) => ({
            value: c.id,
            text: `${c.name} - ${c.description}`,
        })
    );
});

const mappedIncoterms = computed(() => {
    return store.getters.incoterms.map((c: IncotermViewModel) => ({
        value: c.name,
        text: c.name,
    }));
});

store.dispatch(DataAction.FetchEuDestinationCountries);
store.dispatch(DataAction.FetchIncoterms);
</script>
