<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" class="pa-0">
                <form>
                    <v-data-table
                        :headers="headers"
                        :items="schemes"
                        :expanded.sync="expanded"
                        :server-items-length="totalAmountOfItems"
                        :options.sync="options"
                        :footer-props="footerOptions"
                        item-key="id"
                        height="calc(100vh - 187px)"
                        fixed-header
                        fixed-footer
                        show-expand
                        class="elevation-1"
                        :loading="isLoading"
                        :search="search"
                        @update:options="onUpdateOptions"
                        @item-expanded="openScheme"
                    >
                        <template v-slot:top>
                            <v-toolbar flat>
                                <v-text-field
                                    v-model="search"
                                    append-icon="mdi-magnify"
                                    label="Search"
                                    single-line
                                    hide-details
                                ></v-text-field>
                                <v-spacer></v-spacer>

                                <v-dialog
                                    v-model="showDialogNewScheme"
                                    max-width="800px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            color="primary"
                                            dark
                                            class="mb-2"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            New scheme
                                        </v-btn>
                                    </template>
                                    <v-card color="grey darken-3">
                                        <ValidationObserver
                                            ref="createObserver"
                                            v-slot="{ invalid }"
                                            slim
                                        >
                                            <v-card-title class="mb-5"
                                                >New scheme</v-card-title
                                            >
                                            <v-card-text>
                                                <CustomsSchemeForm
                                                    v-model="newScheme"
                                                ></CustomsSchemeForm>
                                            </v-card-text>
                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                    color="blue darken-1"
                                                    text
                                                    @click="
                                                        (showDialogNewScheme = false),
                                                            (newScheme = {})
                                                    "
                                                >
                                                    Cancel
                                                </v-btn>
                                                <v-btn
                                                    color="success"
                                                    :disabled="invalid"
                                                    @click="createNewScheme"
                                                >
                                                    Create
                                                </v-btn>
                                            </v-card-actions>
                                        </ValidationObserver>
                                    </v-card>
                                </v-dialog>
                                <v-dialog
                                    v-model="showDialogDeleteScheme"
                                    max-width="500px"
                                >
                                    <v-card>
                                        <v-card-title class="text-h5"
                                            >Are you sure you want to delete
                                            this item?</v-card-title
                                        >
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                color="blue darken-1"
                                                text
                                                @click="
                                                    showDialogDeleteScheme = false
                                                "
                                                >Cancel</v-btn
                                            >
                                            <v-btn
                                                color="error"
                                                @click="deleteScheme"
                                                >Delete</v-btn
                                            >
                                            <v-spacer></v-spacer>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </v-toolbar>
                        </template>

                        <template v-slot:expanded-item="{ headers, item }">
                            <td :colspan="headers.length">
                                <v-flex class="pa-3 pb-5">
                                    <v-container>
                                        <v-row>
                                            <v-col>
                                                <v-card color="grey darken-3">
                                                    <ValidationObserver
                                                        v-slot="{ invalid }"
                                                        ref="observer"
                                                        slim
                                                    >
                                                        <v-toolbar
                                                            flat
                                                            class="grey darken-3 mb-5"
                                                            color="white"
                                                            dark
                                                        >
                                                            <v-toolbar-title
                                                                class="grow"
                                                                >Edit scheme:
                                                                {{
                                                                    selectedScheme?.customerScheme
                                                                }}</v-toolbar-title
                                                            >
                                                            <v-spacer></v-spacer>
                                                            <v-btn
                                                                class="mr-4"
                                                                color="error"
                                                                @click="
                                                                    showDialogDeleteScheme = true
                                                                "
                                                            >
                                                                Delete
                                                            </v-btn>
                                                            <v-btn
                                                                class="mr-4"
                                                                color="success"
                                                                @click="
                                                                    updateScheme
                                                                "
                                                                :disabled="
                                                                    invalid
                                                                "
                                                            >
                                                                Save
                                                            </v-btn>
                                                        </v-toolbar>

                                                        <v-card-text>
                                                            <CustomsSchemeForm
                                                                v-model="
                                                                    selectedScheme
                                                                "
                                                            ></CustomsSchemeForm>
                                                        </v-card-text>
                                                    </ValidationObserver>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-flex>
                            </td>
                        </template>
                    </v-data-table>
                    <v-snackbar :timeout="5000" v-model="showSuccessMessage">
                        {{ successMessage }}
                        <template v-slot:action="{ attrs }">
                            <v-btn
                                color="success"
                                v-bind="attrs"
                                @click="showSuccessMessage = false"
                            >
                                Close
                            </v-btn>
                        </template>
                    </v-snackbar>
                </form>
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
import { ToolbarItem } from '../models/ToolbarItem';
import { SchemeApi, Scheme, SchemeOverview } from '@/openapi';
import { emitError } from '@/event-bus';
import { DataOptions, DataTableHeader } from 'vuetify';
import { FooterOptions } from '@/types/types';
import CustomsSchemeForm from '@/components/forms/CustomsSchemeForm.vue';
import { ValidationObserver } from 'vee-validate';
import { computed, ref } from 'vue';

const api = new SchemeApi(undefined, '');

const emits = defineEmits(['PageInfoReceived']);

const createObserver = ref<InstanceType<typeof ValidationObserver> | null>(
    null
);
const expanded = ref<Scheme[]>([]);
const isLoading = ref(false);
const showSuccessMessage = ref(false);
const successMessage = ref('');
const headers = ref<DataTableHeader[]>([
    {
        text: 'Customer/scheme',
        align: 'start',
        value: 'customerScheme',
    },
    { text: 'Telephone number', value: 'telephoneNumber' },
    { text: 'Customs Status', value: 'customsStatus' },
    { text: 'Invoice Max Attempts', value: 'invoiceMaxAttempts' },
]);
const search = ref('');
const showDialogNewScheme = ref(false);
const showDialogDeleteScheme = ref(false);

const schemes = ref<SchemeOverview[]>([]);
const selectedScheme = ref<Scheme | null>({});
const newScheme = ref<Scheme | null>({});

const options = ref<DataOptions>({
    page: 1,
    itemsPerPage: 25,
    sortBy: [],
    sortDesc: [],
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
});

const footerOptions = ref<FooterOptions>({
    showFirstLastPage: true,
    itemsPerPageOptions: [5, 10, 25, 50, 100],
    disablePagination: false,
});

const totalAmountOfItems = ref(0);

const sortBy = computed(() => {
    return options.value.sortBy?.[0] ?? '';
});

const sortDesc = computed(() => {
    return options.value.sortDesc?.[0] ?? false;
});

const getSchemes = async (page?: number) => {
    page = page ?? options.value.page;
    isLoading.value = true;
    try {
        const response = await api.getSchemes(
            search.value,
            sortBy.value,
            sortDesc.value,
            page,
            options.value.itemsPerPage
        );
        schemes.value = response.data.items ?? [];
        totalAmountOfItems.value = response.data.totalAmountOfItems ?? 0;
    } catch (e) {
        emitError('Something went wrong while retrieving the schemes');
    }
    isLoading.value = false;
};

const getSelectedScheme = async (schemeId: number) => {
    isLoading.value = true;
    try {
        const response = await api.getScheme(schemeId);
        selectedScheme.value = response.data.scheme ?? {};
    } catch (e) {
        emitError('Something went wrong while retrieving the selected scheme');
    }
    isLoading.value = false;
};

const openScheme = ({
    item,
    value,
}: {
    item: SchemeOverview;
    value: boolean;
}) => {
    getSelectedScheme(item.id!);
    if (value) {
        selectedScheme.value = item;
    } else {
        selectedScheme.value = null;
    }
};

const updateScheme = async () => {
    isLoading.value = true;
    try {
        await saveScheme(selectedScheme.value!);
        showSuccessMessage.value = true;
        successMessage.value = 'All changes have been saved for this customer';
        refresh();
    } catch (e) {
        emitError('Something went wrong while saving');
    }
    isLoading.value = false;
};

const deleteScheme = async () => {
    isLoading.value = true;
    try {
        await api.deleteScheme(selectedScheme.value?.id);
        showDialogDeleteScheme.value = false;
        selectedScheme.value = null;
        expanded.value = [];
        showSuccessMessage.value = true;
        successMessage.value = 'The scheme has been deleted';
        await getSchemes();
    } catch (e) {
        emitError('Something went wrong while deleting the scheme');
    }
    isLoading.value = false;
};

const createNewScheme = async () => {
    isLoading.value = true;
    try {
        await saveScheme(newScheme.value!);
        showDialogNewScheme.value = false;
        newScheme.value = {};
        createObserver.value!.reset();
        successMessage.value = 'The scheme has been created';
        showSuccessMessage.value = true;
        refresh();
    } catch (e) {
        emitError('Something went wrong creating new scheme');
    }
    isLoading.value = false;
};

const saveScheme = (scheme: Scheme) => {
    return api.saveScheme({
        id: scheme?.id,
        customerScheme: scheme?.customerScheme!,
        registration: scheme?.registration,
        customsStatus: scheme?.customsStatus!,
        statisticalStatus: scheme?.statisticalStatus!,
        clearanceOffice: scheme?.clearanceOffice,
        freightCostPercentage: scheme?.freightCostPercentage,
        destinationCountry: scheme?.destinationCountry!,
        deliveryCondition: scheme?.deliveryCondition!,
        phoneNumber: scheme?.phoneNumber!,
        enableEmailSMS: scheme?.enableEmailSMS,
        sendNotificationToEmailAddress: scheme?.sendNotificationToEmailAddress,
        avisRepeatRate: scheme?.avisRepeatRate,
        avisMaxAttempts: scheme?.avisMaxAttempts,
        documentsRepeatRate: scheme?.documentsRepeatRate,
        documentsMaxAttempts: scheme?.documentsMaxAttempts,
        invoiceRepeatRate: scheme?.invoiceRepeatRate,
        invoiceMaxAttempts: scheme?.invoiceMaxAttempts,
    });
};

const onUpdateOptions = () => {
    if (schemes.value.length) {
        getSchemes();
    }
};

const refresh = () => {
    getSchemes();
};

getSchemes();

var toolbarButtons: ToolbarItem[] = [
    {
        callback: () => refresh(),
        icon: 'mdi-refresh',
        tooltipText: 'Refresh overview',
    },
];
emits('PageInfoReceived', 'Schemes', toolbarButtons);
</script>
