<template>
    <Fragment>
        <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="totalAmountOfItems"
            :options.sync="options"
            :footer-props="footerOptions"
            item-key="shipmentId"
            fixed-footer
            fixed-header
            dense
            height="calc(100vh - 187px)"
            class="elevation-1"
            :loading="loading"
            @update:options="onUpdateOptions"
        >
            <template #top>
                <v-toolbar>
                    <div class="table-top">
                        <span class="table-top__next-number"
                            >Next free debtor number: {{ nextNumber }}</span
                        >
                        <v-btn
                            class="table-top__create"
                            color="success"
                            @click="showDialog = true"
                            >Create</v-btn
                        >
                    </div>
                </v-toolbar>
            </template>
            <template v-slot:item="{ item }">
                <tr :class="{ 'grey lighten-3': item.isActive }">
                    <td>{{ item.startRange }}</td>
                    <td>{{ item.endRange }}</td>
                    <td>
                        <v-icon :color="item.isActive ? 'success' : 'error'">
                            {{
                                item.isActive ? 'mdi-check' : 'mdi-close'
                            }}</v-icon
                        >
                    </td>
                    <td>
                        <v-icon :color="item.isCompleted ? 'success' : 'error'">
                            {{
                                item.isCompleted ? 'mdi-check' : 'mdi-close'
                            }}</v-icon
                        >
                    </td>
                </tr>
            </template>
        </v-data-table>
        <ValidationObserver v-slot="{ invalid }" ref="observer" slim>
            <DefaultDialog v-model="showDialog">
                <template #header>Create range of debtor numbers</template>
                <template #content>
                    <v-form>
                        <v-container>
                            <v-row>
                                <v-col cols="6">
                                    <ValidationProvider
                                        name="Start range"
                                        vid="startRange"
                                        rules="required|min_value:1"
                                        v-slot="{ errors }"
                                    >
                                        <v-text-field
                                            v-model.number="formData.startRange"
                                            type="number"
                                            label="Start range"
                                            :min="1"
                                            :error-messages="errors"
                                        ></v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="6">
                                    <ValidationProvider
                                        name="End range"
                                        rules="required|min_value:1|isGreaterThan:@startRange"
                                        v-slot="{ errors }"
                                    >
                                        <v-text-field
                                            v-model.number="formData.endRange"
                                            type="number"
                                            label="End range"
                                            :min="1"
                                            :error-messages="errors"
                                        ></v-text-field>
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </template>
                <template #footer>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="showDialog = false"
                        :loading="isCreating"
                        text
                        small
                        >Close</v-btn
                    >
                    <v-btn
                        color="primary"
                        @click="createDebtorNumber"
                        :disabled="invalid"
                        :loading="isCreating"
                        text
                        small
                        >Confirm</v-btn
                    >
                </template>
            </DefaultDialog>
        </ValidationObserver>
    </Fragment>
</template>

<script setup lang="ts">
import DefaultDialog from '@/components/dialogs/DefaultDialog.vue.html';
import { emitErrorWithFallback, emitSuccess } from '@/event-bus';
import { DebtorNumberApi } from '@/openapi';
import { FooterOptions } from '@/types/types';
import { ValidationObserver } from 'vee-validate';
import { ref, watch } from 'vue';
import { DataOptions, DataTableHeader } from 'vuetify';

interface DebtorNumberForm {
    startRange: number | null;
    endRange: number | null;
}

const api = new DebtorNumberApi(undefined, '');

const observer = ref<InstanceType<typeof ValidationObserver> | null>(null);

const headers = ref<DataTableHeader[]>([
    {
        text: 'Start range',
        value: 'startRange',
        sortable: false,
    },
    {
        text: 'End range',
        value: 'endRange',
        sortable: false,
    },
    {
        text: 'Is active',
        value: 'isActive',
        sortable: false,
    },
    {
        text: 'Is completed',
        value: 'isCompleted',
        sortable: false,
    },
]);

const options = ref<DataOptions>({
    page: 1,
    itemsPerPage: 25,
    sortBy: [],
    sortDesc: [],
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
});

const footerOptions = ref<FooterOptions>({
    showFirstLastPage: true,
    itemsPerPageOptions: [5, 25, 50, 100],
    disablePagination: false,
});

const defaultFormValues: DebtorNumberForm = {
    startRange: null,
    endRange: null,
};

const formData = ref<DebtorNumberForm>({ ...defaultFormValues });

const items = ref<any[]>([]);
const totalAmountOfItems = ref<number | undefined>(0);
const loading = ref(false);

const showDialog = ref(false);
const isCreating = ref(false);

const nextNumber = ref<number | null>();

const getDebtorNumbers = async (page?: number) => {
    page ??= options.value.page;
    loading.value = true;
    try {
        const response = await api.getDebtorNumbers(
            page,
            options.value.itemsPerPage
        );
        items.value = response.data.items ?? [];
        totalAmountOfItems.value = response.data.totalAmountOfItems ?? 0;
        nextNumber.value = response.data.nextNumber;
    } catch (error) {
        emitErrorWithFallback(
            error,
            'Something went wrong while retrieving the debtor numbers'
        );
    }
    loading.value = false;
};

let timeout = 0;
const onUpdateOptions = () => {
    clearTimeout(timeout);
    setTimeout(() => {
        getDebtorNumbers();
    }, 300);
};

const createDebtorNumber = async () => {
    loading.value = true;
    try {
        await api.createDebtorNumber({
            startRange: formData.value.startRange!,
            endRange: formData.value.endRange!,
        });
        emitSuccess('Successfully created the given range of debtor numbers');
        showDialog.value = false;
        getDebtorNumbers();
    } catch (error) {
        emitErrorWithFallback(
            error,
            'Someting went wrong while creating the range of debtor numbers'
        );
    }
    loading.value = false;
};

watch(
    () => showDialog.value,
    (newValue: boolean, oldVal: boolean) => {
        if (newValue == false) {
            formData.value = { ...defaultFormValues };
            observer.value!.reset();
        }
    }
);
</script>

<style scoped lang="scss">
.table-top {
    display: flex;
    justify-content: center;
    width: 100%;

    &__next-number {
        font-size: 18px;
        font-weight: 600;
        margin: auto;
    }

    &__create {
        margin-left: auto;
        margin-right: 15px;
    }
}
</style>
