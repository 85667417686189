<template>
    <div class="dual-list-box">
        <v-data-table
            :items="availableItems"
            :options="options"
            :loading="loading"
            :height="height"
            hide-default-footer
            hide-default-header
            dense
            class="selection-table"
        >
            <template #top>
                <div class="dual-list-box__title mx-4">
                    <slot name="top-left"></slot>
                </div>
            </template>
            <template #item="{ item }">
                <tr @click="selectItem(item)">
                    <td>
                        <slot name="item" :item="item">
                            {{ item[props.itemText] }}
                        </slot>
                    </td>
                </tr>
            </template>
        </v-data-table>
        <v-divider vertical></v-divider>
        <v-data-table
            :items="value"
            :options="options"
            :loading="loading"
            :height="height"
            hide-default-footer
            hide-default-header
            dense
            class="assigned-table"
        >
            <template #top>
                <div class="dual-list-box__title mx-4">
                    <slot name="top-right"></slot>
                </div>
            </template>
            <template #item="{ item }">
                <tr @click="removeItem(item)">
                    <td>
                        <slot name="item" :item="item">
                            {{ item[props.itemText] }}
                        </slot>
                    </td>
                </tr>
            </template>
        </v-data-table>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useDataTableDefaults } from '@/composables/dataTable';

interface DualListBoxProps {
    value: any[];
    items: any[];
    itemKey: string;
    itemText: string;
    loading: boolean;
    height?: number;
}

const props = withDefaults(defineProps<DualListBoxProps>(), {
    items: () => [],
    height: 500,
});

const { options } = useDataTableDefaults();
options.value.itemsPerPage = -1;

const availableItems = computed(() => {
    return props.items.filter(
        (item) =>
            !props.value.some(
                (selectedItem) =>
                    selectedItem[props.itemKey] === item[props.itemKey]
            )
    );
});

const selectItem = (item: any) => {
    props.value.push(item);
};

const removeItem = (item: any) => {
    const index = props.value.findIndex(
        (c) => c[props.itemKey] === item[props.itemKey]
    );
    props.value.splice(index, 1);
};
</script>

<style scoped lang="scss">
.dual-list-box {
    display: flex;
    gap: 15px;

    &__title {
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 10px;
        display: inline-block;
    }
}

.selection-table {
    width: 100%;
}

.assigned-table {
    width: 100%;
}

tr {
    cursor: pointer;
}
</style>
