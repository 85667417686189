import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

export interface UploadFileForm {
    files: File[] | null;
}

@Component({})
export default class UploadFileDialog extends Vue {
    @Prop({ default: false })
    value!: boolean;

    @Prop({ default: false })
    isLoading!: boolean;

    @Prop({ default: 'Upload file(s)' })
    title!: string;

    @Prop({ default: 'Confirm' })
    confirmButtonText!: string;

    @Prop({ default: 'Close' })
    closeButtonText!: string;

    @Prop({ default: false })
    multiple!: boolean;

    @Prop()
    rules!: object;

    valid = true;
    form: UploadFileForm = this.getDefaultFormValues();

    @Watch('value')
    onValueChanged(newValue: boolean, oldVal: boolean) {
        if (newValue == false) {
            (this.$refs.observer as Vue & { reset: () => boolean }).reset();
            this.form = this.getDefaultFormValues();
        }
    }

    getDefaultFormValues(): UploadFileForm {
        return {
            files: null,
        };
    }

    displayError(errorMessage: string) {
        this.$emit('errorOccured', errorMessage);
    }

    onConfirm() {
        this.$emit('confirm', {
            files: this.form.files,
        } as UploadFileForm);
    }

    onFileUpload(eventData: File | File[]) {
        this.form.files = eventData
            ? Array.isArray(eventData)
                ? eventData
                : [eventData]
            : [];
    }

    get hasSelectedFiles(): boolean {
        return !!this.form.files?.length;
    }

    get fileValidationRules() {
        return this.rules
            ? { required: true, ...this.rules }
            : { required: true };
    }
}
