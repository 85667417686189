<template>
    <ValidationProvider :name="name" :rules="rules" v-slot="{ errors }">
        <v-text-field
            v-model="localValue"
            :label="label"
            :error-messages="errors"
            :outlined="outlined"
            :dense="dense"
            :hide-details="hideDetails"
            :disabled="disabled"
        >
        </v-text-field>
    </ValidationProvider>
</template>
<script setup lang="ts">
import { computed } from 'vue';

interface TextFieldProps {
    value: string;
    name: string;
    label: string;
    rules?: any;
    outlined?: boolean;
    dense?: boolean;
    hideDetails?: boolean;
    disabled?: boolean;
}

const props = withDefaults(defineProps<TextFieldProps>(), {
    dense: true,
    outlined: true,
    hideDetails: false,
    disabled: false,
});
const emits = defineEmits(['input']);

const localValue = computed({
    get() {
        return props.value;
    },
    set(value: string) {
        emits('input', value);
    },
});
</script>
