import { emitError } from "@/event-bus";
import {
  ConsolidationMoveableShipment,
  CustomsFlightHandlingApi,
} from "@/openapi";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import DefaultDialog from "../dialogs/DefaultDialog.vue.html";
import CreateTransitDeclarationDialog from "../dialogs/CreateTransitDeclarationDialog.vue.html";
import { CreateTransitDeclarationForm } from "../dialogs/CreateTransitDeclarationDialog";

interface Filters {
  hawb?: string;
}

const api = new CustomsFlightHandlingApi(undefined, "");

interface Enrichment {
  isSelectable: boolean;
}

type EnrichedConsolidationMoveableShipment = ConsolidationMoveableShipment &
  Enrichment;

@Component({
  components: {
    CreateTransitDeclarationDialog,
    DefaultDialog,
  },
})
export default class CustomsConsolidationMoveableShipments extends Vue {
  @Prop()
  consolidationId!: number;

  loading = false;
  items: EnrichedConsolidationMoveableShipment[] = [];
  currentItem: EnrichedConsolidationMoveableShipment | null = null;
  selectedItems: EnrichedConsolidationMoveableShipment[] = [];

  showDialog = false;
  dialogLoading = false;

  showNotMovedDialog = false;
  notMovedDialogLoading = false;
  notMovedReason = "";

  filters: Filters = {
    hawb: "",
  };

  headers = [
    {
      text: "HAWB/Parcel ID",
      value: "hawb",
      sortable: false,
    },
    {
      text: "Anzahl ",
      value: "pieces",
      sortable: false,
    },
    {
      text: "Gewicht ",
      value: "weight",
      sortable: false,
    },
    {
      text: "Show in HAWB overview",
      value: "showInHawbOverview",
      sortable: false,
    },
    {
      text: "Dakosy reference",
      value: "dakosyReference",
      sortable: false,
    },
    {
      text: "MRN",
      value: "mrn",
      sortable: false,
    },
    {
      text: "Not moved reason",
      value: "notMovedReason",
      sortable: false,
    },
  ];

  @Watch("showNotMovedDialog")
  showNotMovedDialogWatcher(newValue: boolean, oldValue: boolean) {
    if (!newValue) {
      this.notMovedReason = "";
    }
  }

  created() {
    this.getShipments();
  }

  async getShipments() {
    this.loading = true;
    try {
      const response = await api.getConsolidationMoveableShipments(
        this.consolidationId,
        this.filters.hawb ?? "",
      );
      this.items = response.data
        .shipments!.map((item) => ({
          ...item,
          isSelectable:
            !item.notMovedReason && !item.dakosyReference && !item.mrn,
        }))
        .sort((a, b) => (a.isSelectable ? -1 : 1));
      this.selectedItems = [];
    } catch {
      emitError(
        "Something went wrong while retrieving the moveable shipments.",
      );
    }
    this.loading = false;
  }

  timeout = 0;
  async onFilterSelected() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(async () => {
      await this.getShipments();
    }, 250);
  }

  async moveShipments(eventData: CreateTransitDeclarationForm) {
    this.dialogLoading = true;

    try {
      await api.moveShipmentsFromAirport({
        amount: eventData.amount,
        country: eventData.country,
        ids: this.selectedItems.map((c) => c.id!),
        registration: eventData.registration,
        signs: eventData.signs.map((c) => c.value).filter((c) => !!c),
        routeId: eventData.routeId ?? undefined,
        templateId: eventData.templateId ?? undefined,
        transportAcrossEuBorder: eventData.transportAcrossEuBorder,
      });
      this.showDialog = false;
      this.selectedItems = [];
      await this.getShipments();
    } catch {
      emitError("Something went wrong while moving the shipment(s).");
    }

    this.dialogLoading = false;
  }

  async setNotMovedReason() {
    try {
      this.notMovedDialogLoading = true;
      await api.setNotMovedReason({
        shipmentIds: this.selectedItems.map((c) => c.id!),
        reason: this.notMovedReason,
      });

      this.showNotMovedDialog = false;
      this.getShipments();
    } catch {
      emitError(
        "Something went wrong while setting the reason for not moving the shipment",
      );
    }

    this.notMovedDialogLoading = false;
  }

  async toggleShowInHawbOverview() {
    try {
      this.loading = true;
      await api.toggleShowInHawbOverview(
        this.selectedItems.map((item) => item.id!),
      );
      for (const selectedItem of this.selectedItems) {
        selectedItem.showInHawbOverview = !selectedItem.showInHawbOverview;
      }
      this.selectedItems = [];
    } catch {
      emitError(
        'Something went wrong while changing the "Show in HAWB overview" status.',
      );
    }

    this.loading = false;
  }

  get hasSelectedItems() {
    return !!this.selectedItems?.length;
  }
}
