import { render, staticRenderFns } from "./MrnDiscrepanciesOverview.vue?vue&type=template&id=56f8c918&scoped=true"
import script from "./MrnDiscrepanciesOverview.vue?vue&type=script&setup=true&lang=ts"
export * from "./MrnDiscrepanciesOverview.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./MrnDiscrepanciesOverview.vue?vue&type=style&index=0&id=56f8c918&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56f8c918",
  null
  
)

export default component.exports