import { FooterOptions } from '@/types/types';
import { ref } from 'vue';
import { DataOptions } from 'vuetify';

export const useDataTableDefaults = <T>() => {
    const options = ref<DataOptions>({
        page: 1,
        itemsPerPage: 25,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
    });

    const footerOptions = ref<FooterOptions>({
        showFirstLastPage: true,
        itemsPerPageOptions: [5, 25, 50, 100],
        disablePagination: false,
    });

    const loading = ref(false);
    const items = ref<T[]>([]);
    const totalAmountOfItems = ref(0);

    return {
        options,
        footerOptions,
        loading,
        items,
        totalAmountOfItems,
    };
};