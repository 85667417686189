import { Component, Vue } from 'vue-property-decorator';
import ShipmentsOnStock from '@/components/dpd-consolidation/ShipmentsOnStock.vue.html';
import ShipmentConsolidations from '@/components/dpd-consolidation/ShipmentConsolidations.vue.html';
import ShipmentConsolidationsHistory from '@/components/dpd-consolidation/ShipmentConsolidationsHistory.vue.html';

@Component({components: {
    ShipmentsOnStock,
    ShipmentConsolidations,
    ShipmentConsolidationsHistory
}})
export default class DpdConsolidation extends Vue {
    isLoading = false;
    tab = null;
}
