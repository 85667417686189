import { Component, Vue } from 'vue-property-decorator';
@Component({})
export default class EditableField extends Vue {

    isEditable: Boolean = false;

    makeEditable() {
        this.isEditable = true;
    }

    removeEditable() {
        setTimeout(() => {
            this.isEditable = false;
        }, 125)
    }
}