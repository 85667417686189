import { Component, Mixins, Vue, Watch } from 'vue-property-decorator';
import { ToolbarItem } from '../models/ToolbarItem';
import { ValidationResult } from 'vee-validate/dist/types/types';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { CustomsTransportTypeApi, TransportTypeViewModel } from '../openapi';
import CrudPageMixin from '../mixins/CrudPageMixin';


@Component({
})
export default class TransportTypes extends Mixins<CrudPageMixin<TransportTypeViewModel>>(CrudPageMixin) {
    api: CustomsTransportTypeApi = new CustomsTransportTypeApi(undefined, '')

    headers = [
        { text: ' ', value: 'actions', sortable: false, width: '6em' },
        { text: 'Transport types', value: 'description', width:'30%' },
        { text: 'Code', value: 'code' },
    ];

    isLoading = false;

    created() {
        var toolbarButtons: ToolbarItem[] = [
            {
                callback: () => this.refresh(),
                icon: 'mdi-refresh',
                tooltipText: 'Refresh overview'
            }
        ];
        this.$emit('PageInfoReceived', 'Customs Transport types', toolbarButtons)

        this.loadItems();
    }


    loadItems() {
        this.isLoading = true

        this.api.getTransportTypes()
            .then(result => {
                this.items = result.data;
            }).finally(() => {
                this.isLoading = false;
            });
    }

    async saveItem(item: TransportTypeViewModel) {
        if (this.isLoading) {
            return;
        }

        var validationObserver = this.getValidationObserver(item);
        const promises: Promise<ValidationResult>[] = [];
        for (const key in this.$refs) {
            const split = key.split('-');
            if (!split.length || (key === 'observer-' + item.id)) {
                continue;
            }

            const propName = split[0];
            const id = parseInt(split[1]);
            if (id != item.id) {
                continue;
            }

            const validationProvider = (<Vue[]>this.$refs[key])[0] as InstanceType<typeof ValidationProvider>;
            promises.push(validationProvider.validate(item[propName as keyof TransportTypeViewModel]));
        }
        validationObserver.validate().then(async isValid => {
            const validationResults = await Promise.all(promises);
            let errorsArray: { id: string, errors: string[] }[] = [];
            Object.keys(validationObserver.errors).map(key => {
                if (validationObserver.errors[key].length > 0) {
                    errorsArray.push({ id: key, errors: validationObserver.errors[key] });
                }
            })
            for (var validationResult of validationResults) {
                if (!validationResult.valid) {
                    var errorObject = errorsArray.find(o => o.errors.indexOf(validationResult.errors[0]) !== -1);
                    if (errorObject) {
                        var errorElementId = errorObject?.id;
                        document.getElementById(errorElementId)?.scrollIntoView({ inline: 'start' });
                    }

                    return;
                }
            }

            this.isLoading = true;
            const isNewItem = this.isNewItem(item);
            const postData = JSON.parse(JSON.stringify(item));

            this.api.saveTransportType(postData as TransportTypeViewModel)
                .then(result => {
                    if (!result?.data) {
                        return;
                    }

                    this.mapItem(item, result.data);
                    if (isNewItem) {
                        this.disableNewItemButton = false;
                    }
                })
                .catch(error => {
                    this.$emit('errorOccured', [error.message]);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        });
    }

    deleteItem(item: any) {
        this.isLoading = true;
        const index = this.items.findIndex(x => x.id === item.id);
        if (this.isNewItem(item)) {
            this.items.splice(index, 1);
            this.isLoading = false;
            this.disableNewItemButton = false;
            return;
        }

        this.api.deleteTransportType(item.id).then(c => {
            this.items.splice(index, 1);
        })
            .catch(() => { })
            .finally(() => {
                this.loadItems();
            });
    }

    refresh() {
        this.loadItems();
        this.disableNewItemButton = false;
    }
}