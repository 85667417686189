<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="pa-6">
        <v-card class="pa-6">
          <v-toolbar class="elevation-0" dense>
            <v-toolbar-title>Invoice footer texts</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon
              @click="openDialog(-1)">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-toolbar>
          <v-simple-table class="invoice-footer-table">
            <template v-slot:default>
              <thead>
                <tr>
                  <th>Description</th>
                  <th>Text</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(model, index) in models" :key="model.id">
                  <td>{{ model.description }}</td>
                  <td v-html="model.text">
                  </td>
                  <td>
                      <v-btn icon @click="openDialog(index)">
                          <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                      <v-btn icon @click="openDeleteDialog(index)">
                          <v-icon>mdi-delete</v-icon>
                      </v-btn>
                  </td>
                </tr>
              </tbody>
              <InvoiceFooterTextDialog 
                        v-if="showDialog" 
                        :value="currentModel"
                        :loading="loading"
                        @cancel="showDialog = false"
                        @save="saveText"/>
              <InvoiceFooterTextDeleteDialog 
                        v-if="showDeleteDialog" 
                        :value="currentModel"
                        :deleting="deleting"
                        @cancel="showDeleteDialog = false"
                        @delete="deleteText"/>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import { ref, onBeforeMount } from "vue";
import { CustomsInvoiceFooterTextApi, CustomsInvoiceFooterTextViewModel } from '@/openapi';
import InvoiceFooterTextDialog from '@/components/dialogs/InvoiceFooterTextDialog.vue'
import InvoiceFooterTextDeleteDialog from '@/components/dialogs/InvoiceFooterTextDeleteDialog.vue'
import { emitErrorWithFallback } from '@/event-bus';

const api = new CustomsInvoiceFooterTextApi(undefined, '');
const loading = ref(false);
const deleting = ref(false);
const showDialog = ref(false);
const showDeleteDialog = ref(false);
const models = ref([] as CustomsInvoiceFooterTextViewModel[])
const currentModel = ref(null)

const openDialog = (index: number) => {
  if (index > -1) {
    currentModel.value = models.value[index]
  } else {
    currentModel.value = {} as CustomsInvoiceFooterTextViewModel
  }
  console.log('openDialog', index, currentModel.value)
  showDialog.value = true
}


const openDeleteDialog = (index: number) => {
  currentModel.value = models.value[index]
  showDeleteDialog.value = true
}

const deleteText = (id: number) => {
  deleting.value = true
  try {
    api.deleteCustomsInvoiceFooterTexts(id)
      .then(() => {
        deleting.value = false
        showDeleteDialog.value = false
        init()
      })
  } catch (e: unknown) {
    emitErrorWithFallback('Something went wrong');
    init()
  }
}

const saveText = (model: CustomsInvoiceFooterTextViewModel) => {
  loading.value = true
  try {
    api.saveCustomsInvoiceFooterTexts(model)
      .then((response) => {
        loading.value = false
        showDialog.value = false
        init()
      })
  } catch (e: unknown) {
    emitErrorWithFallback('Something went wrong');
    init()
  }
}

const init = () => {
  api.getCustomsInvoiceFooterTexts()
    .then((response) => {
      models.value = response.data
    });
  }


onBeforeMount(async () => {
    init();
});
</script>
<style lang="scss" scoped>
.invoice-footer-table {
  tr {
    td:nth-child(3) {
      text-align: end;
      padding-right: 0;
    }
  }
  tfoot{
    td{
      text-align: end;
      padding-right: 0;
    }
  }
}

</style>
