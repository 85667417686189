<template>
  <ValidationObserver ref="observer" v-slot="{ invalid }">
    <DefaultDialog
      v-model="internalModelOpen"
      :loading="dataLoading"
      max-width="1200px"
    >
      <template #header
        ><span class="title-holder">Move shipment</span></template
      >
      <template #content>
        <v-form>
          <v-container
            v-if="modelId > 0 && !dataLoading && items.length == 0"
            fluid
          >
            <v-row><v-col md="12"> No shipment found </v-col> </v-row>
          </v-container>
          <v-container v-else fluid>
            <v-row
              ><v-col md="12">
                <div class="form-fields">
                  <v-select
                    v-model="selectedCustomsHandlingFinalWAB"
                    label="Choose an LTS-number"
                    :items="customsHandlingFinalWAB"
                    item-text="value"
                    item-value="key"
                  ></v-select>
                </div>
              </v-col>
            </v-row>
            <v-row
              ><v-col> <strong>Move</strong></v-col
              ><v-col>
                <strong>HAWB number</strong>
              </v-col>
              <v-col>
                <strong>Anzahl</strong>
              </v-col>
              <v-col>
                <strong>Status</strong>
              </v-col>
              <v-col>
                <strong>Gewicht</strong>
              </v-col>
              <v-col>
                <strong>Versender</strong>
              </v-col>
              <v-col>
                <strong>Empfänger</strong>
              </v-col>
            </v-row>
            <v-row v-for="item in items" class="align-start">
              <v-col>
                <div class="form-fields">
                  <v-checkbox
                    v-model="item.selected"
                    class="mt-0 pt-0"
                    hide-details
                  ></v-checkbox>
                </div>
              </v-col>
              <v-col>
                {{ item.hawb }}
              </v-col>
              <v-col>
                {{ item.pieces }}
              </v-col>
              <v-col>
                {{ item.exportFlowStatus }}
              </v-col>
              <v-col> {{ item.grossWeight }}<br /> </v-col>
              <v-col>
                {{ item.shipper.name }}<br />
                {{ item.shipper.address }}<br />
                {{ item.shipper.zipcode }}
                {{ item.shipper.city }}
                {{ item.shipper.country }}
              </v-col>
              <v-col>
                {{ item.consignee.name }}<br />
                {{ item.consignee.address }}<br />
                {{ item.consignee.zipcode }}
                {{ item.consignee.city }}
                {{ item.consignee.country }}
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </template>
      <template #footer
        ><v-spacer></v-spacer>
        <v-btn :disabled="dataLoading" @click="close">Close</v-btn>
        <v-btn :disabled="disabledMove" color="success" @click="move"
          >Move</v-btn
        >
      </template>
    </DefaultDialog>
  </ValidationObserver>
</template>

<script setup lang="ts">
import {
  CustomsShipmentItemViewModel,
  CustomsTransitApi,
  MoveDeclarationShipmentsRequest,
} from "@/openapi";
import DefaultDialog from "@/components/dialogs/DefaultDialog.vue.html";
import { computed, ref, watch } from "vue";
import { emitError, emitSuccess } from "@/event-bus";
import { KeyValueItem } from "@/openapi";

const customsTransitApi = new CustomsTransitApi(undefined, "");

interface CustomsParcelProps {
  modelOpen: boolean;
  modelId: number;
}

interface SelectableCustomsShipmentItemViewModel
  extends CustomsShipmentItemViewModel {
  selected: boolean;
}

const props = defineProps<CustomsParcelProps>();
const emit = defineEmits(["close", "moved", "update:ids"]);

const items = ref<SelectableCustomsShipmentItemViewModel[]>([]);
const customsHandlingFinalWAB = ref<KeyValueItem[]>([]);
const selectedCustomsHandlingFinalWAB = ref(0);
const dataLoading = ref(false);

const internalModelOpen = computed(() => {
  return props.modelId > 0;
});

const disabledMove = computed(() => {
  return (
    dataLoading.value ||
    selectedCustomsHandlingFinalWAB.value === 0 ||
    items.value.filter((item) => item.selected).length === 0
  );
});

const getDeclarationsShipments = async () => {
  dataLoading.value = true;
  try {
    const response = await customsTransitApi.getFinalWabShipments({
      page: 1,
      finalWabId: props.modelId,
      itemsPerPage: 99999,
      parcels: [],
    });
    const responseItems: SelectableCustomsShipmentItemViewModel[] = [];
    const responseData = response.data.shipments ?? [];
    for (const item of responseData) {
      const convertedItem = {
        ...item,
      } as SelectableCustomsShipmentItemViewModel;
      responseItems.push(convertedItem);
    }

    items.value = responseItems;
  } catch {
    emitError("Something went wrong while retrieving data");
  } finally {
    dataLoading.value = false;
  }
};

const getCustomsHandlingFinalWABs = async () => {
  try {
    const response = await customsTransitApi.getCustomsHandlingFinalWABs();
    customsHandlingFinalWAB.value = response.data;
  } catch {
    emitError("Something went wrong while retrieving data");
  }
};

const close = () => {
  emit("close");
};

const move = async () => {
  dataLoading.value = true;
  try {
    const selectedShipmentIds = items.value
      .filter((item) => item.selected)
      .map((item) => item.id);
    await customsTransitApi.moveDeclarationShipments({
      customsHandlingFinalWABId: selectedCustomsHandlingFinalWAB.value,
      customsHandlingHistoryIds: selectedShipmentIds,
    });
    selectedCustomsHandlingFinalWAB.value = 0;
    emit("moved");
    emitSuccess("Shipemnts moved.");
  } catch {
    emitError("Something went wrong while saving data");
  } finally {
    getDeclarationsShipments();
  }
};

watch(
  () => props.modelId,
  (newVal) => {
    if (newVal === 0) {
      items.value = [];
      customsHandlingFinalWAB.value = [];
    } else {
      getDeclarationsShipments();
      getCustomsHandlingFinalWABs();
    }
    selectedCustomsHandlingFinalWAB.value = 0;
  },
);
</script>
<style lang="scss" scoped>
.form-field .v-input__slot {
  align-items: flex-start;
}
</style>
