<template>
  <ValidationObserver ref="observer" v-slot="{ invalid }">
    <DefaultDialog v-model="internalModelOpen" max-width="1024px">
      <template #header><span class="title-holder">Variants</span></template>
      <template #content>
        <v-form>
          <div v-if="internalModelValue" class="variants-grid">
            <div class="variants-grid-field">
              <ValidationProvider v-slot="{ errors }" name="additionalCode">
                <v-text-field
                  v-model="internalModelValue.additionalCode"
                  label="Zusatzcode"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </div>
            <div class="variants-grid-field">
              <ValidationProvider
                :ref="'country+' + internalModelValue.id"
                name="Country"
                :vid="'country+' + internalModelValue.id"
              >
                <v-select
                  v-model="internalModelValue.country"
                  :items="enrichedCountries"
                  :return-object="false"
                  :multiple="false"
                  item-value="code"
                  item-text="code"
                  display-text="code"
                  label="Country"
                  :check-difference="false"
                >
                </v-select>
              </ValidationProvider>
            </div>
            <div class="variants-grid-field">
              <ValidationProvider v-slot="{ errors }" name="conditionOne">
                <v-text-field
                  v-model="internalModelValue.conditionOne"
                  label="Bedingungen 1"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </div>
            <div class="variants-grid-field">
              <ValidationProvider v-slot="{ errors }" name="conditionTwo">
                <v-text-field
                  v-model="internalModelValue.conditionTwo"
                  label="Bedingungen 2"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </div>
            <div class="variants-grid-field">
              <ValidationProvider v-slot="{ errors }" name="conditionThree">
                <v-text-field
                  v-model="internalModelValue.conditionThree"
                  label="Bedingungen 3"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </div>
            <div class="variants-grid-field">
              <ValidationProvider v-slot="{ errors }" name="conditionFour">
                <v-text-field
                  v-model="internalModelValue.conditionFour"
                  label="Bedingungen 4"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </div>
            <div class="variants-grid-field">
              <ValidationProvider v-slot="{ errors }" name="conditionFive">
                <v-text-field
                  v-model="internalModelValue.conditionFive"
                  label="Bedingungen 5"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </div>
            <div class="variants-grid-field">
              <ValidationProvider v-slot="{ errors }" name="conditionSix">
                <v-text-field
                  v-model="internalModelValue.conditionSix"
                  label="Bedingungen 6"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </div>
          </div>
          <h2>Variants</h2>
          <div
            v-if="
              !internalModelValue?.variants ||
              internalModelValue?.variants?.length == 0
            "
          >
            No variants found
          </div>
          <div>
            <div
              v-for="(variant, index) in internalModelValue?.variants"
              :key="index"
              class="variants-grid"
            >
              <div class="variants-grid-field">
                <ValidationProvider v-slot="{ errors }" name="additionalCode">
                  <v-text-field
                    v-model="variant.additionalCode"
                    label="Zusatzcode"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </div>
              <div class="variants-grid-field">
                <ValidationProvider
                  :ref="'country+' + index"
                  name="Country"
                  :vid="'country+' + index"
                  rules="required"
                >
                  <v-select
                    v-model="variant.country"
                    :items="enrichedCountries"
                    :return-object="false"
                    :multiple="false"
                    item-value="code"
                    item-text="code"
                    display-text="code"
                    label="Country"
                    :check-difference="false"
                  >
                  </v-select>
                </ValidationProvider>
              </div>
              <div class="variants-grid-field">
                <ValidationProvider v-slot="{ errors }" name="conditionOne">
                  <v-text-field
                    v-model="variant.conditionOne"
                    label="Bedingungen 1"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </div>
              <div class="variants-grid-field">
                <ValidationProvider v-slot="{ errors }" name="conditionTwo">
                  <v-text-field
                    v-model="variant.conditionTwo"
                    label="Bedingungen 2"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </div>
              <div class="variants-grid-field">
                <ValidationProvider v-slot="{ errors }" name="conditionThree">
                  <v-text-field
                    v-model="variant.conditionThree"
                    label="Bedingungen 3"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </div>
              <div class="variants-grid-field">
                <ValidationProvider v-slot="{ errors }" name="conditionFour">
                  <v-text-field
                    v-model="variant.conditionFour"
                    label="Bedingungen 4"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </div>
              <div class="variants-grid-field">
                <ValidationProvider v-slot="{ errors }" name="conditionFive">
                  <v-text-field
                    v-model="variant.conditionFive"
                    label="Bedingungen 5"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </div>
              <div class="variants-grid-field">
                <ValidationProvider v-slot="{ errors }" name="conditionSix">
                  <v-text-field
                    v-model="variant.conditionSix"
                    label="Bedingungen 6"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </div>
              <div class="variants-grid-delete">
                <v-tooltip top>
                  <template #activator="{ on, attrs }">
                    <v-icon v-bind="attrs" @click="removeRow(index)" v-on="on">
                      mdi-delete
                    </v-icon>
                  </template>
                  <span>Delete variant</span>
                </v-tooltip>
              </div>
            </div>
            <v-spacer></v-spacer>
          </div>
        </v-form>
      </template>
      <template #footer
        ><v-spacer></v-spacer>
        <v-btn class="btn-left" @click="addRow()">Add</v-btn>
        <v-btn :disabled="loading" @click="$emit('cancel')">Cancel</v-btn>
        <v-btn
          :disabled="invalid"
          color="success"
          :loading="loading"
          @click="$emit('save', internalModelValue)"
          >Save</v-btn
        ></template
      >
    </DefaultDialog>
  </ValidationObserver>
</template>

<script setup lang="ts">
import {
  CustomsArticleVariantsViewModel,
  CountryOfOriginViewModel,
} from "@/openapi";
import DefaultDialog from "@/components/dialogs/DefaultDialog.vue.html";
import { ref, watch, computed } from "vue";
import { useDataStore } from "@/stores/data-store";

interface ArticleVariantProps {
  modelOpen: boolean;
  loading: boolean;
  modelValue?: CustomsArticleVariantsViewModel;
}
const dataStore = useDataStore();
const props = defineProps<ArticleVariantProps>();
const emit = defineEmits([
  "cancel",
  "save",
  "update:model-open",
  "update:model-value",
]);

const internalModelOpen = ref(props.modelOpen);
const internalModelValue = ref(props.modelValue);

const enrichedCountries = computed(() => {
  return dataStore.originCountries
    .filter((item) => item.code != null)
    .map((item) => ({
      ...item,
      itemText: getItemText(item),
    }));
});

const getItemText = (item: CountryOfOriginViewModel) => {
  return `${item.code} | ${item.description}`;
};

watch(
  () => props.modelOpen,
  (newVal) => {
    if (internalModelOpen.value != newVal) {
      internalModelOpen.value = newVal;
    }
  },
);

watch(
  () => props.modelValue,
  (newVal) => {
    if (internalModelValue.value != newVal) {
      internalModelValue.value = newVal;
    }
  },
);

const addRow = () => {
  internalModelValue.value?.variants?.push({ id: 0 });
};

const removeRow = (index: number) => {
  internalModelValue.value?.variants?.splice(index, 1) ?? [];
};
</script>
<style lang="scss" scoped>
.title-holder {
  display: inline-block;
  min-height: 32px;
}
.variants-grid {
  display: flex;
  grid-template-columns: repeat(8, 1fr);
  gap: 12px;
}

.variants-grid-field {
  flex: 1 1 150px;
}
.variants-grid-delete {
  flex: 1 1 50px;
  padding-top: 16px;
}
.btn-left {
  position: absolute;
  left: 16px;
}
</style>
