import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { CustomsArticleMaintenanceApi } from '@/openapi';
import { emitError } from '@/event-bus';

const api = new CustomsArticleMaintenanceApi(undefined, '');

@Component({})
export default class SenderAutocomplete extends Vue {
    @Prop({ default: '' })
    value!: string;

    @Prop({ default: 500 })
    debounce!: number;

    @Prop({ default: false })
    isExport!: boolean;

    @Prop({ default: false })
    disabled!: boolean;

    @Prop({
        validator: (value: string) => ['open', 'fulfilled'].includes(value)
    })
    type!: string;

    loading = false;
    search = '';
    items: string[] = [];

    timeout = 0;
    @Watch('search')
    searchWatcher(newValue: string, oldValue: string) {
        clearTimeout(this.timeout);
        if (!newValue) {
            return;
        }

        this.timeout = setTimeout(this.getSenders, this.debounce);
    }

    async getSenders() {
        this.loading = true;
        try {
            const response =
                this.type === 'open'
                    ? await api.getOpenSenders(this.search, this.isExport)
                    : await api.getFulfilledSenders(this.search, this.isExport);
            this.items = response.data;
        } catch {
            emitError(
                'Something went wrong while retrieving the selectable sender options.'
            );
        }
        this.loading = false;
    }
}
