<template>
    <Fragment>
        <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="totalAmountOfItems"
            :options.sync="options"
            :footer-props="footerOptions"
            item-key="id"
            fixed-footer
            fixed-header
            dense
            height="calc(100vh - 280px)"
            class="elevation-1"
            :loading="loading"
        >
            <template v-slot:top>
                <div class="px-4 py-4 d-flex justify-space-between">
                    <div>
                        <v-text-field
                            v-model="filters.search"
                            placeholder="Search"
                            @keyup.enter="onFilterChanged"
                            append-icon="mdi-magnify"
                            :disabled="loading"
                        ></v-text-field>
                    </div>
                    <div class="py-4">
                        <v-btn color="primary" @click="add"
                            >Add <v-icon right> mdi-plus </v-icon></v-btn
                        >
                    </div>
                </div>
            </template>

            <template v-slot:item.actions="{ item }">
                <v-btn color="primary" class="ma-2" small @click="edit(item)"
                    >Edit <v-icon right>mdi-pencil</v-icon></v-btn
                >
            </template>
        </v-data-table>
        <TransitDeclarationTemplateDialog
            v-if="showDialog"
            v-model="showDialog"
            :id="transitDeclarationDefinitionId"
            @saved="saved"
        >
        </TransitDeclarationTemplateDialog>
    </Fragment>
</template>
<script setup lang="ts">
import {
    CustomsTransitDeclarationTemplateApi,
    TransitDeclarationTemplate,
} from '@/openapi';
import { DataOptions, DataTableHeader } from 'vuetify';
import { FooterOptions } from '@/types/types';
import { emitError } from '@/event-bus';
import TransitDeclarationTemplateDialog from '@/components/dialogs/TransitDeclarationTemplateDialog.vue';
import { computed, ref, watch } from 'vue';
import { useSorting } from '@/composables/sort';

const api = new CustomsTransitDeclarationTemplateApi(undefined, '');

interface TransitDeclarationTemplatesFilters {
    search: string;
}

const headers = ref<DataTableHeader[]>([
    { text: 'Name', value: 'name', sortable: true },
    {
        text: 'Durchgangszollstelle 1',
        value: 'durchgangszollstelle1',
        sortable: true,
    },
    {
        text: 'Durchgangszollstelle 2',
        value: 'durchgangszollstelle2',
        sortable: true,
    },
    {
        text: 'Tage bis zur "Wiedergestellung"',
        value: 'tageBisZurWiedergestellung',
        sortable: true,
    },
    {
        text: 'Ausgangszollstelle ID',
        value: 'ausgangszollstelleId',
        sortable: true,
    },
    { text: 'Art der anmeldung', value: 'artDerAnmeldung', sortable: true },
    {
        text: 'Bewilligungsnummer Versender',
        value: 'bewilligungsnummerVersender',
        sortable: true,
    },
    {
        text: 'Bewilligung besondere Verschlüsse',
        value: 'bewilligungsnummerVersender2',
        sortable: true,
    },
    {
        text: 'Ansprechpartner Email',
        value: 'ansprechpartnerEmail',
        sortable: true,
    },
    {
        text: 'Ansprechpartner Telefonnummer',
        value: 'ansprechpartnerTelefonnummer',
        sortable: true,
    },
    {
        text: 'Sicherheitsangaben GRN',
        value: 'sicherheitsangabenGRN',
        sortable: true,
    },
    { text: 'Zugriffscode', value: 'zugriffscode', sortable: true },
    {
        text: 'Holder of Transit Procedure EORI',
        value: 'holderOfTransitProcedureEORI',
        sortable: true,
    },
    {
        text: '',
        value: 'actions',
        sortable: false,
        width: '6em',
    },
]);

const loading = ref(false);

const filters = ref<TransitDeclarationTemplatesFilters>({
    search: '',
});

const options = ref<DataOptions>({
    page: 1,
    itemsPerPage: 50,
    sortBy: [],
    sortDesc: [],
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
});

const footerOptions = ref<FooterOptions>({
    showFirstLastPage: true,
    itemsPerPageOptions: [5, 25, 50, 100],
    disablePagination: false,
});

const items = ref<TransitDeclarationTemplate[]>([]);
const currentItem = ref<TransitDeclarationTemplate | null>(null);
const totalAmountOfItems = ref(0);

const showDialog = ref(false);

const { sortBy, sortDesc } = useSorting(options);

const transitDeclarationDefinitionId = computed(() => {
    return currentItem.value?.id ?? null;
});

let timeoutDelay = 0;
watch(
    () => options.value,
    (newVal: DataOptions, oldVal: DataOptions) => {
        clearTimeout(timeoutDelay);
        timeoutDelay = setTimeout(() => {
            getTransitDeclarationTemplates(options.value.page);
        }, 250);
    }
);

const getTransitDeclarationTemplates = async (page?: number) => {
    page ??= options.value.page;
    loading.value = true;
    try {
        const response = await api.getTransitDeclarationTemplates(
            filters.value.search,
            sortBy.value,
            sortDesc.value,
            page,
            options.value.itemsPerPage
        );

        items.value = response.data.items ?? [];
        totalAmountOfItems.value = response.data.totalAmountOfItems ?? 0;
        options.value.page = response.data.page ?? 1;
    } catch {
        emitError('Something went wrong while retrieving the items.');
    }
    loading.value = false;
};

const onFilterChanged = async () => {
    if (options.value.page === 1) {
        await getTransitDeclarationTemplates(1);
    } else {
        options.value.page = 1;
    }
};

const add = () => {
    currentItem.value = null;
    showDialog.value = true;
};

const edit = (item: TransitDeclarationTemplate) => {
    currentItem.value = item;
    showDialog.value = true;
};

const saved = async () => {
    await getTransitDeclarationTemplates();
};
</script>
