import { Component, Vue } from 'vue-property-decorator';
import { CustomsMistakeCategoryViewModel } from '../api/viewmodels/CustomsMistakeCategoryViewModel';
import { CreateMistakeViewModel } from '../api/viewmodels/CreateMistakeViewModel';
import { AddMistakesViewModel } from '../api/viewmodels/AddMistakesViewModel';
import * as MistakesController from '../api/MistakesController';
import { ValidationObserver, extend } from 'vee-validate';

extend('hawbValid', {
    validate: async value => {
        let response = await MistakesController.checkIfHawbExists(value);
        return response.data;
    },
    message: 'This HAWB does not exist'
});

@Component({})
export default class Mistakes extends Vue {
    drawer = false;
    tab = '';
    saveSuccessful = false;

    observer!: InstanceType<typeof ValidationObserver>;

    categories: CustomsMistakeCategoryViewModel[] = [];
    items: CreateMistakeViewModel[] = [];
    headers = [
        {
            text: 'HAWB',
            value: 'hawb',
            width: '40%'
        },
        {
            text: 'Category',
            value: 'categoryId',
            width: '20%'
        },
        {
            text: 'Responsibility',
            value: 'responsibility',
            width: '35%'
        },
        {
            text: '',
            value: 'addButton',
            sortable: false,
            width: '5%'
        }
    ];

    created() {
        this.$emit('PageInfoReceived', 'Dakosy Mistakes', [])

        MistakesController.getCategories()
            .then(result => {
                this.categories = result.data;
            })
            .catch(error => {
                this.$emit('errorOccured', [error.message]);
            });
    }

    mounted() {
        this.observer = this.$refs.observer as InstanceType<typeof ValidationObserver>;
    }

    addItem() {
        let item = {
            categoryId: 1
        } as CreateMistakeViewModel;
        this.items.push(item);
    }

    removeItem(item: CreateMistakeViewModel) {
        let index = this.items.indexOf(item);
        if (index !== -1) {
            this.items.splice(index, 1);
        }
    }

    addMistakes() {
        this.observer
            .validateWithInfo()
            .then((x) => {
                if (!x.isValid) {
                    return;
                }

                let vm = {
                    mistakes: this.items
                } as AddMistakesViewModel;
                MistakesController.addMistakes(vm)
                    .then(result => {
                        this.saveSuccessful = true;
                        this.items = [];
                    })
                    .catch(error => {
                        this.$emit('errorOccured', [error.message]);
                    });
            });
    }

    getValidationName(item: CreateMistakeViewModel, propValue: string) {
        let index = this.items.indexOf(item);
        return `item${index}-${propValue}`;
    }
}