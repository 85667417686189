<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :options.sync="options"
    :footer-props="footerOptions"
    :server-items-length="totalAmountOfItems"
    :single-expand="true"
    :expanded.sync="expanded"
    fixed-header
    fixed-footer
    show-expand
    class="elevation-1"
    :loading="loading"
  >
    <template #expanded-item="{ item }">
      <td colspan="7">
        <FlighthandlingConsolidation
          :id="item.id"
          :key="item.id"
        ></FlighthandlingConsolidation>
      </td>
    </template>
  </v-data-table>
</template>

<script setup lang="ts">
import { ConsolidationViewModel, CustomsFlightHandlingApi } from "@/openapi";
import CustomsConsolidationShipments from "./CustomsConsolidationShipments.vue";
import FlighthandlingConsolidation from "./FlighthandlingConsolidation.vue";
import { ref, watch } from "vue";
import { DataOptions, DataTableHeader } from "vuetify";
import { FooterOptions } from "@/types/types";

const consolidationApi = new CustomsFlightHandlingApi(undefined, "");

interface FlighHandlingConsolidationsProps {
  mawbId: number;
}

const props = defineProps<FlighHandlingConsolidationsProps>();
const emits = defineEmits<{
  (e: "errorOccured", values: string[]): void;
}>();

const loading = ref(false);
const headers = ref<DataTableHeader[]>([
  { text: "Carrier", value: "carrier", groupable: false },
  { text: "ETA", value: "eta", groupable: false },
  {
    text: "Packages",
    value: "packages",
    groupable: false,
  },
  {
    text: "Shipments",
    value: "shipments",
    groupable: false,
  },
  { text: "Weight", value: "weight", groupable: false },
  {
    text: "Weigh unit",
    value: "weightUnit",
    groupable: false,
  },
]);

const options = ref<DataOptions>({
  page: 1,
  itemsPerPage: 5,
  sortBy: [],
  sortDesc: [],
  groupBy: [],
  groupDesc: [],
  multiSort: false,
  mustSort: true,
});

const footerOptions = ref<FooterOptions>({
  showFirstLastPage: true,
  itemsPerPageOptions: [5, 25, 50, 100],
  disablePagination: false,
});

const totalAmountOfItems = ref(0);
const items = ref<ConsolidationViewModel[]>([]);
const expanded = ref([]);

watch(
  () => options.value,
  (newVal, oldVal) => {
    getConsolidations(props.mawbId);
  },
);

const getConsolidations = async (mawbId: number) => {
  loading.value = true;
  try {
    const response = await consolidationApi.getConsolidations(
      mawbId,
      options.value.page,
      options.value.itemsPerPage,
    );
    items.value = response.data.items ?? [];
    totalAmountOfItems.value = response.data.totalAmountOfItems ?? 0;
  } catch {
    emits("errorOccured", [
      "Something went wrong while retrieving the consolidations",
    ]);
  }
  loading.value = false;
};
</script>
