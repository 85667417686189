import { Component, Mixins, Vue, Watch } from 'vue-property-decorator';
import { ToolbarItem } from '../models/ToolbarItem';
import { ValidationResult } from 'vee-validate/dist/types/types';
import { ValidationProvider } from 'vee-validate';
import CrudPageMixin from '../mixins/CrudPageMixin';
import { BookingAccountApi, BookingAccountViewModel } from '../openapi';

@Component
export default class BookingAccounts extends Mixins<
    CrudPageMixin<BookingAccountViewModel>
>(CrudPageMixin) {
    api: BookingAccountApi = new BookingAccountApi(undefined, '');
    headers = [
        { text: 'Name', value: 'name' },
        { text: 'Kontonummer', value: 'kontonummer' },
        { text: 'Actions', value: 'actions', sortable: false },
    ];
    isLoading = false;
    footerOptions: any = {
        showFirstLastPage: true,
        itemsPerPageOptions: [25, 50, 100, -1],
        disablePagination: false,
    };
    options: any = {
        page: 1,
        itemsPerPage: 100,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
    };
    totalItems: number = 0;
    timeoutDelay: any = null;
    disableNewItemButton: Boolean = false;

    created() {
        const toolbarButtons: ToolbarItem[] = [
            {
                callback: () => this.refresh(),
                icon: 'mdi-refresh',
                tooltipText: 'Refresh overview',
            },
        ];
        this.$emit('PageInfoReceived', 'Buchungskonten', toolbarButtons);
    }

    @Watch('options')
    onOptionsChanged(newVal: any, oldVal: any) {
        clearTimeout(this.timeoutDelay);
        this.timeoutDelay = setTimeout(() => {
            this.disableNewItemButton = false;
            this.loadItems(
                newVal.page,
                newVal.itemsPerPage,
                this.getSort(newVal.sortDesc),
                this.getSortField(newVal.sortBy)
            );
        }, 250);
    }

    // Overwrites mixin method
    addNewItem() {
        this.disableNewItemButton = true;
        this.items.unshift({ id: 0 } as BookingAccountViewModel);
        this.totalItems += 1;
    }

    loadItems(
        page: number,
        itemsPerPage: number,
        orderByField: string,
        orderBy: string
    ) {
        this.isLoading = true;
        this.footerOptions.disablePagination = true;
        this.api
            .getCustomsBookingAccounts(
                itemsPerPage,
                page,
                orderBy,
                orderByField
            )
            .then((result) => {
                this.items = result.data.items as BookingAccountViewModel[];
                this.totalItems = result.data.totalItems as number;
            })
            .catch((error) => {
                this.$emit('errorOccured', [error.message]);
            })
            .finally(() => {
                this.isLoading = false;
                this.footerOptions.disablePagination = false;
            });
    }

    saveItem(item: BookingAccountViewModel) {
        const validationObserver = this.getValidationObserver(item);
        const promises: Promise<ValidationResult>[] = [];
        for (const key in this.$refs) {
            const split = key.split('-');
            if (!split.length || key === 'observer-' + item.id) {
                continue;
            }

            const propName = split[0];
            const id = parseInt(split[1]);
            if (id != item.id) {
                continue;
            }

            const validationProvider = (<Vue[]>(
                this.$refs[key]
            ))[0] as InstanceType<typeof ValidationProvider>;
            promises.push(
                validationProvider.validate(
                    item[propName as keyof BookingAccountViewModel]
                )
            );
        }

        validationObserver.validate().then(async (isValid) => {
            const validationResults = await Promise.all(promises);
            for (const validationResult of validationResults) {
                if (!validationResult.valid) {
                    return;
                }
            }

            this.isLoading = true;
            const isNewItem = this.isNewItem(item);

            const model: BookingAccountViewModel = {
                id: item.id,
                name: item.name,
                kontonummer: item.kontonummer
                    ? parseInt(item.kontonummer.toString())
                    : 0,
            };

            this.api
                .saveCustomsBookingAccount(model)
                .then((result) => {
                    if (!result?.data) {
                        return;
                    }

                    this.mapItem(item, result.data);
                    if (isNewItem) {
                        this.disableNewItemButton = false;
                        this.totalItems++;
                    }
                })
                .catch((error) => {
                    this.$emit('errorOccured', [error.message]);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        });
    }

    deleteItem(item: any) {
        this.isLoading = true;
        const index = this.items.findIndex((cocu) => cocu.id === item.id);
        if (this.isNewItem(item)) {
            this.items.splice(index, 1);
            this.isLoading = false;
            this.disableNewItemButton = false;
            return;
        }

        this.api
            .deleteCustomsBookingAccount(item.id)
            .then((c) => {
                this.items.splice(index, 1);
            })
            .catch(() => {})
            .finally(() => {
                const options = this.options;
                this.loadItems(
                    options.page,
                    options.itemsPerPage,
                    this.getSort(options.sortDesc),
                    this.getSortField(options.sortBy)
                );
            });
    }

    getSortField(sortFields: string[]) {
        return sortFields[0] ?? '';
    }

    getSort(sortDesc: Boolean[]) {
        const isDesc = sortDesc[0] ?? null;
        if (!(isDesc === false || isDesc === true)) {
            return '';
        }
        return isDesc ? 'DESC' : 'ASC';
    }

    refresh() {
        this.loadItems(
            this.options.page,
            this.options.itemsPerPage,
            this.getSort(this.options.sortDesc),
            this.getSortField(this.options.sortBy)
        );
        this.disableNewItemButton = false;
    }
}
