

import axios, { AxiosPromise, AxiosTransformer } from 'axios';

let ISO_8601 = /^(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2}:\d{2})$/;
let transformDate = function (data: any) {
    if(data === undefined || data === null)
    {
        return null;
    }

    Object.keys(data).forEach(function (k) {
        if(data[k] instanceof Object) {
            transformDate(data[k]);
        } else {
            if (ISO_8601.test(data[k])) {
                data[k] = new Date(Date.parse(data[k]));
            }
        }
    });
    return data;
};
let transformResponse = (axios.defaults.transformResponse as AxiosTransformer[]).concat(transformDate)

import { CustomsHandlingWABItemViewModel } from './viewmodels/CustomsHandlingWABItemViewModel'
import { CustomsShipmentItemViewModel } from './viewmodels/CustomsShipmentItemViewModel'
import { CustomsMrnUpdateViewModel } from './viewmodels/CustomsMrnUpdateViewModel'
import { ShipmentDetailUpdateViewModel } from './viewmodels/ShipmentDetailUpdateViewModel'
import { CustomsHandlingItemViewModel } from './viewmodels/CustomsHandlingItemViewModel'
import { PagedResultCustomsExport } from './viewmodels/PagedResultCustomsExport'
import { CustomsHandlingExportDashboardViewModel } from './viewmodels/CustomsHandlingExportDashboardViewModel'
import { EmployeeViewModel } from './viewmodels/EmployeeViewModel'
import { CustomsShipmentDetailViewModel } from './viewmodels/CustomsShipmentDetailViewModel'
import { ReturnReasonFirstViewModel } from './viewmodels/ReturnReasonFirstViewModel'
import { CustomsCountryViewModel } from './viewmodels/CustomsCountryViewModel'
import { ExportFlowStatusHistoryItem } from './viewmodels/ExportFlowStatusHistoryItem'
import { KeyValueItem } from './viewmodels/KeyValueItem'


        
    // GetCustomsHandlingItems
    export function getCustomsHandlingItems (activeModus: number, currentWABId: number, currentValueBreak: number, amount: number, page: number, orderByField: string, orderBy: string, filterJson: string, flowStep: number) : AxiosPromise<PagedResultCustomsExport> {
        return axios({
            url: `api/CustomsExport/getCustomsHandlingItems/?activeModus=${activeModus}&currentWABId=${currentWABId}&currentValueBreak=${currentValueBreak}&amount=${amount}&page=${page}&orderByField=${encodeURIComponent(orderByField)}&orderBy=${encodeURIComponent(orderBy)}&filterJson=${encodeURIComponent(filterJson)}&flowStep=${flowStep}`, 
            method: "get",
            data: null,
            transformResponse: transformResponse,
            responseType: "json"
        });
    };    
    // GetCustomsHandlingItemById
    export function getCustomsHandlingItemById (customsHandlingItemId: number) : AxiosPromise<CustomsHandlingItemViewModel> {
        return axios({
            url: `api/CustomsExport/getCustomsHandlingItemById/?customsHandlingItemId=${customsHandlingItemId}`, 
            method: "get",
            data: null,
            transformResponse: transformResponse,
            responseType: "json"
        });
    };    
    // GetCustomsHandlingWABItems
    export function getCustomsHandlingWABItems (activeModus: number) : AxiosPromise<CustomsHandlingWABItemViewModel[]> {
        return axios({
            url: `api/CustomsExport/getCustomsHandlingWABItems/?activeModus=${activeModus}`, 
            method: "get",
            data: null,
            transformResponse: transformResponse,
            responseType: "json"
        });
    };    
    // GetDashboardTotals
    export function getDashboardTotals () : AxiosPromise<CustomsHandlingExportDashboardViewModel> {
        return axios({
            url: `api/CustomsExport/getDashboardTotals/`, 
            method: "get",
            data: null,
            transformResponse: transformResponse,
            responseType: "json"
        });
    };    
    // GetReturnReasons
    export function getReturnReasons () : AxiosPromise<ReturnReasonFirstViewModel[]> {
        return axios({
            url: `api/CustomsExport/getReturnReasons/`, 
            method: "get",
            data: null,
            transformResponse: transformResponse,
            responseType: "json"
        });
    };    
    // GetCustomsCountries
    export function getCustomsCountries () : AxiosPromise<CustomsCountryViewModel[]> {
        return axios({
            url: `api/CustomsExport/getCustomsCountries/`, 
            method: "get",
            data: null,
            transformResponse: transformResponse,
            responseType: "json"
        });
    };    
    // GetEmployees
    export function getEmployees () : AxiosPromise<EmployeeViewModel[]> {
        return axios({
            url: `api/CustomsExport/getEmployees/`, 
            method: "get",
            data: null,
            transformResponse: transformResponse,
            responseType: "json"
        });
    };    
    // GetCurrentEmployee
    export function getCurrentEmployee () : AxiosPromise<EmployeeViewModel> {
        return axios({
            url: `api/CustomsExport/getCurrentEmployee/`, 
            method: "get",
            data: null,
            transformResponse: transformResponse,
            responseType: "json"
        });
    };    
    // SaveSelectedHandlingItems
    export function saveSelectedHandlingItems (flowStep: number, activeModus: number, currentWABId: number, currentValueBreak: number, models: CustomsHandlingItemViewModel[]) : AxiosPromise<CustomsHandlingItemViewModel[]> {
        return axios({
            url: `api/CustomsExport/saveSelectedHandlingItems/?flowStep=${flowStep}&activeModus=${activeModus}&currentWABId=${currentWABId}&currentValueBreak=${currentValueBreak}`, 
            method: "post",
            data: models,
            transformResponse: transformResponse,
            responseType: "json"
        });
    };    
    // SaveCustomsHandlingWABItem
    export function saveCustomsHandlingWABItem (saveAction: string, model: CustomsHandlingWABItemViewModel) : AxiosPromise<CustomsHandlingWABItemViewModel> {
        return axios({
            url: `api/CustomsExport/saveCustomsHandlingWABItem/?saveAction=${encodeURIComponent(saveAction)}`, 
            method: "post",
            data: model,
            transformResponse: transformResponse,
            responseType: "json"
        });
    };    
    // DownloadExcelFile
    export function downloadExcelFile (filePath: string) : AxiosPromise<any> {
        return axios({
            url: `api/CustomsExport/downloadExcelFile/?filePath=${encodeURIComponent(filePath)}`, 
            method: "get",
            data: null,
            transformResponse: transformResponse,
            responseType: "blob"
        });
    };    
    // GetShipmentDetail
    export function getShipmentDetail (customsHandlingItemId: number) : AxiosPromise<CustomsShipmentDetailViewModel> {
        return axios({
            url: `api/CustomsExport/getShipmentDetail/?customsHandlingItemId=${customsHandlingItemId}`, 
            method: "get",
            data: null,
            transformResponse: transformResponse,
            responseType: "json"
        });
    };    
    // ChangeShipmentStatus
    export function changeShipmentStatus (model: CustomsShipmentItemViewModel) : AxiosPromise<CustomsShipmentItemViewModel> {
        return axios({
            url: `api/CustomsExport/changeShipmentStatus/`, 
            method: "post",
            data: model,
            transformResponse: transformResponse,
            responseType: "json"
        });
    };    
    // SetMrn
    export function setMrn (model: CustomsMrnUpdateViewModel) : AxiosPromise<any> {
        return axios({
            url: `api/CustomsExport/setMrn/`, 
            method: "post",
            data: model,
            transformResponse: transformResponse,
            responseType: "json"
        });
    };    
    // UpdateShipmentDetail
    export function updateShipmentDetail (model: ShipmentDetailUpdateViewModel) : AxiosPromise<any> {
        return axios({
            url: `api/CustomsExport/updateShipmentDetail/`, 
            method: "post",
            data: model,
            transformResponse: transformResponse,
            responseType: "json"
        });
    };    
    // GetExportFlowStatusHistory
    export function getExportFlowStatusHistory (shipmentId: number) : AxiosPromise<ExportFlowStatusHistoryItem[]> {
        return axios({
            url: `api/CustomsExport/getExportFlowStatusHistory/?shipmentId=${shipmentId}`, 
            method: "get",
            data: null,
            transformResponse: transformResponse,
            responseType: "json"
        });
    };    
    // GetRoutes
    export function getRoutes () : AxiosPromise<KeyValueItem[]> {
        return axios({
            url: `api/CustomsExport/getRoutes/`, 
            method: "get",
            data: null,
            transformResponse: transformResponse,
            responseType: "json"
        });
    };

