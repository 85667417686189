import { Ref, ref, watchEffect } from 'vue';
import { DataOptions } from 'vuetify';

export function useSorting(options: Ref<DataOptions>) {
    const sortBy = ref<string>('');
    const sortDesc = ref(false);

    const decideSorting = () => {
        sortBy.value = options.value.sortBy?.length
            ? options.value.sortBy[0]
            : '';

        sortDesc.value = options.value.sortDesc?.length
            ? options.value.sortDesc[0]
            : false;
    };

    watchEffect(() => {
        decideSorting();
    });

    return { sortBy, sortDesc };
}
