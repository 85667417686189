import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

export interface AddMrnForm {
    mrn: string;
}

@Component({})
export default class AddMrnDialog extends Vue {
    @Prop({ default: false })
    value!: Boolean;

    @Prop({ default: false })
    isLoading!: Boolean;

    @Prop({ default: 'Shipment add MRN' })
    title!: String;

    @Prop({ default: 'Save' })
    confirmButtonText!: String;

    @Prop({ default: 'Close' })
    closeButtonText!: String;

    @Prop({})
    parcelNumber!: String;

    @Prop({ default: '' })
    initialMrn!: String;

    rules = {
        mrn: (value: string) => {
            const pattern = /^[0-9]{2}[A-Z]{2}[a-zA-Z0-9]{14}$/;
            return pattern.test(value) || 'Invalid MRN number';
        },
    };

    valid = true;
    form: AddMrnForm = {
        mrn: '',
    };

    @Watch('initialMrn', { immediate: true })
    onMrnChanged(newValue: string, oldVal: string) {
        this.form.mrn = newValue;
    }

    @Watch('value')
    onValueChanged(newValue: boolean, oldVal: boolean) {
        if (newValue == false) {
            this.form.mrn = '';
            (
                this.$refs.form as Vue & { resetValidation: () => boolean }
            ).resetValidation();
        }
    }
}
