<template>
    <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :footer-props="footerOptions"
        :loading="loading"
        :search="search"
        :expanded.sync="expanded"
        @item-expanded="onExpanded"
        show-expand
        single-expand
        :height="tableHeightCalc"
        class="elevation-1"
    >
        <template #top>
            <div class="d-flex pa-4">
                <v-text-field
                    v-model="search"
                    label="Search"
                    placeholder="Search"
                    append-icon="mdi-magnify"
                    class="search"
                ></v-text-field>
            </div>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
                <v-card color="lighten-3" class="ma-6">
                    <v-card-text>
                        <v-data-table
                            :headers="nestedHeaders"
                            :items="nestedItems"
                            :options.sync="nestedOptions"
                            :footer-props="nestedFooterOptions"
                            :loading="nestedLoading"
                        ></v-data-table>
                    </v-card-text>
                </v-card>
            </td>
        </template>
    </v-data-table>
</template>

<script setup lang="ts">
import { useDataTableDefaults } from '@/composables/dataTable';
import { emitError } from '@/event-bus';
import { determineTableHeightCalc } from '@/helpers/dataTableHelper';
import { SimpleEmployeeDto, UserRightApi, UserRightDto } from '@/openapi';
import { onBeforeMount, ref } from 'vue';
import { DataTableHeader } from 'vuetify';

const api = new UserRightApi(undefined, '');

const headers = ref<DataTableHeader[]>([
    {
        text: 'Name',
        value: 'name',
    },
    {
        text: 'Description',
        value: 'description',
    },
    {
        text: 'Assigned to',
        value: 'assignedToCount',
    },
    { text: '', value: 'data-table-expand' },
]);

const { options, footerOptions, items, loading } =
    useDataTableDefaults<UserRightDto>();

const nestedHeaders = ref<DataTableHeader[]>([
    {
        text: 'Id',
        value: 'id',
    },
    {
        text: 'Name',
        value: 'name',
    },
]);
const {
    options: nestedOptions,
    footerOptions: nestedFooterOptions,
    items: nestedItems,
    loading: nestedLoading,
} = useDataTableDefaults<SimpleEmployeeDto>();

const expanded = ref<UserRightDto[]>([]);
const search = ref('');
const tableHeightCalc = ref<string>(determineTableHeightCalc(297));

const getUserRights = async () => {
    try {
        loading.value = true;
        const response = await api.getUserRights(true);
        items.value = response.data.items ?? [];
    } catch {
        emitError('Something went wrong while retrieving the user rights');
    }
    loading.value = false;
};

const onExpanded = async ({
    item,
    value,
}: {
    item: UserRightDto;
    value: boolean;
}) => {
    nestedItems.value = [];
    if (!value) {
        return;
    }

    nestedLoading.value = true;
    try {
        const response = await api.getUserRightEmployees(item.id!);
        nestedItems.value = response.data.items ?? [];
    } catch {
        emitError('Failed to retrieve the related employees');
    }
    nestedLoading.value = false;
};

onBeforeMount(async () => {
    await getUserRights();
});
</script>

<style scoped lang="scss">
.search {
    flex: 0 1 350px;
}
</style>
