import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class ConfirmDialog extends Vue {
    @Prop({ default: false })
    value!: boolean;

    @Prop({ default: false })
    isLoading!: boolean;

    @Prop({ default: false })
    isConfirmDisabled!: boolean;

    @Prop({ default: 'Confirm action' })
    title!: string;

    @Prop({ default: 'Confirm' })
    confirmButtonText!: string;

    @Prop({ default: 'Close' })
    closeButtonText!: string;

    @Prop({ default: 'primary' })
    color!: string;

    @Prop({ default: '600px' })
    maxWidth!: string;
}
