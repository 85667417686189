<template>
    <v-data-table
        :items="displayedItems"
        :server-items-length="totalAmountOfItems"
        :height="height"
        fixed-header
        hide-default-footer
        v-bind="$attrs"
    >
        <template #body="{ items, headers }">
            <tbody>
                <tr :height="`${topFiller}px`"></tr>
                <tr v-for="item in items">
                    <td v-for="header in headers">
                        <slot :name="`item.${header.value}`" :item="item">{{
                            item[header.value]
                        }}</slot>
                    </td>
                </tr>
                <tr :height="`${bottomFiller}px`"></tr>
            </tbody>
        </template>
    </v-data-table>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, useAttrs, watch } from 'vue';

interface VirtualDataTableProps {
    items: any[];
    height?: number;
}

const DATATABLE_WRAPPER_CLASS = '.v-data-table__wrapper';

const props = withDefaults(defineProps<VirtualDataTableProps>(), {
    items: () => [],
    height: 300,
});
const attrs = useAttrs();

const tableRowHeight = computed(() => {
    return attrs.hasOwnProperty('dense') ? 32 : 48;
});

const itemsDisplayedAtOnce = computed(() => {
    return Math.ceil(props.height / tableRowHeight.value);
});

const displayedItems = computed(() => {
    return (
        props.items?.slice(
            Math.max(0, startIndex.value - 2),
            Math.min(
                totalAmountOfItems.value,
                startIndex.value + itemsDisplayedAtOnce.value + 3
            )
        ) ?? []
    );
});

const startIndex = ref(0);
const topFiller = ref(0);
const bottomFiller = ref(0);

const totalAmountOfItems = computed(() => props.items.length);

const dataTableWrapper = ref<HTMLElement | null>();
const onScroll = (event: any) => {
    calculateTableFillers();
};

const calculateTableFillers = () => {
    if (!dataTableWrapper.value) {
        return;
    }
    const scrollTop = dataTableWrapper.value.scrollTop;
    const totalHeight = totalAmountOfItems.value * tableRowHeight.value;
    const localTopFiller = Math.min(totalHeight - props.height, scrollTop);

    const localBottomFiller = Math.max(
        0,
        totalHeight - (localTopFiller + props.height)
    );

    if (localBottomFiller === 0 && bottomFiller.value === 0) {
        return;
    }

    topFiller.value = localTopFiller;
    startIndex.value = Math.max(
        0,
        Math.floor(localTopFiller / tableRowHeight.value)
    );

    bottomFiller.value = localBottomFiller;
};

watch(
    () => props.items,
    () => {
        if (dataTableWrapper.value) {
            dataTableWrapper.value!.scrollTop = 0;
        }
        topFiller.value = 0;
        bottomFiller.value = 0;
        startIndex.value = 0;
        calculateTableFillers();
    }
);

onMounted(() => {
    dataTableWrapper.value = document.querySelector(
        DATATABLE_WRAPPER_CLASS
    )! as HTMLElement;
    dataTableWrapper.value!.addEventListener('scroll', onScroll);
    calculateTableFillers();
});

onUnmounted(() => {
    const dataTableWrapper = document.querySelector(DATATABLE_WRAPPER_CLASS)!;
    dataTableWrapper.removeEventListener('scroll', onScroll);
});
</script>
