<template>
    <Fragment>
        <v-container fluid>
            <v-row>
                <v-col cols="12">
                    <v-card>
                        <v-toolbar dark color="grey darken-3">
                            <v-btn icon :to="{ name: 'Employees' }" exact>
                                <v-icon>mdi-arrow-left</v-icon></v-btn
                            >
                            <v-toolbar-title
                                >Employees bulk assignment</v-toolbar-title
                            >
                        </v-toolbar>
                        <v-card-text>
                            <div
                                v-if="hasEmployees"
                                class="selected-values mb-4"
                            >
                                <v-chip
                                    v-for="employee in employees"
                                    label
                                    :ripple="false"
                                    color="primary"
                                >
                                    <v-icon left>
                                        mdi-account-circle-outline
                                    </v-icon>
                                    {{ employee.firstName }}
                                    {{ employee.additionalNames }}
                                    {{ employee.lastName }}
                                </v-chip>
                            </div>
                            <div v-else>
                                <v-alert type="warning" outlined
                                    >No employees selected</v-alert
                                >
                            </div>
                            <div v-if="hasEmployees" class="bulk-wrapper">
                                <div class="bulk-add">
                                    <v-data-table
                                        v-model="selectedBulkAdd"
                                        :headers="headers"
                                        :items="userRights"
                                        :options.sync="options"
                                        :loading="
                                            loadingUserRights &&
                                            loadingEmployeesUserRights
                                        "
                                        :height="500"
                                        hide-default-header
                                        hide-default-footer
                                        dense
                                        class="mb-4"
                                    >
                                        <template #top
                                            ><h2 class="mb-4">
                                                Bulk add
                                            </h2></template
                                        >
                                        <template
                                            #item="{ item, select, isSelected }"
                                        >
                                            <tr
                                                @click="select(!isSelected)"
                                                :class="{
                                                    'grey lighten-3':
                                                        isSelected,
                                                }"
                                            >
                                                <td>
                                                    <v-tooltip top>
                                                        <template
                                                            v-slot:activator="{
                                                                on,
                                                                attrs,
                                                            }"
                                                        >
                                                            <div
                                                                v-bind="attrs"
                                                                v-on="on"
                                                            >
                                                                {{ item.name }}
                                                            </div>
                                                        </template>
                                                        <span>{{
                                                            item.description
                                                        }}</span>
                                                    </v-tooltip>
                                                </td>
                                            </tr>
                                        </template>
                                    </v-data-table>
                                    <div class="actions">
                                        <div class="selected-values">
                                            <template
                                                v-for="selected in selectedBulkAdd"
                                            >
                                                <v-tooltip top>
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                            attrs,
                                                        }"
                                                    >
                                                        <v-chip
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            label
                                                            color="success"
                                                            :ripple="false"
                                                        >
                                                            {{ selected.name }}
                                                        </v-chip>
                                                    </template>
                                                    <span>{{
                                                        selected.description
                                                    }}</span>
                                                </v-tooltip>
                                            </template>
                                        </div>
                                        <div class="actions__save">
                                            <v-btn
                                                color="success"
                                                :loading="saving"
                                                :disabled="
                                                    loadingUserRights ||
                                                    loadingEmployeesUserRights
                                                "
                                                @click="bulkAdd"
                                                >Add ({{
                                                    selectedBulkAdd.length
                                                }})</v-btn
                                            >
                                        </div>
                                    </div>
                                </div>
                                <v-divider vertical></v-divider>
                                <div class="bulk-delete">
                                    <v-data-table
                                        v-model="selectedBulkDelete"
                                        :headers="headers"
                                        :items="userRights"
                                        :options.sync="options"
                                        :loading="
                                            loadingUserRights &&
                                            loadingEmployeesUserRights
                                        "
                                        :height="500"
                                        hide-default-header
                                        hide-default-footer
                                        dense
                                        class="mb-4"
                                    >
                                        <template #top
                                            ><h2 class="mb-4">
                                                Bulk delete
                                            </h2></template
                                        >
                                        <template
                                            #item="{ item, select, isSelected }"
                                        >
                                            <tr
                                                @click="select(!isSelected)"
                                                :class="{
                                                    'grey lighten-3':
                                                        isSelected,
                                                }"
                                            >
                                                <td>
                                                    <v-tooltip top>
                                                        <template
                                                            v-slot:activator="{
                                                                on,
                                                                attrs,
                                                            }"
                                                        >
                                                            <div
                                                                v-bind="attrs"
                                                                v-on="on"
                                                            >
                                                                {{ item.name }}
                                                            </div>
                                                        </template>
                                                        <span>{{
                                                            item.description
                                                        }}</span>
                                                    </v-tooltip>
                                                </td>
                                            </tr>
                                        </template>
                                    </v-data-table>
                                    <div class="actions">
                                        <div class="selected-values">
                                            <v-chip
                                                v-for="selected in selectedBulkDelete"
                                                label
                                                color="error"
                                                :ripple="false"
                                            >
                                                {{ selected.name }}
                                            </v-chip>
                                        </div>
                                        <div class="actions__save">
                                            <v-btn
                                                color="error"
                                                :loading="saving"
                                                :disabled="
                                                    loadingUserRights ||
                                                    loadingEmployeesUserRights
                                                "
                                                @click="bulkDelete"
                                                >Delete ({{
                                                    selectedBulkDelete.length
                                                }})</v-btn
                                            >
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>

        <ConfirmDialog v-model="showLoader" @confirm="confirm" @close="close">
            <span v-if="!saving">
                Are you sure you want to continue with this action?</span
            >
            <div v-else class="test">
                <div
                    class="employee-action-status"
                    v-for="actionStatus in actionStatuses"
                >
                    <span class="font-weight-bold">{{
                        actionStatus.name
                    }}</span>
                    <span
                        ><v-icon :color="actionStatus.color">{{
                            actionStatus.icon
                        }}</v-icon></span
                    >
                </div>
            </div>
        </ConfirmDialog>
    </Fragment>
</template>

<script setup lang="ts">
import { emitError, emitErrorWithFallback, emitSuccess } from '@/event-bus';
import {
    EmployeeDetailDto,
    EmployeesApi,
    UserRightApi,
    UserRightDto,
} from '@/openapi';
import { computed, ref, watch } from 'vue';
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue.html';
import { DataTableHeader } from 'vuetify';
import { ColorIcon } from '@/types/types';
import { useDataTableDefaults } from '@/composables/dataTable';

interface EmployeeDetailRightsProps {
    employeeIds: number[];
}

enum BulkActionStatus {
    InProgress,
    Success,
    Failure,
}

interface EmployeeBulkActionStatus {
    id: number;
    name: string;
    status: BulkActionStatus;
}

enum BulkAction {
    Add,
    Delete,
}

const userRightsApi = new UserRightApi(undefined, '');
const employeesApi = new EmployeesApi(undefined, '');

const props = defineProps<EmployeeDetailRightsProps>();

const headers = ref<DataTableHeader[]>([
    {
        value: 'name',
        text: '',
        align: 'start',
    },
]);

const { options } = useDataTableDefaults();
options.value.itemsPerPage = -1;

const employees = ref<EmployeeDetailDto[]>([]);
const userRights = ref<UserRightDto[]>([]);
const userRightsByEmployee = ref<Map<number, UserRightDto[]>>(new Map());

const selectedBulkAdd = ref<UserRightDto[]>([]);
const selectedBulkDelete = ref<UserRightDto[]>([]);
const currentBulkAction = ref<BulkAction | null>();

const employeeBulkActionStatus = ref<EmployeeBulkActionStatus[]>([]);

const loadingUserRights = ref(false);
const loadingEmployeesUserRights = ref(false);
const saving = ref(false);

const showLoader = ref(false);

const bulkAdd = () => {
    currentBulkAction.value = BulkAction.Add;
    showLoader.value = true;
};

const bulkDelete = () => {
    currentBulkAction.value = BulkAction.Delete;
    showLoader.value = true;
};

const close = () => {
    saving.value = false;
    employeeBulkActionStatus.value = [];
};

const confirm = async () => {
    saving.value = true;

    const promises: Promise<any>[] = [];
    for (const employee of employees.value) {
        const bulkActionStatus = {
            id: employee.id!,
            name: `${employee.firstName ?? ''} ${
                employee.additionalNames ?? ''
            } ${employee.lastName ?? ''}`,
            status: BulkActionStatus.InProgress,
        };
        employeeBulkActionStatus.value.push(bulkActionStatus);

        const userRightIds: Set<number> = new Set();
        const currentUserRights = userRightsByEmployee.value.get(employee.id!)!;
        for (const userRight of currentUserRights) {
            userRightIds.add(userRight.id!);
        }

        if (currentBulkAction.value == BulkAction.Add) {
            for (const userRightToAdd of selectedBulkAdd.value) {
                userRightIds.add(userRightToAdd.id!);
            }
        } else if (currentBulkAction.value == BulkAction.Delete) {
            for (const userRightToDelete of selectedBulkDelete.value) {
                userRightIds.delete(userRightToDelete.id!);
            }
        }

        promises.push(
            userRightsApi
                .putEmployeeUserRights({
                    employeeId: employee.id,
                    userRightIds: Array.from(userRightIds),
                })
                .then(
                    () => (bulkActionStatus.status = BulkActionStatus.Success)
                )
                .catch(
                    () => (bulkActionStatus.status = BulkActionStatus.Failure)
                )
        );
    }
    try {
        await Promise.all(promises);
        saving.value = false;
        showLoader.value = false;
        employeeBulkActionStatus.value = [];
        if (currentBulkAction.value === BulkAction.Add) {
            selectedBulkAdd.value = [];
        } else if (currentBulkAction.value === BulkAction.Delete) {
            selectedBulkDelete.value = [];
        }
        emitSuccess('Succesfully executed bulk change');
    } catch {
        emitError('Something went wrong during the bulk change');
    } finally {
        await getEmployeesUserRights();
    }
};

const getActionStatusIcon = (status: BulkActionStatus): ColorIcon => {
    switch (status) {
        case BulkActionStatus.InProgress:
            return { icon: 'mdi-progress-clock', color: 'info' };
        case BulkActionStatus.Success:
            return { icon: 'mdi-progress-check', color: 'success' };
        case BulkActionStatus.Failure:
            return { icon: 'mdi-progress-close', color: 'error' };
        default: {
            return { icon: '', color: '' };
        }
    }
};

const getUserRights = async () => {
    loadingUserRights.value = true;
    try {
        const response = await userRightsApi.getUserRights();
        userRights.value = response.data.items ?? [];
    } catch (e: unknown) {
        emitErrorWithFallback(
            e,
            'Something went wrong while retrieving the user rights'
        );
    }
    loadingUserRights.value = false;
};

const getEmployeesUserRights = async () => {
    loadingEmployeesUserRights.value = true;
    try {
        await Promise.all(
            props.employeeIds.map(async (employeeId) => {
                const response = await userRightsApi.getEmployeeUserRights(
                    employeeId
                );
                userRightsByEmployee.value.set(
                    employeeId,
                    response.data.items!
                );
            })
        );
    } catch (e: unknown) {
        emitErrorWithFallback(
            e,
            'Something went wrong while retrieving the employees user rights'
        );
    } finally {
        loadingEmployeesUserRights.value = false;
    }
};

const getEmployees = async () => {
    try {
        const results = await Promise.all(
            props.employeeIds.map((employeeId) =>
                employeesApi.getEmployee(employeeId)
            )
        );
        const values: EmployeeDetailDto[] = [];
        for (const result of results) {
            values.push(result.data);
        }
        employees.value = values;
    } catch {
        emitError('Something went wrong while retrieving the employees');
    }
};

const hasEmployees = computed(() => {
    return !!props.employeeIds?.length;
});

const actionStatuses = computed(
    (): (EmployeeBulkActionStatus & ColorIcon)[] => {
        return employeeBulkActionStatus.value.map((item) => {
            return {
                ...item,
                ...getActionStatusIcon(item.status),
            };
        });
    }
);

watch(
    () => props.employeeIds,
    (newValue: number[] | undefined, oldValue: number[] | undefined) => {
        if (newValue?.length != oldValue?.length) {
            getEmployees();
            getEmployeesUserRights();
            getUserRights();
        }
    },
    {
        immediate: true,
    }
);
</script>

<style scoped lang="scss">
tr {
    cursor: pointer;
}

.actions {
    display: flex;
    justify-content: space-between;
    gap: 15px;
}

.selected-values {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.bulk-wrapper {
    display: flex;
    gap: 15px;
}

.bulk-delete {
    width: 100%;
}

.bulk-add {
    width: 100%;
}

.employee-action-status {
    display: flex;
    justify-content: space-between;
}
</style>
