<template>
    <ValidationObserver ref="observer" v-slot="{ invalid }">
    <DefaultDialog v-model="value">
        <template #header><span class="title-holder">{{ value.description }}</span></template>
        <template #content>
            <v-form slim>
                <div class="form-fields">
                    <ValidationProvider
                        name="Description"
                        rules="required"
                        v-slot="{ errors }"
                    >
                        <v-text-field
                            v-model="value.description"
                            label="Description"
                            :counter="100"
                            :error-messages="errors"
                        ></v-text-field>
                    </ValidationProvider>
                </div>
                <div class="form-fields">
                    <ValidationProvider
                        name="Text"
                        rules="required"
                        v-slot="{ errors }"
                    >
                    <v-textarea
                        v-model="value.text"
                        label="Text"
                        :counter="200"
                        :error-messages="errors"
                    ></v-textarea>
                    </ValidationProvider>
                </div>
            </v-form>
        </template>
        <template #footer
            ><v-spacer></v-spacer>
            <v-btn @click="$emit('cancel', false)" :disabled="loading"
                >Cancel</v-btn
            >
            <v-btn
                :disabled="invalid"
                color="success"
                :loading="loading"
                @click="$emit('save', value)"
                >Save</v-btn
            ></template>
        </DefaultDialog>
    </ValidationObserver>
</template>
  
<script setup lang="ts">

import { computed } from "vue";
import { CustomsInvoiceFooterTextViewModel } from '@/openapi';
import DefaultDialog from '@/components/dialogs/DefaultDialog.vue.html';
const emits = defineEmits(['cancel', 'save']);
interface CustomsInvoiceFooterTextProps {
    value: CustomsInvoiceFooterTextViewModel
    loading: boolean
}
const props = defineProps<CustomsInvoiceFooterTextProps>()

const title = computed(() => {
    return props.value.description ? props.value.description : '&nbsp;';
});
</script>
<style lang="scss" scoped>
.title-holder{
  display: inline-block;
  min-height: 32px;
}
  </style>
  