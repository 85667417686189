import { emitError, emitErrorWithFallback } from '@/event-bus';
import ArticlesMixin from '@/mixins/ArticlesMixin';
import {
    CustomsArticleMaintenanceApi,
    FulfilledArticle,
    IncotermViewModel,
} from '@/openapi';
import { DefaultDataTableItem } from '@/types/types';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { DataOptions } from 'vuetify';

import { ArticleMaintenanceFulfillmentForm } from './dialogs/ArticleMaintenanceDialog';

import ArticleMaintenanceDialog from './dialogs/ArticleMaintenanceDialog.vue.html';
import SenderAutocomplete from './editors/SenderAutocomplete.vue.html';
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue.html';
import { DataAction } from '@/store/dataModule';

const api = new CustomsArticleMaintenanceApi(undefined, '');

@Component({
    components: {
        ArticleMaintenanceDialog,
        SenderAutocomplete,
        ConfirmDialog,
    },
})
export default class CustomsArticleMaintenanceFulfilled extends Mixins(
    ArticlesMixin
) {
    @Prop({ default: () => [] })
    customers!: DefaultDataTableItem<string>[];

    @Prop({ default: () => [] })
    senders!: DefaultDataTableItem<string>[];

    @Prop({ default: () => [] })
    incotermIds!: number[];

    loading = false;
    dialogLoading = false;

    showDeleteDialog = false;
    deleteDialogLoading = false;

    headers = [
        { text: 'Customer', value: 'customer', sortable: false },
        { text: 'Sender', value: 'sender', sortable: false },
        { text: 'HS Code', value: 'hsCode', sortable: false },
        { text: 'Content description', value: 'description', sortable: false },
        { text: 'EZA HS Code', value: 'ezaHsCode', sortable: false },
        { text: 'EZA Description', value: 'ezaDescription', sortable: false },
        { text: 'IMPOST HS Code', value: 'impostHsCode', sortable: false },
        {
            text: 'IMPOST Description',
            value: 'impostDescription',
            sortable: false,
        },
        { text: 'DAP/DDP', value: 'incoterm', sortable: false },
        {
            text: 'Use general customer for automated process',
            value: 'useGeneralCustomerForAutomatedProcess',
            sortable: false,
        },
        {
            text: 'Use sender for automated process',
            value: 'useSenderForAutomatedProcess',
            sortable: false,
        },
        {
            text: 'Documents',
            value: 'documents',
            sortable: false,
        },
        { text: ' ', value: 'actions', sortable: false, width: '6em' },
    ];

    selectedCustomer: string | null = null;
    selectedSender: string | null = null;
    selectedIncoterm: number | null = null;

    search: string | null = null;

    items: FulfilledArticle[] = [];
    currentItem: FulfilledArticle | null = null;
    initialData: ArticleMaintenanceFulfillmentForm | null = null;

    options: DataOptions = {
        page: 1,
        itemsPerPage: 50,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
    };

    footerOptions: any = {
        showFirstLastPage: true,
        itemsPerPageOptions: [5, 25, 50, 100],
        disablePagination: false,
    };

    totalAmountOfItems = 0;

    showDialog = false;

    created() {
        this.$store.dispatch(DataAction.FetchIncoterms);
    }

    timeoutDelay = 0;
    @Watch('options')
    onOptionsChanged(newVal: any, oldVal: any) {
        clearTimeout(this.timeoutDelay);
        this.timeoutDelay = setTimeout(() => {
            this.getFulfilledArticles(this.options.page);
        }, 250);
    }

    async getFulfilledArticles(page: number) {
        this.loading = true;
        try {
            const response = await api.getFulfilledArticles(
                !!this.selectedCustomer
                    ? parseInt(this.selectedCustomer)
                    : undefined,
                this.selectedSender ?? undefined,
                this.search ?? undefined,
                this.selectedIncoterm ?? undefined,
                page,
                this.options.itemsPerPage
            );
            this.items = response.data.items ?? [];
            this.totalAmountOfItems = response.data.totalAmountOfItems ?? 0;
        } catch {
            emitError(
                'Something went wrong while retrieving the fulfilled articles.'
            );
        }

        this.loading = false;
    }

    async onFilterSelected() {
        await this.getFulfilledArticles(1);
    }

    onEdit(item: FulfilledArticle) {
        this.currentItem = item;
        this.initialData = {
            eza: {
                hsCode: item.ezaHsCode,
                description: item.ezaDescription,
            },
            impost: {
                description: item.impostDescription,
                hsCode: item.impostHsCode,
            },
            useGeneralCustomerForAutomatedProcess:
                item.useGeneralCustomerForAutomatedProcess!,
            useSenderForAutomatedProcess: item.useSenderForAutomatedProcess!,
            documents: item.documents!,
            senderName: item.sender,
            customer: item.customer
        };
        this.showDialog = true;
    }

    onDelete(item: FulfilledArticle) {
        this.currentItem = item;
        this.showDeleteDialog = true;
    }

    async onEditConfirmed(eventData: ArticleMaintenanceFulfillmentForm) {
        this.dialogLoading = true;
        try {
            await api.editFulfilledArticle({
                fulfilledArticleId: this.currentItem!.id,
                ezaHsCode: eventData.eza.hsCode!,
                ezaDescription: eventData.eza.description,
                impostHsCode: eventData.impost.hsCode,
                impostDescription: eventData.impost.description,
                useGeneralCustomerForAutomatedProcess:
                    eventData.useGeneralCustomerForAutomatedProcess,
                useSenderForAutomatedProcess:
                    eventData.useSenderForAutomatedProcess,
                documents: eventData.documents,
            });
            this.dialogLoading = false;
            this.showDialog = false;
            this.currentItem = null;
            this.initialData = null;
            await this.getFulfilledArticles(this.options.page);
        } catch (e: any) {
            this.dialogLoading = false;
            let errorMessages: string[] = [
                'Something went wrong while editing the fulfilled article.',
            ];

            if (e?.response?.data?.errorMessages?.length) {
                errorMessages = e.response.data.errorMessages;
            }
            emitError(...errorMessages);
        }
    }

    async onDeleteConfirmed() {
        this.deleteDialogLoading = true;

        try {
            await api.deleteFullfilledArticle({
                id: this.currentItem!.id,
            });

            this.showDeleteDialog = false;
            this.currentItem = null;

            await this.getFulfilledArticles(this.options.page);
        } catch (e: unknown) {
            emitErrorWithFallback(
                e,
                'Something went wrong while deleting the fulfilled article.'
            );
        }

        this.deleteDialogLoading = false;
    }

    getEzaHsCode(item: FulfilledArticle) {
        return (
            this.ezaArticles.find((c) => c.id === item.ezaHsCode)?.hsCode ?? ''
        );
    }

    getImpostHsCode(item: FulfilledArticle) {
        return (
            this.impostArticles.find((c) => c.id === item.impostHsCode)
                ?.hsCode ?? ''
        );
    }

    get incoterms() {
        return this.$store.getters.incoterms.filter((c: IncotermViewModel) =>
            this.incotermIds.includes(c.id!)
        );
    }
}
