import { Component, Prop, Vue } from 'vue-property-decorator';
import { ShipmentStatusApi } from '@/openapi';
import SetHoldReasonDialog from '@/components/dialogs/SetHoldReasonDialog.vue.html';
import { SetHoldReasonForm } from '../dialogs/SetHoldReasonDialog';
import { emitError } from '@/event-bus';

const shipmentStatusApi = new ShipmentStatusApi(undefined, '');

interface Successful {
    success: boolean;
}

@Component({
    components: {
        SetHoldReasonDialog
    }
})

export default class HoldShipmentButton extends Vue {
    @Prop()
    shipmentId!: number;

    @Prop()
    exportFlowStatus!: number;

    loading = false;
    showDialog = false;

    async onConfirmedHoldReason(value: SetHoldReasonForm) {
        this.loading = true;
        let success = false;
        try {
            await shipmentStatusApi.setHoldStatus({
                shipmentId: this.shipmentId,
                reason: value.reason
            });

            success = true;
            this.showDialog = false;
        } catch {
            emitError('Something went wrong while setting the Hold status.');
        }

        this.loading = false;
        this.$emit('shipmentStatusChanged', { success } as Successful);
    }

    get cssClasses() {
        return this.$vnode?.data?.staticClass ?? '';
    }
}