<template>
    <ValidationObserver ref="observer" v-slot="{ invalid }">
        <DefaultDialog v-model="value">
            <template #header>Complete MRN</template>
            <template #content>
                <v-form slim>
                    <div class="form-fields">
                        <ValidationProvider
                            name="Customs clearance office"
                            rules="required"
                            v-slot="{ errors }"
                        >
                            <v-autocomplete
                                v-model="formData.customsOfficeId"
                                :items="customsOffices"
                                label="Customs clearance office"
                                item-value="id"
                                :item-text="getCustomsOfficeName"
                                :error-messages="errors"
                            ></v-autocomplete>
                        </ValidationProvider>
                        <ValidationProvider
                            name="Customer"
                            rules="required"
                            v-slot="{ errors }"
                        >
                            <v-autocomplete
                                v-model="formData.customerId"
                                :items="customers"
                                label="Customer"
                                item-value="id"
                                item-text="shortName"
                                :error-messages="errors"
                            ></v-autocomplete>
                        </ValidationProvider>
                        <ValidationProvider
                            v-if="!hideEta"
                            name="ETA date/time"
                            rules="required"
                            v-slot="{ errors }"
                        >
                            <DateTimePicker
                                v-model="formData.eta"
                                :error-messages="errors"
                                label="ETA date"
                            ></DateTimePicker>
                        </ValidationProvider>
                    </div>
                </v-form>
            </template>
            <template #footer
                ><v-spacer></v-spacer>
                <v-btn @click="$emit('input', false)" :disabled="loading"
                    >Cancel</v-btn
                >
                <v-btn
                    :disabled="invalid"
                    color="success"
                    :loading="loading"
                    @click="$emit('confirm', formData)"
                    >Complete</v-btn
                ></template
            >
        </DefaultDialog>
    </ValidationObserver>
</template>

<script setup lang="ts">
import { CustomerViewModel, CustomsOfficeViewModel } from '@/openapi';
import store from '@/store';
import { DataAction } from '@/store/dataModule';
import { ValidationObserver } from 'vee-validate';
import { computed, ref, watch } from 'vue';
import DefaultDialog from '@/components/dialogs/DefaultDialog.vue.html';

export interface CompletePreCheckDialogFormData {
    customerId: number | null;
    customsOfficeId: number | null;
    eta: string | null;
}

interface CompletePreCheckDialogProps {
    value?: boolean;
    loading: boolean;
    hideEta?: boolean;
}

const defaultFormValues: CompletePreCheckDialogFormData = {
    customerId: null,
    customsOfficeId: null,
    eta: null,
};

const props = defineProps<CompletePreCheckDialogProps>();

const observer = ref<InstanceType<typeof ValidationObserver>>();
const formData = ref<CompletePreCheckDialogFormData>({ ...defaultFormValues });

const customers = computed(
    () => store.getters.generalCustomers as CustomerViewModel[]
);
const customsOffices = computed(
    () => store.getters.customsOffices as CustomsOfficeViewModel[]
);

watch(
    () => props.value,
    (newValue: boolean, oldValue: boolean) => {
        if (!newValue) {
            formData.value = { ...defaultFormValues };
            observer.value?.reset();
        }
    }
);

const getCustomsOfficeName = (item: CustomsOfficeViewModel) => {
    return `${item.officeNumber} | ${item.name}`;
};

store.dispatch(DataAction.FetchCustomsOffices);
store.dispatch(DataAction.FetchGeneralCustomers);
</script>

<style scoped lang="scss">
.form-fields {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}
</style>
