<template>
    <Fragment>
        <ValidationObserver slim ref="observer" v-slot="{ invalid }">
            <DefaultDialog
                :value="value"
                :max-width="'900px'"
                @input="$emit('input', $event)"
            >
                <template v-slot:header> Search MRN </template>
                <template v-slot:content>
                    <v-form>
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <v-data-table
                                        :headers="headers"
                                        :items="items"
                                        :options="options"
                                        item-key="mrn"
                                        dense
                                        :search="search"
                                        class="elevation-1"
                                        :loading="loading"
                                    >
                                        <template v-slot:top>
                                            <div class="px-4 py-4">
                                                <v-text-field
                                                    v-model="search"
                                                    placeholder="Search"
                                                    append-icon="mdi-magnify"
                                                    :disabled="loading"
                                                ></v-text-field>
                                            </div>
                                        </template>
                                        <template
                                            v-slot:item.dateAdded="{ item }"
                                        >
                                            {{ item.dateAdded | formatDate }}
                                        </template>
                                        <template
                                            v-slot:item.isMrnConfirmed="{
                                                item,
                                            }"
                                        >
                                            <v-icon
                                                :color="
                                                    item.isMrnConfirmed
                                                        ? 'success'
                                                        : 'error'
                                                "
                                            >
                                                {{
                                                    item.isMrnConfirmed
                                                        ? 'mdi-check'
                                                        : 'mdi-close'
                                                }}</v-icon
                                            >
                                        </template>
                                        <template
                                            v-slot:item.actions="{ item }"
                                        >
                                            <v-btn
                                                color="primary"
                                                class="ma-2"
                                                small
                                                @click="selectMRN(item)"
                                                >Select
                                                <v-icon right
                                                    >mdi-eye</v-icon
                                                ></v-btn
                                            >
                                        </template>
                                    </v-data-table>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </template>
                <template v-slot:footer>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        :disabled="loading"
                        @click="$emit('input', false), $emit('close')"
                    >
                        Cancel
                    </v-btn>
                </template>
            </DefaultDialog>
        </ValidationObserver>
    </Fragment>
</template>

<script setup lang="ts">
import { emitError } from '@/event-bus';
import { MrnDto } from '@/openapi';
import { MrnApi } from '@/openapi';
import DefaultDialog from '@/components/dialogs/DefaultDialog.vue.html';
import { DataOptions, DataTableHeader } from 'vuetify';
import { computed, onMounted, ref } from 'vue';

interface ShowMrnDialogProps {
    value: boolean;
    preCheckConfirmed?: boolean;
    mrnConfirmed?: boolean;
    showConfirmedColumn?: boolean;
    onlyShowUncompleted?: boolean;
}

const mrnApi = new MrnApi(undefined, '');

const props = withDefaults(defineProps<ShowMrnDialogProps>(), {
    value: false,
    mrnConfirmed: undefined,
    onlyShowUncompleted: false,
    preCheckConfirmed: undefined,
    showConfirmedColumn: false,
});
const emits = defineEmits(['errorOccured', 'getMrn']);

const options = ref<DataOptions>({
    page: 1,
    itemsPerPage: 10,
    sortBy: ['dateAdded'],
    sortDesc: [true],
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
});
const items = ref<MrnDto[]>([]);

const loading = ref(true);
const search = ref('');

const getMrnData = async () => {
    try {
        const response = await mrnApi.getMrnData(
            props.mrnConfirmed,
            props.preCheckConfirmed,
            props.onlyShowUncompleted
        );
        items.value = response.data ?? [];
    } catch {
        emitError('Something went wrong while retrieving the data.');
    }
    loading.value = false;
};

const selectMRN = (mrn: MrnDto) => {
    emits('getMrn', mrn);
};

const headers = computed(() => {
    const headers: DataTableHeader[] = [
        { text: 'MRN number', value: 'mrn', sortable: true },
        { text: 'General customer', value: 'customer', sortable: true },
        { text: 'MRN date added', value: 'dateAdded', sortable: true },
    ];

    if (props.showConfirmedColumn) {
        headers.push({
            text: 'Confirmed',
            value: 'isMrnConfirmed',
            sortable: true,
        });
    }
    headers.push({
        text: '',
        value: 'actions',
        sortable: false,
        width: '6em',
    });

    return headers;
});

onMounted(async () => {
    await getMrnData();
});
</script>
