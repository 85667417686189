export function decodeImage(base64Image: string) {
    // Remove the "data:image/jpeg;base64," part from the base64Image string
    if (!base64Image) {
        return ''
    }
    const base64Data = base64Image.replace(
        /^data:image\/(png|jpeg|jpg);base64,/,
        ''
    )

    // Convert the Base64 data to binary
    const binaryData = atob(base64Data)

    // Create a Blob from the binary data
    const byteArray = new Uint8Array(binaryData.length)
    for (let i = 0; i < binaryData.length; i++) {
        byteArray[i] = binaryData.charCodeAt(i)
    }
    const blob = new Blob([byteArray], { type: 'image/jpeg' }) // Replace 'image/jpeg' with the appropriate MIME type

    // Create a URL for the Blob and set it as the image source
    return URL.createObjectURL(blob)
}
