import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class AddLtsNumberDialog extends Vue {
    @Prop({default: false})
    value!: Boolean;

    @Prop({default: false})
    isLoading!: Boolean;

    @Prop({default: 'Add LTS number'})
    title!: String

    @Prop({default: 'Confirm'})
    confirmButtonText!: String

    @Prop({default: 'Close'})
    closeButtonText!: String

    rules = {
        required: (value: string) =>
            (value && !!value.toString()) || 'Required'
    };

    valid = true;
    ltsNumber = '';
}