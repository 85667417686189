import { Component, Vue, Watch } from 'vue-property-decorator';
import { ToolbarItem } from '../models/ToolbarItem';

@Component({})
export default class LoggedIn extends Vue {
    drawer = false;
    title = '';

    extraToolbarButtons: ToolbarItem[] = [];

    @Watch('$route')
    onRouteChanged(to: string, from: string) {
        this.title = '';
        this.extraToolbarButtons = [];
    }

    onPageInfoReceived(title: string, toolbarButtons: ToolbarItem[]) {
        this.title = title;
        this.extraToolbarButtons = toolbarButtons;
    }
}