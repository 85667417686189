<template>
    <Fragment>
        <div v-bind="attrs">
            <div class="tiptap-vuetify-editor__toolbar">
                <span class="font-weight-bold">{{ label }}</span>
                <v-toolbar
                    v-if="!disabled"
                    :label="label"
                    dense
                    elevation="1"
                    class="mb-3"
                >
                    <editor-toolbar
                        v-for="btn in toolbarButtons"
                        :key="btn.tooltipText"
                        v-bind="btn"
                    />
                </v-toolbar>
            </div>
            <editor-content :editor="editor"></editor-content>
        </div>

        <LinkButtonDialog
            v-model="showEditorLinkButtonActionDialog"
            @confirm="onConfirmLinkButton"
        ></LinkButtonDialog>
    </Fragment>
</template>

<script lang="ts">
export default {
    inheritAttrs: false,
};
</script>

<script setup lang="ts">
import {
    computed,
    onBeforeUnmount,
    onMounted,
    ref,
    watch,
    getCurrentInstance,
} from 'vue';
import { Editor, EditorContent, EditorOptions } from '@tiptap/vue-2';
import StarterKit from '@tiptap/starter-kit';
import Color from '@tiptap/extension-color';
import Link from '@tiptap/extension-link';
import TextStyle from '@tiptap/extension-text-style';
import EditorToolbar from './EditorToolbar.vue.html';
import LinkButtonDialog, {
    LinkButtonFormData,
} from '../dialogs/editor/LinkButtonDialog.vue';
import { markPasteRule, PasteRuleMatch } from '@tiptap/core';
import { find } from 'linkifyjs';

interface TipTapProps {
    mediaIds?: number[] | null;
    value?: string | null;
    disabled?: boolean;
    label?: string;
}

const props = withDefaults(defineProps<TipTapProps>(), {
    mediaIds: () => [],
    value: '',
    disabled: false,
    label: '',
});
const emits = defineEmits(['input']);

const attrs = computed(() => {
    const instance = getCurrentInstance()!.proxy;
    const attrs = { ...instance.$attrs };
    attrs.class = instance.$vnode.data?.staticClass ?? '';
    return attrs;
});

const editor = ref<Editor>();
const showEditorLinkButtonActionDialog = ref(false);
const toolbarButtons = computed(() => {
    return editor.value
        ? [
              {
                  tooltipText: 'Bold',
                  icon: 'mdi-format-bold',
                  action: () =>
                      editor.value!.chain().focus().toggleBold().run(),
                  active: editor.value.isActive('bold'),
              },

              {
                  tooltipText: 'Italic',
                  icon: 'mdi-format-italic',
                  action: () =>
                      editor.value!.chain().focus().toggleItalic().run(),
                  active: editor.value.isActive('italic'),
              },
              {
                  tooltipText: 'Red',
                  class: 'toolbar--color-red',
                  icon: 'mdi-eyedropper',
                  action: () =>
                      editor.value!.chain().focus().setColor('#b71c1c ').run(),
                  active: editor.value.isActive('red'),
              },
              {
                  tooltipText: 'Green',
                  class: 'toolbar--color-green',
                  icon: 'mdi-eyedropper',
                  action: () =>
                      editor.value!.chain().focus().setColor('#4caf50 ').run(),
                  active: editor.value.isActive('green'),
              },
              {
                  tooltipText: 'Unset color',
                  icon: 'mdi-eyedropper-remove',
                  action: () =>
                      editor.value!.chain().focus().unsetColor().run(),
                  active: false,
              },
              {
                  tooltipText: 'Link',
                  icon: 'mdi-link',
                  action: () => {
                      const link = window.prompt('Enter the URL:');
                      if (link) {
                          editor
                              .value!.chain()
                              .focus()
                              .toggleLink({ href: link })
                              .run();
                      }
                  },
                  active: editor.value.isActive('green'),
              },
              {
                  tooltipText: 'Link button',
                  icon: 'mdi-button-cursor',
                  action: () => {
                      showEditorLinkButtonActionDialog.value = true;
                  },
                  active: editor.value.isActive('link'),
              },
          ]
        : [];
});

const onConfirmLinkButton = (eventData: LinkButtonFormData) => {
    showEditorLinkButtonActionDialog.value = false;
    editor
        .value!.chain()
        .focus()
        .insertContent(`{{LinkButton:${eventData.url}|${eventData.text}}}`)
        .run();
};

watch(
    () => props.disabled,
    (newValue: boolean, oldValue: boolean) => {
        editor.value?.setEditable(!newValue);
    }
);

watch(
    () => props.value,
    (newValue: string | null, oldValue: string | null) => {
        const isSame = editor.value?.getHTML() === newValue;
        if (isSame) {
            return;
        }
        editor.value?.commands.setContent(newValue, false);
    }
);

onMounted(() => {
    const configuredLink = Link.extend({
        addPasteRules() {
            return [
                markPasteRule({
                    find: (text) => {
                        return [];
                    },
                    type: this.type,
                    getAttributes: (match) => {
                        return {
                            href: match.data?.href,
                        };
                    },
                }),
            ];
        },
        inclusive: false,
    }).configure({
        autolink: false,
        linkOnPaste: false,
    });

    editor.value = new Editor({
        content:
            props.value != null
                ? props.value
                      .replace('<i', '</p><i')
                      .replace('">', '"><p>')
                      .replace('<p>', '')
                : '',
        extensions: [StarterKit, Color, TextStyle, configuredLink],
    } as EditorOptions);
    editor.value.on('update', () => {
        emits('input', editor.value?.getHTML() ?? '');
    });
});

onBeforeUnmount(() => {
    editor.value?.destroy();
});
</script>
