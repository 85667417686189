<template>
    <Fragment>
        <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="totalAmountOfItems"
            :options.sync="options"
            :footer-props="footerOptions"
            item-key="id"
            fixed-footer
            fixed-header
            dense
            height="calc(100vh - 223px)"
            class="elevation-1"
            :loading="loading"
        >
            <template #top>
                <div class="filters">
                    <v-text-field
                        v-model="filters.search"
                        placeholder="Search"
                        append-icon="mdi-magnify"
                        :disabled="loading"
                        class="filters__search"
                        @keyup.enter="onFilterChanged"
                        @blur="onFilterChanged"
                        v-refocus-on-enter
                    ></v-text-field>
                </div>
            </template>

            <template #item.actions="{ item }">
                <v-btn small color="primary" class="ma-2" @click="edit(item)"
                    ><v-icon left>mdi-pencil</v-icon>Edit</v-btn
                >
            </template>
        </v-data-table>

        <ValidationObserver slim v-slot="{ invalid }">
            <ConfirmDialog
                v-if="showDialog && currentItem"
                v-model="showDialog"
                title="Edit item"
                :is-loading="dialogLoading"
                :is-confirm-disabled="invalid"
                @cancel="currentItem = null"
                @confirm="onEditConfirmed"
            >
                <v-text-field
                    v-model.number="currentItem.maximumNumberOfJobsToAssign"
                    label="Maximum number of jobs"
                    :min="0"
                    type="number"
                ></v-text-field>

                <v-select
                    :value="selectedAllowedCustomers"
                    :items="customers"
                    item-value="id"
                    item-text="shortName"
                    label="Customers"
                    multiple
                    @change="setAllowedCustomers"
                ></v-select>
                <v-select
                    :value="selectedAllowedJobLevels"
                    :items="jobLevels"
                    item-value="id"
                    item-text="level"
                    label="Levels"
                    multiple
                    @change="setAllowedJobLevels"
                ></v-select>

                <v-select
                    :value="selectedAllowedOptionalJobLevels"
                    :items="optionalJobLevels"
                    item-value="id"
                    item-text="level"
                    label="Optional levels"
                    multiple
                    @change="setAllowedOptionalJobLevels"
                ></v-select>
            </ConfirmDialog>
        </ValidationObserver>
    </Fragment>
</template>

<script setup lang="ts">
import { emitErrorWithFallback } from '@/event-bus';
import {
    CustomerViewModel,
    EditableEmployeeCustomsCustomerItem,
    EditableEmployeeCustomsLevelItem,
    EmployeeCustomsApi,
    EmployeeCustomsItem,
} from '@/openapi';
import { FooterOptions } from '@/types/types';
import { computed, onBeforeMount, ref, watch } from 'vue';
import { DataOptions, DataTableHeader } from 'vuetify';
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue.html';
import { useSorting } from '@/composables/sort';
import { useDataStore } from '@/stores/data-store';

interface EmployeeCustomsFilter {
    search: string;
}

interface EditableEmployeeCustomsItem extends EmployeeCustomsItem {
    editableJobLevels: EditableEmployeeCustomsLevelItem[];
    editableOptionalJobLevels: EditableEmployeeCustomsLevelItem[];
    editableAllowedCustomers: EditableEmployeeCustomsCustomerItem[];
}

const api = new EmployeeCustomsApi(undefined, '');
const dataStore = useDataStore();

const headers = ref<DataTableHeader[]>([
    {
        text: 'Employee',
        value: 'name',
        align: 'start',
        width: 300,
        sortable: true,
    },
    {
        text: 'Maximum number of jobs',
        value: 'maximumNumberOfJobsToAssign',
        align: 'start',
        sortable: false,
    },
    {
        text: ' ',
        value: 'actions',
        align: 'end',
        width: '6em',
        sortable: false,
    },
]);

const items = ref<EditableEmployeeCustomsItem[]>([]);
const totalAmountOfItems = ref(0);
const loading = ref(false);

const currentItem = ref<EditableEmployeeCustomsItem | null>(null);
const showDialog = ref(false);
const dialogLoading = ref(false);

const options = ref<DataOptions>({
    page: 1,
    itemsPerPage: 50,
    sortBy: [],
    sortDesc: [],
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
});
const footerOptions = ref<FooterOptions>({
    showFirstLastPage: true,
    itemsPerPageOptions: [5, 25, 50, 100],
    disablePagination: false,
});

const filters = ref<EmployeeCustomsFilter>({
    search: '',
});

const { sortBy, sortDesc } = useSorting(options);

const edit = (item: EditableEmployeeCustomsItem) => {
    currentItem.value = item;
    showDialog.value = true;
};

const onEditConfirmed = async () => {
    dialogLoading.value = true;
    try {
        await api.editEmployeeCustomsItem({
            id: currentItem.value!.id,
            maximumNumberOfJobsToAssign:
                currentItem.value!.maximumNumberOfJobsToAssign,
            allowedLevels: currentItem.value!.editableJobLevels,
            allowedOptionalLevels: currentItem.value!.editableOptionalJobLevels,
            allowedCustomers: currentItem.value!.editableAllowedCustomers,
        });
        currentItem.value = null;
        showDialog.value = false;
        await getItems();
    } catch (e: unknown) {
        emitErrorWithFallback(
            e,
            'Something went wrong while saving the changes'
        );
    }
    dialogLoading.value = false;
};

const getItems = async (page?: number) => {
    page ??= options.value.page;
    loading.value = true;

    try {
        const response = await api.getEmployeeCustomsItems(
            filters.value.search,
            sortBy.value,
            sortDesc.value,
            page,
            options.value.itemsPerPage
        );
        items.value =
            response.data.items?.map((c) => {
                const editableLevels: EditableEmployeeCustomsLevelItem[] = [];
                const editableOptionalLevels: EditableEmployeeCustomsLevelItem[] =
                    [];

                for (const level of c.allowedLevels ?? []) {
                    if (level.isOptional) {
                        editableOptionalLevels.push({
                            id: level.id,
                            levelId: level.levelId,
                        });
                    } else {
                        editableLevels.push({
                            id: level.id,
                            levelId: level.levelId,
                        });
                    }
                }

                return {
                    ...c,
                    editableAllowedCustomers: c?.allowedCustomers?.map(
                        (ac) => ({
                            id: ac.id,
                            customerId: ac.customerId,
                        })
                    ),
                    editableJobLevels: editableLevels,
                    editableOptionalJobLevels: editableOptionalLevels,
                } as EditableEmployeeCustomsItem;
            }) ?? [];
        totalAmountOfItems.value = response.data.totalAmountOfItems ?? 0;
    } catch (e: unknown) {
        emitErrorWithFallback(
            e,
            'Something went wrong while retrieving the items.'
        );
    }

    loading.value = false;
};

const onFilterChanged = async () => {
    await getItems(1);
};

const setAllowedJobLevels = (jobLevelIds: number[]) => {
    currentItem.value!.editableJobLevels = jobLevelIds.map((c) => ({
        id: currentItem.value?.allowedLevels?.find(
            (al) => al.levelId == c && !al.isOptional
        )?.id,
        levelId: c,
    }));
};

const setAllowedOptionalJobLevels = (jobLevelIds: number[]) => {
    currentItem.value!.editableOptionalJobLevels = jobLevelIds.map((c) => ({
        id: currentItem.value?.allowedLevels?.find(
            (al) => al.levelId == c && al.isOptional
        )?.id,
        levelId: c,
    }));
};

const setAllowedCustomers = (customerIds: number[]) => {
    currentItem.value!.editableAllowedCustomers = customerIds.map((c) => ({
        id: currentItem.value?.allowedCustomers?.find(
            (ac) => ac.customerId == c
        )?.id,
        customerId: c,
    }));
};

const jobLevels = computed(() => {
    return dataStore.nonHiddenJobLevels;
});

const optionalJobLevels = computed(() => {
    return dataStore.optionalJobLevels;
});

const customers = computed(() => {
    return dataStore.generalCustomers;
});

const selectedAllowedJobLevels = computed(() => {
    return jobLevels.value.filter((c) =>
        currentItem.value?.editableJobLevels?.some((d) => d.levelId == c.id)
    );
});

const selectedAllowedOptionalJobLevels = computed(() => {
    return jobLevels.value.filter((c) =>
        currentItem.value?.editableOptionalJobLevels?.some(
            (d) => d.levelId == c.id
        )
    );
});

const selectedAllowedCustomers = computed(() => {
    return customers.value.filter((c: CustomerViewModel) =>
        currentItem.value?.editableAllowedCustomers?.some(
            (d) => d.customerId == c.id
        )
    );
});

let timeoutDelay = 0;
watch(options, (newValue: DataOptions, oldValue: DataOptions) => {
    clearTimeout(timeoutDelay);
    timeoutDelay = setTimeout(async () => {
        await getItems();
    }, 250);
});

onBeforeMount(async () => {
    await Promise.all([
        dataStore.fetchJobLevels(),
        dataStore.fetchGeneralCustomers(),
    ]);
});
</script>

<style scoped lang="scss">
.filters {
    display: flex;
    justify-content: start;
    gap: 15px;
    padding: 15px;

    &__search {
        max-width: 500px;
    }
}
</style>
