<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <v-tabs
                    v-model="currentTab"
                    background-color="grey darken-3"
                    center-active
                    dark
                >
                    <v-tab>Overview Jobs Distribution</v-tab>
                    <v-tab>User Access Levels</v-tab>
                    <v-tab>User Job Status</v-tab>
                </v-tabs>
                <v-tabs-items v-model="currentTab">
                    <v-tab-item>
                        <JobDistributionDashboard
                            v-if="currentTab === 0"
                        ></JobDistributionDashboard>
                    </v-tab-item>
                    <v-tab-item>
                        <EmployeeLevelDashboard
                            v-if="currentTab === 1"
                        ></EmployeeLevelDashboard>
                    </v-tab-item>
                    <v-tab-item>
                        <EmployeeJobStatusDashboard
                            v-if="currentTab === 2"
                        ></EmployeeJobStatusDashboard
                    ></v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
import JobDistributionDashboard from '@/components/dashboards/JobDistributionDashboard.vue';
import EmployeeLevelDashboard from '@/components/dashboards/EmployeeLevelDashboard.vue';
import EmployeeJobStatusDashboard from '@/components/dashboards/EmployeeJobStatusDashboard.vue';
import { ref } from 'vue';

const currentTab = ref(0);
</script>
