<template>
    <DefaultDialog v-model="value">
        <template #header
            ><slot name="header">Find shipment by HAWB</slot></template
        >
        <template #content>
            <p>
                Enter the HAWB in the field below and press 'ENTER' to search
                for the corresponding shipment.
            </p>
            <v-text-field
                v-model="hawbSearch"
                @keydown.enter="searchHawbs"
                label="HAWB searcher"
                placeholder="Enter the HAWB number"
                :loading="loadingHawbItems"
                clearable
            ></v-text-field>
            <template v-if="hawbItems.length">
                <v-radio-group v-model="selected" column hide-details>
                    <v-radio
                        v-for="hawbItem in hawbItems"
                        :label="hawbItem.value"
                        color="primary"
                        :value="hawbItem"
                        :disabled="loadingHawbItems"
                    ></v-radio>
                </v-radio-group>
            </template>
            <div v-else-if="!firstSearch">No results found.</div>
        </template>
        <template #footer>
            <v-spacer></v-spacer>
            <v-btn @click="$emit('input', false)" :disabled="isLoading">{{
                cancelButtonText
            }}</v-btn>
            <v-btn
                :disabled="selected == null"
                color="success"
                :loading="isLoading"
                @click="$emit('confirm', selected)"
                >{{ confirmButtonText }}</v-btn
            >
        </template>
    </DefaultDialog>
</template>
<script setup lang="ts">
import { emitError } from '@/event-bus';
import { DutiesAndTaxesCheckUpApi, Int32StringKeyValueItem } from '@/openapi';
import DefaultDialog from '@/components/dialogs/DefaultDialog.vue.html';
import { ref, watch } from 'vue';

const api = new DutiesAndTaxesCheckUpApi(undefined, '');

interface ConnectToExistingShipmentDialogProps {
    value: boolean;
    cancelButtonText?: string;
    confirmButtonText?: string;
    isLoading?: boolean; // Decides the load state of the 'confirm' button
}
const props = withDefaults(
    defineProps<ConnectToExistingShipmentDialogProps>(),
    {
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Confirm',
        isLoading: false,
    }
);
const emits = defineEmits(['input', 'confirm']);

const hawbSearch = ref('');
const firstSearch = ref(true);
const selected = ref<Int32StringKeyValueItem | null>(null);
const hawbItems = ref<Int32StringKeyValueItem[]>([]);
const loadingHawbItems = ref(false);

watch(
    () => props.value,
    (newValue: boolean, oldVal: boolean) => {
        if (newValue == false) {
            hawbSearch.value = '';
            firstSearch.value = false;
            selected.value = null;
            hawbItems.value = [];
        }
    }
);

async function searchHawbs() {
    firstSearch.value = false;
    selected.value = null;
    loadingHawbItems.value = true;
    try {
        const response = await api.getShipmentsByHawb(hawbSearch.value);
        hawbItems.value = response.data;

        if (hawbItems.value.length === 1) {
            selected.value = hawbItems.value[0];
        }
    } catch {
        emitError('Something went wrong while search for the given HAWB.');
    }
    loadingHawbItems.value = false;
}
</script>
