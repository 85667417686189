

import axios, { AxiosPromise, AxiosTransformer } from 'axios';

let ISO_8601 = /^(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2}:\d{2})$/;
let transformDate = function (data: any) {
    if(data === undefined || data === null)
    {
        return null;
    }

    Object.keys(data).forEach(function (k) {
        if(data[k] instanceof Object) {
            transformDate(data[k]);
        } else {
            if (ISO_8601.test(data[k])) {
                data[k] = new Date(Date.parse(data[k]));
            }
        }
    });
    return data;
};
let transformResponse = (axios.defaults.transformResponse as AxiosTransformer[]).concat(transformDate)

import { ClaimViewModel } from './viewmodels/ClaimViewModel'


        
    // ListClaims
    export function listClaims () : AxiosPromise<ClaimViewModel[]> {
        return axios({
            url: `api/CustomsClaim/listClaims/`, 
            method: "get",
            data: null,
            transformResponse: transformResponse,
            responseType: "json"
        });
    };    
    // GetClaimById
    export function getClaimById (claimId: number) : AxiosPromise<ClaimViewModel> {
        return axios({
            url: `api/CustomsClaim/getClaimById/?claimId=${claimId}`, 
            method: "get",
            data: null,
            transformResponse: transformResponse,
            responseType: "json"
        });
    };    
    // GetInvoiceData
    export function getInvoiceData (input: string) : AxiosPromise<ClaimViewModel> {
        return axios({
            url: `api/CustomsClaim/getInvoiceData/?input=${encodeURIComponent(input)}`, 
            method: "get",
            data: null,
            transformResponse: transformResponse,
            responseType: "json"
        });
    };    
    // AddClaim
    export function addClaim (invoiceId: number, categoryName: string) : AxiosPromise<void> {
        return axios({
            url: `api/CustomsClaim/addClaim/?invoiceId=${invoiceId}&categoryName=${encodeURIComponent(categoryName)}`, 
            method: "post",
            data: null,
            transformResponse: transformResponse,
            responseType: "json"
        });
    };    
    // DownloadFile
    export function downloadFile (invoicePdfId: number) : AxiosPromise<any> {
        return axios({
            url: `api/CustomsClaim/downloadFile/?invoicePdfId=${invoicePdfId}`, 
            method: "get",
            data: null,
            transformResponse: transformResponse,
            responseType: "json"
        });
    };

