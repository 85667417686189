import { Component, Prop, Vue } from 'vue-property-decorator';
import { emitError } from '@/event-bus';
import { CustomsCheckApi } from '@/openapi';

const customsCheckApi = new CustomsCheckApi(undefined, '');

interface Successful {
    success: boolean;
}

@Component({})
export default class ConfirmShipmentButton extends Vue {
    @Prop()
    shipmentId!: number;

    loading = false;

    async confirmShipment() {
        this.loading = true;
        let success = false;

        try {
            await customsCheckApi.confirmShipments([this.shipmentId]);
            success = true;
        } catch {
            emitError(
                'Something went wrong while trying to confirm the shipment.'
            );
        }

        this.$emit('confirmedShipment', { success } as Successful);
        this.loading = false;
    }
}
