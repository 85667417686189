<template>
    <Fragment>
        <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="totalAmountOfItems"
            :options.sync="options"
            :footer-props="footerOptions"
            item-key="id"
            fixed-footer
            fixed-header
            dense
            height="calc(100vh - 280px)"
            class="elevation-1"
            :loading="loading"
        >
            <template #top>
                <div class="filters">
                    <v-text-field
                        v-model="filters.search"
                        placeholder="Search"
                        @keyup.enter="onFilterChanged"
                        append-icon="mdi-magnify"
                        :disabled="loading"
                    ></v-text-field>

                    <v-autocomplete
                        v-model="filters.customerId"
                        :items="dataStore.generalCustomers"
                        item-value="id"
                        item-text="shortName"
                        label="General customer"
                        clearable
                        @change="onFilterChanged"
                    ></v-autocomplete>

                    <v-select
                        v-model="filters.incotermId"
                        :items="dataStore.incoterms"
                        item-value="id"
                        item-text="name"
                        label="Incoterm"
                        @change="onFilterChanged"
                        clearable
                    ></v-select>

                    <v-autocomplete
                        v-model="filters.assignedEmployeeId"
                        :items="dataStore.employees"
                        item-value="id"
                        item-text="name"
                        label="Assigned employee"
                        clearable
                        @change="onFilterChanged"
                    ></v-autocomplete>

                    <v-autocomplete
                        v-model="filters.finalisedEmployeeId"
                        :items="dataStore.employees"
                        item-value="id"
                        item-text="name"
                        label="Finalised employee"
                        clearable
                        @change="onFilterChanged"
                    ></v-autocomplete>

                    <DatePicker
                        v-model="filters.assignedeDate"
                        label="Assigned date"
                        @input="onFilterChanged"
                    ></DatePicker>

                    <v-select
                        v-model="filters.level"
                        :items="dataStore.jobLevels"
                        item-value="level"
                        item-text="level"
                        label="Job level"
                        @change="onFilterChanged"
                        clearable
                    ></v-select>

                    <IndeterminateCheckbox
                        v-model="filters.isHandled"
                        :label="'Is handled?'"
                        @change="onFilterChanged"
                    ></IndeterminateCheckbox>
                </div>
            </template>
            <template #item.jobAssignmentDate="{ item }">{{
                item.jobAssignmentDate | formatDate
            }}</template>
            <template #item.jobFinalisedDate="{ item }">{{
                item.jobFinalisedDate | formatDate
            }}</template>
            <template #item.isJobHandled="{ item }"
                ><v-icon :color="item.isJobHandled ? 'success' : 'error'"
                    >{{ item.isJobHandled ? 'mdi-check' : 'mdi-close' }}
                </v-icon></template
            >
        </v-data-table>
    </Fragment>
</template>
<script setup lang="ts">
import { emitError } from '@/event-bus';
import { ToolbarItem } from '@/models/ToolbarItem';
import { DistributedImportJobsApi } from '@/openapi';
import { FooterOptions } from '@/types/types';
import { onBeforeMount, ref, watch } from 'vue';
import { DataOptions, DataTableHeader } from 'vuetify';
import DatePicker from '@/components/editors/DatePicker.vue.html';
import IndeterminateCheckbox from '@/components/editors/IndeterminateCheckbox.vue';
import { useSorting } from '@/composables/sort';
import { useDataStore } from '@/stores/data-store';

interface DistributedImportJobsFilters {
    search: string;
    customerId: number | null;
    incotermId: number | null;
    assignedEmployeeId: number | null;
    assignedeDate: string | null;
    isHandled: boolean | null;
    level: number | null;
    finalisedEmployeeId: number | null;
}

const distributedImportJobsApi = new DistributedImportJobsApi(undefined, '');
const emits = defineEmits(['PageInfoReceived']);
const dataStore = useDataStore();

const headers = ref<DataTableHeader[]>([
    {
        text: 'General customer',
        value: 'generalCustomer',
        align: 'start',
        width: 300,
        sortable: false,
    },
    {
        text: 'HAWB',
        value: 'hawbNumber',
        sortable: false,
    },
    { text: 'Incoterm', value: 'incoterm', sortable: false },
    {
        text: 'Assigned employee',
        value: 'assignedEmployee',
        sortable: false,
    },
    {
        text: 'Assigned date',
        value: 'jobAssignmentDate',
        sortable: true,
    },
    {
        text: 'Job handled?',
        value: 'isJobHandled',
        sortable: false,
    },
    {
        text: 'Job level',
        value: 'jobLevel',
        sortable: false,
    },
    {
        text: 'Finalised date',
        value: 'jobFinalisedDate',
        sortable: true,
    },
    {
        text: 'Finalised by',
        value: 'finalisedEmployee',
        sortable: false,
    },
]);
const options = ref<DataOptions>({
    page: 1,
    itemsPerPage: 50,
    sortBy: [],
    sortDesc: [],
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
});
const footerOptions = ref<FooterOptions>({
    showFirstLastPage: true,
    itemsPerPageOptions: [5, 25, 50, 100],
    disablePagination: false,
});

const filters = ref<DistributedImportJobsFilters>({
    search: '',
    customerId: null,
    incotermId: null,
    assignedeDate: null,
    assignedEmployeeId: null,
    finalisedEmployeeId: null,
    isHandled: null,
    level: null,
});

const { sortBy, sortDesc } = useSorting(options);

const items = ref<any[]>([]);
const totalAmountOfItems = ref(0);
const loading = ref(false);

const onFilterChanged = async () => {
    if (options.value.page === 1) {
        await getJobs();
    } else {
        options.value.page = 1;
    }
};

const refresh = async () => {
    await getJobs();
};

const getJobs = async (page?: number) => {
    page ??= options.value.page;

    loading.value = true;
    try {
        const response =
            await distributedImportJobsApi.getDistributedImportJobs(
                filters.value.search,
                filters.value.customerId ?? undefined,
                filters.value.incotermId ?? undefined,
                filters.value.assignedEmployeeId ?? undefined,
                filters.value.assignedeDate ?? undefined,
                filters.value?.isHandled ?? undefined,
                filters.value.level ?? undefined,
                filters.value.finalisedEmployeeId ?? undefined,
                sortBy.value,
                sortDesc.value,
                page,
                options.value.itemsPerPage
            );
        items.value = response.data.items ?? [];
        totalAmountOfItems.value = response.data.totalAmountOfItems ?? 0;
    } catch {
        emitError('Something went while retrieving the jobs');
    }
    loading.value = false;
};

watch(
    () => options.value,
    async (newValue, oldValue) => {
        await getJobs();
    },
    { deep: true }
);

onBeforeMount(async () => {
    var toolbarButtons: ToolbarItem[] = [
        {
            callback: () => refresh(),
            icon: 'mdi-refresh',
            tooltipText: 'Refresh overview',
        },
    ];
    emits('PageInfoReceived', 'Distributed import jobs', toolbarButtons);

    await Promise.all([
        dataStore.fetchGeneralCustomers(),
        dataStore.fetchEmployees(),
        dataStore.fetchIncoterms(),
        dataStore.fetchJobLevels(),
    ]);
});
</script>

<style scoped lang="scss">
.filters {
    display: flex;
    justify-content: center;
    gap: 15px;
    padding: 15px;
}
</style>
