<template>
    <div>
        <div class="d-flex justify-space-between">
            <h1>Master template</h1>
            <v-btn @click="openDialogNewTemplate" color="success" elevation="2"
                ><v-icon color="white">mdi-plus</v-icon> Create template</v-btn
            >
        </div>
        <p>Select existing template or create a new one.</p>

        <v-select
            :items="emailTemplates"
            v-model="selectedEmailTemplateId"
            label="Select existing template"
            item-text="name"
            item-value="id"
            @change="getEmailTemplate"
            solo
        ></v-select>
        <NewEmailTemplateDialog
            v-model="showDialogNewTemplate"
            title="Create new master template"
            :loading="dialogNewTemplateLoading"
            @confirm="createTemplate"
        ></NewEmailTemplateDialog>
        <ConfirmDialog
            v-model="showDialogDeleteTemplate"
            title="Delete master template"
            :is-loading="dialogDeleteTemplateLoading"
            @confirm="deleteTemplate"
        >
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <p>
                            Are you sure you want to delete this master
                            template?
                        </p>
                    </v-col>
                </v-row>
            </v-container>
        </ConfirmDialog>

        <p v-if="!selectedEmailTemplateId">
            <strong>Please create or select an existing template.</strong>
        </p>
        <div
            v-else-if="selectedEmailTemplate"
            class="v-card v-sheet theme--light grey darken-3 mb-5"
        >
            <ValidationObserver v-slot="{ invalid }" ref="observerSelected">
                <div class="d-flex justify-space-between">
                    <div class="v-card__title" v-if="!canEditTemplateName">
                        <b class="mr-2">{{ selectedEmailTemplate.name }}</b>
                        E-mail template
                        <v-icon
                            @click="editTemplateName"
                            class="ml-2"
                            color="grey darken-3"
                            >mdi-pencil</v-icon
                        >
                    </div>
                    <div class="v-card__title" v-else>
                        <ValidationProvider
                            name="New name"
                            rules="required|max:100"
                            v-slot="{ errors }"
                        >
                            <v-text-field
                                v-model="selectedEmailTemplate.name"
                                class="mr-2"
                                label="New name"
                                :counter="100"
                                :error-messages="errors"
                            ></v-text-field>
                        </ValidationProvider>
                        E-mail template
                    </div>
                    <div class="mt-4">
                        <v-btn
                            class="mr-4"
                            @click="openDialogDeleteTemplate"
                            color="error"
                            elevation="2"
                            ><v-icon small color="white"
                                >mdi-trash-can-outline</v-icon
                            >
                            Delete template</v-btn
                        >
                        <v-btn
                            class="mr-4"
                            @click="saveTemplate"
                            :disabled="invalid"
                            color="success"
                            elevation="2"
                            ><v-icon small color="white">mdi-floppy</v-icon>
                            Save template</v-btn
                        >
                    </div>
                </div>

                <div class="v-card__text">
                    <div class="row">
                        <v-col cols="12" xl="6">
                            <ValidationProvider
                                name="Subject"
                                rules="max:50"
                                v-slot="{ errors }"
                            >
                                <v-text-field
                                    dense
                                    outlined
                                    v-model="selectedEmailTemplate.subject"
                                    label="Subject"
                                    :counter="50"
                                    :error-messages="errors"
                                ></v-text-field>
                            </ValidationProvider>
                        </v-col>
                        <v-col cols="12" xl="6">
                            <ValidationProvider
                                name="Sender"
                                rules="email|max:50"
                                v-slot="{ errors }"
                            >
                                <v-text-field
                                    dense
                                    outlined
                                    v-model="selectedEmailTemplate.sender"
                                    label="Sender"
                                    prepend-icon="mdi-account-arrow-right-outline"
                                    :counter="50"
                                    :error-messages="errors"
                                ></v-text-field>
                            </ValidationProvider>
                        </v-col>
                        <v-col cols="12" xl="6">
                            <ValidationProvider
                                name="SMS Sender"
                                :rules="{ regex: /^[a-zA-Z0-9]{1,11}$/ }"
                                v-slot="{ errors }"
                            >
                                <v-text-field
                                    dense
                                    outlined
                                    v-model="selectedEmailTemplate.smsSender"
                                    label="SMS Sender"
                                    prepend-icon="mdi-cellphone-text"
                                    :error-messages="errors"
                                ></v-text-field>
                            </ValidationProvider>
                        </v-col>
                        <v-col cols="12" xl="6">
                            <ValidationProvider
                                name="SMS Text"
                                :rules="{ max: 320 }"
                                v-slot="{ errors }"
                            >
                                <v-textarea
                                    dense
                                    outlined
                                    v-model="selectedEmailTemplate.smsText"
                                    label="SMS Text"
                                    prepend-icon="mdi-cellphone-text"
                                    :error-messages="errors"
                                    counter="320"
                                ></v-textarea>
                            </ValidationProvider>
                        </v-col>
                        <div class="col col-12">
                            <v-file-input
                                v-if="!selectedEmailTemplate.logoId"
                                v-model="selectedEmailTemplate.logoFile"
                                color="primary accent-4"
                                counter
                                label="Logo 1"
                                placeholder="Select your image"
                                prepend-icon="mdi-paperclip"
                                outlined
                                :show-size="1000"
                                @change="onChangeLogo"
                            >
                            </v-file-input>
                            <div class="existing-file" v-else>
                                <v-text-field
                                    :value="selectedEmailTemplate.logoName"
                                    :label="'Logo 1'"
                                    outlined
                                    disabled
                                    hide-details
                                    prepend-icon="mdi-paperclip"
                                ></v-text-field>
                                <v-icon
                                    class="existing-file__remove"
                                    color="error"
                                    @click="removeExistingLogo"
                                    >mdi-close</v-icon
                                >
                            </div>
                        </div>
                        <div class="col col-12">
                            <v-file-input
                                v-if="!selectedEmailTemplate.logoTwoId"
                                v-model="selectedEmailTemplate.logoTwoFile"
                                color="primary accent-4"
                                counter
                                label="Logo 2"
                                placeholder="Select your image"
                                prepend-icon="mdi-paperclip"
                                outlined
                                :show-size="1000"
                                @change="onChangeLogoTwo"
                            >
                            </v-file-input>
                            <div class="existing-file" v-else>
                                <v-text-field
                                    :value="selectedEmailTemplate.logoTwoName"
                                    :label="'Logo 2'"
                                    outlined
                                    disabled
                                    hide-details
                                    prepend-icon="mdi-paperclip"
                                ></v-text-field>
                                <v-icon
                                    class="existing-file__remove"
                                    color="error"
                                    @click="removeExistingLogoTwo"
                                    >mdi-close</v-icon
                                >
                            </div>
                        </div>
                        <div class="col col-12">
                            <text-editor
                                v-model="selectedEmailTemplate.bodyText"
                                label="Body"
                                class="mb-5"
                            ></text-editor>
                            <text-editor
                                v-model="
                                    selectedEmailTemplate.legalInformationText
                                "
                                label="Legal information"
                                class="mb-5"
                            ></text-editor>
                            <text-editor
                                v-model="selectedEmailTemplate.footerText"
                                label="Footer"
                                class="mb-5"
                            ></text-editor>
                        </div>

                        <v-col cols="12">
                            <span class="d-inline-block font-weight-bold mb-2"
                                >Plain text body</span
                            >
                            <v-textarea
                                v-model="selectedEmailTemplate.plainTextBody"
                                dense
                                outlined
                                label="Plain text body"
                            ></v-textarea>
                        </v-col>
                    </div>
                </div>
            </ValidationObserver>
        </div>
    </div>
</template>

<script setup lang="ts">
import TextEditor from '@/components/editors/Tiptap.vue';
import { EmailTemplateApi } from '@/openapi';
import { emitError, emitErrorWithFallback, emitSuccess } from '@/event-bus';
import { ValidationObserver } from 'vee-validate';
import { decodeImage } from '@/helpers/decodeImageHelper';
import { onBeforeMount, ref, watch } from 'vue';
import NewEmailTemplateDialog, {
    NewEmailTemplateFormData,
} from './NewEmailTemplateDialog.vue';
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue.html';
import { useEmailTemplate } from '@/composables/emailTemplate';

interface MasterTemplatesProps {
    currentTab: number | null;
}

const api = new EmailTemplateApi(undefined, '');

const props = defineProps<MasterTemplatesProps>();
const emits = defineEmits([
    'getLoadingStatus',
    'getSelectedEmailTemplate',
    'pushSuccessMessage',
]);

const {
    dialogDeleteTemplateLoading,
    dialogNewTemplateLoading,
    emailTemplates,
    showDialogDeleteTemplate,
    showDialogNewTemplate,
    canEditTemplateName,
    selectedEmailTemplate,
    selectedEmailTemplateId,
    setSelectedEmailTemplateValues,
    setSelectedEmailTemplateId,
    clearSelectedEmailTemplate,
    openDialogDeleteTemplate,
    openDialogNewTemplate,
    updateEmailTemplateInCollection,
    editTemplateName,
    removeExistingLogo,
    removeExistingLogoTwo,
} = useEmailTemplate();

const onChangeLogo = async () => {
    if (!selectedEmailTemplate.value) {
        return;
    }
    selectedEmailTemplate.value.logoId = null;
    selectedEmailTemplate.value.logoPreview =
        selectedEmailTemplate.value.logoFile != null
            ? URL.createObjectURL(selectedEmailTemplate.value.logoFile)
            : '';
    emits('getSelectedEmailTemplate', selectedEmailTemplate.value);
};

const onChangeLogoTwo = async () => {
    if (!selectedEmailTemplate.value) {
        return;
    }
    let masterTemplateImage = '';
    selectedEmailTemplate.value.logoTwoId = null;

    selectedEmailTemplate.value.logoTwoPreview =
        selectedEmailTemplate.value.logoTwoFile != null
            ? URL.createObjectURL(selectedEmailTemplate.value.logoTwoFile)
            : decodeImage(masterTemplateImage);
};

const getLoadingStatus = (status: boolean) => {
    emits('getLoadingStatus', status);
};

const getEmailTemplate = async (id: number) => {
    getLoadingStatus(true);
    try {
        const response = await api.getEmailTemplate(id);
        setSelectedEmailTemplateValues(response.data);
        emits('getSelectedEmailTemplate', selectedEmailTemplate.value);
        getLoadingStatus(false);
    } catch {
        emitError('Something went wrong while retrieving the email template');
    }
};

const setSelectedEmailTemplate = () => {
    if (props.currentTab !== 0) {
        return;
    }
    const emailTemplateId = setSelectedEmailTemplateId();
    if (emailTemplateId) {
        getEmailTemplate(emailTemplateId);
    } else {
        clearSelectedEmailTemplate();
        emits('getSelectedEmailTemplate', selectedEmailTemplate.value);
    }
};

const getEmailTemplates = async () => {
    getLoadingStatus(true);
    try {
        const response = await api.getEmailTemplates(undefined, true);
        emailTemplates.value = response.data.emailTemplates ?? [];
        getLoadingStatus(false);
        setSelectedEmailTemplate();
    } catch (e) {
        emitError('Something went wrong while retrieving the email templates');
    }
};

const createTemplate = async (eventData: NewEmailTemplateFormData) => {
    getLoadingStatus(true);
    dialogNewTemplateLoading.value = true;
    try {
        const response = await api.saveEmailTemplate(
            undefined,
            undefined,
            undefined,
            eventData.templateName,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            '',
            '',
            '',
            '',
            '',
            '',
            true
        );
        getEmailTemplate(response.data.id!);
        emits('pushSuccessMessage');
        emailTemplates.value.push(response.data);
        showDialogNewTemplate.value = false;
    } catch (e) {
        emitError('Something went wrong while creating the template');
    }
    getLoadingStatus(false);
    dialogNewTemplateLoading.value = false;
};

const saveTemplate = async () => {
    if (!selectedEmailTemplate.value) {
        return;
    }
    getLoadingStatus(true);

    try {
        await api.saveEmailTemplate(
            selectedEmailTemplate.value.id,
            undefined,
            undefined,
            selectedEmailTemplate.value.name ?? undefined,
            selectedEmailTemplate.value.plainTextBody ?? undefined,
            undefined,
            selectedEmailTemplate.value.logoId ?? undefined,
            selectedEmailTemplate.value.logoFile ?? undefined,
            selectedEmailTemplate.value.logoTwoId ?? undefined,
            selectedEmailTemplate.value.logoTwoFile ?? undefined,
            selectedEmailTemplate.value.bodyText ?? undefined,
            selectedEmailTemplate.value.footerText ?? undefined,
            selectedEmailTemplate.value.legalInformationText ?? undefined,
            selectedEmailTemplate.value.subject ?? undefined,
            selectedEmailTemplate.value.sender ?? undefined,
            selectedEmailTemplate.value.smsSender ?? undefined,
            selectedEmailTemplate.value.smsText ?? undefined,
            selectedEmailTemplate.value.isMasterTemplate!
        );
        emitSuccess('Successfully saved the email template');
        await getEmailTemplate(selectedEmailTemplate.value.id!);
        updateEmailTemplateInCollection(selectedEmailTemplate.value);
        canEditTemplateName.value = false;
    } catch (e) {
        emitError('Something went wrong saving the email template');
    }
    getLoadingStatus(false);
};

const deleteTemplate = async () => {
    getLoadingStatus(true);
    dialogDeleteTemplateLoading.value = true;
    try {
        await api.deleteEmailTemplate(selectedEmailTemplate.value?.id!);
        emitSuccess('Successfully deleted the email template');
        showDialogDeleteTemplate.value = false;
        clearSelectedEmailTemplate();
        await getEmailTemplates();
    } catch (e: unknown) {
        emitErrorWithFallback(
            e,
            'Something went wrong while deleting the email template'
        );
    }
    dialogDeleteTemplateLoading.value = false;
    getLoadingStatus(false);
};

watch(() => props.currentTab, setSelectedEmailTemplate);

onBeforeMount(async () => {
    await getEmailTemplates();
});
</script>

<style scoped lang="scss">
.existing-file {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 20px 0 22px;
    gap: 10px;

    &__name {
        margin-left: 35px;
        font-weight: 600;
    }
}
</style>
