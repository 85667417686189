import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class DatePicker extends Vue {
    @Prop({ required: true })
    value!: string | string[] | null;

    @Prop({ default: 'Date' })
    label!: string;

    @Prop({ default: () => [] })
    errorMessages!: string[];

    @Prop({ default: false })
    range!: boolean;

    @Prop({
        default: 'date',
        validator: (value: string) => ['date', 'month'].includes(value),
    })
    type!: string;

    get dateDisplayText() {
        if (!this.value) {
            return '';
        }

        return Array.isArray(this.value)
            ? this.value
                  ?.map((c) => this.formatDate(c.split('T')[0]))
                  .join(' - ')
            : this.formatDate(this.value.split('T')[0]);
    }

    get dateValue() {
        if (!this.value) {
            return this.value;
        }
        return Array.isArray(this.value)
            ? this.value?.map((c) => c.split('T')[0])
            : this.value?.split('T')[0];
    }

    set dateValue(value) {
        this.$emit('input', value);
    }

    formatDate(dateText: string) {
        if (!dateText) {
            return '';
        }
        const date = new Date(dateText);
        const day = `${date.getDate()}`.padStart(2, '0');
        const month = `${date.getMonth() + 1}`.padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    }
}
