<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <v-tabs
                    v-model="currentTab"
                    background-color="grey darken-3"
                    center-active
                    dark
                >
                    <v-tab>Backlog</v-tab>
                    <v-tab>Distributed jobs</v-tab>
                </v-tabs>
                <v-tabs-items v-model="currentTab">
                    <v-tab-item>
                        <DistributedImportJobsBacklog
                            v-if="currentTab == 0"
                        ></DistributedImportJobsBacklog>
                    </v-tab-item>
                    <v-tab-item>
                        <DistributedImportJobs
                            v-if="currentTab == 1"
                        ></DistributedImportJobs>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
import DistributedImportJobs from '@/components/admin/DistributedImportJobs.vue';
import DistributedImportJobsBacklog from '@/components/admin/DistributedImportJobsBacklog.vue';
import { ref } from 'vue';

const currentTab = ref(0);
</script>
