

import axios, { AxiosPromise, AxiosTransformer } from 'axios';

let ISO_8601 = /^(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2}:\d{2})$/;
let transformDate = function (data: any) {
    if(data === undefined || data === null)
    {
        return null;
    }

    Object.keys(data).forEach(function (k) {
        if(data[k] instanceof Object) {
            transformDate(data[k]);
        } else {
            if (ISO_8601.test(data[k])) {
                data[k] = new Date(Date.parse(data[k]));
            }
        }
    });
    return data;
};
let transformResponse = (axios.defaults.transformResponse as AxiosTransformer[]).concat(transformDate)

import { CurrencyItemModel } from './viewmodels/CurrencyItemModel'
export function getCurrencies () : AxiosPromise<CurrencyItemModel[]> {
    return axios({
        url: `api/Currency/getCurrencies/`, 
        method: "get",
        data: null,
        transformResponse: transformResponse,
        responseType: "json"
    });
};

