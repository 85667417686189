var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"grey darken-3"},scopedSlots:_vm._u([{key:"extension",fn:function(){return [_c('v-tabs',{attrs:{"align-with-title":""}},[_c('v-tabs-slider',{attrs:{"color":"yellow"}}),_c('v-tab',{attrs:{"to":{
                                    name: 'EmployeeDetailInfo',
                                    params: { employeeId: _vm.employeeId },
                                },"exact":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account")]),_vm._v(" Info ")],1),_c('v-tab',{attrs:{"to":{
                                    name: 'EmployeeDetailRights',
                                    params: { employeeId: _vm.employeeId },
                                },"exact":"","disabled":!_vm.employeeId}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-key")]),_vm._v("User rights")],1)],1)]},proxy:true}])},[_c('v-btn',{attrs:{"icon":"","to":{ name: 'Employees' },"exact":""}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_c('v-toolbar-title',[_vm._v(_vm._s(_setup.title))])],1),_c('v-card-text',[_c('router-view')],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }