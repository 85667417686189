import { emitError } from '@/event-bus';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class MultipleItemsEditor extends Vue {
    @Prop({ default: () => [] })
    value!: unknown[];

    @Prop({ required: false, default: null })
    constructorFn!: Function | null;

    @Prop({ default: true })
    canDelete!: boolean;

    remove(index: number) {
        if (index < 0) {
            emitError('Failed to remove the item');
        }

        const deepCopy = JSON.parse(JSON.stringify(this.value)) as unknown[];
        const removedElements = deepCopy.splice(index, 1);

        if (!removedElements?.length) {
            emitError('Failed to remove the item');
        }

        this.items = deepCopy;
    }

    add() {
        this.items.push(this.constructorFn!());
    }

    get items() {
        return this.value;
    }

    set items(value: unknown[]) {
        this.$emit('input', value);
    }
}
