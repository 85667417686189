var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Fragment',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"grey darken-3"}},[_c('v-btn',{attrs:{"icon":"","to":{ name: 'Employees' },"exact":""}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_c('v-toolbar-title',[_vm._v("Employees bulk assignment")])],1),_c('v-card-text',[(_setup.hasEmployees)?_c('div',{staticClass:"selected-values mb-4"},_vm._l((_setup.employees),function(employee){return _c('v-chip',{attrs:{"label":"","ripple":false,"color":"primary"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-account-circle-outline ")]),_vm._v(" "+_vm._s(employee.firstName)+" "+_vm._s(employee.additionalNames)+" "+_vm._s(employee.lastName)+" ")],1)}),1):_c('div',[_c('v-alert',{attrs:{"type":"warning","outlined":""}},[_vm._v("No employees selected")])],1),(_setup.hasEmployees)?_c('div',{staticClass:"bulk-wrapper"},[_c('div',{staticClass:"bulk-add"},[_c('v-data-table',{staticClass:"mb-4",attrs:{"headers":_setup.headers,"items":_setup.userRights,"options":_setup.options,"loading":_setup.loadingUserRights &&
                                        _setup.loadingEmployeesUserRights,"height":500,"hide-default-header":"","hide-default-footer":"","dense":""},on:{"update:options":function($event){_setup.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('h2',{staticClass:"mb-4"},[_vm._v(" Bulk add ")])]},proxy:true},{key:"item",fn:function({ item, select, isSelected }){return [_c('tr',{class:{
                                                'grey lighten-3':
                                                    isSelected,
                                            },on:{"click":function($event){return select(!isSelected)}}},[_c('td',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({
                                                            on,
                                                            attrs,
                                                        }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.description))])])],1)])]}}],null,false,3365493327),model:{value:(_setup.selectedBulkAdd),callback:function ($$v) {_setup.selectedBulkAdd=$$v},expression:"selectedBulkAdd"}}),_c('div',{staticClass:"actions"},[_c('div',{staticClass:"selected-values"},[_vm._l((_setup.selectedBulkAdd),function(selected){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({
                                                        on,
                                                        attrs,
                                                    }){return [_c('v-chip',_vm._g(_vm._b({attrs:{"label":"","color":"success","ripple":false}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(selected.name)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(selected.description))])])]})],2),_c('div',{staticClass:"actions__save"},[_c('v-btn',{attrs:{"color":"success","loading":_setup.saving,"disabled":_setup.loadingUserRights ||
                                                _setup.loadingEmployeesUserRights},on:{"click":_setup.bulkAdd}},[_vm._v("Add ("+_vm._s(_setup.selectedBulkAdd.length)+")")])],1)])],1),_c('v-divider',{attrs:{"vertical":""}}),_c('div',{staticClass:"bulk-delete"},[_c('v-data-table',{staticClass:"mb-4",attrs:{"headers":_setup.headers,"items":_setup.userRights,"options":_setup.options,"loading":_setup.loadingUserRights &&
                                        _setup.loadingEmployeesUserRights,"height":500,"hide-default-header":"","hide-default-footer":"","dense":""},on:{"update:options":function($event){_setup.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('h2',{staticClass:"mb-4"},[_vm._v(" Bulk delete ")])]},proxy:true},{key:"item",fn:function({ item, select, isSelected }){return [_c('tr',{class:{
                                                'grey lighten-3':
                                                    isSelected,
                                            },on:{"click":function($event){return select(!isSelected)}}},[_c('td',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({
                                                            on,
                                                            attrs,
                                                        }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.description))])])],1)])]}}],null,false,633220375),model:{value:(_setup.selectedBulkDelete),callback:function ($$v) {_setup.selectedBulkDelete=$$v},expression:"selectedBulkDelete"}}),_c('div',{staticClass:"actions"},[_c('div',{staticClass:"selected-values"},_vm._l((_setup.selectedBulkDelete),function(selected){return _c('v-chip',{attrs:{"label":"","color":"error","ripple":false}},[_vm._v(" "+_vm._s(selected.name)+" ")])}),1),_c('div',{staticClass:"actions__save"},[_c('v-btn',{attrs:{"color":"error","loading":_setup.saving,"disabled":_setup.loadingUserRights ||
                                                _setup.loadingEmployeesUserRights},on:{"click":_setup.bulkDelete}},[_vm._v("Delete ("+_vm._s(_setup.selectedBulkDelete.length)+")")])],1)])],1)],1):_vm._e()])],1)],1)],1)],1),_c(_setup.ConfirmDialog,{on:{"confirm":_setup.confirm,"close":_setup.close},model:{value:(_setup.showLoader),callback:function ($$v) {_setup.showLoader=$$v},expression:"showLoader"}},[(!_setup.saving)?_c('span',[_vm._v(" Are you sure you want to continue with this action?")]):_c('div',{staticClass:"test"},_vm._l((_setup.actionStatuses),function(actionStatus){return _c('div',{staticClass:"employee-action-status"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(actionStatus.name))]),_c('span',[_c('v-icon',{attrs:{"color":actionStatus.color}},[_vm._v(_vm._s(actionStatus.icon))])],1)])}),0)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }