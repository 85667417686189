import { ValidationObserver } from 'vee-validate';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({})
export default class CrudPageMixin<
    T extends { id?: number | null }
> extends Vue {
    disableNewItemButton: Boolean = false;
    items: T[] = [];

    addNewItem() {
        this.disableNewItemButton = true;
        this.items.unshift({ id: 0 } as T);
    }

    isNewItem(item: T): Boolean {
        return item?.id === 0;
    }

    mapItem(oldItem: any, newItem: any) {
        for (const key in oldItem) {
            oldItem[key] = newItem[key];
        }
    }

    getValidationObserver(item: T) {
        return (<Vue[]>this.$refs['observer-' + item.id])[0] as InstanceType<
            typeof ValidationObserver
        >;
    }
}
