import { emitError } from '@/event-bus';
import { KeyValueItem, DataApi } from '@/openapi';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

export interface UploadPaperworkForm {
    paperworkFiles?: File[] | null;
    paperworkCategory?: number | null;
}

export interface PaperworkGrouping {
    files: File[];
    category: number;
}

export interface UploadPaperworkFormConfirmed {
    paperworkGroupings: PaperworkGrouping[];
}

const dataApi = new DataApi(undefined, '');

@Component({})
export default class UploadPaperworkDialog extends Vue {
    @Prop({ default: false })
    value!: boolean;

    @Prop({ default: false })
    isLoading!: boolean;

    @Prop({ default: 'Upload paperwork' })
    title!: string;

    @Prop({ default: 'Save' })
    confirmButtonText!: string;

    @Prop({ default: 'Close' })
    closeButtonText!: string;

    @Prop()
    parcelNumber!: string;

    @Prop({ default: false })
    multiple!: boolean;

    valid = true;
    form: UploadPaperworkForm = {
        paperworkFiles: null,
        paperworkCategory: null,
    };

    paperworkGroupings: PaperworkGrouping[] = [];

    paperworkCategories: KeyValueItem[] = [];

    @Watch('value')
    onValueChanged(newValue: boolean, oldVal: boolean) {
        if (!newValue) {
            this.resetForm();
            this.paperworkGroupings = [];
        }
    }

    created() {
        this.getCategories();
    }

    async getCategories() {
        try {
            const response = await dataApi.getPaperworkCategories();
            this.paperworkCategories = response.data as KeyValueItem[];
        } catch {
            emitError('Something went wrong while retrieving the categories');
        }
    }

    onClose() {
        this.$emit('input', false);
        this.$emit('close');
    }

    onConfirm() {
        this.$emit('confirm', {
            paperworkGroupings: this.allPaperworkGroupings,
        } as UploadPaperworkFormConfirmed);
    }

    uploadAnother() {
        this.paperworkGroupings.push({
            category: this.form.paperworkCategory!,
            files: this.form.paperworkFiles!,
        });
        this.resetForm();
    }

    resetForm() {
        (
            this.$refs.form as Vue & {
                resetValidation: () => boolean;
            }
        ).resetValidation();

        this.form.paperworkCategory = null;
        this.form.paperworkFiles = [];
    }

    getCategoryName(categoryId: number) {
        return (
            this.paperworkCategories.find((pc) => pc.key === categoryId)
                ?.value ?? 'ERROR'
        );
    }

    removePaperworkGrouping(paperworkGrouping: PaperworkGrouping) {
        const index = this.paperworkGroupings.indexOf(paperworkGrouping);
        if (index >= 0) {
            this.paperworkGroupings.splice(index, 1);
        } else {
            this.resetForm();
        }
    }

    onFileUpload(eventData: File | File[]) {
        this.form.paperworkFiles = eventData
            ? Array.isArray(eventData)
                ? eventData
                : [eventData]
            : [];
    }

    get canConfirm() {
        if (
            this.hasPaperworkGroupings &&
            ((!this.hasSelectedFiles && !this.form.paperworkCategory) ||
                (this.hasSelectedFiles && this.form.paperworkCategory))
        ) {
            return true;
        } else {
            return (
                !this.hasPaperworkGroupings &&
                this.hasSelectedFiles &&
                this.form.paperworkCategory
            );
        }
    }

    get fileInputLabel() {
        let label = 'Upload paperwork file';
        if (this.multiple) {
            label += 's';
        }
        return label;
    }

    get fileInputPlaceholder() {
        let placeholder = 'Please upload the paperwork';
        if (this.multiple) {
            placeholder += 's';
        }
        return placeholder;
    }

    get hasSelectedFiles() {
        return !!this.form.paperworkFiles?.length;
    }

    get hasPaperworkGroupings() {
        return !!this.paperworkGroupings?.length;
    }

    get fileNames() {
        if (!this.form.paperworkFiles?.length) {
            return [];
        }

        return this.form.paperworkFiles.map((c) => c.name);
    }

    get allPaperworkGroupings() {
        let groupings = this.paperworkGroupings.slice();

        if (this.hasSelectedFiles && this.form.paperworkCategory) {
            groupings.push({
                category: this.form.paperworkCategory!,
                files: this.form.paperworkFiles!,
            });
        }

        return groupings;
    }
}
