import { emitError } from '@/event-bus';
import { decodeImage } from '@/helpers/decodeImageHelper';
import {
    EmailTemplateApi,
    EmailTemplateSimple,
    GetEmailTemplateResponse,
} from '@/openapi/api';
import { EnrichedEmailTemplate } from '@/types/email';
import { ref } from 'vue';

const api = new EmailTemplateApi(undefined, '');

export function useEmailTemplate() {
    const emailTemplates = ref<EmailTemplateSimple[]>([]);
    const showDialogNewTemplate = ref(false);
    const dialogNewTemplateLoading = ref(false);

    const showDialogDeleteTemplate = ref(false);
    const dialogDeleteTemplateLoading = ref(false);
    const canEditTemplateName = ref(false);

    const selectedEmailTemplateId = ref<number | null>();
    const selectedEmailTemplate = ref<EnrichedEmailTemplate | null>();

    const setSelectedEmailTemplateValues = (data: GetEmailTemplateResponse) => {
        selectedEmailTemplate.value = {
            ...data.template,
            logoFile: null,
            logoTwoFile: null,
            logoPreview: '',
            logoTwoPreview: '',
        } as EnrichedEmailTemplate;
        selectedEmailTemplateId.value = data.template?.id;
        selectedEmailTemplate.value.logoFile = null;
        selectedEmailTemplate.value.logoTwoFile = null;
        selectedEmailTemplate.value.logoPreview = decodeImage(
            selectedEmailTemplate.value.logo ?? ''
        );
        selectedEmailTemplate.value.logoTwoPreview = decodeImage(
            selectedEmailTemplate.value.logoTwo ?? ''
        );
    };

    const setSelectedEmailTemplateId = () => {
        const emailTemplateId = emailTemplates?.value?.[0].id;
        if (!emailTemplateId) {
            return emailTemplateId;
        }
        selectedEmailTemplateId.value = emailTemplateId;
        return emailTemplateId;
    };

    const clearSelectedEmailTemplate = () => {
        selectedEmailTemplate.value = null;
        selectedEmailTemplateId.value = null;
    };

    const openDialogNewTemplate = () => {
        showDialogNewTemplate.value = true;
    };

    const openDialogDeleteTemplate = () => {
        showDialogDeleteTemplate.value = true;
    };

    const updateEmailTemplateInCollection = (
        emailTemplate: EnrichedEmailTemplate
    ) => {
        const emailTemplateFromCollection = emailTemplates.value.find(
            (c) => c.id === emailTemplate.id
        );
        if (emailTemplateFromCollection) {
            emailTemplateFromCollection.name = emailTemplate.name;
        }
    };

    const editTemplateName = () => {
        canEditTemplateName.value = true;
    };

    const removeExistingLogo = () => {
        selectedEmailTemplate.value!.logoId = null;
        selectedEmailTemplate.value!.logoName = null;
        selectedEmailTemplate.value!.logoPreview = null;
    };

    const removeExistingLogoTwo = () => {
        selectedEmailTemplate.value!.logoTwoId = null;
        selectedEmailTemplate.value!.logoTwoName = null;
        selectedEmailTemplate.value!.logoTwoPreview = null;
    };

    return {
        emailTemplates,
        showDialogDeleteTemplate,
        showDialogNewTemplate,
        dialogDeleteTemplateLoading,
        dialogNewTemplateLoading,
        canEditTemplateName,
        selectedEmailTemplateId,
        selectedEmailTemplate,
        setSelectedEmailTemplateValues,
        setSelectedEmailTemplateId,
        clearSelectedEmailTemplate,
        openDialogNewTemplate,
        openDialogDeleteTemplate,
        updateEmailTemplateInCollection,
        editTemplateName,
        removeExistingLogo,
        removeExistingLogoTwo,
    };
}
