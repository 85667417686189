import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import CustomsFlowStepsMixin from '@/mixins/CustomsFlowStepsMixin';

@Component({})
export default class ConfirmShipmentsDialog extends mixins(CustomsFlowStepsMixin)  {
    @Prop({default: false})
    value!: Boolean;

    @Prop({default: false})
    isLoading!: Boolean;

    @Prop({default: 'Confirm shipments'})
    title!: String

    @Prop({default: 'Confirm'})
    confirmButtonText!: String

    @Prop({default: 'Close'})
    closeButtonText!: String

    assignedToEmployeeStep = 1;
    checkShipmentStep = 2;
    valueBreaksStep = 3;
    confirmLoadingStep = 4;

    displayError(errorMessage: string) {
        this.$emit('errorOccured', errorMessage);
    }
}