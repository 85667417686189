import { render, staticRenderFns } from "./TransitDeclarationDefinitionDialog.vue?vue&type=template&id=002548c4&scoped=true"
import script from "./TransitDeclarationDefinitionDialog.vue?vue&type=script&setup=true&lang=ts"
export * from "./TransitDeclarationDefinitionDialog.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./TransitDeclarationDefinitionDialog.vue?vue&type=style&index=0&id=002548c4&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "002548c4",
  null
  
)

export default component.exports