import { Component, Vue, Watch } from 'vue-property-decorator';
import { ToolbarItem } from '../models/ToolbarItem';
import { ValidationResult } from 'vee-validate/dist/types/types';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import {
    CustomerViewModel,
    CustomsDakosyExportCustomersApi,
    CustomsDakosyExportCustomerViewModel,
    DataApi,
} from '../openapi';

@Component
export default class DakosyExport extends Vue {
    dataApi: DataApi = new DataApi(undefined, '');
    customsDakosyExportApi: CustomsDakosyExportCustomersApi =
        new CustomsDakosyExportCustomersApi(undefined, '');

    headers = [
        { text: 'Actions', value: 'actions', sortable: false, width: '6em' },
        { text: 'Customer*', align: 'start', value: 'customerId' },
        { text: 'Name*', value: 'name' },
        { text: 'Import Enabled*', value: 'importEnabled' },
        { text: 'Import Abgabenbescheid*', value: 'importAbgabenbescheid' },
        { text: 'Import Entladeerlaubnis*', value: 'importEntladeerlaubnis' },
        {
            text: 'Import GenerellesEreignis*',
            value: 'importGenerellesEreignis',
        },
        {
            text: 'Import EntscheidungAnmeldung*',
            value: 'importEntscheidungAnmeldung',
        },
        {
            text: 'Import ErledigungsmeldungNCTSDE*',
            value: 'importErledigungsmeldungNCTSDE',
        },
        { text: 'Export Enabled*', value: 'exportEnabled' },
        { text: 'Export FreierVerkehr*', value: 'exportFreierVerkehr' },
        { text: 'Export Ankunftsanzeige*', value: 'exportAnkunftsanzeige' },
        { text: 'Export Entladekommentar*', value: 'exportEntladekommentar' },
        {
            text: 'Export SummarischeAnmeldung*',
            value: 'exportSummarischeAnmeldung',
        },
        {
            text: 'Export BestaetigungVorzeitigeAnmeldung*',
            value: 'exportBestaetigungVorzeitigeAnmeldung',
        },
        { text: 'Server*', value: 'server' },
        { text: 'Port*', value: 'port' },
        { text: 'Username*', value: 'username' },
        { text: 'Password*', value: 'password' },
        { text: 'Import Folder*', value: 'importFolder' },
        { text: 'Export Folder*', value: 'exportFolder' },
        {
            text: 'Use Clearance Office For Adressierte Zollstelle*',
            value: 'useClearanceOfficeForAdressierteZollstelle',
        },
        { text: 'Adressierte Zollstelle*', value: 'adressierteZollstelle' },
        {
            text: 'Registering Worker Phone Number*',
            value: 'registeringWorkerPhoneNumber',
        },
        { text: 'Registering Worker Email*', value: 'registeringWorkerEmail' },
        { text: 'IssueingOffice*', value: 'issueingOffice' },
        { text: 'Vorpapier Type* Code', value: 'vorpapierTypeCode' },
        { text: 'Empfaenger EORI*', value: 'empfaengerEori' },
        { text: 'Empfaenger NLNR*', value: 'empfaengerNlnr' },
        { text: 'Aufschubnehmer EORI*', value: 'aufschubnehmerEori' },
        { text: 'Kennbuchstaben Aufschub*', value: 'kennbuchstabenAufschub' },
        { text: 'Konto NR 10*', value: 'kontoNr10' },
        { text: 'Aufschub BIN 10*', value: 'aufschubBin10' },
        { text: 'Konto NR 20*', value: 'kontoNr20' },
        { text: 'Aufschub BIN 20*', value: 'aufschubBin20' },
        { text: 'Gestellungs Ort*', value: 'gestellungsOrt' },
        {
            text: 'Bewilligungsnummer Empfaenger*',
            value: 'bewilligungsnummerEmpfaenger',
        },
        {
            text: 'Use Hardcoded Bestimmungsstelle Dienststellnummer*',
            value: 'useHardcodedBestimmungsstelleDienststellnummer',
        },
        {
            text: 'Bestimmungsstelle Dienststellnummer*',
            value: 'bestimmungsstelleDienststellnummer',
        },
        {
            text: 'Use Hardcoded Dienststelle*',
            value: 'useHardcodedDienststelle',
        },
        { text: 'Dienststelle*', value: 'dienststelle' },
        { text: 'Warehouse License*', value: 'warehouseLicense' },
        { text: 'Ort des Verbringens*', value: 'ortDesVerbringens' },
        { text: 'Export Percentage Impost*', value: 'exportPercentageImpost' },
        { text: 'Export Maximum Regular*', value: 'exportMaximumRegular' },
        { text: 'Export Maximum Impost*', value: 'exportMaximumImpost' },
        {
            text: 'Export Prioritize Regular*',
            value: 'exportPrioritizeRegular',
        },
        { text: 'Export Counter Regular*', value: 'exportCounterRegular' },
        { text: 'Export Counter Impost*', value: 'exportCounterImpost' },
        { text: 'Actions', value: 'actions', sortable: false, width: '6em' },
    ];
    isLoading = false;
    footerOptions: any = {
        showFirstLastPage: true,
        itemsPerPageOptions: [25, 50, 100, -1],
        disablePagination: false,
    };
    options: any = {
        page: 1,
        itemsPerPage: 100,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
    };
    items: CustomsDakosyExportCustomerViewModel[] = [];
    totalItems: Number = 0;
    timeoutDelay: any = null;
    disableNewItemButton: Boolean = false;
    customers: CustomerViewModel[] = [];

    created() {
        var toolbarButtons: ToolbarItem[] = [
            {
                callback: () => this.refresh(),
                icon: 'mdi-refresh',
                tooltipText: 'Refresh overview',
            },
        ];
        this.$emit(
            'PageInfoReceived',
            'Dakosy definitions for Import shipments',
            toolbarButtons
        );

        this.dataApi.getCustomers(true).then((result) => {
            this.customers = result.data;
        });
    }

    @Watch('options')
    onOptionsChanged(newVal: any, oldVal: any) {
        clearTimeout(this.timeoutDelay);
        this.timeoutDelay = setTimeout(() => {
            this.disableNewItemButton = false;
            this.loadItems(
                newVal.page,
                newVal.itemsPerPage,
                this.getSort(newVal.sortDesc),
                this.getSortField(newVal.sortBy)
            );
        }, 250);
    }

    loadItems(
        page: number,
        itemsPerPage: number,
        orderByField: string,
        orderBy: string
    ) {
        this.isLoading = true;
        this.footerOptions.disablePagination = true;
        this.customsDakosyExportApi
            .getDakosyExportCustomers(itemsPerPage, page, orderBy, orderByField)
            .then((result) => {
                this.items = result.data
                    .items as CustomsDakosyExportCustomerViewModel[];
                this.totalItems = result.data.totalItems as number;
            })
            .finally(() => {
                this.isLoading = false;
                this.footerOptions.disablePagination = false;
            });
    }

    async saveItem(item: CustomsDakosyExportCustomerViewModel) {
        if (this.isLoading) {
            return;
        }

        var validationObserver = (<Vue[]>(
            this.$refs['observer-' + item.id]
        ))[0] as InstanceType<typeof ValidationObserver>;
        const promises: Promise<ValidationResult>[] = [];
        for (const key in this.$refs) {
            const split = key.split('-');
            if (!split.length || key === 'observer-' + item.id) {
                continue;
            }

            const propName = split[0];
            const id = parseInt(split[1]);
            if (id != item.id) {
                continue;
            }

            const validationProvider = (<Vue[]>(
                this.$refs[key]
            ))[0] as InstanceType<typeof ValidationProvider>;
            promises.push(
                validationProvider.validate(
                    item[propName as keyof CustomsDakosyExportCustomerViewModel]
                )
            );
        }
        validationObserver.validate().then(async (isValid) => {
            const validationResults = await Promise.all(promises);
            let errorsArray: { id: string; errors: string[] }[] = [];
            Object.keys(validationObserver.errors).map((key) => {
                if (validationObserver.errors[key].length > 0) {
                    errorsArray.push({
                        id: key,
                        errors: validationObserver.errors[key],
                    });
                }
            });
            for (var validationResult of validationResults) {
                if (!validationResult.valid) {
                    var errorObject = errorsArray.find(
                        (o) =>
                            o.errors.indexOf(validationResult.errors[0]) !== -1
                    );
                    if (errorObject) {
                        var errorElementId = errorObject?.id;
                        document
                            .getElementById(errorElementId)
                            ?.scrollIntoView({ inline: 'start' });
                    }

                    return;
                }
            }

            this.isLoading = true;
            const isNewItem = this.isNewItem(item);

            this.customsDakosyExportApi
                .saveDakosyExportCustomer(item)
                .then((result) => {
                    if (!result?.data) {
                        return;
                    }

                    this.mapItem(item, result.data);
                    if (isNewItem) {
                        this.disableNewItemButton = false;
                    }
                })
                .catch((error) => {
                    this.$emit('errorOccured', [error.message]);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        });
    }

    deleteItem(item: any) {
        this.isLoading = true;
        const index = this.items.findIndex((cdec) => cdec.id === item.id);
        if (this.isNewItem(item)) {
            this.items.splice(index, 1);
            this.isLoading = false;
            this.disableNewItemButton = false;
            return;
        }

        this.customsDakosyExportApi
            .deleteDakosyExportCustomer(item.id)
            .then((c) => {
                this.items.splice(index, 1);
            })
            .catch(() => {})
            .finally(() => {
                const options = this.options;
                this.loadItems(
                    options.page,
                    options.itemsPerPage,
                    this.getSort(options.sortDesc),
                    this.getSortField(options.sortBy)
                );
            });
    }

    addNewItem() {
        this.disableNewItemButton = true;
        this.items.unshift({ id: 0 } as CustomsDakosyExportCustomerViewModel);
    }

    isNewItem(item: CustomsDakosyExportCustomerViewModel): Boolean {
        return item?.id === 0;
    }

    getSortField(sortFields: string[]) {
        return sortFields[0] ?? '';
    }

    getSort(sortDesc: Boolean[]) {
        let isDesc = sortDesc[0] ?? null;
        if (!(isDesc === false || isDesc === true)) {
            return '';
        }
        return isDesc ? 'DESC' : 'ASC';
    }

    mapItem(oldItem: any, newItem: any) {
        for (const key in oldItem) {
            oldItem[key] = newItem[key];
        }
    }

    refresh() {
        this.loadItems(
            this.options.page,
            this.options.itemsPerPage,
            this.getSort(this.options.sortDesc),
            this.getSortField(this.options.sortBy)
        );
        this.disableNewItemButton = false;
    }

    getCustomerName(item: CustomsDakosyExportCustomerViewModel) {
        var customer = this.customers.find((c) => c.id === item.customerId);
        if (!customer) {
            return '';
        }

        return customer.shortName;
    }
}
