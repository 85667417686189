<template>
    <div>
        <v-card>
            <v-card-title>Parcels</v-card-title>
            <v-card-text>
                <TextAreaCollection
                    v-model="parcelNumbers"
                    label="Parcel numbers"
                    :loading="isValidatingParcels"
                    :disabled="isValidatingParcels"
                >
                </TextAreaCollection>
                <v-btn
                    @click="validateParcels"
                    :loading="isValidatingParcels"
                    :disabled="!parcelNumbers.length"
                    :color="validateButtonColor"
                    :depressed="hasValidParcels"
                    >Validate parcels ({{ parcelNumbers.length }})
                    <v-icon v-if="validateButtonIcon" class="ps-1">{{
                        validateButtonIcon
                    }}</v-icon></v-btn
                >

                <div v-if="!hasValidParcels" class="mt-8">
                    <span
                        v-if="
                            amountOfValidatedParcels ===
                                invalidParcelNumbers.length &&
                            !!invalidParcelNumbers.length
                        "
                        class="red--text text-body-1"
                    >
                        All of the given parcel numbers don't exist.
                    </span>

                    <div
                        v-else-if="!!invalidParcelNumbers.length"
                        class="warning--text text-body-1"
                    >
                        The following parcel number don't exist:
                        <p class="font-weight-bold">
                            {{ formattedInvalidParcelNumbers }}
                        </p>
                        <p>
                            Do you want to create an empty parcel for each
                            parcel number and continue with the creation of the
                            consolidation?
                        </p>
                        <v-btn
                            color="success"
                            @click="
                                (hasValidParcels = true),
                                    (shouldCreateEmptyParcels = true)
                            "
                            >Yes</v-btn
                        >
                    </div>
                </div>
                <div
                    v-if="
                        hasValidParcels &&
                        extraShipmentParcelNumbers.length > 0 &&
                        !overwriteNotAllParcelsWillBeConsolidatedCheck
                    "
                    class="mt-8"
                >
                    <div class="warning--text text-body-1">
                        The following extra parcel numbers were found which are
                        not in the parcels list. This can happen when not all
                        parcels within a shipment are selected.
                        <p class="font-weight-bold">
                            {{ formattedExtraShipmentParcelNumbers }}
                        </p>
                        <p>
                            Do you still want to continue? This may cause the
                            consolidation to be incomplete.
                        </p>
                        <v-btn
                            color="warning"
                            @click="
                                overwriteNotAllParcelsWillBeConsolidatedCheck = true
                            "
                            >Yes <v-icon right>mdi-alert</v-icon></v-btn
                        >
                    </div>
                </div>

                <div
                    v-if="shouldCreateEmptyParcels"
                    class="mt-8 warning--text text-body-1 font-weight-bold"
                >
                    <span class="text-decoration-underline">
                        Empty parcels will be created using the following parcel
                        numbers:
                    </span>
                    <p class="font-weight-bold">
                        {{ formattedInvalidParcelNumbers }}
                    </p>
                </div>

                <div
                    v-if="
                        !isValidatingParcels &&
                        hasValidParcels &&
                        !hasOneAddress &&
                        (extraShipmentParcelNumbers.length === 0 ||
                            overwriteNotAllParcelsWillBeConsolidatedCheck)
                    "
                    class="mt-4"
                >
                    <v-divider class="mb-4"></v-divider>
                    <span class="font-weight-black"
                        >Multiple addresses where found. Please choose an
                        address to use for the consolidation.</span
                    >
                    <v-select
                        v-model="shipperAddress"
                        label="Choose an address"
                        :items="addresses"
                        item-text="name"
                        :return-object="true"
                    ></v-select>
                </div>

                <div
                    v-if="
                        !isValidatingParcels &&
                        hasValidParcels &&
                        shipperAddress &&
                        (extraShipmentParcelNumbers.length === 0 ||
                            overwriteNotAllParcelsWillBeConsolidatedCheck)
                    "
                >
                    <span class="d-block mt-4 font-weight-black"
                        >Selected address:
                    </span>
                    {{ shipperAddress.name }}<br />
                    {{ shipperAddress.address }}
                    {{ shipperAddress.additionalAddress }}<br />
                    {{ shipperAddress.zipcode }} {{ shipperAddress.city }}<br />
                    {{ shipperAddress.country }}<br />
                    <div
                        v-if="shipperAddress.contact || shipperAddress.phone"
                        class="mt-4"
                    >
                        {{ shipperAddress.contact }}<br />
                        {{ shipperAddress.phone }}
                    </div>
                </div>
            </v-card-text>
        </v-card>
        <template v-if="hasValidParcels && shipperAddress">
            <v-stepper v-model="currentStep" non-linear>
                <v-stepper-header>
                    <v-stepper-step
                        :editable="currentStep > 1 && currentStep < 7"
                        :complete="currentStep > 1"
                        step="1"
                    >
                        Consignee address
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step
                        :editable="currentStep > 2 && currentStep < 7"
                        :complete="currentStep > 2"
                        step="2"
                    >
                        Invoices
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step
                        :editable="currentStep > 3 && currentStep < 7"
                        :complete="currentStep > 3"
                        step="3"
                    >
                        Mrn
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step
                        :editable="currentStep > 4 && currentStep < 7"
                        :complete="currentStep > 4"
                        step="4"
                    >
                        Article information
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step
                        :editable="currentStep > 4 && currentStep < 7"
                        :complete="currentStep > 4"
                        step="5"
                    >
                        Freight costs
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step :complete="currentStep > 6" step="6">
                        Incoterms
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step step="7"> Result </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                    <v-stepper-content step="1">
                        <CustomsConsolidationConsignee
                            @next-step="currentStep = 2"
                        ></CustomsConsolidationConsignee>
                    </v-stepper-content>

                    <v-stepper-content step="2">
                        <CustomsConsolidationInvoices
                            :parcel-numbers="parcelNumbers"
                            @next-step="currentStep = 3"
                        ></CustomsConsolidationInvoices>
                    </v-stepper-content>

                    <v-stepper-content step="3">
                        <CustomsConsolidationMrn
                            :parcel-numbers="parcelNumbers"
                            @next-step="currentStep = 4"
                        ></CustomsConsolidationMrn>
                    </v-stepper-content>

                    <v-stepper-content step="4">
                        <CustomsConsolidationArticleInformation
                            @next-step="currentStep = 5"
                        ></CustomsConsolidationArticleInformation>
                    </v-stepper-content>

                    <v-stepper-content step="5">
                        <CustomsConsolidationFreight
                            :parcel-numbers="parcelNumbers"
                            @next-step="currentStep = 6"
                        ></CustomsConsolidationFreight>
                    </v-stepper-content>

                    <v-stepper-content step="6">
                        <CustomsConsolidationIncoterms
                            @create-consolidation="createConsolidation"
                            :is-creating-consolidation="isLoading"
                            :parcel-numbers="parcelNumbers"
                        ></CustomsConsolidationIncoterms>
                    </v-stepper-content>
                    <v-stepper-content step="7">
                        <div>
                            <p>
                                Successfully created the consolidation with the
                                given information. The HAWB used for the
                                consolidation is:
                            </p>
                            <strong>{{ consolidationHawb }}</strong>
                        </div>
                        <div class="d-flex mt-4">
                            <v-btn color="d-block primary" @click="resetForm"
                                >Reset form</v-btn
                            >
                            <v-btn
                                class="d-block ml-4"
                                color="primary"
                                @click="resetFormExcludingConsigneeAddress"
                                >Reset form but keep address</v-btn
                            >
                        </div>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </template>
        <v-snackbar
            v-model="showConsolidationCreationSnackbar"
            :color="consolidationCreationSuccessful ? 'success' : 'error'"
            >{{ consolidationCreationMessage }}</v-snackbar
        >
    </div>
</template>

<script setup lang="ts">
import {
    ConsolidationShipperAddress,
    CustomsConsolidationApi,
} from '@/openapi/api';

import CustomsConsolidationConsignee from './CustomsConsolidationConsignee.vue';
import CustomsConsolidationInvoices from './CustomsConsolidationInvoices.vue';
import CustomsConsolidationMrn from './CustomsConsolidationMrn.vue';
import CustomsConsolidationFreight from './CustomsConsolidationFreight.vue';
import CustomsConsolidationIncoterms from './CustomsConsolidationIncoterms.vue';
import CustomsConsolidationArticleInformation from './CustomsConsolidationArticleInformation.vue';
import { Invoice, Mrn } from '@/types/consolidation-types';
import TextAreaCollection from './editors/TextAreaCollection.vue.html';
import { serialize } from 'object-to-formdata';
import axios from 'axios';
import { emitError } from '@/event-bus';
import { computed, ref, watch } from 'vue';
import { useConsolidationStore } from '@/stores/consolidation-store';

const consolidationStore = useConsolidationStore();
const consolidationApi = new CustomsConsolidationApi(undefined, '');

const isLoading = ref(false);

const invalidParcelNumbers = ref<string[]>([]);
const amountOfValidatedParcels = ref<number>(0);
const extraShipmentParcelNumbers = ref<string[]>([]);

const addresses = ref<ConsolidationShipperAddress[]>([]);

const currentStep = ref(1);

const hasValidParcels = ref(false);
const hasOneAddress = ref(false);
const isValidatingParcels = ref(false);
const shouldCreateEmptyParcels = ref(false);
const overwriteNotAllParcelsWillBeConsolidatedCheck = ref(false);

const consolidationHawb = ref('');
const showConsolidationCreationSnackbar = ref(false);
const consolidationCreationSuccessful = ref(false);
const consolidationCreationMessage = ref('');

const validateParcels = async () => {
    isValidatingParcels.value = true;
    shouldCreateEmptyParcels.value = false;

    try {
        const response = await consolidationApi.validateParcelNumbers({
            parcelNumbers: parcelNumbers.value,
        });

        amountOfValidatedParcels.value = parcelNumbers.value.length;
        invalidParcelNumbers.value =
            response.data.invalidParcelNumbers ?? [];
        hasValidParcels.value =
            !!!invalidParcelNumbers.value.length;
        extraShipmentParcelNumbers.value =
            response.data.extraShipmentParcels ?? [];
        overwriteNotAllParcelsWillBeConsolidatedCheck.value = false;

        const addressesResponse = await consolidationApi.getAddresses({
            parcelNumbers: parcelNumbers.value,
        });

        addresses.value = addressesResponse.data.addresses ?? [];
        hasOneAddress.value = addresses.value.length === 1;
        if (hasOneAddress.value) {
            shipperAddress.value = addresses.value[0];
        }
    } catch (e) {
        emitError('Something went wrong while validating the parcel numbers.');
    }

    isValidatingParcels.value = false;
};

const createConsolidation = async () => {
    isLoading.value = true;

    try {
        const consigneeAddress = consolidationStore.consignee;
        const formData = serialize(
            {
                parcelNumbers: consolidationStore.parcelNumbers,
                shipperAddress: consolidationStore.shipperAddress,
                address: {
                    street: consigneeAddress?.street,
                    city: consigneeAddress?.city,
                    country: consigneeAddress?.country,
                    name: consigneeAddress?.name,
                    zipCode: consigneeAddress?.zipCode,
                    eoriNumber: consigneeAddress?.eoriNumber,
                    vatNumber: consigneeAddress?.vatNumber,
                    contactName: consigneeAddress?.contactName,
                    contactPhone: consigneeAddress?.contactPhone,
                },
                reasonForExport: consolidationStore.reasonForExport,
                invoices: consolidationStore.invoices.map(
                    (invoice: Invoice) => ({
                        invoiceNumber: invoice.invoiceNumber,
                        invoiceFile: invoice.invoiceFile,
                    })
                ),
                mrns: consolidationStore.mrns.map((mrn: Mrn) => ({
                    mrn: mrn.mrn,
                    exportDeclarationFile: mrn.exportDeclarationFile,
                })),
                freightCosts: consolidationStore.freightCosts,
                incoterm: consolidationStore.incoterm,
                exportDeclarationId:
                    consolidationStore.selectedExportDeclaration?.id,
                invoiceId: consolidationStore.selectedInvoice?.id,
            },
            {
                indices: true,
                dotsForObjectNotation: true,
                noFilesWithArrayNotation: true,
            }
        );

        const response = await axios.post(
            '/api/CustomsConsolidation/Consolidate',
            formData
        );

        consolidationHawb.value = response.data.hawbNumber!;
        currentStep.value++;
    } catch (e) {
        showConsolidationCreationSnackbar.value = true;
        consolidationCreationSuccessful.value = false;
        consolidationCreationMessage.value =
            'Failed to create a consolidation with the given information.';
    }

    isLoading.value = false;
};

const reset = () => {
    hasValidParcels.value = false;
    invalidParcelNumbers.value = [];
    hasOneAddress.value = false;
    currentStep.value = 1;
    consolidationHawb.value = '';
    amountOfValidatedParcels.value = 0;
    shouldCreateEmptyParcels.value = false;
    extraShipmentParcelNumbers.value = [];
    overwriteNotAllParcelsWillBeConsolidatedCheck.value = false;
};

const resetForm = () => {
    reset();
    consolidationStore.resetState();
};

const formattedInvalidParcelNumbers = computed(() => {
    return invalidParcelNumbers.value.join(', ');
});

const formattedExtraShipmentParcelNumbers = computed(() => {
    return extraShipmentParcelNumbers.value.join(', ');
});

const parcelNumbers = computed({
    get() {
        return [...consolidationStore.parcelNumbers];
    },

    set(value: string[]) {
        consolidationStore.setParcelNumbers(value);
    },
});

const shipperAddress = computed({
    get() {
        return consolidationStore.shipperAddress;
    },
    set(value: ConsolidationShipperAddress | null) {
        consolidationStore.setShipperAddress(value);
    },
});

const validateButtonColor = computed(() => {
    switch (true) {
        case hasValidParcels.value:
            return 'success';
        case !!invalidParcelNumbers.value.length &&
            amountOfValidatedParcels.value ===
                invalidParcelNumbers.value.length:
            return 'error';
        case !!invalidParcelNumbers.value.length &&
            amountOfValidatedParcels.value !==
                invalidParcelNumbers.value.length:
            return 'warning';
        default:
            return '';
    }
});

const validateButtonIcon = computed(() => {
    switch (true) {
        case hasValidParcels.value:
            return 'mdi-check-circle-outline';
        case !!invalidParcelNumbers.value.length &&
            amountOfValidatedParcels.value ===
                invalidParcelNumbers.value.length:
            return 'mdi-alert-circle';
        case !!invalidParcelNumbers.value.length &&
            amountOfValidatedParcels.value !==
                invalidParcelNumbers.value.length:
            return 'mdi-alert';
        default:
            return '';
    }
});

const resetFormExcludingConsigneeAddress = () => {
    reset();
    consolidationStore.resetStateExcludingConsigneeAddress();
};

watch(
    () => parcelNumbers.value,
    (newValue: string[], oldValue: string[]) => {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
            if (hasValidParcels.value) {
                invalidParcelNumbers.value = [];
            }
            hasValidParcels.value = false;
            shipperAddress.value = null;
            shouldCreateEmptyParcels.value = false;
            overwriteNotAllParcelsWillBeConsolidatedCheck.value = false;
            extraShipmentParcelNumbers.value = [];
        }
    },
    { deep: true }
);
</script>
