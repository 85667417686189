<template>
    <Fragment>
        <ConfirmDialog
            v-model="localValue"
            @confirm="onConfirm"
            title="Release | EORI number invalid or missing"
            :is-confirm-disabled="isRetrieving"
            :is-loading="isProcessing"
        >
            <v-text-field
                dense
                label="EORI-number"
                outlined
                v-model="formData.eoriNumber"
            ></v-text-field>
            <v-text-field
                dense
                label="Niederlassungsnummer"
                outlined
                v-model="formData.niederlassungsnummer"
            ></v-text-field>
        </ConfirmDialog>
    </Fragment>
</template>

<script setup lang="ts">
import { emitErrorWithFallback, emitSuccess } from '@/event-bus';
import { ProblemShipmentsApi } from '@/openapi';
import { computed, onBeforeMount, ref, watch } from 'vue';
import ConfirmDialog from '../ConfirmDialog.vue.html';

export interface EoriNumberInvalidFormData {
    eoriNumber: string;
    niederlassungsnummer: string;
}

interface EoriNumberInvalidDialogProps {
    value: boolean;
    blockingMilestoneId: number;
}

const api = new ProblemShipmentsApi(undefined, '');
const props = defineProps<EoriNumberInvalidDialogProps>();
const emits = defineEmits(['input', 'confirm']);

const defaultFormValues: EoriNumberInvalidFormData = {
    eoriNumber: '',
    niederlassungsnummer: '',
};

const formData = ref<EoriNumberInvalidFormData>({ ...defaultFormValues });
const isRetrieving = ref(false);
const isProcessing = ref(false);

const getEoriNumberInvalidData = async () => {
    isRetrieving.value = true;
    try {
        const response = await api.getEoriNumberInvalidOrMissingCategoryData(
            props.blockingMilestoneId
        );
        formData.value.eoriNumber = response.data.eoriNumber!;
        formData.value.niederlassungsnummer =
            response.data.niederlassungsnummer!;
    } catch (e: unknown) {
        emitErrorWithFallback(
            e,
            'Something went wrong while retrieving the necessary data'
        );
    }
    isRetrieving.value = false;
};

const onConfirm = async () => {
    isProcessing.value = true;
    try {
        await api.setEoriNumberInvalidOrMissingCategoryData({
            blockingMilestoneId: props.blockingMilestoneId,
            eoriNumber: formData.value.eoriNumber,
            niederlassungsnummer: formData.value.niederlassungsnummer,
        });
        localValue.value = false;
        emitSuccess('Release successful');
        emits('confirm');
    } catch (e: unknown) {
        emitErrorWithFallback(
            e,
            'Something went wrong while saving the changes'
        );
    } finally {
        isProcessing.value = false;
    }
};

const localValue = computed({
    get() {
        return props.value;
    },
    set(newValue: boolean) {
        emits('input', newValue);
    },
});

watch(
    () => props.value,
    (newValue: boolean, oldValue: boolean) => {
        if (!newValue) {
            formData.value = { ...defaultFormValues };
        }
    }
);

onBeforeMount(async () => {
    await getEoriNumberInvalidData();
});
</script>
