import { Component, Vue, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { CustomsHandlingItemViewModel } from '../api/viewmodels/CustomsHandlingItemViewModel';
import { CustomsHandlingWABItemViewModel } from '../api/viewmodels/CustomsHandlingWABItemViewModel';
import * as CustomsExportController from '../api/CustomsExportController';
import { ValidationObserver } from 'vee-validate';
import { ToolbarItem } from '../models/ToolbarItem';
import { CustomsCountryViewModel } from '../api/viewmodels/CustomsCountryViewModel';
import { EmployeeViewModel } from '../api/viewmodels/EmployeeViewModel';
import { ReturnReasonFirstViewModel } from '../api/viewmodels/ReturnReasonFirstViewModel';
import { ReturnReasonSecondViewModel } from '../api/viewmodels/ReturnReasonSecondViewModel';
import CustomsExportShipmentDetailViewNew from '../components/CustomsExportShipmentDetailViewNew.vue.html';
import moment from 'moment';
import { CustomsHandlingExportDashboardViewModel } from '../api/viewmodels/CustomsHandlingExportDashboardViewModel';
import {
    CustomsExportApi,
    CustomsExportInformationApi,
    CustomsExportInformationViewModel,
} from '../openapi';
import CustomsExportWab from '@/components/CustomsExportWab.vue.html';
import CustomsExportShipmentsLow from '@/components/CustomsExportShipmentsLow.vue.html';
import CustomsExportShipmentsHigh from '@/components/CustomsExportShipmentsHigh.vue.html';
import CustomsExportReadyForTransit from '@/components/CustomsExportReadyForTransit.vue.html';
import CustomsExportInTransit from '@/components/CustomsExportInTransit.vue.html';
import { CustomsShipmentDetailViewModel } from '@/api/viewmodels/CustomsShipmentDetailViewModel';
import CustomsFlowStepsMixin from '@/mixins/CustomsFlowStepsMixin';
import { ExportFlowStatusHistoryItem } from '@/api/viewmodels/ExportFlowStatusHistoryItem';
import ShipmentStatusFlow from '@/components/ShipmentStatusFlow.vue.html';
import HoldReturnExportDialog from '@/components/dialogs/HoldReturnExportDialog.vue.html';
import { HoldReturnExportDialogDateFilter } from '@/components/dialogs/HoldReturnExportDialog';
import CustomsExportCheck from '@/components/CustomsExportCheck.vue.html';
import CustomsExportAdmin from '@/components/CustomsExportAdmin.vue.html';
import ShipmentValueChangesDialog from '@/components/dialogs/ShipmentValueChangesExportDialog.vue.html';
import CustomsExportHoldReturn from '@/components/CustomsExportHoldReturn.vue.html';
import CustomsPaperworkHandeling from '@/components/CustomsPaperworkHandeling.vue.html';
import CustomsDiscrepancies from '@/components/CustomsDiscrepancies.vue';
import CustomsExportTransitHistory from '@/components/CustomsExportTransitHistory.vue.html';
import CustomsT2Creation from '@/components/CustomsT2Creation.vue.html';
import { useConsolidationStore } from '@/stores/consolidation-store';

Vue.prototype.moment = moment;
@Component({
    components: {
        CustomsExportShipmentDetailViewNew,
        CustomsExportWab,
        CustomsExportShipmentsLow,
        CustomsExportShipmentsHigh,
        CustomsExportReadyForTransit,
        CustomsExportInTransit,
        ShipmentStatusFlow,
        HoldReturnExportDialog,
        CustomsExportCheck,
        CustomsExportAdmin,
        ShipmentValueChangesDialog,
        CustomsExportHoldReturn,
        CustomsPaperworkHandeling,
        CustomsDiscrepancies,
        CustomsExportTransitHistory,
        CustomsT2Creation,
    },
})
export default class CustomsExport extends mixins(CustomsFlowStepsMixin) {
    api: CustomsExportApi = new CustomsExportApi(undefined, '');
    exportInformationApi: CustomsExportInformationApi =
        new CustomsExportInformationApi(undefined, '');
    consolidationStore = useConsolidationStore();

    saveSuccessful = false;
    noItemsImported = false;
    noItemsSelected = false;
    noEmployeeSelected = false;
    noStatusSelected = false;
    loadingBarDashboard = false;
    loadingBarConfirmManifest = false;

    expanded = [];

    loadingButton1 = false;
    loadingButton2 = false;
    loadingButton3 = false;
    loadingButton4 = false;
    loadingButton5 = false;
    loadingButton6 = false;
    loadingButton7 = false;
    loadingButton8 = false;
    loadingButton9 = false;
    loadingButton10 = false;
    loadingButton11 = false;

    observer!: InstanceType<typeof ValidationObserver>;

    expandValueBreak = false;
    isLoading = false;
    options: any = {
        page: 1,
        itemsPerPage: 5,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: true,
    };
    totalItems: Number = 0;
    footerOptions: any = {
        showFirstLastPage: true,
        itemsPerPageOptions: [5, 25, 50, 100],
        disablePagination: false,
    };
    timeoutDelay: any = null;
    filter: any = {
        step: '',
        shipmentStatusDescription: '',
        hawb: '',
        exportMrn: '',
        eori: '',
        employee: 0,
        loadedConfirmed: 0,
        showAssigned: false,
        showWithoutExportMrn: false,
        showReturn: false,
        isLoaded: '',
        isConfirmed: '',
        returnReasonSecond: '',
        returnShipmentsDateRange: null,
    };

    dashboardTotals: CustomsHandlingExportDashboardViewModel = {
        totalCheck: 0,
        totalLoadedButNotConfirmed: 0,
        totalConfirmedButNotLoaded: 0,
        totalExport: 0,
        totalExportValueBreak1: 0,
        totalExportValueBreak2: 0,
        totalExportValueBreak3: 0,
        totalTransit: 0,
        totalPerStep: [0],
        totalHoldPerStep: [0],
        totalReturnPerStep: [],
        totalCustomsInspectionPerStep: [],
        exportWABNumbers: [],
        transitWABNumbers: [],
    };
    currentHandlingItem: CustomsHandlingItemViewModel | null = null;
    currentShipmentDetail: CustomsShipmentDetailViewModel | null = null;
    customsHandlingItems: CustomsHandlingItemViewModel[] = [];
    selectedHandlingItems: CustomsHandlingItemViewModel[] = [];
    filteredCustomsHandlingItems: CustomsHandlingItemViewModel[] = [];
    currentHandlingWABItem: CustomsHandlingWABItemViewModel | null = null;
    customsHandlingWABItems: CustomsHandlingWABItemViewModel[] = [];
    customsHandlingItemsValuebreak: CustomsHandlingItemViewModel[] = [];
    allCustomsExportInformationItems: CustomsExportInformationViewModel[] = [];

    customsCountries: CustomsCountryViewModel[] = [];

    currentEmployee: EmployeeViewModel | null = null;
    employees: EmployeeViewModel[] = [];
    selectedEmployee: EmployeeViewModel | null = null;
    selectedStatus = '';
    assignedCustomsHandlingItems = 0;

    currentValueBreak = 0;

    currentAction = '';
    newFunction: Function = new Function();

    flowIndex = 0;
    flowPathIndex = 0;

    currentTab = 0;
    exportModusInnerTab = 0;
    transitModusInnerTab = 0;

    dataReceivedStep = 0;
    assignedToEmployeeStep = 1;

    checkShipmentStep = 2;

    valueBreaksStep = 3;
    confirmLoadingStep = 4;
    confirmDocumentOrMRN = 5;
    pendingCustomsStep = 6;
    confirmManifestStep = 7;

    confirmWABStep = 8;
    t1CreationStep = 9;
    t2ReleasedStep = 10;
    consoFileCreatedStep = 11;
    t2DischargedStep = 13;

    fullfilledStep = 14;

    holdStep = 20;
    returnStep = 21;
    customsInspectionStep = 22;

    adminStep = 99;

    automatedSteps = [6, 9, 10, 11, 13]; // for testing

    valid = true;

    loadDataDate = 0;

    uploadExcelLTSFile: string | Blob = '';

    dialogShipmentReason = false;
    enterShipmentReason = '';
    enterShipmentReasonFirst = -1;
    enterShipmentReasonSecond = -1;

    returnReasonsFirst: ReturnReasonFirstViewModel[] = [];
    returnReasonsSecond: ReturnReasonSecondViewModel[] = [];
    returnReasonsBeschau: string[] = [];

    docLink = '';

    dialogUploadShipments = false;
    dialogUploadShipmentsFile = false;

    dialogShipmentAddMrn = false;

    dialogConfirmShipment = false;

    dialogAddRoute = false;
    dialogAddRouteId = 0;

    dialogConfirmLoading = false;
    dialogExportMrn = '';

    dialogConfirmManifest = false;
    manifestTotal7Exit = 0;
    manifestTotalNot7 = 0;
    manifestTotal7NotExit = 0;

    dialogConfirmWABReadyToDepart = false;
    dialogWABReadyToDepartType = '';
    dialogWABReadyToDepartAmount = 1;
    dialogWABReadyToDepartSign = '';
    dialogWABReadyToDepartRegistration = '';
    dialogWABReadyToDepartCountry = '';

    statusFilter: string[] = [];
    statusFilterWithHoldReturn: string[] = [];
    loadedConfirmedFilter: any[] = [{}];
    selectedLoadedConfirmed = '';
    shipmentStatusFilter = [
        'Hold',
        'Return',
        'Customs inspection',
        'Loading WAB confirmed',
        'Waiting for customs release',
        'Customs EXIT released',
    ];
    booleanFilter = ['Yes', 'No'];

    modusColor = [
        'grey',
        'yellow darken-3',
        'red darken-3',
        'light-blue darken-1',
        'purple',
        'green darken-3',
        'brown',
        '',
        'teal darken-3',
        'orange darken-3',
    ];
    dashboardModus = 0;
    checkModus = 1;
    holdReturnModus = 2;
    exportModus = 3;
    transitModus = 4;
    adminModus = 5;
    wabModus = 6;
    paperworkHandlingModus = 7;
    discrepanciesModus = 8;
    t2CreationModus = 9;
    activeModus = 0;

    dashboardTotalCheck = 0;
    dashboardTotalConfirmedButNotLoaded = 0;
    dashboardTotalLoadedButNotConfirmed = 0;

    dashboardTotalExport = 0;
    dashboardTotalExportValueBreak1 = 0;
    dashboardTotalExportValueBreak2 = 0;
    dashboardTotalExportValueBreak3 = 0;

    dashboardTotalTransit = 0;
    dashboardTotalTransitWABNumbers: string[] = [];

    dashboardTotalPerStep: number[] = [];
    dashboardTotalHoldPerStep: number[] = [];
    dashboardTotalReturnPerStep: number[] = [];
    dashboardTotalCustomsInspectionPerStep: number[] = [];

    showDateRangeMenu = false;
    showShipmentValueChangeDialog = false;

    // NEW STUFF

    exportFlowStatusHistoryItems: ExportFlowStatusHistoryItem[] = [];

    // NEW STUFF

    showHoldReturnExportDialog = false;

    data() {
        return {
            headersCheckModus: [
                {
                    text: 'Step',
                    value: 'customsHandlingHistoryItem.exportFlowStatus',
                    align: 'center',
                },
                {
                    text: 'Loaded',
                    value: 'customsHandlingHistoryItem.shipmentLoaded',
                    align: 'center',
                },
                {
                    text: 'Confirmed',
                    value: 'customsHandlingHistoryItem.shipmentConfirmed',
                    align: 'center',
                },
                {
                    text: 'Shipment status',
                    value: 'customsHandlingHistoryItem.shipmentStatusDescription',
                },
                {
                    text: 'Paketscheinnummer',
                    value: 'hawb',
                    sortable: false,
                    width: '15em',
                },
                {
                    text: 'MRN ',
                    value: 'customsHandlingHistoryItem.exportMrn',
                    width: '18em',
                },
                {
                    text: 'Anzahl ',
                    value: 'pieces',
                    sortable: false,
                    align: 'end',
                },
                {
                    text: 'Gewicht ',
                    value: 'grossWeight',
                    sortable: false,
                    align: 'end',
                },
                { text: 'EORI', value: 'eoriNumber', sortable: false },
                {
                    text: 'Versender',
                    value: 'shipperName',
                    sortable: false,
                    width: '15em',
                },
                {
                    text: 'Empfänger',
                    value: 'consigneeName',
                    sortable: false,
                    width: '15em',
                },
                {
                    text: 'Warenwert',
                    value: 'value',
                    sortable: false,
                    align: 'end',
                },
                { text: 'Währung', value: 'valueCurrency', sortable: false },
                {
                    text: 'Warenbeschreibung / tarifnummer',
                    value: 'articles',
                    sortable: false,
                    width: '25em',
                },
            ],
            headersHoldReturnModus: [
                {
                    text: 'Step',
                    value: 'customsHandlingHistoryItem.exportFlowStatus',
                    align: 'center',
                },
                {
                    text: 'Loaded',
                    value: 'customsHandlingHistoryItem.shipmentLoaded',
                    align: 'center',
                },
                {
                    text: 'Confirmed',
                    value: 'customsHandlingHistoryItem.shipmentConfirmed',
                    align: 'center',
                },
                {
                    text: 'Shipment status',
                    value: 'customsHandlingHistoryItem.shipmentStatusDescription',
                },
                {
                    text: 'Paketscheinnummer',
                    value: 'hawb',
                    sortable: false,
                    width: '15em',
                },
                {
                    text: 'MRN ',
                    value: 'customsHandlingHistoryItem.exportMrn',
                    width: '18em',
                },
                {
                    text: 'Return reason ',
                    value: 'customsHandlingHistoryItem.shipmentReason',
                    width: '18em',
                },
                {
                    text: 'Anzahl ',
                    value: 'pieces',
                    sortable: false,
                    align: 'end',
                },
                {
                    text: 'Gewicht ',
                    value: 'grossWeight',
                    sortable: false,
                    align: 'end',
                },
                { text: 'EORI', value: 'eoriNumber', sortable: false },
                {
                    text: 'Versender',
                    value: 'shipperName',
                    sortable: false,
                    width: '15em',
                },
                {
                    text: 'Empfänger',
                    value: 'consigneeName',
                    sortable: false,
                    width: '15em',
                },
                {
                    text: 'Warenwert',
                    value: 'value',
                    sortable: false,
                    align: 'end',
                },
                { text: 'Währung', value: 'valueCurrency', sortable: false },
                {
                    text: 'Warenbeschreibung / tarifnummer',
                    value: 'articles',
                    sortable: false,
                    width: '25em',
                },
            ],
            headersExportModus: [
                { text: 'Step', value: 'status', align: 'center' },
                { text: 'WAB number', value: 'wabNumber' },
                {
                    text: 'Total < 1.000',
                    value: 'totalValuebreak1',
                    align: 'end',
                    width: '15%',
                },
                {
                    text: 'Total 1.000 - 3.000',
                    value: 'totalValuebreak2',
                    align: 'end',
                    width: '15%',
                },
                {
                    text: 'Total > 3.000 or has MRN',
                    value: 'totalValuebreak3',
                    align: 'end',
                    width: '15%',
                },
                { text: '', width: '25%' },
            ],
            headersExportModusHandlings: [
                {
                    text: 'Step',
                    value: 'customsHandlingHistoryItem.exportFlowStatus',
                    align: 'center',
                },
                {
                    text: 'Shipment status',
                    value: 'customsHandlingHistoryItem.shipmentStatusDescription',
                },
                {
                    text: 'Paketscheinnummer',
                    value: 'hawb',
                    sortable: false,
                    width: '15em',
                },
                {
                    text: 'MRN ',
                    value: 'customsHandlingHistoryItem.exportMrn',
                    width: '18em',
                },
                {
                    text: 'Anzahl ',
                    value: 'pieces',
                    sortable: false,
                    align: 'end',
                },
                {
                    text: 'Gewicht ',
                    value: 'grossWeight',
                    sortable: false,
                    align: 'end',
                },
                { text: 'EORI', value: 'eoriNumber', sortable: false },
                {
                    text: 'Versender',
                    value: 'shipperName',
                    sortable: false,
                    width: '15em',
                },
                {
                    text: 'Empfänger',
                    value: 'consigneeName',
                    sortable: false,
                    width: '15em',
                },
                {
                    text: 'Warenwert',
                    value: 'value',
                    sortable: false,
                    align: 'end',
                },
                { text: 'Währung', value: 'valueCurrency', sortable: false },
                {
                    text: 'Warenbeschreibung / tarifnummer',
                    value: 'articles',
                    sortable: false,
                    width: '25em',
                },
            ],
            headersTransitModus: [
                { text: 'Step', value: 'status', align: 'center' },
                { text: 'WAB number', value: 'wabNumber' },
                {
                    text: 'Total < 1.000',
                    value: 'totalValuebreak1',
                    align: 'end',
                    width: '15%',
                },
                {
                    text: 'Total 1.000 - 3.000',
                    value: 'totalValuebreak2',
                    align: 'end',
                    width: '15%',
                },
                {
                    text: 'Total > 3.000 or has MRN',
                    value: 'totalValuebreak3',
                    align: 'end',
                    width: '15%',
                },
                { text: '', width: '25%' },
            ],
            headersTransitModusHandlings: [
                {
                    text: 'Step',
                    value: 'customsHandlingHistoryItem.exportFlowStatus',
                    align: 'center',
                },
                {
                    text: 'Shipment status',
                    value: 'customsHandlingHistoryItem.shipmentStatusDescription',
                },
                {
                    text: 'Paketscheinnummer',
                    value: 'hawb',
                    sortable: false,
                    width: '15em',
                },
                {
                    text: 'MRN',
                    value: 'customsHandlingHistoryItem.exportMrn',
                    width: '18em',
                },
                {
                    text: 'Dakosy reference',
                    value: 'customsHandlingHistoryItem.dakosyReference',
                    width: '15em',
                },
                {
                    text: 'Anzahl ',
                    value: 'pieces',
                    sortable: false,
                    align: 'end',
                },
                {
                    text: 'Gewicht ',
                    value: 'grossWeight',
                    sortable: false,
                    align: 'end',
                },
                { text: 'EORI', value: 'eoriNumber', sortable: false },
                {
                    text: 'Versender',
                    value: 'shipperName',
                    sortable: false,
                    width: '15em',
                },
                {
                    text: 'Empfänger',
                    value: 'consigneeName',
                    sortable: false,
                    width: '15em',
                },
                {
                    text: 'Warenwert',
                    value: 'value',
                    sortable: false,
                    align: 'end',
                },
                { text: 'Währung', value: 'valueCurrency' },
                {
                    text: 'Warenbeschreibung / tarifnummer',
                    value: 'articles',
                    sortable: false,
                    width: '25em',
                },
            ],
            headersAdminModus: [
                {
                    text: 'Create date',
                    value: 'customsHandlingHistoryItem.createDate',
                    width: '10em',
                },
                {
                    text: 'Employee',
                    value: 'customsHandlingHistoryItem.assignedEmployeeName',
                    width: '15em',
                },
                {
                    text: 'Step',
                    value: 'customsHandlingHistoryItem.exportFlowStatus',
                    align: 'center',
                },
                {
                    text: 'Loaded',
                    value: 'customsHandlingHistoryItem.shipmentLoaded',
                },
                {
                    text: 'Confirmed',
                    value: 'customsHandlingHistoryItem.shipmentConfirmed',
                },
                {
                    text: 'Shipment status',
                    value: 'customsHandlingHistoryItem.shipmentStatusDescription',
                },
                {
                    text: 'Paketscheinnummer',
                    value: 'hawb',
                    sortable: false,
                    width: '15em',
                },
                {
                    text: 'MRN',
                    value: 'customsHandlingHistoryItem.exportMrn',
                    width: '18em',
                },
                {
                    text: 'Temp. WAB Number',
                    value: 'customsHandlingHistoryItem.shipmentWABNumber',
                    width: '15em',
                },
                {
                    text: 'Final WAB Number',
                    value: 'customsHandlingHistoryItem.finalWabNumber',
                    width: '15em',
                },
                {
                    text: 'Anzahl ',
                    value: 'pieces',
                    sortable: false,
                    align: 'end',
                },
                {
                    text: 'Gewicht ',
                    value: 'grossWeight',
                    sortable: false,
                    align: 'end',
                },
                {
                    text: 'EORI',
                    value: 'eoriNumber',
                    sortable: false,
                    width: '15em',
                },
                {
                    text: 'Versender',
                    value: 'shipperName',
                    sortable: false,
                    width: '15em',
                },
                {
                    text: 'Empfänger',
                    value: 'consigneeName',
                    sortable: false,
                    width: '15em',
                },
                {
                    text: 'Warenwert',
                    value: 'value',
                    sortable: false,
                    align: 'end',
                },
                { text: 'Währung', value: 'valueCurrency', sortable: false },
                {
                    text: 'Warenbeschreibung',
                    value: 'articles',
                    sortable: false,
                    width: '25em',
                },
            ],
            rules: {
                required: (value: string) =>
                    (value && !!value.toString()) || 'Required',
                mrnnumber: (value: string) => {
                    const pattern = /^[0-9]{2}[A-Z]{2}[a-zA-Z0-9]{14}$/;
                    return pattern.test(value) || 'Invalid MRN number';
                },
                email: (value: string) => {
                    const pattern =
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || 'Invalid e-mail';
                },
            },
        };
    }

    get returnReasonsFilter() {
        if (!this.returnReasonsFirst?.length) {
            return [];
        }

        var filters = [];
        for (const reason of this.returnReasonsFirst) {
            if (!reason.returnReasonsSecond?.length) {
                continue;
            }

            filters.push({
                divider: true,
                header: reason.reason,
            });

            for (const secondReason of reason.returnReasonsSecond) {
                filters.push({
                    value: secondReason.id,
                    text: secondReason.reason,
                });
            }
        }

        return filters;
    }

    @Watch('filter.loadedConfirmed')
    @Watch('filter.showReturn')
    @Watch('filter.showAssigned')
    @Watch('filter.showWithoutExportMrn')
    @Watch('filter.step')
    @Watch('filter.shipmentLoaded')
    @Watch('filter.shipmentConfirmed')
    @Watch('filter.shipmentStatusDescription')
    @Watch('filter.employee')
    @Watch('filter.returnReasonSecond')
    onFilterChanged(newVal: any, oldVal: any) {
        this.loadData(
            this.options.page,
            this.options.itemsPerPage,
            this.getSort(this.options.sortDesc),
            this.getSortField(this.options.sortBy),
            this.filter,
            true
        );
    }

    @Watch('filter.returnShipmentsDateRange')
    onReturnShipmentsDateRangeChanged(
        newVal: string[] | null,
        oldVal: string[] | null
    ) {
        if (newVal == null || newVal.length === 2) {
            this.loadData(
                this.options.page,
                this.options.itemsPerPage,
                this.getSort(this.options.sortDesc),
                this.getSortField(this.options.sortBy),
                this.filter,
                true
            );
        }
    }

    filterSearchEntered() {
        this.loadData(
            this.options.page,
            this.options.itemsPerPage,
            this.getSort(this.options.sortDesc),
            this.getSortField(this.options.sortBy),
            this.filter
        );
    }

    @Watch('options')
    onOptionsChanged(newVal: any, oldVal: any) {
        if (!this.expandValueBreak) {
            clearTimeout(this.timeoutDelay);
            this.timeoutDelay = setTimeout(() => {
                this.loadData(
                    newVal.page,
                    newVal.itemsPerPage,
                    this.getSort(newVal.sortDesc),
                    this.getSortField(newVal.sortBy),
                    this.filter
                );
            }, 250);
        }
    }

    @Watch('expanded')
    onExpandedChanged(
        newVal: CustomsHandlingItemViewModel[],
        oldVal: CustomsHandlingItemViewModel[]
    ) {
        if (newVal.length === 0) {
            this.currentHandlingItem = null;
            this.currentShipmentDetail = null;
        } else {
            var customsHandlingId = newVal[0].id;
            this.isLoading = true;

            this.loadDataDate = Date.now();
            var callDate = this.loadDataDate;
            var customsHandlingItemPromise =
                CustomsExportController.getCustomsHandlingItemById(
                    customsHandlingId
                ).then((response) => {
                    if (this.loadDataDate != callDate) {
                        return;
                    }

                    this.currentHandlingItem = response.data;
                });

            var shipmentDetailPromise =
                CustomsExportController.getShipmentDetail(
                    customsHandlingId
                ).then(
                    (response) => (this.currentShipmentDetail = response.data)
                );
            var exportFlowStatusHistoryPromise =
                CustomsExportController.getExportFlowStatusHistory(
                    customsHandlingId
                ).then(
                    (response) =>
                        (this.exportFlowStatusHistoryItems = response.data)
                );

            Promise.all([
                customsHandlingItemPromise,
                shipmentDetailPromise,
                exportFlowStatusHistoryPromise,
            ]).then(() => (this.isLoading = false));
        }
    }

    @Watch('currentTab')
    currentTabWatcher(newValue: number, oldValue: number) {
        this.consolidationStore.resetState();
    }

    created() {
        this.getCurrentEmployee();

        var toolbarButtons: ToolbarItem[] = [
            {
                callback: () => this.refresh(),
                icon: 'mdi-refresh',
                tooltipText: 'Refresh overview',
            },
        ];
        this.$emit(
            'PageInfoReceived',
            'Customs Export shipments',
            toolbarButtons
        );

        for (let i = 1; i <= this.fullfilledStep; i++) {
            this.statusFilter.push(i.toString());
            this.statusFilterWithHoldReturn.push(i.toString());
        }
        this.statusFilterWithHoldReturn.push(this.returnStep.toString());
        this.statusFilterWithHoldReturn.push(
            this.customsInspectionStep.toString()
        );

        this.getReturnReasons();
        this.returnReasonsBeschau[0] = 'Customs inspection documents';
        this.returnReasonsBeschau[1] = 'Customs inspection parcel';
        this.getCustomsCountries();
        this.getEmployees();
        this.getExportInformation();
        this.loadData(
            this.options.page,
            this.options.itemsPerPage,
            this.getSort(this.options.sortDesc),
            this.getSortField(this.options.sortBy),
            this.filter
        );
    }

    mounted() {
        this.observer = this.$refs.observer as InstanceType<
            typeof ValidationObserver
        >;
    }

    getSortField(sortFields: string[]) {
        return sortFields[0] ?? '';
    }

    getSort(sortDesc: Boolean[]) {
        let isDesc = sortDesc[0] ?? null;
        if (!(isDesc === false || isDesc === true)) {
            return '';
        }
        return isDesc ? 'DESC' : 'ASC';
    }

    refresh() {
        this.loadData(
            this.options.page,
            this.options.itemsPerPage,
            this.getSort(this.options.sortDesc),
            this.getSortField(this.options.sortBy),
            this.filter
        );
    }

    loadData(
        page: number,
        itemsPerPage: number,
        orderByField: string,
        orderBy: string,
        filter: any,
        filterOn: boolean = false
    ) {
        this.isLoading = true;

        if (
            filterOn &&
            (this.activeModus == this.exportModus ||
                this.activeModus == this.transitModus)
        ) {
            this.customsHandlingItems = [];
            this.filteredCustomsHandlingItems = [];

            this.loadDataDate = Date.now();
            var callDate = this.loadDataDate;
            CustomsExportController.getCustomsHandlingItems(
                this.activeModus,
                this.currentHandlingWABItem!.id,
                this.currentValueBreak,
                itemsPerPage,
                page,
                orderBy,
                orderByField,
                JSON.stringify(filter),
                0
            )
                .then((response) => {
                    if (this.loadDataDate != callDate) {
                        return;
                    }

                    this.customsHandlingItems = response.data.items;
                    this.totalItems = response.data.totalItems;
                    this.filteredCustomsHandlingItems =
                        this.customsHandlingItems;
                    this.isLoading = false;
                })
                .catch((error) => {
                    this.isLoading = false;
                });

            return;
        }

        if (this.activeModus == this.dashboardModus) {
            this.loadingBarDashboard = true;

            this.loadDataDate = Date.now();
            var callDate = this.loadDataDate;
            CustomsExportController.getDashboardTotals()
                .then((response) => {
                    this.loadingBarDashboard = false;

                    if (this.loadDataDate != callDate) {
                        return;
                    }

                    this.dashboardTotals = response.data;
                    this.isLoading = false;
                })
                .catch((error) => {
                    this.isLoading = false;
                });
        }

        if (
            this.activeModus == this.checkModus ||
            this.activeModus == this.holdReturnModus ||
            this.activeModus == this.adminModus
        ) {
            this.customsHandlingItems = [];
            this.filteredCustomsHandlingItems = [];

            this.loadDataDate = Date.now();
            var callDate = this.loadDataDate;

            if (filter.returnShipmentsDateRange?.length == 1) {
                filter.returnShipmentsDateRange = null;
            }

            CustomsExportController.getCustomsHandlingItems(
                this.activeModus,
                0,
                0,
                itemsPerPage,
                page,
                orderBy,
                orderByField,
                JSON.stringify(filter),
                0
            )
                .then((response) => {
                    if (this.loadDataDate != callDate) {
                        return;
                    }

                    this.expanded = [];
                    this.customsHandlingItems = response.data.items;
                    this.totalItems = response.data.totalItems;
                    this.filteredCustomsHandlingItems =
                        this.customsHandlingItems;

                    this.isLoading = false;
                })
                .catch((error) => {
                    this.isLoading = false;
                });
        }

        if (
            this.activeModus == this.exportModus ||
            this.activeModus == this.transitModus
        ) {
            this.customsHandlingWABItems = [];

            this.loadDataDate = Date.now();
            var callDate = this.loadDataDate;
            CustomsExportController.getCustomsHandlingWABItems(this.activeModus)
                .then((response) => {
                    if (this.loadDataDate != callDate) {
                        return;
                    }

                    this.expanded = [];
                    this.customsHandlingWABItems = response.data;
                    this.isLoading = false;
                })
                .catch((error) => {
                    this.isLoading = false;
                });
        }
    }

    readExcelLTS(saveSuccessfulCallback: Function) {
        if (
            !(this.$refs.form as Vue & { validate: () => boolean }).validate()
        ) {
            this.stopLoadingButtons();
            return;
        }

        var castedFile = this.uploadExcelLTSFile as File;
        this.api
            .readExcelLTS([castedFile])
            .then((result) => {
                var returnString = result.data;
                if (returnString) {
                    alert(returnString);
                } else {
                    this.saveSuccessful = true;
                    if (saveSuccessfulCallback) {
                        saveSuccessfulCallback();
                    }
                }

                this.stopLoadingButtons();
            })
            .catch((error) => {
                this.stopLoadingButtons();
                this.closeAllDialogs();
            });
    }

    downLoadExcelFile(filePath: string) {
        CustomsExportController.downloadExcelFile(filePath).then((response) => {
            this.downloadFile(
                response.data,
                this.docLink.replace(/^.*[\\\/]/, '')
            );
        });
    }

    downloadFile(file: Blob, fileName: string) {
        const url = window.URL.createObjectURL(new Blob([file]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

    getReturnReasons() {
        this.returnReasonsFirst = [];

        CustomsExportController.getReturnReasons()
            .then((response) => {
                this.returnReasonsFirst = response.data;
            })
            .catch((error) => {});
    }

    getCustomsCountries() {
        this.customsCountries = [];

        CustomsExportController.getCustomsCountries()
            .then((response) => {
                this.customsCountries = response.data;

                this.customsCountries.forEach(function (item) {
                    item.description = item.code + ' - ' + item.description;
                });
            })
            .catch((error) => {});
    }

    getEmployees() {
        this.employees = [];

        CustomsExportController.getEmployees()
            .then((response) => {
                this.employees = response.data;
            })
            .catch((error) => {});
    }

    getCurrentEmployee() {
        CustomsExportController.getCurrentEmployee()
            .then((response) => {
                this.currentEmployee = response.data;
            })
            .catch((error) => {});
    }

    getExportInformation() {
        this.allCustomsExportInformationItems = [];

        this.exportInformationApi
            .getCustomsExportInformation(100, 1, '', 'ASC')
            .then((result) => {
                this.allCustomsExportInformationItems = result.data.items ?? [];
            })
            .catch((error) => {});
    }

    clearFilters() {
        this.filter.step = '';
        this.filter.shipmentStatusDescription = '';
        this.filter.search = '';
        this.filter.loadedConfirmed = 0;
        this.filter.showAssigned = false;
        this.filter.showWithoutExportMrn = false;
        this.filter.showReturn = false;
        this.filter.isLoaded = '';
        this.filter.isConfirmed = '';

        if (this.activeModus == this.holdReturnModus) {
            this.options.sortBy[0] = 'customsHandlingHistoryItem.createDate';
            this.options.sortDesc[0] = 'DESC';
        } else {
            this.options.sortBy[0] =
                'customsHandlingHistoryItem.exportFlowStatus';
            this.options.sortDesc[0] = 'ASC';
        }
    }

    changeModus(selectedModus: number) {
        this.selectedHandlingItems = [];
        this.customsHandlingItems = [];
        this.customsHandlingWABItems = [];
        this.stopLoadingButtons();
        this.activeModus = selectedModus;
        this.clearFilters();
        this.$data.expanded = [];

        if (this.activeModus == this.checkModus) {
            this.loadedConfirmedFilter = [
                { text: 'Show all (mine)', value: 0 },
                { text: 'Show Confirmed but not loaded (mine)', value: 1 },
                { text: 'Show Loaded but not confirmed (all)', value: 2 },
            ];

            this.selectedLoadedConfirmed = 'Show all (mine)';
        } else {
            this.loadData(
                this.options.page,
                this.options.itemsPerPage,
                this.getSort(this.options.sortDesc),
                this.getSortField(this.options.sortBy),
                this.filter
            );
            this.loadedConfirmedFilter = [
                { text: 'Show all', value: 0 },
                { text: 'Show Confirmed but not loaded', value: 1 },
                { text: 'Show Loaded but not confirmed', value: 2 },
            ];
            this.selectedLoadedConfirmed = 'Show all';
        }
    }

    changeShipmentStatus(action: string) {
        this.currentAction = action;
        this.selectedHandlingItems = [];
        this.currentHandlingItem!.action = action;
        this.selectedHandlingItems.push(this.currentHandlingItem!);

        this.saveSelectedHandlingItems(
            this.currentHandlingItem!.customsHandlingHistoryItem
                .exportFlowStatus,
            this.newFunction
        );
    }

    returnReasonsChange() {
        var reasonFirst = this.returnReasonsFirst.find(
            (x) => x.id == this.enterShipmentReasonFirst
        );
        if (reasonFirst != null) {
            this.returnReasonsSecond = reasonFirst.returnReasonsSecond;
            if (this.returnReasonsSecond.length == 1) {
                this.enterShipmentReasonSecond = this.returnReasonsSecond[0].id;
            }
        }
    }

    openDialogShipmentAddMrn(item: CustomsHandlingItemViewModel) {
        this.stopLoadingButtons();
        this.currentHandlingItem = item;
        this.dialogExportMrn = '';
        this.dialogShipmentAddMrn = true;
    }

    openDialogReason(action: string) {
        this.stopLoadingButtons();
        this.currentAction = action;
        this.enterShipmentReason = '';
        this.enterShipmentReasonFirst = -1;
        this.enterShipmentReasonSecond = -1;
        this.dialogShipmentReason = true;
    }

    openDialogConfirmShipment() {
        this.stopLoadingButtons();

        if (this.selectedHandlingItems.length == 0) {
            this.closeAllDialogs();
            this.noItemsSelected = true;
            return;
        }

        this.dialogConfirmShipment = true;
    }

    openDialogConfirmLoading() {
        if (this.currentHandlingWABItem!.routeId == null) {
            this.dialogAddRoute = true;

            return;
        }

        this.dialogConfirmLoading = true;
    }

    openDialogConfirmManifest() {
        this.stopLoadingButtons();
        this.manifestTotal7Exit = 0;
        this.manifestTotalNot7 = 0;
        this.manifestTotal7NotExit = 0;

        this.loadingBarConfirmManifest = true;
        this.dialogConfirmManifest = true;
    }

    saveDialogConfirmShipments() {
        this.currentAction = 'Confirmed';
        this.selectedHandlingItems.forEach(function (item) {
            item.action = 'Confirmed';
        });

        this.saveSelectedHandlingItems(
            this.checkShipmentStep,
            this.newFunction
        );
    }

    saveDialogShipmentReason(action: string) {
        if (action == 'Return') {
            this.currentHandlingItem!.customsHandlingHistoryItem.shipmentReasonFirstId =
                this.enterShipmentReasonFirst;
            this.currentHandlingItem!.customsHandlingHistoryItem.shipmentReasonSecondId =
                this.enterShipmentReasonSecond;
        } else {
            this.currentHandlingItem!.customsHandlingHistoryItem.shipmentReasonFirstId =
                undefined;
            this.currentHandlingItem!.customsHandlingHistoryItem.shipmentReasonSecondId =
                undefined;
            this.currentHandlingItem!.customsHandlingHistoryItem.shipmentReason =
                this.enterShipmentReason;
        }

        this.changeShipmentStatus(action);
    }

    saveDialogConfirmLoading() {
        this.currentAction = 'ConfirmLoading';
        this.saveSelectedHandlingItems(
            this.confirmLoadingStep,
            this.newFunction,
            this.currentValueBreak == 3,
            false
        );
    }

    saveDialogEnterExportMrn() {
        if (
            !(this.$refs.form as Vue & { validate: () => boolean }).validate()
        ) {
            this.stopLoadingButtons();
            return;
        }

        this.selectedHandlingItems = [];
        this.selectedHandlingItems.push(this.currentHandlingItem!);
        this.currentHandlingItem!.customsHandlingHistoryItem.exportMrn =
            this.dialogExportMrn;

        this.currentAction = 'changeExportMrn';
        this.currentHandlingItem!.action = 'changeExportMrn';
        this.saveSelectedHandlingItems(-1, this.newFunction);
    }

    saveButtonConfirmExcelIsSentToCustoms() {
        this.currentAction = 'ConfirmExcelIsSentToCustoms';
        this.saveSelectedHandlingItems(
            this.confirmDocumentOrMRN,
            this.newFunction,
            false,
            false
        );
    }

    saveButtonAcceptanceFromCustoms() {
        this.currentAction = 'AcceptanceFromCustoms';
        this.saveSelectedHandlingItems(
            this.pendingCustomsStep,
            this.newFunction,
            false,
            false
        );
    }

    saveDialogAddRouteId() {
        this.currentHandlingWABItem!.routeId = this.dialogAddRouteId!;

        this.saveCurrentHandlingWABItem(this.newFunction, 'AddRouteId');
    }

    saveDialogConfirmManifest() {
        this.currentAction = 'ConfirmManifest';
        this.saveSelectedHandlingItems(
            this.confirmManifestStep,
            this.newFunction,
            true,
            false
        );
    }

    saveDialogConfirmWABReadyToDepart() {
        var signs = this.dialogWABReadyToDepartSign
            ? this.dialogWABReadyToDepartSign.split('\n')
            : [];
        var sign = signs.filter((value) => value).join(',');

        this.currentHandlingWABItem!.type = this.dialogWABReadyToDepartType;
        this.currentHandlingWABItem!.amount = this.dialogWABReadyToDepartAmount;
        this.currentHandlingWABItem!.sign = sign;
        this.currentHandlingWABItem!.registration =
            this.dialogWABReadyToDepartRegistration;
        this.currentHandlingWABItem!.countryCode =
            this.dialogWABReadyToDepartCountry;

        this.saveCurrentHandlingWABItem(this.newFunction);
    }

    saveEmployeeToSelectedShipments() {
        if (this.selectedEmployee == null) {
            this.stopLoadingButtons();
            this.closeAllDialogs();
            this.noEmployeeSelected = true;
            return;
        }

        var empId = this.selectedEmployee.id;
        this.selectedHandlingItems.forEach(function (item) {
            item.action = 'EmployeeToSelectedShipments';
            item.customsHandlingHistoryItem.assignedEmployeeId = empId;
        });
        this.currentAction = 'EmployeeToSelectedShipments';

        this.saveSelectedHandlingItems(this.adminStep, this.newFunction);
    }

    saveStatusToSelectedShipments() {
        if (this.selectedStatus == null || this.selectedStatus == '') {
            this.stopLoadingButtons();
            this.closeAllDialogs();
            this.noStatusSelected = true;
            return;
        }

        var flowStatus: number = +this.selectedStatus;
        this.selectedHandlingItems.forEach(function (item) {
            item.action = 'StatusToSelectedShipments';
            item.customsHandlingHistoryItem.exportFlowStatus = flowStatus;
        });
        this.currentAction = 'StatusToSelectedShipments';

        this.saveSelectedHandlingItems(this.adminStep, this.newFunction);
    }

    saveSelectedHandlingItems(
        flowStep: number,
        saveSuccessfulCallback: Function,
        validateForm: boolean = false,
        validateSelectedItems: boolean = true
    ) {
        if (
            validateSelectedItems &&
            flowStep != this.assignedToEmployeeStep &&
            this.selectedHandlingItems.length == 0
        ) {
            this.stopLoadingButtons();
            this.closeAllDialogs();
            this.noItemsSelected = true;
            return;
        }

        if (validateForm) {
            if (
                !(
                    this.$refs.form as Vue & { validate: () => boolean }
                ).validate()
            ) {
                this.selectedHandlingItems = [];
                this.stopLoadingButtons();
                return;
            }
        }

        //if (this.selectedHandlingItems.length == this.itemsPerPage) {
        //    alert('selected all')
        //
        // }

        var currentHandlingWABItemId = 0;
        if (
            this.currentAction == 'ConfirmLoading' ||
            this.currentAction == 'ConfirmExcelIsSentToCustoms' ||
            this.currentAction == 'AcceptanceFromCustoms' ||
            this.currentAction == 'ConfirmManifest'
        ) {
            currentHandlingWABItemId = this.currentHandlingWABItem!.id;
        }

        CustomsExportController.saveSelectedHandlingItems(
            flowStep,
            this.activeModus,
            currentHandlingWABItemId,
            this.currentValueBreak,
            this.selectedHandlingItems
        )
            .then((result) => {
                this.selectedHandlingItems = result.data;
                if (this.selectedHandlingItems.length == 0) {
                    this.noItemsImported = true;
                } else {
                    this.saveSuccessful = true;
                }

                if (this.selectedHandlingItems.length == 1) {
                    this.currentHandlingItem = this.selectedHandlingItems[0];
                    var filterItem = this.filteredCustomsHandlingItems.find(
                        (x) => x.hawb == this.currentHandlingItem!.hawb
                    );
                    var itemIndex = this.filteredCustomsHandlingItems.indexOf(
                        filterItem!
                    );

                    if (
                        this.currentHandlingItem.action == 'LoadedAndConfirmed'
                    ) {
                        this.expanded = [];
                        this.filteredCustomsHandlingItems.splice(itemIndex, 1);
                        this.refresh();
                    } else if (
                        this.currentHandlingItem.action == 'Release' ||
                        this.currentHandlingItem.action == 'Return' ||
                        this.currentHandlingItem.action ==
                            'Customs inspection' ||
                        this.currentHandlingItem.action == 'changeExportMrn'
                    ) {
                        this.expanded = [];
                        this.filteredCustomsHandlingItems.splice(itemIndex, 1);
                    } else {
                        this.filteredCustomsHandlingItems.splice(
                            itemIndex,
                            1,
                            this.currentHandlingItem
                        );
                    }
                }

                if (flowStep == this.confirmLoadingStep) {
                    if (
                        this.activeModus == this.exportModus &&
                        this.currentValueBreak == 1
                    ) {
                        this.expanded = [];
                        this.refresh();
                    }
                }

                if (this.selectedHandlingItems.length > 1) {
                    this.currentHandlingItem = null;
                    this.refresh();
                }

                this.selectedHandlingItems = [];
                this.stopLoadingButtons();
                this.closeAllDialogs();
                if (saveSuccessfulCallback) {
                    saveSuccessfulCallback();
                }
            })
            .catch((error) => {
                this.stopLoadingButtons();
                this.closeAllDialogs();
            });
    }

    saveCurrentHandlingWABItem(
        saveSuccessfulCallback: Function,
        action: string = ''
    ) {
        if (
            !(this.$refs.form as Vue & { validate: () => boolean }).validate()
        ) {
            this.stopLoadingButtons();
            return;
        }

        CustomsExportController.saveCustomsHandlingWABItem(
            action,
            this.currentHandlingWABItem!
        )
            .then((result) => {
                this.currentHandlingWABItem = result.data;

                this.saveSuccessful = true;
                this.stopLoadingButtons();
                this.closeAllDialogs();

                if (action == 'AddRouteId') {
                    this.dialogConfirmLoading = true;
                } else {
                    this.refresh();
                }

                if (saveSuccessfulCallback) {
                    saveSuccessfulCallback();
                }
            })
            .catch((error) => {
                this.stopLoadingButtons();
                this.closeAllDialogs();
            });
    }

    stopLoadingButtons() {
        this.loadingButton1 = false;
        this.loadingButton2 = false;
        this.loadingButton3 = false;
        this.loadingButton4 = false;
        this.loadingButton5 = false;
        this.loadingButton6 = false;
        this.loadingButton7 = false;
        this.loadingButton8 = false;
        this.loadingButton9 = false;
        this.loadingButton10 = false;
        this.loadingButton11 = false;
    }

    closeAllDialogs() {
        this.dialogShipmentAddMrn = false;
        this.dialogShipmentReason = false;
        this.dialogConfirmShipment = false;
        this.dialogConfirmLoading = false;
        this.dialogAddRoute = false;
        this.dialogConfirmManifest = false;
        this.dialogConfirmWABReadyToDepart = false;
    }

    range(start: number, end: number) {
        return Array(end - start + 1)
            .fill(null)
            .map((_, idx) => start + idx);
    }

    displayError(errorMessage: string) {
        this.$emit('errorOccured', errorMessage);
    }

    async onShipmentDetailsUpdated() {
        this.isLoading = true;
        this.currentShipmentDetail = null;
        try {
            const response = await CustomsExportController.getShipmentDetail(
                this.currentHandlingItem!.id
            );
            this.currentShipmentDetail = response.data;
        } catch {
            this.displayError(
                'Something went wrong while re-retrieving the shipment details'
            );
        }

        this.isLoading = false;
    }

    setDefaultExportUrlParameters(searchParams: URLSearchParams) {
        searchParams.append('includeReturn', this.filter.showReturn);
        if (this.filter.returnShipmentsDateRange?.length === 2) {
            searchParams.append(
                'fromDate',
                this.filter.returnShipmentsDateRange[0]
            );
            searchParams.append(
                'tillDate',
                this.filter.returnShipmentsDateRange[1]
            );
        }
    }

    emitPageInfoReceived(toolbarItems: ToolbarItem[]) {
        this.$emit(
            'PageInfoReceived',
            'Customs Export shipments',
            toolbarItems
        );
    }

    get customsHandlingItemsIds() {
        return this.customsHandlingItems?.map((c) => c.id) ?? [];
    }

    get holdReturnExportDateFilter(): HoldReturnExportDialogDateFilter | null {
        return this.filter.returnShipmentsDateRange?.length === 2
            ? {
                  fromDate: this.filter.returnShipmentsDateRange[0],
                  tillDate: this.filter.returnShipmentsDateRange[1],
              }
            : null;
    }

    get exportFromDate() {
        return this.filter.returnShipmentsDateRange?.length === 2
            ? this.filter.returnShipmentsDateRange[0]
            : '';
    }

    get exportTillDate() {
        return this.filter.returnShipmentsDateRange?.length === 2
            ? this.filter.returnShipmentsDateRange[1]
            : '';
    }

    get returnModusVisibleExportUrl() {
        var searchParams = new URLSearchParams();
        this.setDefaultExportUrlParameters(searchParams);
        for (const item of this.customsHandlingItems) {
            searchParams.append('ids', item.id.toString());
        }

        return `/api/customsReturn/CreateExport?${searchParams.toString()}`;
    }

    get returnModusAllExportUrl() {
        var searchParams = new URLSearchParams();
        this.setDefaultExportUrlParameters(searchParams);
        return `/api/customsReturn/CreateExport?${searchParams.toString()}`;
    }

    get visibleExportDownloadName() {
        var now = moment();
        return `visible-export-${now.format('YYYY-MM-DD')}`;
    }

    get allExportDownloadName() {
        var now = moment();
        return `all-export-${now.format('YYYY-MM-DD')}`;
    }
}
