import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class TextAreaCollection extends Vue {
    @Prop()
    value!: string[];

    get text() {
        return this.value.join('\n');
    }

    set text(value: string) {
        const trimmedValue = value.trim();
        this.$emit(
            'input',
            trimmedValue
                ? trimmedValue.split('\n').filter((text) => !!text)
                : []
        );
    }
}
