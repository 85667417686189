<template>
    <Fragment>
        <ValidationObserver slim ref="observer" v-slot="{ invalid }">
            <DefaultDialog
                :value="value"
                @input="$emit('input', $event)"
                :loading="loading"
            >
                <template v-slot:header>Edit T1/T2 template</template>
                <template v-slot:content>
                    <v-form ref="refForm">
                        <v-container>
                            <v-row>
                                <v-col cols="6">
                                    <ValidationProvider
                                        rules="required"
                                        name="Name"
                                        v-slot="{ errors }"
                                    >
                                        <v-text-field
                                            v-model="form.name"
                                            label="Name*"
                                            :error-messages="errors"
                                            :disabled="loading"
                                        ></v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="6">
                                    <ValidationProvider
                                        :rules="{ regex: /^[0-9a-zA-Z]+$/ }"
                                        name="Durchgangszollstelle 1"
                                        v-slot="{ errors }"
                                    >
                                        <v-text-field
                                            v-model="form.durchgangszollstelle1"
                                            label="Durchgangszollstelle 1"
                                            placeholder="Example: AB123456"
                                            :error-messages="errors"
                                            :disabled="loading"
                                        ></v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="6">
                                    <ValidationProvider
                                        :rules="{ regex: /^[0-9a-zA-Z]+$/ }"
                                        name="Durchgangszollstelle 2"
                                        v-slot="{ errors }"
                                    >
                                        <v-text-field
                                            v-model="form.durchgangszollstelle2"
                                            label="Durchgangszollstelle 2"
                                            placeholder="Example: AB123456"
                                            :error-messages="errors"
                                            :disabled="loading"
                                        ></v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="6">
                                    <ValidationProvider
                                        rules="required"
                                        name="Tage bis zur “Wiedergestellung”"
                                        v-slot="{ errors }"
                                    >
                                        <v-text-field
                                            v-model.number="
                                                form.tageBisZurWiedergestellung
                                            "
                                            type="number"
                                            label="Tage bis zur “Wiedergestellung”*"
                                            :error-messages="errors"
                                            :disabled="loading"
                                        ></v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="6">
                                    <ValidationProvider
                                        :rules="{ regex: /^[0-9a-zA-Z]+$/ }"
                                        name="Ausgangszollstelle ID"
                                        v-slot="{ errors }"
                                    >
                                        <v-text-field
                                            v-model="form.ausgangszollstelleId"
                                            label="Ausgangszollstelle ID"
                                            :error-messages="errors"
                                            :disabled="loading"
                                        ></v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="6">
                                    <ValidationProvider
                                        rules="required|max:3"
                                        name="Art der Anmeldung"
                                        v-slot="{ errors }"
                                    >
                                        <v-text-field
                                            v-model="form.artDerAnmeldung"
                                            label="Art der Anmeldung*"
                                            :error-messages="errors"
                                            :disabled="loading"
                                        ></v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="6">
                                    <ValidationProvider
                                        rules="required|max:17"
                                        name="Bewilligungsnummer Versender"
                                        v-slot="{ errors }"
                                    >
                                        <v-text-field
                                            v-model="
                                                form.bewilligungsnummerVersender
                                            "
                                            label="Bewilligungsnummer Versender*"
                                            :error-messages="errors"
                                            :disabled="loading"
                                        ></v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="6">
                                    <ValidationProvider
                                        rules="required|max:20"
                                        name="Bewilligung besondere Verschlüsse"
                                        v-slot="{ errors }"
                                    >
                                        <v-text-field
                                            v-model="
                                                form.bewilligungsnummerVersender2
                                            "
                                            label="Bewilligung besondere Verschlüsse*"
                                            :error-messages="errors"
                                            :disabled="loading"
                                        ></v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="6">
                                    <ValidationProvider
                                        rules="required|email"
                                        name="Ansprechpartner Email"
                                        v-slot="{ errors }"
                                    >
                                        <v-text-field
                                            v-model="form.ansprechpartnerEmail"
                                            label="Ansprechpartner Email*"
                                            :error-messages="errors"
                                            :disabled="loading"
                                        ></v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="6">
                                    <ValidationProvider
                                        rules="required"
                                        name="Ansprechpartner Telefonnummer"
                                        v-slot="{ errors }"
                                    >
                                        <v-text-field
                                            v-model="
                                                form.ansprechpartnerTelefonnummer
                                            "
                                            label="Ansprechpartner Telefonnummer*"
                                            :error-messages="errors"
                                            :disabled="loading"
                                        ></v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="6">
                                    <ValidationProvider
                                        rules="required|max:17"
                                        name="Sicherheitsangaben GRN"
                                        v-slot="{ errors }"
                                    >
                                        <v-text-field
                                            v-model="form.sicherheitsangabenGRN"
                                            label="Sicherheitsangaben GRN*"
                                            :error-messages="errors"
                                            :disabled="loading"
                                        ></v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="6">
                                    <ValidationProvider
                                        rules="required|max:50"
                                        name="Holder of Transit Procedure EORI"
                                        v-slot="{ errors }"
                                    >
                                        <v-text-field
                                            v-model="
                                                form.holderOfTransitProcedureEORI
                                            "
                                            label="Holder of Transit Procedure EORI*"
                                            :error-messages="errors"
                                            :disabled="loading"
                                        ></v-text-field>
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6">
                                    <ValidationProvider
                                        rules="required|max:4"
                                        name="Zugriffscode"
                                        v-slot="{ errors }"
                                    >
                                        <v-text-field
                                            v-model="form.zugriffscode"
                                            label="Zugriffscode*"
                                            :error-messages="errors"
                                            :disabled="loading"
                                        ></v-text-field>
                                    </ValidationProvider>
                                </v-col>

                                <v-col cols="12">
                                    <ValidationProvider
                                        ref="routesValidator"
                                        rules="isUnique:definitionRouteId"
                                        v-slot="{ errors }"
                                    >
                                        <MultipleItemsEditor
                                            v-model="form.routes"
                                            :constructorFn="createRoute"
                                        >
                                            <template #editor="{ item }">
                                                <TransitDefinitionEditor
                                                    v-model="
                                                        item.definitionRouteId
                                                    "
                                                    :key="item.id"
                                                ></TransitDefinitionEditor>
                                            </template>
                                        </MultipleItemsEditor>
                                        <span
                                            v-if="errors.length"
                                            class="d-block mt-4 error--text"
                                        >
                                            {{ errors[0] }}
                                        </span>
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </template>
                <template v-slot:footer>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        :disabled="loading"
                        @click="$emit('input', false), $emit('close')"
                    >
                        Close
                    </v-btn>
                    <v-btn
                        color="primary"
                        text
                        :loading="loading"
                        :disabled="invalid"
                        @click="saveTransitDeclarationTemplateDetail"
                    >
                        Save
                    </v-btn>
                </template>
            </DefaultDialog>
        </ValidationObserver>

        <v-snackbar v-model="showSnackbar" color="success" class="text-center"
            >Successfully saved "{{ form.name }}"</v-snackbar
        >
    </Fragment>
</template>

<script setup lang="ts">
import { emitError } from '@/event-bus';
import {
    CustomsTransitDeclarationTemplateApi,
    TransitDeclarationTemplateDetailRoute,
} from '@/openapi';
import DefaultDialog from '@/components/dialogs/DefaultDialog.vue.html';
import MultipleItemsEditor from '@/components/editors/MultipleItemsEditor.vue.html';
import TransitDefinitionEditor from '../editors/TransitDefinitionEditor.vue.html';
import { ref, watch } from 'vue';
import { ValidationProvider } from 'vee-validate';

interface TransitDeclarationTemplateProps {
    value: boolean;
    id?: number | null;
}

interface TransitDeclarationTemplateForm {
    name: string;
    durchgangszollstelle1: string | null;
    durchgangszollstelle2: string | null;
    tageBisZurWiedergestellung: number;
    ausgangszollstelleId: string | null;
    artDerAnmeldung: string;
    bewilligungsnummerVersender: string;
    bewilligungsnummerVersender2: string;
    ansprechpartnerEmail: string;
    ansprechpartnerTelefonnummer: string;
    sicherheitsangabenGRN: string;
    zugriffscode: string;
    routes: TransitDeclarationTemplateDetailRoute[];
    holderOfTransitProcedureEORI: string;
}

const api = new CustomsTransitDeclarationTemplateApi(undefined, '');

const props = defineProps<TransitDeclarationTemplateProps>();
const emits = defineEmits(['saved']);
const defaultFormValues: TransitDeclarationTemplateForm = {
    name: '',
    durchgangszollstelle1: '',
    durchgangszollstelle2: '',
    tageBisZurWiedergestellung: 0,
    ausgangszollstelleId: '',
    artDerAnmeldung: '',
    bewilligungsnummerVersender: '',
    bewilligungsnummerVersender2: '',
    ansprechpartnerEmail: '',
    ansprechpartnerTelefonnummer: '',
    sicherheitsangabenGRN: '',
    zugriffscode: '',
    holderOfTransitProcedureEORI: '',
    routes: [],
};

const loading = ref(false);

const form = ref<TransitDeclarationTemplateForm>({ ...defaultFormValues });
const showSnackbar = ref(false);

const refForm = ref<(Vue & { resetValidation: () => boolean }) | null>(null);
const routesValidator = ref<InstanceType<typeof ValidationProvider> | null>(
    null
);

const getTransitDeclarationTemplateDetail = async (id: number) => {
    loading.value = true;
    try {
        const response = await api.getTransitDeclarationTemplate(id);
        form.value.name = response.data.name!;
        form.value.durchgangszollstelle1 = response.data.durchgangszollstelle1!;
        form.value.durchgangszollstelle2 = response.data.durchgangszollstelle2!;
        form.value.tageBisZurWiedergestellung =
            response.data.tageBisZurWiedergestellung!;
        form.value.ausgangszollstelleId = response.data.ausgangszollstelleId!;
        form.value.artDerAnmeldung = response.data.artDerAnmeldung!;
        form.value.bewilligungsnummerVersender =
            response.data.bewilligungsnummerVersender!;
        form.value.bewilligungsnummerVersender2 =
            response.data.bewilligungsnummerVersender2!;
        form.value.ansprechpartnerEmail = response.data.ansprechpartnerEmail!;
        form.value.ansprechpartnerTelefonnummer =
            response.data.ansprechpartnerTelefonnummer!;
        form.value.sicherheitsangabenGRN = response.data.sicherheitsangabenGRN!;
        form.value.zugriffscode = response.data.zugriffscode!;
        form.value.holderOfTransitProcedureEORI =
            response.data.holderOfTransitProcedureEORI!;
        form.value.routes = response.data.routes ?? [];
    } catch {
        emitError('Something went wrong while retrieving the information.');
    }
    loading.value = false;
};

const saveTransitDeclarationTemplateDetail = async () => {
    loading.value = true;
    try {
        await api.saveTransitDeclarationTemplate({
            id: props.id,
            name: form.value.name,
            ansprechpartnerEmail: form.value.ansprechpartnerEmail,
            ansprechpartnerTelefonnummer:
                form.value.ansprechpartnerTelefonnummer,
            artDerAnmeldung: form.value.artDerAnmeldung,
            ausgangszollstelleId: form.value.ausgangszollstelleId,
            bewilligungsnummerVersender: form.value.bewilligungsnummerVersender,
            bewilligungsnummerVersender2:
                form.value.bewilligungsnummerVersender2,
            durchgangszollstelle1: form.value.durchgangszollstelle1,
            durchgangszollstelle2: form.value.durchgangszollstelle2,
            sicherheitsangabenGRN: form.value.sicherheitsangabenGRN,
            tageBisZurWiedergestellung: form.value.tageBisZurWiedergestellung,
            zugriffscode: form.value.zugriffscode,
            holderOfTransitProcedureEORI:
                form.value.holderOfTransitProcedureEORI,
            routes: form.value.routes ?? [],
        });
        showSnackbar.value = true;
        emits('saved');
    } catch {
        emitError('Something went wrong while saving the entered information.');
    }

    loading.value = false;
};

const createRoute = (): TransitDeclarationTemplateDetailRoute => {
    return {
        id: null,
    };
};

watch(
    () => props.value,
    (newValue: boolean, oldVal: boolean) => {
        if (newValue == false) {
            form.value = { ...defaultFormValues };
            refForm.value!.resetValidation();
        }
    }
);

watch(
    () => props.id,
    (newVal: number | null, oldVal: number | null) => {
        if (newVal == null) {
            form.value = { ...defaultFormValues };
        } else {
            getTransitDeclarationTemplateDetail(newVal);
        }
    },
    { immediate: true }
);

watch(
    () => form.value.routes,
    (newValue, oldValue) => {
        routesValidator.value!.validate();
    },
    { deep: true }
);
</script>
