<template>
  <Fragment>
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="options"
      :footer-props="footerOptions"
      :server-items-length="totalAmountOfItems"
      item-key="id"
      fixed-header
      fixed-footer
      class="elevation-1"
      :loading="loading"
    >
      <template #body.prepend>
        <tr>
          <td>
            <v-text-field
              v-model="filters.hawb"
              clearable
              placeholder="Filter on paketscheinnummer"
              dense
              single-line
              @keyup.enter="onFilterSelected"
              @click:clear="(filters.hawb = ''), onFilterSelected()"
            ></v-text-field>
          </td>
        </tr>
      </template>
      <template #item.flightHandlingStatus="{ item }">
        <v-chip
          small
          :color="getFlightHandlingStatusClass(item.status)"
          :text-color="getFlightHandlingStatusTextColor(item.status)"
          >{{ getFlightHandlingStatus(item.status) }}</v-chip
        >
      </template>
      <template #item.customsInspectionStatus="{ item }">
        <v-chip
          text-color="white"
          small
          :color="!item.authority ? 'green' : 'red'"
          >{{ item.authority || "No customs inspection" }}</v-chip
        >
      </template>
      <template #item.moveStatus="{ item }">
        <v-chip
          text-color="white"
          small
          :color="
            !!item.dakosyReference
              ? 'green'
              : !!item.notMovedReason || item.showInHawbOverview
                ? 'primary'
                : 'red'
          "
          >{{
            item.dakosyReference ||
            item.notMovedReason ||
            (item.showInHawbOverview
              ? "Moved to the HAWB overview"
              : "No status")
          }}</v-chip
        >
      </template>
    </v-data-table>
  </Fragment>
</template>

<script setup lang="ts">
import { ConsolidationShipment, CustomsFlightHandlingApi } from "@/openapi";
import { ref, watch } from "vue";
import { DataOptions, DataTableHeader } from "vuetify";
import { FooterOptions } from "@/types/types";
import { emitError } from "@/event-bus";
import { FlightHandlingConsolidationShipmentsType } from "@/models/FlightHandlingConsolidationShipmentsType";

interface Filters {
  hawb?: string;
}

interface CustomsConsolidationShipmentsProps {
  consolidationId: number;
  t1Id?: number | null;
  type: FlightHandlingConsolidationShipmentsType;
  refreshKey: number;
}

type FlightHandlingStatusInfo = {
  name: string;
  class: string;
  textColor: string;
};

const flightHandlingStatus: Record<number, FlightHandlingStatusInfo> = {
  0: {
    name: "No status",
    class: "red",
    textColor: "white",
  },
  1: {
    name: "ICS2 MRN exists",
    class: "yellow lighten-1",
    textColor: "black",
  },
  2: {
    name: "Gestellung created",
    class: "yellow lighten-1",
    textColor: "black",
  },
  3: {
    name: "ATB received",
    class: "green",
    textColor: "white",
  },
};

const flightHandlingApi = new CustomsFlightHandlingApi(undefined, "");
const props = defineProps<CustomsConsolidationShipmentsProps>();

const loading = ref(false);
const headers = ref<DataTableHeader[]>([
  {
    text: "HAWB/Parcel ID",
    value: "hawb",
    sortable: true,
  },
  {
    text: "Anzahl ",
    value: "pieces",
    sortable: false,
  },
  {
    text: "Gewicht ",
    value: "weight",
    sortable: false,
  },
  {
    text: "Flight handling",
    value: "flightHandlingStatus",
    sortable: false,
  },
  {
    text: "Customs inspection",
    value: "customsInspectionStatus",
    sortable: true,
  },
  {
    text: "Move shipment from airport",
    value: "moveStatus",
    sortable: false,
  },
]);

const filters = ref<Filters>({
  hawb: "",
});

const options = ref<DataOptions>({
  page: 1,
  itemsPerPage: 5,
  sortBy: [],
  sortDesc: [],
  groupBy: [],
  groupDesc: [],
  multiSort: false,
  mustSort: true,
});

const footerOptions = ref<FooterOptions>({
  showFirstLastPage: true,
  itemsPerPageOptions: [5, 25, 50, 100],
  disablePagination: false,
});

const totalAmountOfItems = ref(0);
const items = ref<ConsolidationShipment[]>([]);

watch(
  () => options.value,
  (newVal: any, oldVal: any) => {
    getConsolidationShipments(props.consolidationId, props.type, props.t1Id);
  },
);

watch(
  () => props.refreshKey,
  (newVal, oldVal) => {
    getConsolidationShipments(props.consolidationId, props.type, props.t1Id);
  },
);

const getConsolidationShipments = async (
  consolidationId: number,
  componentType: FlightHandlingConsolidationShipmentsType,
  t1Id?: number | null,
) => {
  loading.value = true;
  try {
    let convertedT1Id = t1Id === null ? undefined : t1Id;

    const response = await flightHandlingApi.getConsolidationShipments(
      consolidationId,
      componentType,
      convertedT1Id,
      options.value.page,
      options.value.itemsPerPage,
      filters.value.hawb ?? "",
      options.value.sortBy.length > 0 ? options.value.sortBy[0] : undefined,
      options.value.sortDesc.length > 0 ? options.value.sortDesc[0] : undefined,
    );
    items.value =
      response.data?.items?.map((item) => ({
        ...item,
        loadingInspect: false,
        loadingRelease: false,
        loadingMove: false,
      })) ?? [];
    totalAmountOfItems.value = response.data.totalAmountOfItems ?? 0;
  } catch {
    emitError(
      "Something went wrong while retrieving the consolidation shipments",
    );
  }
  loading.value = false;
};

const getFlightHandlingStatus = (status: number | null | undefined) => {
  if (!status) {
    return flightHandlingStatus[0].name;
  }

  return flightHandlingStatus[status].name;
};

const getFlightHandlingStatusClass = (status: number | null | undefined) => {
  if (!status) {
    return flightHandlingStatus[0].class;
  }

  return flightHandlingStatus[status].class;
};

const getFlightHandlingStatusTextColor = (
  status: number | null | undefined,
) => {
  if (!status) {
    return flightHandlingStatus[0].textColor;
  }

  return flightHandlingStatus[status].textColor;
};

const onFilterSelected = async () => {
  await getConsolidationShipments(
    props.consolidationId,
    props.type,
    props.t1Id,
  );
};
</script>
