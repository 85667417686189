import { render, staticRenderFns } from "./DefaultDialog.vue.html?vue&type=template&id=5a94f1d4"
import script from "./DefaultDialog.ts?vue&type=script&lang=js&external"
export * from "./DefaultDialog.ts?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports