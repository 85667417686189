import moment from 'moment';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

const EXPORT_URL = '/api/customsExport/ExportValueChanges';

@Component({})
export default class ShipmentValueChangesExportDialog extends Vue {
    @Prop({ required: true })
    value!: Boolean;

    @Prop({ default: 'Export value changes' })
    title!: String;

    @Prop({ default: 'Confirm' })
    confirmButtonText!: String;

    @Prop({ default: 'Close' })
    closeButtonText!: String;

    form = {
        dateRange: null as string[] | null
    };

    @Watch('value')
    onValueChange(newValue: boolean, oldValue: boolean) {
        if(newValue) {
            this.form.dateRange = null;
        }
    }

    get hasSelectedDateRange() {
        return !!this.form.dateRange && this.form.dateRange.length === 2
    }

    get exportUrl() {
        const searchParams = new URLSearchParams();
        if (this.hasSelectedDateRange) {
            searchParams.append('startDate', this.form.dateRange![0]);
            searchParams.append('endDate', this.form.dateRange![1]);
        }

        let exportUrl = EXPORT_URL;
        if (searchParams.has('startDate')) {
            exportUrl += `?${searchParams.toString()}`;
        }

        return exportUrl;
    }

    get downloadName() {
        const now = moment();
        return `export-value-change-${now.format('YYYY-MM-DD')}`;
    }

    onConfirm() {
        this.$emit('input', false);
    }
}
