import { Component, Prop, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import * as CustomsExportController from "../api/CustomsExportController";
import CustomsExportShipmentDetailViewNew from "./CustomsExportShipmentDetailViewNew.vue.html";
import CustomsExportInTransitDeclarations from "./CustomsExportInTransitDeclarations.vue.html";
import CustomsFlowStepsMixin from "@/mixins/CustomsFlowStepsMixin";
import ShipmentStatusFlow from "./ShipmentStatusFlow.vue.html";
import { getStatuses } from "@/helpers/statusHelper";
import {
  CreateT2DeclarationsViewModel,
  CustomsFinalWabItem,
  CustomsShipmentItemViewModel,
  CustomsTransitApi,
} from "@/openapi";
import { CustomsShipmentDetailViewModel } from "@/api/viewmodels/CustomsShipmentDetailViewModel";
import moment from "moment";
import ShipmentPaperwork from "./ShipmentPaperwork.vue.html";
import CountriesMixin from "@/mixins/CountriesMixin";
import ToolbarMixin from "@/mixins/ToolbarMixin";
import FilterParcels from "./FilterParcels.vue.html";
import CreateTransitDeclarationDialog from "./dialogs/CreateTransitDeclarationDialog.vue.html";
import { CreateTransitDeclarationForm } from "./dialogs/CreateTransitDeclarationDialog";
import CustomsMoveShipmentDialog from "@/components/dialogs/CustomsMoveShipmentDialog.vue";

const customsTransitApi = new CustomsTransitApi(undefined, "");

@Component({
  components: {
    CustomsExportShipmentDetailViewNew,
    CustomsExportInTransitDeclarations,
    ShipmentStatusFlow,
    ShipmentPaperwork,
    FilterParcels,
    CreateTransitDeclarationDialog,
    CustomsMoveShipmentDialog,
  },
})
export default class CustomsExportInTransit extends mixins(
  CustomsFlowStepsMixin,
  CountriesMixin,
  ToolbarMixin,
) {
  @Prop({ default: "" })
  color!: string;

  isLoading = false;
  options: any = {
    page: 1,
    itemsPerPage: 5,
    sortBy: [],
    sortDesc: [],
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: true,
  };
  shipmentOptions: any = {
    page: 1,
    itemsPerPage: 5,
    sortBy: [],
    sortDesc: [],
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: true,
  };
  filter: any = {
    step: null,
    shipmentStatusDescription: "",
    parcels: [],
    exportMrn: "",
    shipperCountry: null,
    consigneeCountry: null,
  };

  declarationFilter: any = {
    showFulfilled: false,
    fulfilledDateRange: [],
    search: "",
  };
  showDateRangeMenu = false;

  footerOptions: any = {
    showFirstLastPage: true,
    itemsPerPageOptions: [5, 25, 50, 100],
    disablePagination: false,
  };

  totalItems: Number = 0;
  expanded: CustomsFinalWabItem[] = [];
  totalShipmentItems: Number = 0;
  expandedShipments = [];

  statusFilter: number[] = [];
  shipmentStatusFilter = [
    "Hold",
    "Return",
    "Customs inspection",
    "Loading confirmed",
    "Waiting for customs release",
    "Customs EXIT released",
  ];

  items = [] as Array<CustomsFinalWabItem>;
  currentFinalWabItem: CustomsFinalWabItem | null = null;
  customsHandlingItems: CustomsShipmentItemViewModel[] = [];
  currentHandlingItem: CustomsShipmentItemViewModel | null = null;
  currentShipmentDetail: CustomsShipmentDetailViewModel | null = null;

  showDialog = false;
  isDialogLoading = false;
  selectedFinalWabDialogItem: CustomsFinalWabItem | null = null;

  showMoveShipmentDialog = false;
  selectedWabItemId: number = 0;

  headers = [
    {
      text: "LTS-Number",
      value: "ltsNumber",
      align: "start",
      width: "15%",
    },
    {
      text: "Number of shipments",
      value: "numberOfShipments",
      align: "start",
      width: "15%",
    },
    {
      text: "Active",
      value: "shipmentsAddedWarning",
      align: "start",
      width: "5%",
    },
    { text: "", value: "createT2", align: "start" },
    { text: "Status", value: "status" },
  ];

  customsHandlingItemsHeaders = [
    {
      text: "Step",
      value: "exportFlowStatus",
      align: "center",
    },
    {
      text: "Shipment status",
      value: "shipmentStatusDescription",
    },
    {
      text: "HAWB/Parcel ID",
      value: "hawb",
      sortable: false,
      width: "15em",
    },
    {
      text: "MRN ",
      value: "exportMrn",
      width: "18em",
    },
    {
      text: "Anzahl ",
      value: "pieces",
      sortable: false,
      align: "end",
    },
    {
      text: "Gewicht ",
      value: "grossWeight",
      sortable: false,
      align: "end",
    },
    { text: "EORI", value: "eori", sortable: false },
    {
      text: "Versender",
      value: "shipper",
      sortable: false,
      width: "15em",
    },
    {
      text: "Empfänger",
      value: "consignee",
      sortable: false,
      width: "15em",
    },
    {
      text: "Warenwert",
      value: "value",
      sortable: false,
      align: "end",
    },
    { text: "Währung", value: "valueCurrency", sortable: false },
    {
      text: "Warenbeschreibung / tarifnummer",
      value: "articles",
      sortable: false,
      width: "25em",
    },
  ];

  created() {
    this.statusFilter = getStatuses();
    this.setDefaultDateRange();

    this.addRefreshToolbarOption(this.reloadFinalWabs);
  }

  timeoutDelay = 0;
  @Watch("options")
  onOptionsChanged(newVal: any, oldVal: any) {
    clearTimeout(this.timeoutDelay);
    this.timeoutDelay = setTimeout(() => {
      this.loadFinalWabs(newVal.page, newVal.itemsPerPage);
    }, 250);
  }

  timeoutShipmentsDelay = 0;
  @Watch("shipmentOptions")
  onShipmentOptionsChanged(newVal: any, oldVal: any) {
    clearTimeout(this.timeoutShipmentsDelay);
    this.timeoutShipmentsDelay = setTimeout(() => {
      this.loadFinalWabShipments(
        this.currentFinalWabItem!.id!,
        this.shipmentOptions.page,
      );
    }, 250);
  }

  @Watch("expandedShipments")
  onExpandedShipmentsChanged(
    newVal: CustomsShipmentItemViewModel[],
    oldVal: CustomsShipmentItemViewModel[],
  ) {
    if (newVal.length === 0) {
      this.currentHandlingItem = null;
      this.currentShipmentDetail = null;
    } else {
      this.currentHandlingItem = newVal[0];

      this.isLoading = true;
      this.currentShipmentDetail = null;
      CustomsExportController.getShipmentDetail(this.currentHandlingItem.id!)
        .then((response) => (this.currentShipmentDetail = response.data))
        .catch((error) =>
          this.displayError(
            "Something went wrong while retrieving the detail data.",
          ),
        )
        .finally(() => (this.isLoading = false));
    }
  }

  onExpandedFinalWabItem({
    item,
    value,
  }: {
    item: CustomsFinalWabItem;
    value: boolean;
  }) {
    this.currentShipmentDetail = null;
    this.expandedShipments = [];
    this.customsHandlingItems = [];

    if (value) {
      this.currentFinalWabItem = item;
      this.loadFinalWabShipments(item.id!, 1);
    } else {
      this.currentFinalWabItem = null;
    }
  }

  onFilterSelected() {
    this.loadFinalWabShipments(this.currentFinalWabItem!.id!, 1);
  }

  async loadFinalWabs(page: number, itemsPerPage: number) {
    try {
      this.isLoading = true;
      let fromDate;
      let tillDate;

      if (this.declarationFilter.fulfilledDateRange?.length === 2) {
        fromDate = this.declarationFilter.fulfilledDateRange[0];
        tillDate = this.declarationFilter.fulfilledDateRange[1];
      }

      const response = await customsTransitApi.getFinalWabs(
        this.declarationFilter.showFulfilled,
        fromDate,
        tillDate,
        this.declarationFilter.search ?? "",
        page,
        itemsPerPage,
      );

      this.items = response.data.finalWabItems ?? [];
      this.totalItems = response.data.totalAmount ?? 0;
      this.expanded = this.items.filter((c) => !!c.t2Declarations!.length);
    } catch {
      this.displayError("Something went wrong while retrieving the final WABs");
    }

    this.isLoading = false;
  }

  async reloadFinalWabs() {
    this.options.page == 1
      ? await this.loadFinalWabs(this.options.page, this.options.itemsPerPage)
      : (this.options.page = 1);
  }

  async loadFinalWabShipments(finalWabItemId: number, page: number) {
    try {
      this.isLoading = true;
      const response = await customsTransitApi.getFinalWabShipments({
        page,
        finalWabId: finalWabItemId,
        itemsPerPage: this.options.itemsPerPage,
        step: this.filter.step ?? undefined,
        shipmentStatusDescription:
          this.filter.shipmentStatusDescription ?? undefined,
        parcels: this.filter.parcels,
        exportMrn: this.filter.exportMrn ?? undefined,
        shipperCountry: this.filter.shipperCountry ?? undefined,
        consigneeCountry: this.filter.consigneeCountry ?? undefined,
      });

      this.customsHandlingItems = response.data.shipments ?? [];
      this.totalShipmentItems = response.data.totalAmount ?? 0;
    } catch {
      this.displayError(
        "Something went wrong while retrieving the shipments of the selected final WAB.",
      );
    }

    this.isLoading = false;
  }

  displayError(errorMessage: string) {
    this.$emit("errorOccured", errorMessage);
  }

  onCreateT2(item: CustomsFinalWabItem) {
    this.selectedFinalWabDialogItem = item;
    this.showDialog = true;
  }

  openMoveShipmentsDialog(item: CustomsFinalWabItem) {
    this.selectedWabItemId = item.id;
    this.showMoveShipmentDialog = true;
  }

  closeMoveShipment() {
    this.selectedWabItemId = 0;
    this.showMoveShipmentDialog = false;
  }

  movedMoveShipment() {
    this.reloadFinalWabs();
  }

  async removeFromFinalWab() {
    try {
      this.isLoading = true;
      await customsTransitApi.removeShipmentsFromFinalWab({
        shipmentIds: [this.currentShipmentDetail!.id],
      });

      await this.loadFinalWabShipments(
        this.currentFinalWabItem!.id!,
        this.options.page,
      );
      this.currentFinalWabItem!.numberOfShipments!--;
    } catch {
      this.displayError(
        "Something went wrong while removing the shipment from the final WAB.",
      );
    }
    this.isLoading = false;
  }

  onDialogClose() {
    this.selectedFinalWabDialogItem = null;
  }

  async onDialogConfirm(formData: CreateTransitDeclarationForm) {
    this.isDialogLoading = true;

    const postData: CreateT2DeclarationsViewModel = {
      amount: formData.amount,
      country: formData.country,
      countryTruck: formData.countryTruck,
      finalWabId: this.selectedFinalWabDialogItem!.id,
      registration: formData.registration,
      registrationTruck: formData.registrationTruck,
      transportAcrossEuBorder: formData.transportAcrossEuBorder,
      signs: formData.signs.map((c) => c.value).filter((c) => !!c),
      routeId: formData.routeId,
      templateId: formData.templateId,
    };

    try {
      await customsTransitApi.createT2Declarations(postData);

      this.isLoading = true;
      try {
        await this.loadFinalWabs(this.options.page, this.options.itemsPerPage);
      } catch {
        this.displayError(
          "Something went wrong while refreshing the final WABs after creating the T2 declarations.",
        );
      }
      this.isLoading = false;
    } catch {
      this.displayError(
        "Something went wrong while creating the T2 declarations.",
      );
    }
    this.isDialogLoading = false;
    this.showDialog = false;
  }

  customExpand(isExpanded: boolean, expandFn: Function) {
    this.expanded = this.expanded.filter(
      (expandedItem) => expandedItem.t2Declarations!.length,
    );
    this.$nextTick(() => {
      expandFn(!isExpanded);
    });
  }

  reloadDeclarations() {
    this.loadFinalWabs(this.options.page, this.options.itemsPerPage);
  }

  setDefaultDateRange() {
    const from = moment().subtract(1, "months");
    const till = moment();

    this.declarationFilter.fulfilledDateRange[0] = from.format("YYYY-MM-DD");
    this.declarationFilter.fulfilledDateRange[1] = till.format("YYYY-MM-DD");
  }
}
