<template>
    <component :is="wrapper" :class="displayValueClass">
        <slot
            :set-editable="setEditable"
            :is-editable="isEditable"
            :changed-css-class="['amber']"
            :is-different-value="isDifferentValue"
        >
            <span
                v-if="!isEditable"
                @click="setEditable(true)"
                class="editable-text-field"
                ><slot name="display-value">{{ displayValue }}</slot></span
            >
            <slot
                v-else
                name="editor"
                :on="handlers"
                :set-editable="setEditable"
            >
                <v-text-field
                    v-bind="$attrs"
                    :value="value"
                    v-on="handlers"
                    :type="type"
                    :autofocus="true"
                    :rules="rules"
                    append-icon="mdi-arrow-u-left-top"
                    :placeholder="placeholder"
                    :error-messages="errorMessages"
                ></v-text-field>
            </slot>
        </slot>
    </component>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';

interface EditableValueProps {
    value?: any;
    wrapper?: string;
    type?: string;
    rules?: (Function | string | boolean)[];
    readonly?: boolean;
    placeholder?: string;
    errorMessages?: string[];
    checkDifference?: boolean;
    differenceFunc?: (initialValue: unknown, currentValue: unknown) => boolean;
}

interface Handlers {
    input: Function;
    change: Function;
    blur: Function;
    'update:error': Function;
    'click:append': Function;
}

const props = withDefaults(defineProps<EditableValueProps>(), {
    wrapper: 'div',
    type: 'text',
    readonly: false,
    placeholder: '',
    errorMessages: () => [],
    checkDifference: true,
    differenceFunc: (initialValue, currentValue) => {
        return initialValue != currentValue;
    },
});
const emits = defineEmits(['input']);
const isEditable = ref(false);
const initialValue = ref<string | number | null>();
const error = ref(false);

const isDifferentValue = computed(() => {
    return props.checkDifference
        ? props.differenceFunc(initialValue.value, props.value)
        : false;
});

const displayValue = computed(() => {
    return !props.value && props.value !== 0 ? '-' : props.value;
});

const displayValueClass = computed(() => {
    return [
        hasErrors.value ? 'error' : isDifferentValue.value ? 'amber' : '',
        props.readonly ? 'disable-events' : 'text-decoration-underline',
    ];
});

const hasErrors = computed(() => {
    return props.errorMessages?.length;
});

const setEditable = (editable: boolean) => {
    if (props.readonly) {
        return;
    }
    isEditable.value = editable;
};

const onInput = (event: any) => {
    if (typeof event === 'string') {
        event = event.trim();
    }
    emits('input', event);
};

const onAppendClick = () => {
    emits('input', initialValue.value);
};

const hideInput = () => {
    if (error.value) {
        return;
    }
    isEditable.value = false;
};

const onError = (hasError: boolean) => {
    error.value = hasError;
};

const handlers = ref<Handlers>({
    input: onInput,
    change: hideInput,
    blur: hideInput,
    'update:error': onError,
    'click:append': onAppendClick,
});

initialValue.value = props.value;
</script>
