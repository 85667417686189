import { emitError } from '@/event-bus';
import { CustomerViewModel, CustomsFlightHandlingApi, MAWBDetailItem } from '@/openapi';
import { ValidationObserver } from 'vee-validate';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

const consolidationApi = new CustomsFlightHandlingApi(undefined, '');

@Component({})
export default class CustomsMawb extends Vue {
    @Prop()
    item!: MAWBDetailItem | null;
    
    @Prop({ default: () => []})
    generalCustomers!: CustomerViewModel[];
    
    loading = false;
    showDatePicker = false;


    editableItem: MAWBDetailItem | null = null;
    copyEditableItem: MAWBDetailItem | null = null;

    @Watch('item', { immediate: true })
    onItemChange(
        newValue: MAWBDetailItem | null,
        oldValue: MAWBDetailItem | null
    ) {
        if (newValue === null) {
            this.editableItem = null;
            return;
        }

        this.editableItem = JSON.parse(JSON.stringify(newValue));
        this.copyEditableItem = JSON.parse(JSON.stringify(newValue));
    }

    async update() {
        if (!this.editableItem) {
            return;
        }

        const entityId = await this.save(this.editableItem);
        this.$emit('updated', { entityId });
    }

    async create() {
        if (!this.editableItem) {
            return;
        }
        const entityId = await this.save(this.editableItem);
        this.$emit('created', { entityId });
    }

    cancel() {
        this.$emit('canceled', { entityId: this.editableItem?.id });
    }

    async save(detailItem: MAWBDetailItem): Promise<number | null> {
        this.loading = true;
        let entityId: number | null = null;
        try {
            const response = await consolidationApi.saveMawb(detailItem);
            entityId = response.data.entityId ?? null;
            (this.$refs.observer as InstanceType<typeof ValidationObserver>).reset();
        } catch {
            emitError('Something went wrong while saving the MAWB information');
        }
        this.loading = false;
        return entityId;
    }

    get netWeight() {
        return this.editableItem?.netWeight;
    }

    set netWeight(value: any) {
        this.editableItem!.netWeight = !value && value !== 0 ? 0 : value;
    }

    get packages() {
        return this.editableItem?.packages;
    }

    set packages(value: any) {
        this.editableItem!.packages = !value && value !== 0 ? 0 : value;
    }

    get brutoWeight() {
        return this.editableItem?.grossWeight;
    }

    set brutoWeight(value: any) {
        this.editableItem!.grossWeight = !value && value !== 0 ? 0 : value;
    }

    get isNew() {
        return this.item?.id === 0;
    }
}
