<template>
  <ValidationObserver ref="observer" slim v-slot="{ invalid }">
    <DefaultDialog
      v-model="value"
      :persistent="true"
      content-overflow-height="60vh"
    >
      <template #header>Connect to new shipment</template>
      <template #content>
        <v-container>
          <v-row
            ><v-col cols="12">
              <p>
                Enter the necessary information below to create a new shipment
                and connect it to this newly created shipment
              </p>
            </v-col>
          </v-row>

          <v-form>
            <v-row>
              <v-col cols="12">
                <div class="form-group">
                  <span class="form-group__header">Shipment information</span>
                  <ValidationProvider
                    name="HAWB"
                    vid="hawbNumber"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="formData.hawbNumber"
                      label="HAWB*"
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                  <ValidationProvider name="Origin" vid="origin">
                    <v-select
                      v-model="formData.origin"
                      :items="origins"
                      item-value="key"
                      item-text="value"
                      label="Origin"
                    ></v-select>
                  </ValidationProvider>
                  <ValidationProvider name="Pieces" vid="pieces">
                    <v-text-field
                      v-model.number="formData.pieces"
                      type="number"
                      min="0"
                      label="Pieces"
                    ></v-text-field>
                  </ValidationProvider>
                  <ValidationProvider name="Real weight" vid="realWeight">
                    <v-text-field
                      v-model.number="formData.realWeight"
                      type="number"
                      label="Real weight"
                    ></v-text-field>
                  </ValidationProvider>
                  <ValidationProvider name="Value" vid="value">
                    <v-text-field
                      v-model.number="formData.value"
                      type="number"
                      min="0"
                      label="Value"
                    ></v-text-field>
                  </ValidationProvider>
                  <ValidationProvider
                    name="Value currency"
                    vid="valueCurrencyId"
                  >
                    <v-select
                      v-model="formData.valueCurrencyId"
                      :items="currencies"
                      item-value="id"
                      item-text="name"
                      label="Currency"
                    ></v-select>
                  </ValidationProvider>
                  <ValidationProvider name="Incoterm" vid="incotermId">
                    <v-select
                      v-model="formData.incotermId"
                      :items="incoterms"
                      item-value="id"
                      item-text="name"
                      label="Incoterm"
                    ></v-select>
                  </ValidationProvider>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <div class="form-group">
                  <span class="form-group__header">Shipper address</span>
                  <ValidationProvider name="Name" vid="shipperName">
                    <v-text-field
                      v-model="formData.shipperName"
                      label="Name"
                    ></v-text-field>
                  </ValidationProvider>
                  <ValidationProvider name="Address" vid="shipperAddress">
                    <v-text-field
                      v-model="formData.shipperAddress"
                      label="Address"
                    ></v-text-field>
                  </ValidationProvider>
                  <ValidationProvider name="ZIP code" vid="shipperZipCode">
                    <v-text-field
                      v-model="formData.shipperZipCode"
                      label="ZIP code"
                    ></v-text-field>
                  </ValidationProvider>
                  <ValidationProvider name="City" vid="shipperCity">
                    <v-text-field
                      v-model="formData.shipperCity"
                      label="City"
                    ></v-text-field>
                  </ValidationProvider>
                  <ValidationProvider name="Country" vid="shipperCountryId">
                    <v-select
                      v-model="formData.shipperCountryId"
                      :items="countries"
                      item-value="id"
                      :item-text="getCountrySelectText"
                      label="Country"
                    ></v-select>
                  </ValidationProvider>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <div class="form-group">
                  <span class="form-group__header">Invoice address</span>
                  <ValidationProvider name="Name" vid="invoiceName">
                    <v-text-field
                      v-model="formData.invoiceName"
                      label="Name"
                    ></v-text-field>
                  </ValidationProvider>
                  <ValidationProvider name="Address" vid="invoiceAddress">
                    <v-text-field
                      v-model="formData.invoiceAddress"
                      label="Address"
                    ></v-text-field>
                  </ValidationProvider>
                  <ValidationProvider name="ZIP Code" vid="invoiceZipCode">
                    <v-text-field
                      v-model="formData.invoiceZipCode"
                      label="ZIP code"
                    ></v-text-field>
                  </ValidationProvider>
                  <ValidationProvider name="City" vid="invoiceCity">
                    <v-text-field
                      v-model="formData.invoiceCity"
                      label="City"
                    ></v-text-field>
                  </ValidationProvider>
                  <ValidationProvider name="Country" vid="invoiceCountryId">
                    <v-select
                      v-model="formData.invoiceCountryId"
                      :items="countries"
                      item-value="id"
                      :item-text="getCountrySelectText"
                      label="Country"
                    ></v-select>
                  </ValidationProvider>
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <div class="form-group">
                  <span class="form-group__header">Order/details</span>
                  <ValidationProvider name="EZA" vid="eza">
                    <v-checkbox
                      v-model="formData.isEza"
                      label="EZA"
                      hide-details
                    ></v-checkbox>
                  </ValidationProvider>
                  <ValidationProvider name="Transit T1" vid="transitOne">
                    <v-checkbox
                      v-model="formData.transitT1"
                      label="Transit T1"
                      hide-details
                    ></v-checkbox>
                  </ValidationProvider>
                  <ValidationProvider
                    name="Clear. by cust"
                    vid="clearedByCustoms"
                  >
                    <v-checkbox
                      v-model="formData.isClearedByCustomer"
                      label="Clear. by cust"
                      hide-details
                    ></v-checkbox>
                  </ValidationProvider>
                  <ValidationProvider
                    name="Charges to sender"
                    vid="chargesToSender"
                  >
                    <v-checkbox
                      v-model="formData.chargesToSender"
                      label="Charges to sender"
                      hide-details
                    ></v-checkbox>
                  </ValidationProvider>
                  <ValidationProvider
                    name="Customer"
                    vid="customer"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-select
                      v-model="formData.customerId"
                      :items="customers"
                      item-value="id"
                      item-text="shortName"
                      label="Customer*"
                      :error-messages="errors"
                    ></v-select>
                  </ValidationProvider>
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <div class="form-group">
                  <span class="form-group__header">Contact details</span>
                  <ValidationProvider name="Phone" vid="contactPhone">
                    <v-text-field
                      v-model="formData.contactPhone"
                      label="Phone"
                    ></v-text-field>
                  </ValidationProvider>
                  <ValidationProvider
                    name="Email"
                    vid="contactEmail"
                    rules="email"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="formData.contactEmail"
                      label="Email"
                      type="email"
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <div class="form-group">
                  <span class="form-group__header">Financial</span>
                  <ValidationProvider name="Charges 'Zoll'" vid="chargesZoll">
                    <v-text-field
                      v-model.number="formData.chargesZoll"
                      type="number"
                      label="Charges 'Zoll'"
                    ></v-text-field>
                  </ValidationProvider>
                  <ValidationProvider name="Charges 'EUST'" vid="chargesEust">
                    <v-text-field
                      v-model.number="formData.chargesEust"
                      type="number"
                      label="Charges 'EUST'"
                    ></v-text-field>
                  </ValidationProvider>
                  <ValidationProvider
                    name="Charges 'other' + VAT"
                    vid="chargesOther"
                  >
                    <v-text-field
                      v-model.number="formData.chargesOther"
                      type="number"
                      label="Charges 'other' + VAT"
                    ></v-text-field>
                  </ValidationProvider>
                  <ValidationProvider
                    name="Charges 'other' w/o VAT"
                    vid="chargesOtherWithoutVat"
                  >
                    <v-text-field
                      v-model.number="formData.chargesOtherWithoutVat"
                      type="number"
                      label="Charges 'other' w/o VAT"
                    ></v-text-field>
                  </ValidationProvider>
                  <ValidationProvider name="Abfertig. EZA" vid="abfertigEza">
                    <v-text-field
                      v-model.number="formData.abfertigEza"
                      type="number"
                      label="Abfertig. EZA"
                    ></v-text-field>
                  </ValidationProvider>
                  <ValidationProvider name="Zessionsgeb." vid="zessionsgeb">
                    <v-text-field
                      v-model.number="formData.zessionsgeb"
                      type="number"
                      label="Zessionsgeb."
                    ></v-text-field>
                  </ValidationProvider>
                  <ValidationProvider name="CC Charges" vid="ccCharges">
                    <v-text-field
                      v-model.number="formData.ccCharges"
                      type="number"
                      label="CC Charges"
                    ></v-text-field>
                  </ValidationProvider>
                  <ValidationProvider name="Zus. Frachtkost." vid="frachtkost">
                    <v-text-field
                      v-model.number="formData.frachtkosten"
                      type="number"
                      label="Zus. Frachkost."
                    ></v-text-field>
                  </ValidationProvider>
                  <ValidationProvider name="Lagerkosten" vid="lagerkosten">
                    <v-text-field
                      v-model.number="formData.lagerkosten"
                      type="number"
                      label="Lagerkosten"
                    ></v-text-field>
                  </ValidationProvider>
                  <ValidationProvider name="Beschaukosten" vid="beschaukosten">
                    <v-text-field
                      v-model.number="formData.beschaukosten"
                      type="number"
                      label="Beschaukosten"
                    ></v-text-field>
                  </ValidationProvider>
                  <ValidationProvider name="Vorlageengelt" vid="vorlageengelt">
                    <v-text-field
                      v-model.number="formData.vorlageengelt"
                      type="number"
                      label="Vorlageengelt"
                    ></v-text-field>
                  </ValidationProvider>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </template>
      <template #footer>
        <v-spacer></v-spacer>
        <v-btn @click="$emit('input', false)" :disabled="isLoading"
          >Cancel</v-btn
        >
        <v-btn
          :disabled="invalid"
          color="success"
          :loading="isLoading"
          @click="$emit('confirm', formData)"
          >Connect</v-btn
        >
      </template>
    </DefaultDialog>
  </ValidationObserver>
</template>
<script setup lang="ts">
import { CountryOfOriginViewModel } from "@/openapi";
import store from "@/store";
import { DataAction } from "@/store/dataModule";
import { computed, ref, watch } from "vue";
import DefaultDialog from "./DefaultDialog.vue.html";

export interface ConnectToNewShipmentForm {
  hawbNumber?: string | null;
  origin?: number | null;
  pieces?: number | null;
  realWeight?: number | null;
  value?: number | null;
  valueCurrencyId?: number | null;
  incotermId?: number | null;
  shipperName?: string | null;
  shipperAddress?: string | null;
  shipperZipCode?: string | null;
  shipperCity?: string | null;
  shipperCountryId?: number | null;
  invoiceName?: string | null;
  invoiceAddress?: string | null;
  invoiceZipCode?: string | null;
  invoiceCity?: string | null;
  invoiceCountryId?: number | null;
  isEza?: boolean;
  transitT1?: boolean;
  isClearedByCustomer?: boolean;
  chargesToSender?: boolean;
  customerId?: number | null;
  contactPhone?: string | null;
  contactEmail?: string | null;
  chargesZoll?: number | null;
  chargesEust?: number | null;
  chargesOther?: number | null;
  chargesOtherWithoutVat?: number | null;
  abfertigEza?: number | null;
  zessionsgeb?: number | null;
  ccCharges?: number | null;
  frachtkosten?: number | null;
  lagerkosten?: number | null;
  beschaukosten?: number | null;
  vorlageengelt?: number | null;
}

interface ConnectToNewShipmentFormProps {
  value: boolean;
  isLoading: boolean;
  initialFormData?: ConnectToNewShipmentForm | null;
}
const defaultFormValues: ConnectToNewShipmentForm = {
  hawbNumber: null,
  pieces: null,
  realWeight: null,
  value: null,
  valueCurrencyId: null,
  incotermId: null,
  shipperName: null,
  shipperAddress: null,
  shipperZipCode: null,
  shipperCity: null,
  shipperCountryId: null,
  invoiceName: null,
  invoiceAddress: null,
  invoiceZipCode: null,
  invoiceCity: null,
  invoiceCountryId: null,
  isEza: false,
  transitT1: false,
  isClearedByCustomer: false,
  chargesToSender: false,
  customerId: null,
  contactPhone: null,
  contactEmail: null,
  chargesZoll: null,
  chargesEust: null,
  chargesOther: null,
  chargesOtherWithoutVat: null,
  abfertigEza: null,
  zessionsgeb: null,
  ccCharges: null,
  frachtkosten: null,
  beschaukosten: null,
  vorlageengelt: null,
};

const props = withDefaults(defineProps<ConnectToNewShipmentFormProps>(), {
  initialFormData: () => null,
});
const emits = defineEmits(["input", "confirm"]);

const formData = ref({ ...defaultFormValues });
const observer = ref<(Vue & { reset: () => void }) | null>(null);

store.dispatch(DataAction.FetchIncoterms);
store.dispatch(DataAction.FetchCountriesOfOrigin);
store.dispatch(DataAction.FetchGeneralCustomers);
store.dispatch(DataAction.FetchCurrencies);
store.dispatch(DataAction.FetchOrigins);
const incoterms = computed(() => store.getters.incoterms);
const countries = computed(() => store.getters.countriesOfOrigin);
const customers = computed(() => store.getters.generalCustomers);
const currencies = computed(() => store.getters.currencies);
const origins = computed(() => store.getters.origins);

watch(
  () => props.value,
  async (newValue, oldValue) => {
    if (!newValue) {
      formData.value = { ...defaultFormValues };
      observer.value?.reset();
    }
  },
);

watch(
  () => props.initialFormData,
  (
    newValue: ConnectToNewShipmentForm | null,
    oldValue: ConnectToNewShipmentForm | null,
  ) => {
    if (newValue) {
      formData.value = { ...formData.value, ...newValue };
    }
  },
  { immediate: true },
);

function getCountrySelectText(item: CountryOfOriginViewModel) {
  return `${item.code} - ${item.description}`;
}
</script>

<style lang="scss" scoped>
.form-group {
  margin-bottom: 10px;
}

.form-group__header {
  font-size: 18px;
  font-weight: 600;
}
</style>
