import { emitError } from '@/event-bus';
import {
    CustomsHandlingConsolidationViewModel,
    Int32StringKeyValueItem,
    ShipmentApi,
} from '@/openapi';
import { DataAction } from '@/store/dataModule';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { DataOptions, DataTableHeader } from 'vuetify';

const api = new ShipmentApi(undefined, '');

@Component({})
export default class ShipmentsHistory extends Vue {
    @Prop({ required: true, default: '' })
    consolidation!: string;

    isLoading = false;
    headers: DataTableHeader[] = [
        {
            text: 'HAWB',
            align: 'start',
            value: 'hawb',
            groupable: false,
            sortable: false,
        },
        { text: 'Pieces', value: 'pieces', groupable: false, sortable: false },
        { text: 'Weight', value: 'weight', groupable: false, sortable: false },
        {
            text: 'Multi piece complete',
            value: 'multiPieceComplete',
            groupable: false,
            sortable: false,
        },
        {
            text: 'Export MRN',
            value: 'exportMrn',
            groupable: false,
            sortable: false,
        },
        { text: 'Value', value: 'value', groupable: false, sortable: false },
        {
            text: 'Value currency',
            value: 'valueCurrency',
            groupable: false,
            sortable: false,
        },
        {
            text: 'Warehouse location',
            value: 'warehouseLocation',
            groupable: false,
            sortable: false,
        },
        {
            text: 'Incoterm',
            value: 'incoterm',
            groupable: false,
            sortable: false,
        },
        {
            text: 'Category',
            value: 'category',
            groupable: false,
            sortable: false,
        },
        { text: '', value: 'actions', sortable: false },
    ];

    options: DataOptions = {
        page: 1,
        itemsPerPage: 50,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
    };
    footerOptions = {
        showFirstLastPage: true,
        itemsPerPageOptions: [5, 25, 50, 100],
        disablePagination: false,
    };

    totalAmountOfItems: number = 0;
    items: CustomsHandlingConsolidationViewModel[] = [];

    showExportMrnModal = false;

    @Watch('consolidation', { immediate: true })
    consolidationWatcher(newVal: string, oldVal: string) {
        if (!!newVal) {
            this.getShipmentHistory(1);
        }
    }

    timeoutDelay = 0;
    @Watch('options')
    onOptionsChanged(newVal: any, oldVal: any) {
        clearTimeout(this.timeoutDelay);
        this.timeoutDelay = setTimeout(() => {
            this.getShipmentHistory();
        }, 250);
    }

    async created() {
        await this.$store.dispatch(DataAction.FetchCustomsHandlingCategories);
    }

    async getShipmentHistory(page?: number) {
        page ??= this.options.page;
        this.isLoading = true;
        try {
            const response = await api.getShipmentsOfConsolidationHistory(
                this.consolidation,
                page,
                this.options.itemsPerPage
            );
            this.items = response.data.items ?? [];
            this.options.page = response.data.page ?? 1;
            this.options.itemsPerPage = response.data.itemsPerPage ?? 5;
            this.totalAmountOfItems = response.data.totalAmountOfItems ?? 0;
        } catch {
            emitError('Something went wrong while retrieving the items.');
        }
        this.isLoading = false;
    }

    getCategoryName(category: number) {
        return this.categories.find((c) => c.key === category)?.value ?? '';
    }

    get categories(): Int32StringKeyValueItem[] {
        return this.$store.getters.customsHandlingCategories;
    }
}
