<template>
  <v-dialog
    v-model="groupArticleDialog"
    persistent
    :content-class="alignTop ? 'dialog-top' : ''"
    :max-width="alignTop ? '95vw' : '690px'"
  >
    <v-card :class="{ 'dialog-top': alignTop }">
      <v-toolbar color="primary">
        <v-toolbar-title> Group articles X</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="alignTop = !alignTop">
          <v-icon v-if="alignTop">mdi-format-vertical-align-bottom</v-icon>
          <v-icon v-if="!alignTop">mdi-format-vertical-align-top</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-title>
        <span class="text-h5"></span>
      </v-card-title>
      <v-card-text>
        <v-container v-if="!alignTop">
          <v-row>
            <slot name="1" :align-top="alignTop"></slot>
            <slot name="2" :align-top="alignTop"></slot>
            <slot name="3" :align-top="alignTop"></slot>
            <slot name="4" :align-top="alignTop"></slot>
          </v-row>
        </v-container>
        <v-container v-else fluid>
          <v-row>
            <v-col>
              <v-row>
                <slot name="1" :align-top="alignTop"></slot>
              </v-row>
            </v-col>
            <v-col>
              <v-row>
                <slot name="2" :align-top="alignTop"></slot>
              </v-row>
            </v-col>
            <v-col>
              <v-row>
                <slot name="3" :align-top="alignTop"></slot>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <slot name="4" :align-top="alignTop"></slot>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="groupArticleDialog = false"> Close </v-btn>
        <slot name="groupArticlesButton"></slot>
        <slot name="setArticlesInfoButton"></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";

interface GroupArticlesDialogProps {
  groupArticleDialog: boolean;
}

const props = defineProps<GroupArticlesDialogProps>();
const emits = defineEmits<{
  (e: "updateGroupArticleDialog", value: boolean): void;
}>();

const groupArticleDialog = ref(props.groupArticleDialog);
const alignTop = ref(false);

watch(
  () => props.groupArticleDialog,
  (newVal, oldVal) => {
    groupArticleDialog.value = newVal;
  },
);

watch(
  () => groupArticleDialog.value,
  (newVal, oldVal) => {
    emits("updateGroupArticleDialog", newVal);
  },
);
</script>
