import { TransitDeclarationData } from '@/openapi';
import { ValidationProvider } from 'vee-validate';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { DataAction } from '../../store/dataModule';
@Component({})
export default class TransitDefinitionEditor extends Vue {
    @Prop({ default: null })
    value!: number | null;

    selectedSenderId: number | null = null;

    $refs!: {
        toField: InstanceType<typeof ValidationProvider>;
    };

    async created() {
        await this.$store.dispatch(
            DataAction.FetchTransitDeclarationDefinitionRoutes
        );
    }

    onSelectedSenderChange() {
        this.selectedRoute = null;
        this.$refs.toField!.reset();
    }

    get transitDeclarationDefinitionRoutes(): TransitDeclarationData[] {
        return this.$store.getters.transitDeclarationDefinitionRoutes;
    }

    get selectedRoute(): number | null {
        return this.value;
    }

    get senders() {
        return this.transitDeclarationDefinitionRoutes.map((c) => ({
            value: c.senderId,
            text: c.name,
        }));
    }

    get routes() {
        return (
            this.transitDeclarationDefinitionRoutes
                .find(
                    (c) =>
                        c.senderId == this.selectedSenderId ||
                        c.routes?.some((d) => d.routeId == this.selectedRoute)
                )
                ?.routes?.map((c) => ({ value: c.routeId, text: c.name })) ?? []
        );
    }

    set selectedRoute(routeId: number | null) {
        this.$emit('input', routeId);
    }

    get selectedSender() {
        return (
            this.selectedSenderId ||
            (this.transitDeclarationDefinitionRoutes.find((c) =>
                c.routes?.some((d) => d.routeId == this.selectedRoute)
            )?.senderId ??
                null)
        );
    }

    set selectedSender(senderId: number | null) {
        this.selectedSenderId = senderId;
    }
}
