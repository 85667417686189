import { Component, Mixins } from 'vue-property-decorator';
import {
    KeyValueItem,
    PaperworkItem,
    SavePaperworkDocumentsRequest,
} from '@/openapi';

import axios from 'axios';
import AddMrnDialog from './dialogs/AddMrnDialog.vue.html';
import ToolbarMixin from '@/mixins/ToolbarMixin';
import { emitError } from '@/event-bus';
@Component({
    components: {
        AddMrnDialog,
    },
})
export default class CustomsPaperworkHandeling extends Mixins(ToolbarMixin) {
    color!: string;
    showAddMrnField: boolean = false;
    allCategoriesChosen: boolean = false;

    isLoading = false;
    isSaving = false;

    categories: KeyValueItem[] = [];
    expanded = [];

    items: PaperworkItem[] = [];
    currentPaperworkItem: PaperworkItem | null = null;
    showDialogAddMrn = false;
    dialogAddMrnLoading = false;

    headers = [
        {
            text: 'HAWB',
            value: 'shipmentHawb',
            align: 'center',
            sortable: false,
        },
        {
            text: 'Documents',
            value: 'documents',
            align: 'start',
            sortable: false,
        },
        {
            text: 'MRN',
            value: 'mrn',
            align: 'center',
            sortable: false,
        },
        {
            text: '',
            value: '',
            align: 'end',
            sortable: false,
        },
    ];

    async created() {
        this.isLoading = true;
        const paperWorkPromise = this.getPaperwork();
        const categoriesPromise = this.getCategories();
        await Promise.all([paperWorkPromise, categoriesPromise]);
        this.isLoading = false;

        this.addRefreshToolbarOption(this.getPaperwork);
    }

    onExpandedItem({ item, value }: { item: PaperworkItem; value: boolean }) {
        if (value) {
            this.currentPaperworkItem = item;
        } else {
            this.currentPaperworkItem = null;
        }
    }

    async getPaperwork() {
        try {
            const response = await axios.get(
                '/CustomsPaperworkHandling/GetPaperwork'
            );
            this.items = response.data as PaperworkItem[];
        } catch {
            emitError(
                'Something went wrong while retrieving the paperwork'
            );
        }
    }

    async getCategories() {
        try {
            const response = await axios.get(
                '/Data/GetPaperworkCategories'
            );
            this.categories = response.data as KeyValueItem[];
        } catch {
            emitError(
                'Something went wrong while retrieving the categories'
            );
        }
    }

    async onMrnEntered({ mrn }: { mrn: string }) {
        this.dialogAddMrnLoading = true;
        try {
            const data = {
                customsHandlingId: this.currentPaperworkItem?.shipmentId,
                mrn: mrn,
            };
            await axios.post('/CustomsPaperworkHandling/SetMrn', data, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            this.currentPaperworkItem!.mrn = mrn;
        } catch {
            emitError('Something went wrong while setting the mrn');
        }
        this.dialogAddMrnLoading = false;
        this.showDialogAddMrn = false;
    }

    get isAllowedToSave() {
        return !!this.currentPaperworkItem?.documents?.every(
            (doc) => doc.category !== null
        );
    }

    async save() {
        const data: SavePaperworkDocumentsRequest = {
            documents: this.currentPaperworkItem?.documents,
        };

        try {
            this.isSaving = true;
            await axios.post(
                '/CustomsPaperworkHandling/SavePaperworkDocuments',
                data,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }
            );

            this.isLoading = true;
            await this.getPaperwork();
            this.isLoading = false;
        } catch {
            emitError(
                'Something went wrong while saving the categories to the corresponding documents'
            );
        }
        this.isSaving = false;
    }

    openDialogShipmentAddMrn(item: PaperworkItem) {
        this.currentPaperworkItem = item;
        this.showDialogAddMrn = true;
    }

    displayError(errorMessage: string) {
        this.$emit('errorOccured', errorMessage);
    }
}
