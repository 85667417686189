import Vue from 'vue';
import { defineStore } from 'pinia';
import { Invoice, Mrn } from '@/types/consolidation-types';
import { ArticleInformationFile, ConsolidationShipperAddress } from '@/openapi';

export interface ConsolidationState {
    parcelNumbers: string[];
    shipperAddress: ConsolidationShipperAddress | null;
    consignee: ConsolidationConsigneeAddress;
    reasonForExport: string | null;
    invoices: Invoice[];
    mrns: Mrn[];
    articleInformationItems: ArticleInformationFile[];
    freightCosts: number;
    incoterm: string | null;
}

export interface ConsolidationConsigneeAddress {
    name: string;
    street: string;
    zipCode: string;
    city: string;
    country: string;
    eoriNumber: string;
    vatNumber: string;
    contactName: string;
    contactPhone: string;
}

const getInitialState = (): ConsolidationState => ({
    parcelNumbers: [],
    shipperAddress: {
        additionalAddress: '',
        address: '',
        city: '',
        contact: '',
        country: '',
        name: '',
        phone: '',
        zipcode: '',
    },
    consignee: {
        name: '',
        street: '',
        zipCode: '',
        city: '',
        country: '',
        eoriNumber: '',
        vatNumber: '',
        contactName: '',
        contactPhone: '',
    },
    reasonForExport: null,
    invoices: [],
    mrns: [],
    articleInformationItems: [],
    freightCosts: 0,
    incoterm: null,
});

export const useConsolidationStore = defineStore('consolidation', {
    state: getInitialState,
    actions: {
        resetState() {
            Object.assign(this, getInitialState());
        },
        resetStateExcludingConsigneeAddress() {
            const newData = { ...getInitialState() };
            newData.consignee = { ...this.consignee };
            Object.assign(this, newData);
        },
        setParcelNumbers(parcelNumbers: string[]) {
            Vue.set(this, 'parcelNumbers', [...parcelNumbers]);
        },
        setShipperAddress(shipperAddress: ConsolidationShipperAddress | null) {
            this.shipperAddress =
                shipperAddress != null ? { ...shipperAddress } : null;
        },
        updateConsigneeAddressName(name: string) {
            this.consignee!.name = name;
        },
        updateConsigneeAddressStreet(street: string) {
            this.consignee!.street = street;
        },
        updateConsigneeAddressZipCode(zipCode: string) {
            this.consignee!.zipCode = zipCode;
        },
        updateConsigneeAddressCity(city: string) {
            this.consignee!.city = city;
        },
        updateConsigneeAddressCountry(country: string) {
            this.consignee!.country = country;
        },
        updateConsigneeAddressEoriNumber(eoriNumber: string) {
            this.consignee!.eoriNumber = eoriNumber;
        },
        updateConsigneeAddressVatNumber(vatNumber: string) {
            this.consignee!.vatNumber = vatNumber;
        },
        updateConsigneeAddressContactName(contactName: string) {
            this.consignee!.contactName = contactName;
        },
        updateConsigneeAddressContactPhone(contactPhone: string) {
            this.consignee!.contactPhone = contactPhone;
        },
        updateReasonForExport(reasonForExport: string) {
            this.reasonForExport = reasonForExport;
        },

        setInvoices(invoices: Invoice[]) {
            Vue.set(this, 'invoices', [...invoices]);
        },
        addInvoice(invoice: Invoice) {
            this.invoices.push(invoice);
        },
        removeInvoice(invoiceId: number) {
            const index = this.invoices.findIndex((c) => c.id === invoiceId);
            if (index > -1) {
                this.invoices.splice(index, 1);
            }
        },
        editInvoice(editedInvoice: Invoice) {
            const invoice = this.invoices.find(
                (c) => c.id === editedInvoice.id
            );
            if (!!invoice) {
                Object.assign(invoice, editedInvoice);
            }
        },

        setMrns(mrns: Mrn[]) {
            Vue.set(this, 'mrns', [...mrns]);
        },
        addMrn(mrn: Mrn) {
            this.mrns.push(mrn);
        },
        removeMrn(mrnId: number) {
            const index = this.mrns.findIndex((c) => c.id === mrnId);
            if (index > -1) {
                this.mrns.splice(index, 1);
            }
        },
        editMrn(editedMrn: Mrn) {
            const mrn = this.mrns.find((c) => c.id === editedMrn.id);
            if (!!mrn) {
                Object.assign(mrn, editedMrn);
            }
        },

        updateFreightCosts(freightCosts: number) {
            this.freightCosts = freightCosts;
        },

        updateIncoterm(incoterm: string | null) {
            this.incoterm = incoterm;
        },
    },
    getters: {
        selectedExportDeclaration: (state) =>
            state.articleInformationItems.find(
                (c) => c.typeAlias === 'ExportDeclaration'
            ),
        selectedInvoice: (state) =>
            state.articleInformationItems.find(
                (c) => c.typeAlias === 'Invoice'
            ),
    },
});
