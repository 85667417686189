import { Component, Prop, Vue } from 'vue-property-decorator';

export interface DefaultDialogButtonState {
    loading: boolean;
    disabled: boolean;
}

@Component({})
export default class DefaultDialog extends Vue {
    @Prop({ default: false })
    value!: boolean;

    @Prop({ default: '660px' })
    maxWidth!: string;

    @Prop({ default: false })
    loading!: boolean;

    @Prop({ default: true })
    persistent!: boolean;

    @Prop()
    contentOverflowHeight?: string;

    @Prop({ default: 'Close' })
    closeButtonText?: string;

    @Prop({ default: 'Confirm' })
    confirmButtonText?: string;

    @Prop({
        default: () => ({
            loading: false,
            disabled: false,
        }),
    })
    closeButtonState!: DefaultDialogButtonState;

    @Prop({
        default: () => ({
            loading: false,
            disabled: false,
        }),
    })
    confirmButtonState!: DefaultDialogButtonState;

    @Prop({ default: false })
    readonly!: boolean;

    get cardTextStyle() {
        return this.contentOverflowHeight
            ? `height: ${this.contentOverflowHeight}; overflow: auto;`
            : '';
    }
}
