var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Fragment',[_c('v-toolbar',{staticClass:"email-tabs grey darken-3",attrs:{"color":"white","elevation":"0","dark":"","rounded":""}},[_c('v-tabs',{attrs:{"background-color":"grey darken-3","color":"white","dark":"","fixed-tabs":""}},[_c('v-tab',{on:{"change":function($event){_setup.isPlainText = false}}},[_vm._v("HTML")]),_c('v-tab',{on:{"change":function($event){_setup.isPlainText = true}}},[_vm._v("Plain text")])],1)],1),(_vm.selectedEmailTemplate)?_c('table',{staticClass:"email-preview",attrs:{"border":"0","cellpadding":"0","cellspacing":"0"}},[_c('tr',[_c('td'),_c('td',{staticClass:"email-preview__container"},[_c('div',{staticClass:"email-preview__content"},[(!_setup.isPlainText && _setup.hasLogo)?_c('div',{staticClass:"email-preview__header"},[(_vm.selectedEmailTemplate.logoPreview)?_c('img',{class:[
                                'email-preview__logo',
                                !_vm.selectedEmailTemplate.logoTwoPreview
                                    ? 'email-preview__logo--single'
                                    : '',
                            ],attrs:{"src":_vm.selectedEmailTemplate.logoPreview,"alt":"Useful alt text","border":"0"}}):_vm._e(),(_setup.hasTwoLogos)?_c('span',{staticClass:"email-preview__logo-text"},[_vm._v("IM AUFTRAG VON")]):_vm._e(),(_vm.selectedEmailTemplate.logoTwoPreview)?_c('img',{staticClass:"email-preview__logo",attrs:{"src":_vm.selectedEmailTemplate.logoTwoPreview,"alt":"Useful alt text","border":"0"}}):_vm._e()]):_vm._e(),_c('table',{staticClass:"email-preview-main"},[_c('tr',[_c('td',{staticClass:"email-preview-main__wrapper"},[_c('table',{attrs:{"border":"0","cellpadding":"0","cellspacing":"0"}},[_c('tr',[_c('td',[(_setup.isPlainText)?[_c('p',{staticClass:"normal-text",domProps:{"innerHTML":_vm._s(
                                                        _setup.emailTemplatePlainText
                                                    )}})]:[_c('p',{staticClass:"normal-text",domProps:{"innerHTML":_vm._s(
                                                        _setup.emailTemplateBodyHtml
                                                    )}}),_c('p',{staticClass:"normal-text",domProps:{"innerHTML":_vm._s(
                                                        _setup.emailTemplateLegalInformationHtml
                                                    )}})]],2)])])])])]),(!_setup.isPlainText)?_c('div',{staticClass:"email-preview-footer"},[_c('table',{attrs:{"border":"0","cellpadding":"0","cellspacing":"0"}},[_c('tr',[_c('td',{staticClass:"email-preview-footer__text-wrapper"},[_c('span',{staticClass:"apple-link email-preview-footer__text",domProps:{"innerHTML":_vm._s(_setup.emailTemplateFooterHtml)}})])])])]):_vm._e(),_c('v-divider'),(!_setup.isPlainText && _setup.hasLogo)?_c('div',{staticClass:"email-preview__header"},[(_vm.selectedEmailTemplate.logoPreview)?_c('img',{class:[
                                'email-preview__logo',
                                !_vm.selectedEmailTemplate.logoTwoPreview
                                    ? 'email-preview__logo--single'
                                    : '',
                            ],attrs:{"src":_vm.selectedEmailTemplate.logoPreview,"alt":"Useful alt text","border":"0"}}):_vm._e(),(_setup.hasTwoLogos)?_c('span',{staticClass:"email-preview__logo-text"},[_vm._v("ON BEHALF OF")]):_vm._e(),(_vm.selectedEmailTemplate.logoTwoPreview)?_c('img',{staticClass:"email-preview__logo",attrs:{"src":_vm.selectedEmailTemplate.logoTwoPreview,"alt":"Useful alt text","border":"0"}}):_vm._e()]):_vm._e(),_c('table',{staticClass:"email-preview-main"},[_c('tr',[_c('td',{staticClass:"email-preview-main__wrapper"},[_c('table',{attrs:{"border":"0","cellpadding":"0","cellspacing":"0"}},[_c('tr',[_c('td',[(_setup.isPlainText)?[_c('p',{staticClass:"normal-text",domProps:{"innerHTML":_vm._s(
                                                        _setup.emailTemplatePlainTextAvis
                                                    )}})]:[_c('p',{staticClass:"normal-text",domProps:{"innerHTML":_vm._s(
                                                        _setup.emailTemplateBodyHtmlAvis
                                                    )}}),_c('p',{staticClass:"normal-text",domProps:{"innerHTML":_vm._s(
                                                        _setup.emailTemplateLegalInformationHtmlAvis
                                                    )}})]],2)])])])])]),(!_setup.isPlainText)?_c('div',{staticClass:"email-preview-footer"},[_c('table',{attrs:{"border":"0","cellpadding":"0","cellspacing":"0"}},[_c('tr',[_c('td',{staticClass:"email-preview-footer__text-wrapper"},[_c('span',{staticClass:"apple-link email-preview-footer__text",domProps:{"innerHTML":_vm._s(_setup.emailTemplateFooterHtmlAvis)}})])])])]):_vm._e()],1)]),_c('td')])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }