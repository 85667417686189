<template>
    <Fragment>
        <DefaultDialog
            v-model="localValue"
            :loading="loading"
            @click:close="localValue = false"
            :readonly="true"
            :max-width="1200"
        >
            <template #header> Remark overview </template>
            <template #content>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <v-data-table
                                :headers="headers"
                                :items="items"
                                :server-items-length="totalAmountOfItems"
                                item-key="id"
                                fixed-header
                                dense
                                height="500px"
                                class="elevation-1"
                                hide-default-footer
                                :loading="loading"
                                @dblclick:row="onDblClick"
                            >
                                <template #item.createdAt="{ item }">{{
                                    item.createdAt | formatDate
                                }}</template>

                                <template #item.remark="{ item }">{{
                                    truncate(item.remark, 30)
                                }}</template>
                            </v-data-table>
                        </v-col></v-row
                    ></v-container
                >
            </template>
        </DefaultDialog>

        <RemarkDialog
            v-model="showRemarkDialog"
            :initial-form-data="remarkFormData"
            :readonly="true"
        ></RemarkDialog>
    </Fragment>
</template>

<script setup lang="ts">
import { emitErrorWithFallback } from '@/event-bus';
import { ProblemShipmentRemark, ProblemShipmentsApi } from '@/openapi';
import { computed, onBeforeMount, ref } from 'vue';
import { DataTableHeader } from 'vuetify';
import DefaultDialog from '../DefaultDialog.vue.html';
import RemarkDialog, {
    RemarkDialogFormData,
} from '@/components/dialogs/problem-shipments/RemarkDialog.vue';

interface RemarkOverviewDialogProps {
    value: boolean;
    blockingMilestoneId: number;
}
const api = new ProblemShipmentsApi(undefined, '');

const props = defineProps<RemarkOverviewDialogProps>();
const emits = defineEmits(['input', 'confirm']);

const showRemarkDialog = ref(false);

const headers = ref<DataTableHeader[]>([
    {
        text: 'Date',
        value: 'createdAt',
        align: 'start',
        width: 300,
        sortable: false,
    },
    {
        text: 'Employee',
        value: 'createdBy',
        sortable: false,
    },
    {
        text: 'Remark',
        value: 'remark',
        sortable: false,
    },
]);

const items = ref<ProblemShipmentRemark[]>([]);
const totalAmountOfItems = ref(0);
const loading = ref(false);
const currentItem = ref<ProblemShipmentRemark | null>();

const localValue = computed({
    get() {
        return props.value;
    },
    set(newValue: boolean) {
        emits('input', newValue);
    },
});

const remarkFormData = computed(() => {
    return { remark: currentItem.value?.remark } as RemarkDialogFormData;
});

const onDblClick = (
    event: MouseEvent,
    { item }: { item: ProblemShipmentRemark }
) => {
    currentItem.value = item;
    showRemarkDialog.value = true;
};

const getItems = async () => {
    loading.value = true;
    try {
        const response = await api.getProblemShipmentRemarks(
            props.blockingMilestoneId
        );

        items.value = response.data;
        totalAmountOfItems.value = response.data.length;
    } catch (e: unknown) {
        emitErrorWithFallback(
            e,
            'Something went wrong while retrieving the remarks'
        );
    }
    loading.value = false;
};

const truncate = (text: string, size: number) => {
    return text.length <= size ? text : `${text.substring(0, size + 1)}...`;
};

onBeforeMount(async () => {
    await getItems();
});
</script>
