import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Actions } from '@/config';

export interface SetInspectionReasonForm {
    reason?: string;
}

@Component({})
export default class SetInspectionReasonDialog extends Vue {
    @Prop({default: false})
    value!: Boolean;

    @Prop({default: false})
    isLoading!: Boolean;

    @Prop({default: ''})
    title!: String

    @Prop({default: 'Confirm'})
    confirmButtonText!: String

    @Prop({default: 'Close'})
    closeButtonText!: String

    rules = {
        required: (value: string) =>
            (value && !!value.toString()) || 'Required'
    };
    valid = true;

    form = this.initializeForm();

    returnReasonsBeschau: string[] = ['Customs inspection documents', 'Customs inspection parcel'];

    get displayTitle() {
        return !this.title ? Actions.CUSTOMS_INSPECTION : this.title;
    }

    @Watch('value')
    onValueChanged(newValue: boolean, oldVal: boolean) {
        if(newValue == false) {
            this.form = this.initializeForm();
            (this.$refs.form as Vue & { resetValidation: () => boolean}).resetValidation();
        }
    }

    initializeForm(): SetInspectionReasonForm {
        return {
            reason: '',
        }
    }
}