import { Component, Vue, Watch } from 'vue-property-decorator';
import { ToolbarItem } from '../models/ToolbarItem';
import draggable from 'vuedraggable'
import { WarehouseViewModel } from '../api/viewmodels/WarehouseViewModel';
import { WarehouseLocationViewModel } from '../api/viewmodels/WarehouseLocationViewModel';
import * as WarehouseLocationController from '../api/WarehouseLocationController';

@Component({
    components: {
        draggable
    }
})
export default class WarehouseLocations extends Vue {
    snack = false;
    snackColor = '';
    snackText = '';

    errorSnackbar = false;
    loading = false;

    warehouses: WarehouseViewModel[] = [];

    created() {
        var toolbarButtons: ToolbarItem[] = [
            {
                callback: () => this.refreshData(),
                icon: 'mdi-refresh',
                tooltipText: 'Refresh overview'
            }
        ];
        this.$emit('PageInfoReceived', 'Warehouse locations', toolbarButtons);

        this.refreshData();
    }

    addWarehouse() {
        var newWarehouse: WarehouseViewModel = {
            id: 0,
            name: 'Warehouse ' + (this.warehouses.length + 1),
            warehouseLocations: []
        }

        this.warehouses.push(newWarehouse);
    }

    saveWarehouse(warehouse: WarehouseViewModel) {
        this.loading = true;
        WarehouseLocationController.saveWarehouse(warehouse)
            .then(response => {
                this.snack = true;
                this.snackColor = 'success';
                this.snackText = 'Data saved';
                this.refreshData();
            })
            .catch(error => {
                this.errorSnackbar = true;
                this.loading = false;
            });
    }

    deleteWarehouse(warehouse: WarehouseViewModel) {
        this.loading = true;
        WarehouseLocationController.deleteWarehouse(warehouse.id)
            .then(response => {
                this.snack = true;
                this.snackColor = 'success';
                this.snackText = 'Entity removed';
                this.refreshData();
            })
            .catch(error => {
                this.errorSnackbar = true;
                this.loading = false;
            });
    }

    addLocation(warehouse: WarehouseViewModel) {
        var newLocation: WarehouseLocationViewModel = {
            id: 0,
            warehouseId: warehouse.id,
            name: 'Location ' + (warehouse.warehouseLocations.length + 1)
        }
        warehouse.warehouseLocations.push(newLocation);
    }

    deleteLocation(warehouse: WarehouseViewModel, location: WarehouseLocationViewModel) {
        var arrWarehouse = this.warehouses.find((value) => value.id === warehouse.id);
        if (arrWarehouse) {
            var existingLocationId = arrWarehouse.warehouseLocations.findIndex((value) => value.id === location.id);
            if (existingLocationId !== -1) {
                arrWarehouse.warehouseLocations.splice(existingLocationId, 1);
            }
        }
    }

    refreshData() {
        this.loading = true;
        WarehouseLocationController.getWarehouses()
            .then(response => {
                this.warehouses = response.data;
            })
            .catch(error => {
                this.errorSnackbar = true;
            })
            .finally(() => {
                this.loading = false;
            });
    }
}