import ToolbarMixin from '@/mixins/ToolbarMixin';
import { CustomsDiscrepanciesApi, DamagedShipment } from '@/openapi';
import { Component, Prop, Watch, Mixins } from 'vue-property-decorator';

const api = new CustomsDiscrepanciesApi(undefined, '');

@Component({})
export default class CustomsDiscrepanciesDamaged extends Mixins(ToolbarMixin) {
    @Prop({ default: '' })
    color!: string;
    isLoading = false;
    dates= null as string[] | null;
    showDatepicker = false;

    options: any = {
      page: 1,
      itemsPerPage: 5,
      sortBy: [],
      sortDesc: [],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: true,
    };
    footerOptions: any = {
        showFirstLastPage: true,
        itemsPerPageOptions: [1, 5, 25, 50, 100],
        disablePagination: false,
    };

    items: DamagedShipment[] = [];
    totalAmountOfItems = 0;

    headers = [
        {
            text: 'HAWB',
            value: 'hawbNumber',
            align: 'center',
            sortable: false,
        },
        {
            text: 'parcelNumber',
            value: 'parcelNumber',
            align: 'center',
            sortable: false,
        },
        {
            text: '',
            value: '',
            align: 'end',
            sortable: false,
        },
    ];

    @Watch('options')
    onIncompleteOptionsChanged(newVal: any, oldVal: any) {
        this.getDamagedShipments(this.options.page);
    }

    async created() {
        this.addRefreshToolbarOption(this.reloadDamagedShipments);
    }

    async getDamagedShipments(page: number) {
        let fromDate;
        let toDate;

        if(this.dates?.length === 2) {
            fromDate = this.dates[0];
            toDate = this.dates[1];
        }

        this.isLoading = true;
        try {
            const response = await api.getDamagedShipments(fromDate, toDate, page, this.options.itemsPerPage)
            this.items = response.data.items ?? [];
            this.totalAmountOfItems = response.data.totalAmountOfItems ?? 0;
        }
        catch {
            this.displayError('Something went wrong while retrieving the damaged shipments');
        }
        this.isLoading = false;
    }

    async reloadDamagedShipments() {
        this.options.page == 1
            ? await this.getDamagedShipments(this.options.page)
            : (this.options.page = 1);
    }

    onFilterSelected() {
        this.reloadDamagedShipments();
    }

    get dateDisplayText() {
        return this.dates?.join(' - ');
    }

    displayError(errorMessage: string) {
        this.$emit('errorOccured', errorMessage);
    }
}
